import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FirmService } from '../services/firm.service';
import { RouteService } from '../services/route.service';
import { config } from '../../config';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.css'],
})
export class NotfoundComponent {
  firm: any;
  own_domain: boolean;
  firm_name: string;
  language: string;

  constructor(
    route: ActivatedRoute,
    public routeService: RouteService,
    public firmService: FirmService,
    private languageService: LanguageService
  ) {
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
    this.language ? this.languageService.setLanguage(this.language) : this.languageService.setLanguage('en');
  }

  ngOnInit() {
    if (this.firm_name) {
      this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
        this.firm = data.firm;
        this.own_domain = data.own_domain;
      });
    }
  }

  navigate() {
    if (this.firm) {
      this.routeService.navigateToMenu(this.own_domain, this.firm, this.language);
    } else {
      config.STORE_IDS.forEach((id) => {
        if (id.domain == location.hostname) {
          window.location.href = `https://${id.domain}`;
        }
      });
    }
  }
}
