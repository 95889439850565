import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { LanguageService } from './language.service';
import { ErrorService } from './error.service';
import { StoreService } from './store.service';
import { config } from '../../config';
import { DialogsService } from './dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';


@Injectable()
export class CustomerService {

  login: any;
  token: any;

  endpoint_customer = "/customer";
  endpoint_checkEmail = "/customer/checkemail";
  endpoint_checkActivation = "/checkactivation";
  endpoint_linkFirm = "/checkfirm";
  endpoint_SM_login = "/customer/login/";
  endpoint_check_SM_token = "/customer/check_login_sm";
  endpoint_login = "/customer/login";
  endpoint_reset_pw = "/customer/resetpassword";
  endpoint_login_guest = "/customer_guest";

  constructor(private http: HttpClient,
              private auth: AuthService,
              public languageService: LanguageService,
              public errorService: ErrorService,
              public storeService: StoreService,
              private dialogsService: DialogsService,
              private translate: TranslateService) {}

  async get(firm, cust_id, lang_code, distributor, own_domain) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web',
                'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
              }
          )
        }

        this.http.get(environment.API_URL + this.endpoint_customer + "/" + cust_id, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      this.saveLogin(data.data);
                      resolve(data.data);
                    },
                    async err => {
                      let check = await this.errorService.checkCustomerErrors(err.status, await this.parseError(err), distributor, firm, own_domain, lang_code);

                      if(check == 'TOKEN_INVALID') {
                          this.get(firm, cust_id, lang_code, distributor, own_domain);
                      }
                    });
      })
    }
  }

  async add(firm, user, lang_code, distributor, own_domain) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.post(environment.API_URL + this.endpoint_customer, user, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.customerToken));
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkCustomerErrors(err.status, await this.parseError(err), distributor, firm, own_domain, lang_code);

                      if(check == 'TOKEN_INVALID') {
                          this.add(firm, user, lang_code, distributor, own_domain);
                      }
                    });
      })
    }
  }

  async update(firm, cust_id, user, lang_code, distributor, own_domain) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web',
                'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
              }
          )
        }

        this.http.put(environment.API_URL + this.endpoint_customer + "/" + cust_id, user, httpOptions)
                  .subscribe(
                    (data: any) => {
                      user.id = data.id;
                      this.saveLogin(user);
                      resolve(data);
                    },
                    async err => {
                      let check = await this.errorService.checkCustomerErrors(err.status, await this.parseError(err), distributor, firm, own_domain, lang_code);

                      if(check == 'TOKEN_INVALID') {
                          this.update(firm, cust_id, user, lang_code, distributor, own_domain);
                      }
                    });
      })
    }
  }

  async linkFirm(firm, cust_id, lang_code, distributor, own_domain) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.get(environment.API_URL + this.endpoint_customer + '/' + cust_id + this.endpoint_linkFirm , httpOptions)
                  .subscribe(
                    data => {
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkCustomerErrors(err.status, await this.parseError(err), distributor, firm, own_domain, lang_code);

                      if(check == 'TOKEN_INVALID') {
                        this.linkFirm(firm, cust_id, lang_code, distributor, own_domain);
                      }
                    });
      })
    }
  }

  async checkEmail(firm_id, email: string, lang_code) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm_id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm_id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        let body = {
          email: email
        }

        this.http.post(environment.API_URL + this.endpoint_checkEmail, body , httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.customerToken));
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkEmailErrors(err.status, await this.parseError(err));

                      if(check == 'CUSTOMER_NOT_FOUND') {
                        resolve(err);
                      }
                      else if(check == 'TOKEN_INVALID') {
                        this.checkEmail(firm_id, email, lang_code);
                      }
                    });
      })
    }
  }

  async checkActivation(firm_id, cust_id, lang_code) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm_id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm_id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.get(environment.API_URL + this.endpoint_customer + "/" + cust_id + this.endpoint_checkActivation, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.customerToken));
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkEmailErrors(err.status, await this.parseError(err));

                      if(check == 'CUSTOMER_NOT_ACTIVATED' || check == 'CUSTOMER_NOT_FOUND') {
                        resolve(err);
                      }
                      else if(check == 'TOKEN_INVALID') {
                          this.checkActivation(firm_id, cust_id, lang_code);
                      }
                    });
      })
    }
  }

  async initiateSocialLogin(firm_id, lang_code, social_media) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm_id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm_id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.post(environment.API_URL + this.endpoint_SM_login + social_media, '', httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('socialToken', data.sessionToken);
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkEmailErrors(err.status, await this.parseError(err));

                      if(check === 'CUSTOMER_NOT_ACTIVATED' || check === 'CUSTOMER_NOT_FOUND' || check === 'SESSION_TOKEN_INVALID') {
                        resolve(err);
                      }
                      else if(check == 'TOKEN_INVALID') {
                          this.initiateSocialLogin(firm_id, lang_code, social_media);
                      }
                    });
      })
    }
  }

  async checkSocialToken(firm_id, lang_code) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm_id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm_id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        let body = {
          session_token: await localStorage.getItem('socialToken')
        }

        this.http.post(environment.API_URL + this.endpoint_check_SM_token, body, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.customerToken));
                      resolve(data)
                    },
                    async err => {
                      let check = await this.errorService.checkEmailErrors(err.status, await this.parseError(err));

                      if(check === 'CUSTOMER_NOT_ACTIVATED' || check === 'CUSTOMER_NOT_FOUND' || check === 'SESSION_TOKEN_INVALID') {
                        resolve(err);
                      }
                      else if(check == 'TOKEN_INVALID') {
                          this.checkSocialToken(firm_id, lang_code);
                      }
                    });
      })
    }
  }

  async loginWithPassword(username, password, firm, lang_code, own_domain) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.post(environment.API_URL + this.endpoint_login, { client_id: 'app', username: username, password: password }, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.data.customerToken));
                      await this.linkFirm(firm, data.data.id, lang_code, firm.distributor, own_domain);
                      let user: any = await this.get(firm, data.data.id, lang_code, firm.distributor, own_domain);
                      this.saveLogin(user);
                      resolve(user);
                    },
                    async err => {
                      if(!err.error.success) {
                        this.dialogsService.openError("", err.error.message, true);
                      }
                    });
      })
    }
  }

  async loginAsGuest(firm, lang_code) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              {
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.get(environment.API_URL + this.endpoint_login_guest, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      await localStorage.setItem('customerToken', JSON.stringify(data.customerToken));
                      resolve(data);
                    },
                    async err => {
                      if(!err.error.success) {
                        this.dialogsService.openError("", err.error.message, true);
                      }
                    });
      })
    }
  }

  async resetPassword(email, firm, lang_code) {
    if(email) {
      if(this.errorService.checkConnection()) {
        return new Promise(async (resolve) => {

          this.token = await localStorage.getItem('token');
          if(!this.token) {
              this.token = await this.auth.getToken();
          }

          let httpOptions = {
            headers: new HttpHeaders(
                {
                  'storeId': await this.storeService.getStoreId(),
                  'passwordToken': await this.checkToken(firm.id),
                  'demoPasswordToken': await this.getDemoToken(),
                  'firmId': String(firm.id),
                  'language': String(lang_code),
                  'Accept': 'application/json',
                  'Authorization': "Bearer " + await localStorage.getItem('token'),
                  'appVersion': config.VERSION,
                  'apiVersion': config.VERSION,
                  'deviceOS': 'Web',
                  'deviceOSVersion': 'Web',
                  'deviceModel': 'Web'
                }
            )
          }

          this.http.post(environment.API_URL + this.endpoint_reset_pw, { email: email }, httpOptions)
            .subscribe(
              async (data: any) => {
                this.dialogsService.openError("", this.translate.instant('PASSWORD_RESET_LINK') + email + '.', true);
                resolve(data);
              },
              async err => {
                if(!err.error.success) {
                  this.dialogsService.openError("", err.error.message, true);
                }
              });
        })
      }
    }
    else {
      this.dialogsService.openError("", this.translate.instant('NO_EMAIL'), true);
    }
  }

  async getAllCustomerAddresses(custId, custUuid, firmId, langCode) {
    if (this.errorService.checkConnection()) {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          appVersion: config.VERSION,
          apiVersion: config.VERSION,
          deviceOS: 'Web',
          deviceOSVersion: 'Web',
          deviceModel: 'Web',
          storeId: await this.storeService.getStoreId(),
          passwordToken: await this.checkToken(firmId),
          demoPasswordToken: await this.getDemoToken(),
          firmId: String(firmId),
          language: String(langCode),
          customerToken: JSON.parse(localStorage.getItem('customerToken')),
          customerId: String(custId),
        }),
      };

      return this.http
        .get(`${environment.API_URL}/customers/${custUuid}/addresses`, httpOptions)
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          this.dialogsService.openError('', err.error.message, true);
        });
    }
  }

  async updateCustomerAddress(custId, custUuid, address, firmId, langCode) {
    if (this.errorService.checkConnection()) {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          appVersion: config.VERSION,
          apiVersion: config.VERSION,
          deviceOS: 'Web',
          deviceOSVersion: 'Web',
          deviceModel: 'Web',
          storeId: await this.storeService.getStoreId(),
          passwordToken: await this.checkToken(firmId),
          demoPasswordToken: await this.getDemoToken(),
          firmId: String(firmId),
          language: String(langCode),
          customerToken: JSON.parse(localStorage.getItem('customerToken')),
          customerId: String(custId),
        }),
      };

      return this.http
        .put(
          `${environment.API_URL}/customers/${custUuid}/addresses/${address.id}`,
          {
            is_default: address.is_default,
            description: address.description,
            street: address.street,
            house_number: address.house_number,
            bus: address.bus,
            zip_code: address.zip_code,
            locality: address.locality,
            country_id: address.country?.id,
          },
          httpOptions
        )
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          if (err.error.code === 'VALIDATION_ERROR') {
            const errorMessage = this.errorService.formatErrors(err.error.errors);
            this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errorMessage, false);
          } else {
            this.dialogsService.openError('', err.error.message, true);
          }
        });
    }
  }

  async addCustomerAddress(custId, custUuid, address, firmId, langCode) {
    if (this.errorService.checkConnection()) {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          appVersion: config.VERSION,
          apiVersion: config.VERSION,
          deviceOS: 'Web',
          deviceOSVersion: 'Web',
          deviceModel: 'Web',
          storeId: await this.storeService.getStoreId(),
          passwordToken: await this.checkToken(firmId),
          demoPasswordToken: await this.getDemoToken(),
          firmId: String(firmId),
          language: String(langCode),
          customerToken: JSON.parse(localStorage.getItem('customerToken')),
          customerId: String(custId),
        }),
      };

      return this.http
        .post(
          `${environment.API_URL}/customers/${custUuid}/addresses`,
          {
            is_default: address.is_default,
            description: address.description,
            street: address.street,
            house_number: address.house_number,
            bus: address.bus,
            zip_code: address.zip_code,
            locality: address.locality,
            country_id: address.country?.id,
          },
          httpOptions
        )
        .toPromise()
        .then((data: any) => {
          return data.data;
        })
        .catch((err) => {
          if (err.error.code === 'VALIDATION_ERROR') {
            const errorMessage = this.errorService.formatErrors(err.error.errors);
            this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errorMessage, false);
          } else {
            this.dialogsService.openError('', err.error.message, true);
          }
        });
    }
  }

  async updateBusinessDetails(custId, custUuid, businessDetails, firmId, langCode) {
    if (this.errorService.checkConnection()) {
      const httpOptions = {
        headers: new HttpHeaders({
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          appVersion: config.VERSION,
          apiVersion: config.VERSION,
          deviceOS: 'Web',
          deviceOSVersion: 'Web',
          deviceModel: 'Web',
          storeId: await this.storeService.getStoreId(),
          passwordToken: await this.checkToken(firmId),
          demoPasswordToken: await this.getDemoToken(),
          firmId: String(firmId),
          language: String(langCode),
          customerToken: JSON.parse(localStorage.getItem('customerToken')),
          customerId: String(custId),
        }),
      };

      return this.http
        .put(`${environment.API_URL}/customer/${custUuid}/vat`, businessDetails, httpOptions)
        .toPromise()
        .then((data: any) => {
          return data;
        })
        .catch((err) => {
          if (err.error.code === 'VALIDATION_ERROR') {
            const errorMessage = this.errorService.formatErrors(err.error.errors);
            this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errorMessage, false);
          } else {
            this.dialogsService.openError('', err.error.message, true);
          }
        });
    }
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    }
    catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    let tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    var passwordToken = [];

    if(tokens != null) {
      tokens.forEach(token => {
        if(token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    var demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if(demoToken == null) {
      demoToken = "";
    }

    return demoToken;
  }

  async getCustomer(firm, language, own_domain) {
    let login: any = await JSON.parse(localStorage.getItem('login'));
      if(login) {
        if(login.activated) {
          await this.linkFirm(firm, login.id, language, firm.distributor, own_domain);
          await this.get(firm, login.id, language, firm.distributor, own_domain);
        }
      }
  }

  async saveLogin(user) {
    const login: any = {
      activated: true,
      id: user.id,
      uuid: user.uuid,
      name: `${user.first_name} ${user.last_name}`,
      firstName: user.first_name,
    };
    if(user.loyalty) login.loyaltyPoints = user.loyalty.current_points;
    this.login = login;
    localStorage.setItem('login', JSON.stringify(login));
    await this.setLocalCustomer();
  }

  getLogin() {
    return JSON.parse(localStorage.getItem('login'));
  }

  logout() {
    localStorage.setItem('uuid', undefined);
    localStorage.removeItem('login');
    localStorage.setItem('customerToken', undefined);
    this.login = undefined;
  }

  async setLocalCustomer() {
    let response = localStorage.getItem('login');
    if(response === null || response === undefined || response === 'undefined') {
      this.login == response;
    }
    else {
      this.login = JSON.parse(response);
    }
  }

  userHasBusinessDetails(user) {
    return user?.company && user?.vat_number;
  }
}
