import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { BasketItemsService } from '../services/basket-items.service';
import { CustomerService } from '../services/customer.service';
import { DialogsService } from '../services/dialogs.service';
import { FirmService } from '../services/firm.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import { LoaderService } from '../services/loader.service';
import { OrderService } from '../services/order.service';
import { Units } from '../constants/units';

@Component({
  selector: 'app-basket',
  templateUrl: './basket.component.html',
  styleUrls: ['./basket.component.css'],
})
export class BasketComponent implements OnInit {
  own_domain: boolean;
  firm: any;
  firm_name: any;
  cat_id: any;
  language: any;
  basketItems: any;
  total_price: any;
  result: any;
  login: any;
  cart_info: any;
  basketAmount: number;

  units = Units;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public routeService: RouteService,
    public auth: AuthService,
    public basketItemsService: BasketItemsService,
    public firmService: FirmService,
    public slug: SlugifyService,
    public dialogsService: DialogsService,
    public translate: TranslateService,
    public customerService: CustomerService,
    public loader: LoaderService,
    public orderService: OrderService
  ) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.cat_id = route.snapshot.params.cat_id;
    this.language = this.route.snapshot.params.language;
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;

      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  async initData() {
    if (this.firmService.checkWebsite(this.firm, this.language) && this.firmService.checkForPassword(this.firm)) {
      const response = localStorage.getItem('login');

      if (response === null || response === undefined || response === 'undefined') {
        this.login == response;
      } else {
        this.login = JSON.parse(response);
      }

      this.basketItems = await this.basketItemsService.get(this.firm.id);

      this.calculateTotalPrice();

      this.customerService.getCustomer(this.firm, this.language, this.own_domain);

      // Check for extra cart info
      if (this.firm.info && this.firm.info.length > 0) {
        this.cart_info = await this.firm.info.find((info) => info.content_type === 'cart_info');
      }
    }
  }

  async calculateTotalPrice() {
    if (!this.firm.uses_vat) {
      this.total_price = await this.basketItemsService.calculatePrice(this.firm.id);
    } else {
      this.calculateVatPrice();
    }
  }

  async calculateVatPrice() {
    this.total_price = 0;

    this.basketItems.forEach(async (item) => {
      const init_item = await JSON.parse(item.item.init_item);
      let price = init_item.price + (init_item.price / 100) * Number(item.item.item.vat_percentage);
      price = Number(price.toFixed(2));

      await item.item.ingredients.forEach((ingr) => {
        if (ingr.price > 0) {
          const VATi = ingr.price + (ingr.price / 100) * item.item.item.vat_percentage;
          price = price + Number(VATi.toFixed(2));
          price = Number(price.toFixed(2));
        }
      });

      await item.item.options_selected.forEach((opt) => {
        const VATi = opt.selected_option.price + (opt.selected_option.price / 100) * item.item.item.vat_percentage;
        price = price + Number(VATi.toFixed(2));
        price = Number(price.toFixed(2));
      });

      if (item.item.unit_id !== Units.KG) {
        price = price * item.item.amount;
      } else if (item.item.unit_id === Units.KG) {
        price = price * (item.item.amount / 1000);
      }

      item.item.price_vat = price;
      this.total_price = this.total_price + item.item.price_vat;
    });
  }

  /* Empty the basket */
  emptyBasket() {
    this.basketItems = [];
    this.basketItemsService.removeAll(this.firm.id);
  }

  /* Edit item from basket */
  editItem(item) {
    const index = this.getIndex(item);

    this.dialogsService
      .openEditModal(JSON.stringify(item), this.firm, this.cat_id, index, this.language)
      .subscribe((res) => {
        this.initData();
      });
  }

  /* Remove item from basket */
  removeItem(item: any) {
    const index = this.getIndex(item);

    // remove item, check amount of items in basket & change total price
    if (index > -1) {
      this.basketItems.splice(index, 1);
      this.basketItemsService.remove(this.basketItems, this.firm.id);
      this.total_price = this.total_price - item.item.total_price;
    }
  }

  /* Get index of basket item */
  getIndex(item: any) {
    return this.basketItems.indexOf(item);
  }

  signin() {
    this.routeService.redirectTo = 'checkout';
    this.routeService.navigateToProfile(this.own_domain, this.firm, this.language);
  }
}
