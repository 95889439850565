<head>
  <title>{{ firm?.name }}</title>
</head>
<body>

<!-- Menu section -->
<div class="main" *ngIf="menu_section">

  <!-- Header -->
  <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
  <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

  <!-- Menu Content -->
  <div
    class="pagecontent"
    style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
    [style.color]="firm?.theme.main_gradient_contrast_color">
    <div class="view-homepage">

      <!-- Vat delivery method -->
      <app-vat-delivery-method-opener *ngIf="firm" [firm]="firm"
                                      (vatChanged)="vatDeliveryMethodChanged($event)"></app-vat-delivery-method-opener>

      <!-- Menu -->
      <div class="centered" id="products">

        <div>

          <!-- Featured products -->
          <app-featured-products *ngIf="products" [products]="products" [own_domain]="own_domain" [firm]="firm" [language]="language"></app-featured-products>

          <!-- Title -->
          <div class="title" *ngIf="firm?.website_details.template.reference !== 'list'">
            <h1 class="title-font">{{ firm?.labels?.homepage_title ? firm?.labels?.homepage_title : ('MENU.OUR_SELECTION' | translate) }}</h1>
          </div>

          <div class="clearfix">

            <!-- Shop notification -->
            <div *ngIf="firm?.notification != null" class="categories row header-msg"
                 [style.background-color]="firm?.notification.bg_color">
              <p [style.color]="firm?.notification.text_color"
                 style="white-space: pre-wrap">{{ firm?.notification.message }}</p>
            </div>

            <div
              *ngIf="!firm?.uses_vat || (firm?.uses_vat && vat_delivery_method) || (firm?.uses_vat && firm?.vat_settings.allowed_vat_percentages.length == 1)">

              <ng-container *ngIf="products?.length > 0; else noResults">
                <!-- Default template -->
                <ul class="categories row" *ngIf="firm?.website_details.template.reference == 'default'">

                  <!-- Parent category not available -->
                  <div *ngIf="firm?.only_show_available_parent_categories && parent_category && !parent_category?.isAvailable" class="unavailable">
                    <p><span>{{ parent_category.name }}</span> {{ 'CAT_NOT_AVAILABLE_ATM' | translate }}</p>
                    <p *ngIf="parent_category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: parent_category.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: parent_category.firstAvailableDate?.from_time} }}</p>
                  </div>

                  <li class="col third no-single" *ngFor="let cat of products">
                    <eo-menu-card
                      [title]="cat.name"
                      [image]="cat.images | mainThumb"
                      (clicked)="menuCardClicked(cat)">
                    </eo-menu-card>
                  </li>
                </ul>

                <!-- List template -->
                <div *ngIf="firm?.website_details.template.reference == 'list'">
                  <div class="list float-left">

                    <!-- All categories & products -->
                    <div *ngFor="let category of products" class="category" [id]="category.name">

                      <!-- Category banner -->
                      <div class="category--header" [style.background-color]="firm?.theme.container_color" [style.color]="firm?.theme.container_contrast_color">
                        <div class="detail-image" [style.background-color]="firm?.theme.well_color">
                          <img *ngIf="(category.images | mainDetail) as detailImage" [src]="detailImage" alt="">
                        </div>
                        <div class="details">
                          <h3>{{ category.name }}</h3>
                          <p class="font-12" *ngIf="category.description">{{ category.description }}</p>
                        </div>
                      </div>

                      <!-- Category banner (mobile) -->
                      <div class="category--header-mobile">
                        <div class="details">
                          <h3>{{ category.name }}</h3>
                          <p class="font-12" *ngIf="category.description">{{ category.description }}</p>
                        </div>
                      </div>

                      <!-- Parent category not available -->
                      <div *ngIf="firm?.only_show_available_parent_categories && ((parent_category && !parent_category?.isAvailable) || !category.isAvailable)" class="unavailable">
                        <p><span>{{ parent_category ? parent_category.name : category.name }}</span> {{ 'CAT_NOT_AVAILABLE_ATM' | translate }}</p>
                        <p *ngIf="parent_category && parent_category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: parent_category.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: parent_category.firstAvailableDate?.from_time} }}</p>
                        <p *ngIf="!parent_category && category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: category.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: category.firstAvailableDate?.from_time} }}</p>
                      </div>

                      <!-- Products -->
                      <div class="products" *ngIf="category.products.length > 0">
                        <div class="product-container" *ngFor="let item of category.products">
                          <app-list-item
                            [vat_delivery_method]="vat_delivery_method"
                            [item]="item"
                            [firm]="firm"
                            [own_domain]="own_domain"
                            [language]="language"
                            [isAvailable]="category?.isAvailable">
                          </app-list-item>
                        </div>
                      </div>

                      <!-- Subcategories -->
                      <div class="subcategories" *ngIf="category.subcategories.length > 0">
                        <div class="subcategory" *ngFor="let subcat of category.subcategories">
                          <p class="title">{{ subcat.name }}
                          <p>
                          <div class="products" *ngIf="subcat.products.length > 0">
                            <div class="product-container" *ngFor="let item of subcat.products">
                              <app-list-item [vat_delivery_method]="vat_delivery_method" [item]="item" [firm]="firm"
                                             [own_domain]="own_domain" [language]="language"
                                             [isAvailable]="category?.isAvailable">
                              </app-list-item>
                            </div>
                          </div>
                        </div>
                      </div>

                    </div>
                  </div>

                </div>

                <!-- Mixed template -->
                <div class="list float-left" *ngIf="firm?.website_details.template.reference == 'list-full-width'">

                  <!-- Category list -->
                  <div *ngFor="let category of products" class="category" style="margin-bottom: 20px"
                       [id]="category.name">

                    <!-- Category banner -->
                    <a class="whole_width" href="javascript:void(0)"
                       (click)="category_name == category.name ? setCategoryName(undefined) : setCategoryName(category.name)">
                      <div *ngIf="(category.images | mainDetail) as mainImage" class="category-img" [style.background-image]="'url('+ mainImage +')'">
                        <p class="title">{{ category.name }}</p>
                      </div>
                    </a>

                    <div *ngIf="category_name == category.name">

                      <!-- Parent category not available -->
                      <div *ngIf="firm?.only_show_available_parent_categories && ((parent_category && !parent_category?.isAvailable) || !category.isAvailable)" class="unavailable">
                        <p><span>{{ parent_category ? parent_category.name : category.name }}</span> {{ 'CAT_NOT_AVAILABLE_ATM' | translate }}</p>
                        <p *ngIf="parent_category && parent_category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: parent_category.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: parent_category.firstAvailableDate?.from_time} }}</p>
                        <p *ngIf="!parent_category && category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: category.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: category.firstAvailableDate?.from_time} }}</p>
                      </div>

                      <!-- Products -->
                      <div class="products" *ngIf="category.products.length > 0">
                        <div *ngFor="let item of category.products">
                          <app-list-item [vat_delivery_method]="vat_delivery_method" [item]="item" [firm]="firm"
                                         [own_domain]="own_domain" [language]="language"
                                         [isAvailable]="category?.isAvailable"></app-list-item>
                        </div>
                      </div>

                      <!-- Subcategories -->
                      <div class="subcategories" *ngIf="category.subcategories.length > 0">
                        <div class="subcategory" *ngFor="let subcat of category.subcategories">
                          <p class="title">{{ subcat.name }}
                          <p>
                          <div class="products" *ngIf="subcat.products.length > 0">
                            <div *ngFor="let item of subcat.products">
                              <app-list-item [vat_delivery_method]="vat_delivery_method" [item]="item" [firm]="firm"
                                             [own_domain]="own_domain" [language]="language"
                                             [isAvailable]="category?.isAvailable"></app-list-item>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </ng-container>

              <ng-template #noResults>
                {{ 'NO_RESULTS' | translate }}
              </ng-template>
            </div>

          </div>

        </div>
        <!-- Firm information (sidebar) -->
        <div
          class="side-info"
          style="position: sticky; top: 2rem;"
          [style.color]="firm?.theme.theme_color"
          [style.background-color]="firm?.theme.theme_color_contrast_color">
          <div class="shop-contact">
            <a class="tel" href="tel:{{ firm?.phone }}" *ngIf="firm?.phone">
              <i class="fas fa-phone icon"></i>
             <span> {{ firm?.phone }}</span>
            </a>
            <eo-email-label [email]="firm?.email" [hide]="firm?.hide_email"></eo-email-label>
            <a class="location"
               href="https://www.google.be/maps/place/{{ firm?.address }}+{{ firm?.locality }}+{{ firm?.country.name }}"
               target="_blank">
              <i class="fas fa-location-arrow icon"></i>
              <span class="address">{{ firm?.address }}
                <br/>{{ firm?.zipcode }} {{ firm?.locality }}
                <br/>{{ firm?.country.name }}
              </span>
            </a>
          </div>

          <a href="javascript:void(0)"
             (click)="routeService.navigateToInfo(own_domain, firm, language)"
             class="btn title-font"
          >{{ 'MENU.MORE_INFO' | translate }}</a>
        </div>

      </div>
    </div>

    <!-- Download our app -->
    <div>
      <div class="order-now" *ngIf="!firmService.isHawaiian()">
        <div class="centered">

          <div class="content">
            <div class="text">
              <h2>{{ 'DOWNLOAD.ORDER_APP' | translate }}</h2>
              <p>{{ 'DOWNLOAD.DOWNLOAD_APP' | translate }}</p>
              <div class="download-buttons">
                <a [href]="firm?.android_app_url" target="_blank"><img src="../../assets/img/download_android.png" alt="Download Android App"></a>
                <a [href]="firm?.ios_app_url" target="_blank"><img src="../../assets/img/download_ios.png" alt="Download iOS App"></a>
              </div>
            </div>
            <div class="phone">
              <img src="../../assets/img/iphone6.png" alt="">
              <div class="screen">
                <img *ngIf="firm?.website_details" [src]="firm?.website_details.app_preview_image" alt="">
              </div>
            </div>

          </div>

        </div>
      </div>

      <!-- Firm info -->
      <app-firm-info [firm]="firm"></app-firm-info>

    </div>

  </div>

  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

</div>

<!-- Multifirm section -->
<div *ngIf="multifirm_section">

  <!-- Header (minimal) -->
  <div *ngIf="firmService.checkEmbed() == false" class="multifirm_header"
       [style.background-image]="'url('+ distributor?.header_logo +')'"></div>

  <!-- All firms of the multifirm -->
  <div class="pagecontent" style="margin-top: 50px">
    <div class="view-seoindex">
      <div class="centered">

        <ul class="row">

          <li class="col half" *ngFor="let firm of firms">
            <a (click)="routeService.navigateToMultiFirm(firm.website_details.sitename)">
                                <span class="header">
                                <img *ngIf="firm.logo_fullsize != null" [src]="firm.logo_fullsize" alt=""/>
                            </span>

              <span class="item">
                                <h2>{{ firm.name }}</h2>

                                <span class="address">
                                        {{ firm.address }}<br/>
                                  {{ firm.zipcode }} {{ firm.locality }}<br/>
                                  {{ firm.phone }}<br/>
                                </span>

                                <span class="link">{{ 'FIRMS.VISIT_SITE' | translate }}</span>
                            </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [own_domain]="own_domain" [language]="language"></app-footer>

</div>

<div id="iosfix-top"></div>
<div id="iosfix-btm"></div>

</body>
