import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SliderComponent } from './eo-slider/slider.component';
import { TranslateModule } from '@ngx-translate/core';
import { AddressModalComponent } from './address-modal/address-modal.component';
import { FormsModule } from '@angular/forms';
import { AddressComponent } from './address/address.component';
import { RoundButtonComponent } from './round-button/round-button.component';
import { EoModalComponent } from './eo-modal/eo-modal.component';
import { BusinessDetailsModalComponent } from './business-details-modal/business-details-modal.component';
import { VatValidationBadgeComponent } from './vat-validation-badge/vat-validation-badge.component';
import { SelectInvoiceAddressModalComponent } from './select-invoice-address-modal/select-invoice-address-modal.component';
import { MatRadioModule } from '@angular/material/radio';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';

@NgModule({
  declarations: [
    SliderComponent,
    AddressComponent,
    AddressModalComponent,
    RoundButtonComponent,
    EoModalComponent,
    BusinessDetailsModalComponent,
    VatValidationBadgeComponent,
    SelectInvoiceAddressModalComponent,
  ],
  exports: [
    SliderComponent,
    AddressComponent,
    AddressModalComponent,
    RoundButtonComponent,
    EoModalComponent,
    BusinessDetailsModalComponent,
    VatValidationBadgeComponent,
    SelectInvoiceAddressModalComponent,
  ],
  imports: [CommonModule, TranslateModule, FormsModule, MatRadioModule, MatSlideToggleModule],
})
export class SharedModule {}
