import { Component, OnInit, Input } from '@angular/core';
import { FirmService } from '../services/firm.service';

@Component({
  selector: 'app-order-now-bar',
  templateUrl: './order-now-bar.component.html',
  styleUrls: ['./order-now-bar.component.css']
})
export class OrderNowBarComponent implements OnInit {

  @Input() firm;

  constructor(public firmService: FirmService) { }

  ngOnInit() {}

}
