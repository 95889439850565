<div class="iframe-ingredients" mat-dialog-content>
  <div class="quantity clearfix">

    <div class="input">

      <div class="label">
        <label for="amount">
          {{ 'MODAL.AMOUNT' | translate }} ({{ basketItem?.item.unit.name_singular}})
        </label>
      </div>

      <eo-quantity-selector>
        <eo-unit-selector 
          *ngIf="basketItem?.item.unit.id === units.UNIT || basketItem?.item.unit.id === units.LITER || basketItem?.item.unit.id === units.PERSON"
          [quantity]="quantity"
          [minQuantity]="basketItem?.item.min"
          [maxQuantity]="basketItem?.item.max"
          [maxCartQuantity]="firm?.max_cart_amount"
          [basketAmount]="basketAmount"
          [amountAlreadyInCart]="amountAlreadyInCart"
          (quantityChanged)="quantityChanged($event)">
        </eo-unit-selector>
        <eo-weight-selector 
          *ngIf="basketItem?.item.unit.id === units.GRAM || basketItem?.item.unit.id === units.KG"
          [quantity]="quantity"
          [minQuantity]="basketItem?.item?.min"
          [maxQuantity]="basketItem?.item?.max"
          [interval]="basketItem?.item?.unit_interval"
          [unitId]="basketItem?.item?.unit.id"
          (quantityChanged)="quantityChanged($event)">
        </eo-weight-selector>
      </eo-quantity-selector>

    </div>

    <div class="price" *ngIf="basketItem?.item.unit.id !== units.KG">
      {{ firm?.currency?.symbol }}{{ unit_price?.toFixed(2) * quantity | number:'1.2-2' }}
    </div>
    <div class="price" *ngIf="basketItem?.item.unit.id === units.KG">
      {{ firm?.currency?.symbol }}{{ unit_price?.toFixed(2) * (quantity/1000) | number:'1.2-2' }}
    </div>

  </div>

  <div *ngIf="sequenceGroup?.length > 0">
    <div *ngFor="let group of sequenceGroup">
      
      <!-- Ingredients -->
      <div *ngIf="group.type === 'ingredient_group'">
        <div>
          <label>{{ getGroup(group).name }}
            <mat-icon *ngIf="getGroup(group).description" [matTooltip]="getGroup(group).description"><i class="fas fa-question-circle option-description"></i></mat-icon>
          </label>
          <p *ngIf="group.min_selection > 0">{{'MINIMUM' | translate}}: {{group.min_selection}}</p>
          <p *ngIf="group.max_selection > 0">{{'MAXIMUM' | translate}}: {{group.max_selection}}</p>

          <div *ngIf="getGroup(group).ingredients">
            <div class="ingredients" *ngIf="getGroup(group).ingredients.length != 0">
              <div class="columns">

                <!-- Main -->
                <div class="checkbox" *ngFor="let ingr of getGroup(group).ingredients">
                  <div class="input">
                    <div *ngIf="ingr.type === 'main'">
                      <mat-checkbox disabled=true disableRipple="true" [checked]="true">{{ ingr.name }}</mat-checkbox>
                    </div>
                  </div>
                </div>

                <!-- Free / Paid -->
                <div class="checkbox" *ngFor="let ingr of getGroup(group).ingredients">
                  <div class="input ingredient-container" *ngIf="ingr.type !== 'main'">

                    <mat-checkbox 
                      disableRipple="true" 
                      [disabled]="disableIngredientCheckbox(ingr, getGroup(group)) || productsService.isSnoozed(ingr) || !isInStock(ingr)" 
                      [checked]="ingr?.selected && isInStock(ingr)" 
                      (change)="checkIngredient(ingr, getGroup(group))">
                      <p>{{ ingr?.name }} 
                        <span class="snoozed" *ngIf="productsService.isSnoozed(ingr) && productsService.isDateWithinCurrentYear(ingr?.snooze_end)">({{ 'AVAILABLE_IN' | translate:{date: moment().to(ingr?.snooze_end)} }})</span>
                        <span class="snoozed" *ngIf="productsService.isSnoozed(ingr) && !productsService.isDateWithinCurrentYear(ingr?.snooze_end)">({{ 'NOT_AVAILABLE_ATM' | translate }})</span>
                        
                        <span *ngIf="ingr.price > 0 && isInStock(ingr)">
                          <span *ngIf="!firm?.uses_vat && !productsService.isSnoozed(ingr)" class="checkbox-paid">{{ firm?.currency?.symbol }}{{ ingr?.price | number:'1.2-2' }}</span>
                          <span *ngIf="firm?.uses_vat && !productsService.isSnoozed(ingr)" class="checkbox-paid">{{ firm?.currency?.symbol }}{{ ingr?.price + (ingr?.price/100 * basketItem.item.vat_percentage) | number:'1.2-2' }}</span>
                          <span *ngIf="basketItem?.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                          <span *ngIf="basketItem?.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                        </span>

                        <eo-out-of-stock-label 
                          *ngIf="!isInStock(ingr)">
                        </eo-out-of-stock-label>
                      </p>
                    </mat-checkbox>

                    <div @openSelector *ngIf="ingr.selected && ingr?.price >= 0 && (!getGroup(group).multi_max || (getGroup(group).multi_max && getGroup(group).multi_max > 1))" class="ingr-counter">
                      <a href="javascript:void(0)" (click)="subtractIngredientAmount(ingr); subtractPriceFromItem(ingr.price); this.recalculateUnitPrice(); emit()" class="btn-fill subtract ingr-btn" [ngClass]="ingr.amount <= 1 ? 'disabled' : 'enabled'">-</a>
                      <input class="ingr-input" name="amount" type="number" id="amount" [(ngModel)]="ingr.amount" disabled="true">
                      <a href="javascript:void(0)" (click)="addIngredientAmount(ingr); addPriceToItem(ingr.price); this.recalculateUnitPrice(); emit()" class="btn-fill add ingr-btn" [ngClass]="hasReachedMultiMax(ingr, getGroup(group)) || hasReachedGroupMax(getGroup(group)) ? 'disabled' : 'enabled'">+</a>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Product options -->
      <div *ngIf="group.type === 'option_group'">
        <div>
          <!-- Option group -->
          <label>{{ getGroup(group).name }}
            <mat-icon *ngIf="getGroup(group).description" [matTooltip]="getGroup(group).description"><i class="fas fa-question-circle option-description"></i></mat-icon>
          </label>

          <div class="ingredients">
            <mat-radio-group [name]="getGroup(group).name">

              <!-- Options -->
              <div class="columns">
                <div *ngFor="let option of getGroup(group).options">
                  <mat-radio-button disableRipple="true"
                                    [checked]="option.selected && !productsService.isSnoozed(option) && isInStock(option)"
                                    [value]="option.name" 
                                    [disabled]="productsService.isSnoozed(option) || !isInStock(option)"
                                    (change)="checkOptionGroup(getGroup(group), option)" ngDefaultControl>
                    <p>{{ option.name }}
                      <mat-icon *ngIf="option.description" [matTooltip]="option.description"><i class="fas fa-question-circle option-description"></i></mat-icon>
                      <span class="snoozed" *ngIf="productsService.isSnoozed(option) && productsService.isDateWithinCurrentYear(option?.snooze_end)">({{ 'AVAILABLE_IN' | translate:{date: moment().to(option?.snooze_end)} }})</span>
                      <span class="snoozed" *ngIf="productsService.isSnoozed(option) && !productsService.isDateWithinCurrentYear(option?.snooze_end)">({{ 'NOT_AVAILABLE_ATM' | translate }})</span>
                      
                      <ng-container *ngIf="isInStock(option)">
                        <span *ngIf="option.price > 0 && !firm?.uses_vat && !productsService.isSnoozed(option)" class="checkbox-paid">
                          {{ firm?.currency?.symbol }}{{ option.price | number:'1.2-2' }}
                          <span *ngIf="basketItem?.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                          <span *ngIf="basketItem?.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                        </span>
                        <span *ngIf="option.price > 0 && firm?.uses_vat && !productsService.isSnoozed(option)" class="checkbox-paid">
                          {{ firm?.currency?.symbol }}{{ option.price + (option.price / 100 * basketItem.item.vat_percentage) | number:'1.2-2' }}
                          <span *ngIf="basketItem?.item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                          <span *ngIf="basketItem?.item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                        </span>
                      </ng-container>

                      <eo-out-of-stock-label 
                        *ngIf="!isInStock(option)">
                      </eo-out-of-stock-label>
                    </p>
                  </mat-radio-button>
                </div>
              </div>

            </mat-radio-group>
          </div>
        </div>
      </div>

    </div>
  </div>

  <div class="remarks">
    <div class="input">
      <div class="inputfield">
        <textarea placeholder="{{ 'MODAL.MESSAGE' | translate }}" rows="4" name="remarks" cols="50"
                  [(ngModel)]="comment" (ngModelChange)="emit()"></textarea>
      </div>
    </div>
  </div>

</div>
