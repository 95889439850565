<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.USER_DETAILS' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="centered width-50">

      <!-- Content -->
      <div class="pagecontent">

        <div class="row">

          <h1 class="title-font">{{ 'RECENT_ORDERS.ORDER' | translate }}</h1>
          <p class="ref">#{{ order?.reference }}</p>

          <div class="qr" *ngIf="qr_code">
            <a href="javascript:void(0)" (click)="openQrCode()" class="qr-btn"><i class="fas fa-qrcode icon"></i></a>
          </div>

          <!-- User details -->
          <h2 class="title-font title">{{ 'USER_DETAILS.MY_INFO' | translate }}</h2>
          <div class="well white">
            <div *ngIf="order?.customer_name" class="name">{{ order?.customer_name }}</div>
            <div *ngIf="order?.customer_phone" class="phone">{{ order?.customer_phone }}</div>
            <div *ngIf="!order?.customer_name"
                class="name">{{ order?.customer.first_name }} {{ order?.customer.last_name }}</div>
            <div *ngIf="!order?.customer_phone" class="phone">{{ order?.customer.phone }}</div>
            <div class="company">{{ order?.customer.company }}</div>
          </div>

          <!-- Ordered products -->
          <h2 class="title-font title">{{ 'CHECKOUT_SIDEBAR.MY_ORDER' | translate }}</h2>
          <table class="products width-full">
            <tr *ngFor="let item of order?.order_details">

              <!-- Product -->
              <td class="productinfo">
                <p *ngIf="item.product">{{ item.quantity }} <small>({{ item.unit.name}})</small> {{ item.product.name }}
                </p>

                <!-- Ingredients & option groups -->
                <ul class="ingredients font-12">
                  <div *ngFor="let ingr of item.ingredients">
                    <li *ngIf="ingr.type == 'free' && ingr.action == 'rem'">- {{ ingr.name }}</li>
                  </div>
                  <div *ngFor="let ingr of item.ingredients">
                    <li *ngIf="ingr.type == 'free' && ingr.action == 'add'">+ {{ ingr.name }}</li>
                  </div>
                  <div *ngFor="let ingr of item.ingredients">
                    <li *ngIf="ingr.type == 'paid'">+ {{ ingr.name }}
                      <span *ngIf="ingr.price">{{ firm?.currency.symbol }}{{ ingr.price | number:'1.2-2' }}
                        <span *ngIf="item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                                              <span *ngIf="item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                                          </span>
                    </li>
                  </div>
                  <div *ngFor="let option of item.product_options">
                    <li>+ {{ option.product_option_group_option.name }}
                      <span *ngIf="option.product_option_group_option.price">
                                              {{ firm?.currency.symbol }}{{ option.product_option_group_option.price | number:'1.2-2' }}
                        <span *ngIf="item.unit.id === units.GRAM">/{{ 'GR' | translate }}</span>
                                              <span *ngIf="item.unit.id === units.KG">/{{ 'KG' | translate }}</span>
                                          </span>
                    </li>
                  </div>
                </ul>

                <!-- Comment -->
                <span class="comment">{{ item.comment }}</span>
              </td>

              <!-- Price -->
              <td class="price"
                  *ngIf="!firm?.uses_vat">{{ firm?.currency.symbol }}{{ item.price * item.quantity | number:'1.2-2' }}</td>
              <td class="price"
                  *ngIf="firm?.uses_vat">{{ firm?.currency.symbol }}{{ (item.price + (item.price / 100 * item.vat_percentage)) * item.quantity | number:'1.2-2' }}</td>
            </tr>

            <hr class="line" *ngIf="order?.order_service_fee.length > 0">
            <tr *ngFor="let service_fee of order?.order_service_fee">
              <td>{{ service_fee?.name  }}</td>
              <td>{{ firm?.currency?.symbol }}{{ service_fee.amount | number:'1.2-2' }}</td>
            </tr>

            <hr class="line">

            <!-- Total price of order -->
            <tr>
              <td>{{ 'BASKET.TOTAL' | translate }}:</td>
              <td>{{ firm?.currency.symbol }}{{ order?.total_price | number:'1.2-2' }}</td>
            </tr>
          </table>

          <!-- Delivery method + timestamp -->
          <h2 class="title-font title">{{ 'RECENT_ORDERS.DELIVERY_METHOD' | translate }}</h2>
          <div class="well white">

            <!-- Pickup -->
            <div *ngIf="order?.delivery_method == 'pickup'" class="delivery">
              <span class="icon pickup"></span>{{ 'OVERVIEW.PICKUP' | translate }}
              - {{ order?.process_timestamp | date:'dd/MM' }}
              <br>

              <!-- Normal -->
              <span *ngIf="!order.firm_pickup_point_id">{{ firm?.name }}</span>
              <br>
              <span *ngIf="!order.firm_pickup_point_id">{{ firm?.address }}
                , {{ firm?.zipcode }} {{ firm?.locality }}</span>

              <!-- Pickup point -->
              <span *ngIf="order.firm_pickup_point_id">{{ order?.pickup_point.name }}</span>
              <br>
              <span *ngIf="order.firm_pickup_point_id">{{ order?.pickup_point.address }}
                , {{ order?.pickup_point.zipcode }} {{ order?.pickup_point.locality }}</span>
            </div>

            <!-- Delivery -->
            <div *ngIf="order?.delivery_method == 'delivery'" class="delivery">
              <span class="icon delivery"></span>{{ 'OVERVIEW.DELIVERY' | translate }}
              - {{ order?.process_timestamp | date:'dd/MM' }}
              <br>
              <span>{{ order.delivery_street }} {{order.delivery_street_nr }}
                , {{ order.delivery_zipcode }} {{ order.delivery_locality }}</span>
            </div>

            <!-- Tabel -->
            <div *ngIf="order?.delivery_method == 'table'" class="delivery">
              <i class="fas fa-utensils icon-table color-grey"></i>{{ 'OVERVIEW.TABLE' | translate }} <span *ngIf="order.table_number">{{ order.table_number }}</span>
            </div>
          </div>

          <!-- Payment method -->
          <h2 class="title-font title">{{ 'CHECKOUT_HEADER.PAYMENT_METHOD' | translate }}</h2>
          <div class="well white">
            <div *ngIf="order?.delivery_method === 'pickup' && order?.payment_method.name == 'cash'" class="delivery">
              <span class="icon cash"></span>{{ firm?.labels?.cash_pickup }}
            </div>
            <div *ngIf="order?.delivery_method === 'delivery' && order?.payment_method.name == 'cash'" class="delivery">
              <span class="icon cash"></span>{{ firm?.labels?.cash_delivery }}
            </div>
            <div *ngIf="order?.delivery_method === 'table' && order?.payment_method.name == 'cash'" class="delivery">
              <span class="icon cash"></span>{{ firm?.labels?.cash_table }}
            </div>
            <div *ngIf="order?.payment_method.id !== 1" class="delivery">
              <i class="far fa-credit-card card-icon"></i> {{ 'ELECTRONIC_PAYMENT' | translate }}
            </div>
          </div>

          <!-- Invoice -->
          <ng-container *ngIf="order?.invoice_url">
            <h2 class="title-font title">{{ 'INVOICE' | translate }}</h2>
            <a class="invoice-button flex align-items-center"
              [href]="order.invoice_url">
              <i class="fas fa-file-invoice-dollar font-18 mr-10"></i>
              <p>{{ 'OPEN_INVOICE' | translate }}</p>
            </a>
          </ng-container>

          <div class="buttons">
            <a class="btn-fill custom-btn"
              (click)="routeService.navigateToRecentOrders(own_domain, firm, language)"
              style="margin-top: 40px"
            >{{ 'BUTTONS.BACK' | translate }}</a>
          </div>

        </div>
      </div>
    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>
</body>
