import { Component, Input, OnInit } from '@angular/core';
import { Country } from '../../core/models/country';
import { Address } from '../../core/models/address';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-address-modal',
  templateUrl: './address-modal.component.html',
  styleUrls: ['./address-modal.component.scss'],
})
export class AddressModalComponent implements OnInit {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() countries: Country[];
  @Input() address: Address;

  formAddress: any;

  constructor(private dialogRef: MatDialogRef<AddressModalComponent>) {}

  ngOnInit() {
    this.setFormAddress();
  }

  setFormAddress() {
    this.formAddress = { ...this.address };
    if (!this.formAddress.country) {
      this.formAddress.country = {};
    }
    if (!this.formAddress.is_default) {
      this.formAddress.is_default = false;
    }
  }

  saveHandler() {
    this.dismiss({
      cancelled: false,
      address: { ...this.formAddress },
    });
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
