import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';
import { DialogsService } from '../services/dialogs.service';
import { Units } from '../constants/units';

@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  units = Units;
  firm_name: any;
  language: any;
  order_id: any;
  order: any;
  firm: any;
  own_domain: any;
  qr_code: any;

  constructor(public auth: AuthService,
              public route: ActivatedRoute,
              public router: Router,
              public firmService: FirmService,
              public orderService: OrderService,
              public routeService: RouteService,
              public dialogsService: DialogsService) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
    this.order_id = route.snapshot.params.order_id;
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;

      this.initData();
    });
  }

  async initData() {
    if(this.firmService.checkWebsite(this.firm, this.language)) {
      let login: any = JSON.parse(await localStorage.getItem('login'));
      this.order = await this.orderService.get(this.firm.id, this.order_id, login.id, this.language);
      this.qr_code = JSON.stringify(this.order.qr_url);

      if(!this.order.order_service_fee) {
        this.order.order_service_fee = [];
      }
    }
  }

  openQrCode() {
    this.dialogsService.openQR(this.qr_code);
  }

}
