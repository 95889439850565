import {Component, Input, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {SlugifyService} from '../services/slugify.service';
import {ProductService} from '../services/products.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-featured-products',
  templateUrl: './featured-products.component.html',
  styleUrls: ['./featured-products.component.css']
})
export class FeaturedProductsComponent implements OnInit {

  @Input() products;
  @Input() own_domain;
  @Input() firm;
  @Input() language;

  featuredProducts: any = [];
  featuredIndex = 0;
  VAT_percentage: any;

  constructor(public router: Router, public slug: SlugifyService, public productService: ProductService, public translate: TranslateService) {
  }

  async ngOnInit() {
    this.featuredProducts = [];
    await this.getProducts(this.products);
    this.autoLoop();
  }

  getProducts(categories: any) {
    categories.forEach(cat => {
      if (cat.subcategories.length > 0) {
        this.getProducts(cat.subcategories);
      } else {
        cat.products.forEach(prod => {
          if(prod.featured) {
            this.featuredProducts.push(prod);
          }
        });
      }
    });
  }

  /* Featured products */
  autoLoop() {
    setTimeout(() => {
      this.nextFeatured();
      this.autoLoop();
    }, 5000);
  }

  async nextFeatured() {
    this.featuredIndex = this.featuredIndex + 1;

    if (this.featuredIndex == this.featuredProducts.length) {
      this.featuredIndex = 0;
    }
    if (this.firm.uses_vat) {
      this.VAT_percentage = await this.productService.getVatPercentage(this.featuredProducts[this.featuredIndex], this.firm);
    }
  }

  async previousFeatured() {
    this.featuredIndex = this.featuredIndex - 1;

    if (this.featuredIndex < 0) {
      this.featuredIndex = this.featuredProducts.length - 1;
    }
    if (this.firm.uses_vat) {
      this.VAT_percentage = await this.productService.getVatPercentage(this.featuredProducts[this.featuredIndex], this.firm);
    }
  }

  openFeatured(item) {
    if (!item.category.parent) {
      if (!this.own_domain) {
        this.router.navigate([this.firm.website_details.sitename, this.language, 'category', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
      } else {
        if (this.firm.parent_is_multifirm) {
          this.router.navigate([this.firm.website_details.sitename, this.language, 'category', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
        } else {
          this.router.navigate([this.language, 'category', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
        }
      }
    } else {
      if (!this.own_domain) {
        this.router.navigate([this.firm.website_details.sitename, this.language, 'category', this.slug.slugify(item.category.parent.name), item.category.parent.category_id, 'subcategory', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
      } else {
        if (this.firm.parent_is_multifirm) {
          this.router.navigate([this.firm.website_details.sitename, this.language, 'category', this.slug.slugify(item.category.parent.name), item.category.parent.category_id, 'subcategory', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
        } else {
          this.router.navigate([this.language, 'category', this.slug.slugify(item.category.parent.name), item.category.parent.category_id, 'subcategory', this.slug.slugify(item.category.name), item.category.category_id, 'product', this.slug.slugify(item.name), item.id]);
        }
      }
    }
  }

  getProductPrice(product) {
    if (!this.firm) {
      return null;
    }

    if (product.has_promotional_price) {
      //   {{ firm?.currency.symbol }}{{ featuredProducts[featuredIndex].price | number:'1.2-2' }}
      // / {{ featuredProducts[featuredIndex].unit.name_singular}}
      if (this.firm.uses_vat) {
        if (this.firm.show_vat_in_frontend) {
          return `${product.promotion.price.toFixed(2)} / ${product.unit.name_singular} (${this.translate.instant('VAT_EXCL')})`;
        } else {
          return `${(product.promotion.price * (1 + this.VAT_percentage / 100)).toFixed(2)} / ${product.unit.name_singular}`;
        }
      } else {
        return `${product.promotion.price.toFixed(2)} / ${product.unit.name_singular}`;
      }
    } else { // no promo price
      if (this.firm.uses_vat) {
        if (this.firm.show_vat_in_frontend) {
          return `${product.price.toFixed(2)} / ${product.unit.name_singular} (${this.translate.instant('VAT_EXCL')})`;
        } else { // without vat
          return `${(product.price / (1 + (this.VAT_percentage / 100))).toFixed(2)} / ${product.unit.name_singular} (${this.translate.instant('VAT_EXCL')})`;
        }
      } else {
        // no VAT
        return `${product.price.toFixed(2)} / ${product.unit.name_singular}`;
      }
    }
  }
}
