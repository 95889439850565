import { Injectable } from '@angular/core';
import { config } from '../../config';

@Injectable()
export class StoreService {

  constructor() { }

  getStoreId() {
    var store_id;

    config.STORE_IDS.forEach(id => {
      if(id.domain == location.hostname) {
        store_id = id.store_id;
      }
    });

    if(store_id == undefined) {
      store_id = 'No storeId found'
    }

    return store_id;
  }

}
