import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { UAParser } from "ua-parser-js"

import { ErrorService } from './error.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { StoreService } from './store.service';
import { config } from '../../config';
import { AuthService } from './auth.service';

@Injectable()
export class PaymentService {

  endpoint_order = "/order/";
  endpoint_ogone = "/pay/ogone";
  endpoint_ideal = "/pay/ideal";
  endpoint_payone = "/pay/payone";
  endpoint_ems = "/pay/ems";
  endpoint_ems_online = "/pay/emsonline";
  endpoint_stripe = "/pay/stripe";
  endpoint_mollie = "/pay/mollie";

  token: any;
  order: any;

  returnUrls = {
    "acceptUrl" :     `${location.protocol}//${location.host}${location.pathname}?accept=1`,
    "declineUrl" :    `${location.protocol}//${location.host}${location.pathname}?decline=1`,
    "cancelUrl" :     `${location.protocol}//${location.host}${location.pathname}?cancel=1`,
    "exceptionUrl" :  `${location.protocol}//${location.host}${location.pathname}?exception=1`
  };

  constructor(private http: HttpClient, private auth: AuthService, public errorService: ErrorService, public deviceService: DeviceDetectorService, public storeService: StoreService) {}

  /* Generate Ogone (Bancontact) */
  async ogone(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

      let headers = {
            'storeId': await this.storeService.getStoreId(),
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ogone, this.returnUrls, { headers: headers })
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.ogone(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate Ogone (iDeal) */
  async ideal(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

      let headers = {
            'storeId': await this.storeService.getStoreId(),
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ideal, this.returnUrls, { headers: headers })
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.ideal(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate PayOne */
  async payone(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

      let headers = {
            'storeId': await this.storeService.getStoreId(),
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_payone, this.returnUrls, { headers: headers })
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.payone(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate EMS */
  async ems(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

      let headers = {
            'storeId': await this.storeService.getStoreId(),
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ems, this.returnUrls, { headers: headers })
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.ems(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate EMS Online */
  async emsOnline(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

      let headers = {
            'storeId': await this.storeService.getStoreId(),
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_ems_online, this.returnUrls, { headers: headers })
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.emsOnline(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate PayOne */
  async stripe(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        };

      var store_id;
      await config.STORE_IDS.forEach(id => {
        if(id.domain == location.hostname) {
          store_id = id.store_id;
        }
      });

      let httpOptions = {
        headers: new HttpHeaders({
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
        })
      }

      if(store_id) {
        httpOptions.headers.append('storeId', store_id);
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_stripe, this.returnUrls, httpOptions)
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.stripe(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  /* Generate PayOne */
  async mollie(firm, firm_name, cust_id, order_id, lang_code, own_domain) {

    return new Promise(async (resolve) => {

      this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        };

      var store_id;
      await config.STORE_IDS.forEach(id => {
        if(id.domain == location.hostname) {
          store_id = id.store_id;
        }
      });

      let httpOptions = {
        headers: new HttpHeaders({
            'passwordToken': await this.checkToken(firm.id),
            'demoPasswordToken': await this.getDemoToken(),
            'customerId': String(cust_id),
            'firmId': String(firm.id),
            'language': String(lang_code),
            'Accept': 'application/json',
            'Authorization': "Bearer " + await localStorage.getItem('token'),
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceType': await this.getDeviceType(),
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web',
            'customerToken': await JSON.parse(localStorage.getItem('customerToken'))
        })
      }

      if(store_id) {
        httpOptions.headers.append('storeId', store_id);
      }

      this.http.post(environment.API_URL + this.endpoint_order + order_id + this.endpoint_mollie, this.returnUrls, httpOptions)
                .subscribe(
                  (data: any) => {
                    document.location.href = data.paymentUrl;
                    resolve(data)
                  },
                  async err => {
                    let check = await this.errorService.checkPaymentErrors(err.status, await this.parseError(err), firm, own_domain, lang_code);

                    if(check == 'ORDER_ALREADY_PAID') {
                      resolve("ALREADY_PAID");
                    }
                    if(check == 'TOKEN_INVALID') {
                      this.mollie(firm, firm_name, cust_id, order_id, lang_code, own_domain);
                    }
                    else {
                      resolve("ERROR");
                    }
                  });
      })
  }

  async initiateOnlinePayment(paymentType, firm, customerId, orderId, langCode, ownDomain) {
    return new Promise(async (resolve) => {
      const httpOptions = {
        headers: new HttpHeaders({
          storeId: this.storeService.getStoreId(),
          passwordToken: await this.checkToken(firm.id),
          demoPasswordToken: await this.getDemoToken(),
          customerId: String(customerId),
          firmId: String(firm.id),
          language: String(langCode),
          Accept: 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          appVersion: config.VERSION,
          apiVersion: config.VERSION,
          deviceType: this.getDeviceType(),
          deviceOS: 'Web',
          deviceOSVersion: 'Web',
          deviceModel: 'Web',
          customerToken: localStorage.getItem('customerToken'),
        }),
      };

      this.http
        .post(
          `${environment.API_URL}/payment/order/${orderId}/pay/${paymentType}`,
          { returnUrl: `${location.protocol}//${location.host}${location.pathname}` },
          httpOptions
        )
        .subscribe(
          (data: any) => {
            this.navigateToPayment(data.paymentUrl);
            resolve(data);
          },
          async (err) => {
            const error = await this.errorService.checkPaymentErrors(
              err.status,
              await this.parseError(err),
              firm,
              ownDomain,
              langCode
            );

            switch (error) {
              case 'ORDER_ALREADY_PAID':
                resolve('ALREADY_PAID');
                break;
              case 'TOKEN_INVALID':
                resolve('TOKEN_INVALID');
                break;
              default:
                resolve('ERROR');
                break;
            }
          }
        );
    });
  }

  navigateToPayment(url) {
    document.location.href = url;
  }

  getDeviceType() {
    // get user agent result
    var parser = new UAParser();
    var result = parser.getResult();

    // get device type
    let deviceType = result.device.type;

    // return correct type
    if(deviceType == undefined || deviceType == 'smarttv' || deviceType == 'console') {
      deviceType = 'desktop'
    }
    else if(deviceType == 'mobile' || deviceType == 'wearable') {
      deviceType = 'mobile'
    }
    else if(deviceType == 'tablet') {
      deviceType = 'tablet'
    }
    else {
      deviceType = 'desktop'
    }

    return deviceType;
  }

  isDesktop() {

    let deviceInfo = this.deviceService.getDeviceInfo();

    var deviceType;

    if( deviceInfo.device == 'UNKNOWN' ||
        deviceInfo.device == 'PS4' ||
        deviceInfo.device == 'VITA' ||
        deviceInfo.device == 'APPLE_TV' ||
        deviceInfo.device == 'GOOGLE_TV' ||
        deviceInfo.device == 'CHROME_BOOK' ||
        deviceInfo.device == 'FIREFOX_OS') {
          deviceType = 'desktop';
    }
    else if( deviceInfo.device == 'IPHONE' ||
             deviceInfo.device == 'I_POD' ||
             deviceInfo.device == 'BLACKBERRY' ||
             deviceInfo.device == 'WINDOWS_PHONE') {
          deviceType = 'mobile';
    }
    else if(deviceInfo.device == 'I_PAD') {
          deviceType = 'tablet';
    }
    else if(deviceInfo.device == 'ANDROID') {
      var screensize;
      if(screensize == "small") {
        deviceType = 'mobile';
      }
      else {
        deviceType = 'tablet';
      }
    }
    else {
      deviceType = 'Niet gevonden, dus desktop (of tablet nemen?)';
    }

    return deviceType;
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    }
    catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    let tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    var passwordToken = [];

    if(tokens != null) {
      tokens.forEach(token => {
        if(token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    var demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if(demoToken == null) {
      demoToken = "";
    }

    return demoToken;
  }

}
