<div id="popup">
  <div class="content">
    <div class="wrap"
         style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
         [style.color]="firm?.theme.main_gradient_contrast_color"
    >

      <div class="dialog-title font-20" >
        <div>{{ product?.name }}</div>
        <a class="close"></a>
      </div>

      <div class="popupbody">
        <app-order-item *ngIf="basketItem && firm" [parent]="'item'" [firm]="firm" [language]="language"
                        [sequenceGroup]="groups_by_sequence" [basketItem]="basketItem"
                        (itemChangedOutput)="itemChanged($event)"
                        (disableBtnOutput)="disableOrderBtn($event)"></app-order-item>
        <div mat-dialog-actions class="dialog-buttons">
          <button
            class="margin-auto background-white half-btn" mat-button
                  mat-dialog-close
            [style.background]="firm?.theme.main_gradient_contrast_color"
            [style.color]="firm?.theme.main_gradient_color_from"
          >{{ 'BUTTONS.CANCEL' | translate }}</button>
          <button
            class="margin-auto background-white half-btn custom-btn" mat-button mat-dialog-close (click)="addToBasket()"
            [disabled]="orderBtnDisabled || !basketItem?.item?.in_stock || (basketItem?.item?.max ? amountAlreadyInCart >= basketItem?.item?.max : false) || (firm.max_cart_amount ? basketAmount >= firm.max_cart_amount : false)">{{ 'BUTTONS.OK' | translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>
