import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { DialogsService } from '../services/dialogs.service';
import { FavoritesService } from '../services/favorites.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { ProductService } from '../services/products.service';
import { SlugifyService } from '../services/slugify.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.css']
})
export class FavoritesComponent implements OnInit {

  firm: any;
  products: any = [];
  categories: any;
  language: any;
  firm_name: any;
  own_domain: any;
  favorites: any;
  pickup_label: any;
  vat_delivery_method: any;
  openVat: boolean = false;

  constructor(public firmService: FirmService, public productService: ProductService, public router: Router, public route: ActivatedRoute, public slug: SlugifyService, public loader: LoaderService, public auth: AuthService, public dialogsService: DialogsService, public favoritesService: FavoritesService, public loaderService: LoaderService) {
    window.scrollTo(0, 0);
    this.language = this.route.snapshot.params.language;
    this.firm_name = this.route.snapshot.params.firm_name;
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      
      this.initData();
    });
  }

  async initData() {
    if(this.firmService.checkWebsite(this.firm, this.language) && this.firmService.checkForPassword(this.firm)) {
      this.loaderService.present();
      this.categories = await this.productService.getCategories(this.firm, this.language, this.firm.uses_deliverect);
      this.getFavorites();
      this.loaderService.dismiss();
    }
  }

  /* Get favorites */
  async getFavorites() {
    var favoriteList = await this.favoritesService.get(this.firm.id);

    // No favorites found
    if(!favoriteList.length) {
      this.favorites = [];
    }
    // If favorites found, get firm
    else {
      await this.getProducts(this.categories);

      await favoriteList.forEach(async favObj => {

        let products_found = await this.products.filter(product => product.id == favObj.item_id);

        if(products_found.length > 0) {
          favObj.item = products_found[0];
        }
        else {
          let index = favoriteList.indexOf(favObj);
          if (index !== -1) {
            favoriteList.splice(index, 1);
          }

          await this.removeFavorite(favObj);
        }

      });

      this.favorites = favoriteList;

      this.favorites.forEach(async item => {
        if(item.item.category.parent) {
          item.item.isAvailable = this.productService.isAvailable(item.item.category.parent.availabilities, this.firm.uses_deliverect);
          item.item.firstAvailableDate = await this.productService.getFirstAvailableDate(item.item.category.parent.availabilities);
        }
        else {
          item.item.isAvailable = this.productService.isAvailable(item.item.category.availabilities, this.firm.uses_deliverect);
          item.item.firstAvailableDate = await this.productService.getFirstAvailableDate(item.item.category.availabilities);
        }
      });
    }
  }

  getProducts(categories: any) {
    categories.forEach(cat => {
      if(cat.subcategories.length > 0) {
        this.getProducts(cat.subcategories);
      }
      else {
        cat.products.forEach(prod => {
          this.products.push(prod);
        });
      }
    });
  }

  reloadData(event) {
    if(event == true) {
      this.getFavorites();
    }
  }

  removeFavorite(prod) {
    this.favoritesService.remove(prod)
      .then(async () => {
        this.getFavorites();
      });
  }

  vatDeliveryMethodChanged(event) {
    this.vat_delivery_method = event;
  }

}