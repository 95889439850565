import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';

@Component({
  selector: 'app-extra-info',
  templateUrl: './extra-info.component.html',
  styleUrls: ['./extra-info.component.css'],
})
export class ExtraInfoComponent implements OnInit {
  method: any;
  user: any;
  firm: any;
  firm_name: any;
  language: any;
  own_domain: boolean;

  email: any;
  first_name: any;
  last_name: any;
  guest_name: any;
  phone: any;
  company: any;

  constructor(
    route: ActivatedRoute,
    public firmService: FirmService,
    private loader: LoaderService,
    private customerService: CustomerService,
    private routeService: RouteService,
    private orderService: OrderService
  ) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
    this.method = route.snapshot.params.method;
  }

  /* Get order */
  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  /* Get all data */
  async initData() {
    if (this.firmService.checkWebsite(this.firm, this.language)) {
      if (this.method === 'user') {
        const login: any = await JSON.parse(localStorage.getItem('login'));
        if (!login) {
          this.user = undefined;
        } else if (login.activated) {
          this.loader.present();
          await this.customerService.linkFirm(
            this.firm,
            login.id,
            this.language,
            this.firm.distributor,
            this.own_domain
          );
          this.user = await this.customerService.get(
            this.firm,
            login.id,
            this.language,
            this.firm.distributor,
            this.own_domain
          );
          this.loader.dismiss();
        }
      } else if (this.method === 'guest') {
        this.orderService.getOrder().then((order) => {
          if (order?.customer_email || order?.customer_name || order?.customer_phone || order?.customer_company) {
            this.email = order.customer_email;
            this.guest_name = order.customer_name;
            this.phone = order.customer_phone;
            this.company = order.customer_company;
          }
        });
        this.user = await this.customerService.loginAsGuest(this.firm, this.language);
      }
    }
  }

  async save() {
    if (!this.user.country) {
      this.user.country = [];
    }

    const user = {
      email: this.user.email,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone,
      company: this.user.company,
    };

    if (!user.email) {
      user.email = this.email;
    }
    if (!user.first_name) {
      user.first_name = this.first_name;
    }
    if (!user.last_name) {
      user.last_name = this.last_name;
    }
    if (!user.phone) {
      user.phone = this.phone;
    }

    if (this.method === 'user') {
      this.loader.present();
      await this.customerService.update(
        this.firm,
        this.user.id,
        user,
        this.language,
        this.firm.distributor,
        this.own_domain
      );
      this.routeService.navigateToDeliveryMethod(this.own_domain, this.firm, this.language);
      this.loader.dismiss();
    } else if (this.method === 'guest') {
      if (!user.company) {
        user.company = this.company;
      }
      const order = await this.orderService.getOrder();
      order.customer_email = user.email;
      order.customer_name = this.guest_name;
      order.customer_phone = user.phone;
      order.customer_company = user.company;
      await this.orderService.saveOrder(order);
      this.routeService.navigateToDeliveryMethod(this.own_domain, this.firm, this.language);
    }
  }
}
