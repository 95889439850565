<head>
    <title>{{ firm?.name }} | {{ 'PASSWORD_RESET' | translate }}</title>
</head>
<body>
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="centered width-50">

      <!-- Content -->
      <div class="pagecontent">
        <div class="row">
            <form #formEmail="ngForm">
                <div class="view-login">
                    <h1 style.color="{{firm?.website_details.site_text_color}}" class="title-font greeting">{{ 'RESET_PASSWORD' | translate }}</h1>

                    <!-- Enter e-mail -->
                    <div class="emailform">
                        <div class="well">

                            <div class="input">
                                <div class="inputfield">
                                    <input placeholder="Email*" required="required" name="email" type="email" value="" id="email" [(ngModel)]="email" #emailRef="ngModel" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
                                </div>
                            </div>

                            <div class="buttons next-btn">
                                <button class="btn-fill buttons" (click)="customerService.resetPassword(email, firm, language)" [disabled]="!formEmail.valid">{{ 'RESET_PASSWORD' | translate }}<span class="arrow"></span></button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>
</body>
