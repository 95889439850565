import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

import { Injectable } from '@angular/core';

import { OrderModalComponent } from '../ordermodal/ordermodal.component';
import { EditOrderModalComponent } from '../edit-ordermodal/edit-ordermodal.component';
import { PaymentModal } from '../paymentmodal/paymentmodal.component';
import { ErrorModal } from '../errormodal/errormodal.component';
import { VatDeliveryMethodComponent } from '../vat-delivery-method/vat-delivery-method.component';
import { QrCodeComponent } from '../qr-code/qr-code.component';
import { AddressModalComponent } from '../shared/address-modal/address-modal.component';
import { BusinessDetailsModalComponent } from '../shared/business-details-modal/business-details-modal.component';
import { SelectInvoiceAddressModalComponent } from '../shared/select-invoice-address-modal/select-invoice-address-modal.component';

@Injectable()
export class DialogsService {
  constructor(private dialog: MatDialog) {}

  public openError(title, message, buttons): Observable<boolean> {
    const dialogRef = this.dialog.open(ErrorModal, {
      panelClass: 'myapp-no-padding-dialog',
      width: '350px',
    });

    dialogRef.componentInstance.title = title;
    dialogRef.componentInstance.message = message;
    dialogRef.componentInstance.buttons = buttons;

    return dialogRef.afterClosed();
  }

  /* New item modal */
  public openModal(product, firm, cat_id, language): Observable<boolean> {
    const dialogRef = this.dialog.open(OrderModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.product = product;
    dialogRef.componentInstance.firm = firm;
    dialogRef.componentInstance.cat_id = cat_id;
    dialogRef.componentInstance.language = language;

    return dialogRef.afterClosed();
  }

  /* Edit item modal */
  public openEditModal(product, firm, cat_id, index, language): Observable<boolean> {
    const dialogRef = this.dialog.open(EditOrderModalComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
      autoFocus: false,
    });

    dialogRef.componentInstance.product = product;
    dialogRef.componentInstance.firm = firm;
    dialogRef.componentInstance.cat_id = cat_id;
    dialogRef.componentInstance.index = index;
    dialogRef.componentInstance.language = language;

    return dialogRef.afterClosed();
  }

  /* Payment modal */
  public openPaymentModal(html): Observable<boolean> {
    const dialogRef = this.dialog.open(PaymentModal, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
    });

    dialogRef.componentInstance.html = html;

    return dialogRef.afterClosed();
  }

  /* Payment modal */
  public openVATModal(firm): Observable<boolean> {
    const dialogRef = this.dialog.open(VatDeliveryMethodComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: '700px',
    });

    dialogRef.componentInstance.firm = firm;

    return dialogRef.afterClosed();
  }

  public openQR(qr): Observable<boolean> {
    const dialogRef = this.dialog.open(QrCodeComponent, {
      panelClass: 'myapp-no-padding-dialog',
      width: 'fit-content',
    });

    dialogRef.componentInstance.qr = qr;

    return dialogRef.afterClosed();
  }

  public openAddressModal(header, subHeader, buttonLabel, countries, address?) {
    const dialogRef = this.dialog.open(AddressModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.countries = countries;

    if (address) {
      dialogRef.componentInstance.address = address;
    }

    return dialogRef.afterClosed();
  }

  public openBusinessDetailsModal(header, subHeader, buttonLabel, businessDetails) {
    const dialogRef = this.dialog.open(BusinessDetailsModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.businessDetails = businessDetails;

    return dialogRef.afterClosed();
  }

  public openInvoiceAddressModal(header, subHeader, buttonLabel, addressList, selectedId) {
    const dialogRef = this.dialog.open(SelectInvoiceAddressModalComponent, {
      panelClass: 'side-modal',
      height: '100%',
    });

    dialogRef.componentInstance.header = header;
    dialogRef.componentInstance.subHeader = subHeader;
    dialogRef.componentInstance.buttonLabel = buttonLabel;
    dialogRef.componentInstance.addressList = addressList;
    dialogRef.componentInstance.selectedId = selectedId;

    return dialogRef.afterClosed();
  }
}
