<!-- Large size -->
<header class="menu" [style.background-color]="firm?.theme.main_color" [style.color]="firm?.theme.main_color_contrast_color">
  <div class="menu--logo-menu">

    <!-- Logo image -->
    <a *ngIf="firm?.logo_fullsize"
       (click)="routeService.navigateToMenu(own_domain, firm, language)" class="logo">
      <img [src]="firm?.logo_fullsize" alt="">
    </a>

  </div>

  <!-- Menu -->
  <div class="menu--items-menu">

    <!-- Navigation -->
    <div style="text-transform: uppercase">
      <a *ngIf="!displayDeliverectMenus()" class="title-font" (click)="routeService.navigateToMenu(own_domain, firm, language)">{{ 'HEADER.SELECTION' | translate }}</a>
      <a *ngIf="displayDeliverectMenus()" class="dropdown-btn title-font" (click)="routeService.navigateToMenu(own_domain, firm, language)">
        {{ 'HEADER.SELECTION' | translate }} <i class="fas fa-caret-down"></i>
        <div class="dropdown" [style.background-color]="firm?.theme.main_color_contrast_color" [style.color]="firm?.theme.main_color">
          <a *ngFor="let cat of categories" (click)="$event.stopPropagation(); routeService.navigateToMenu(own_domain, firm, language, cat.index)">{{ cat.name }}</a>
        </div>
      </a>
      <a *ngIf="isReservationEnabled()" class="title-font" (click)="routeService.navigateToReservation(own_domain, firm, language)">{{ 'BOOK_TABLE' | translate }}</a>
      <a class="title-font" (click)="routeService.navigateToFavorites(own_domain, firm, language)">{{ 'HEADER.FAVORITES' | translate }}</a>
      <a class="title-font" (click)="routeService.navigateToInfo(own_domain, firm, language)">{{ 'HEADER.INFO' | translate }}</a>
    </div>

    <!-- Shop buttons (login, shoppingcart, ..)-->
    <div class="shop-buttons">
      <!-- Logout button -->
      <div id="" class="usermenu topmenu--button-bar--button">

        <!-- Not logged in -->
        <button *ngIf="!customerService?.login" mat-button
                class="login-btn"
                (click)="routeService.navigateToProfile(own_domain, firm, language)">
            <span>
              <i class="far fa-user"></i>
              {{ 'HEADER.LOGIN' | translate }}
            </span>
        </button>

        <!-- Logged in -->
        <button *ngIf="customerService?.login" mat-button [matMenuTriggerFor]="menu"
                class="menu--mat-user">
          <i class="far fa-user"></i>
          <span>{{ customerService?.login?.name }}</span>
        </button>

        <!-- User menu -->
        <mat-menu #menu="matMenu" [overlapTrigger]="false">
          <button mat-menu-item class="menu-item"
                  (click)="routeService.navigateToProfile(own_domain, firm, language)">{{ 'HEADER.EDIT_PROFILE' | translate }}</button>
          <button mat-menu-item class="menu-item"
                  (click)="routeService.navigateToLoyalty(own_domain, firm, language)"
                  *ngIf="firm?.use_loyalty && firm?.loyalty">{{ 'LOYALTY_CARD' | translate }}</button>
          <button mat-menu-item class="menu-item"
                  (click)="routeService.navigateToRecentOrders(own_domain, firm, language)">{{ 'RECENT_ORDERS.TITLE' | translate }}</button>
          <button mat-menu-item class="menu-item"
                  (click)="routeService.navigateToSettings(own_domain, firm, language)">{{ 'HEADER.SETTINGS' | translate }}</button>
          <button mat-menu-item class="menu-item" (click)="logout()">{{ 'HEADER.LOGOUT' | translate }}</button>
        </mat-menu>

      </div>

      <!-- Basket -->
      <div *ngIf="firm?.website_details.is_webshop" id="basketmenu">

        <div (click)="showMenu = !showMenu" class="label">
          <i class="fas fa-shopping-cart"></i>&nbsp;
          <p class="cart-title" *ngIf="basket.basketAmount == 1; else multipleBasketItems">
            <span class="num">1</span> {{ 'HEADER.ITEM' | translate }}
          </p>
          <ng-template #multipleBasketItems>
            <p class="cart-title">
              <span class="num">{{ basket.basketAmount }}</span>
              {{ 'HEADER.ITEMS' | translate }}
            </p>
          </ng-template>
        </div>

        <div *ngIf="showMenu && basket.basketAmount > 0" class="submenu"
             style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
             [style.color]="firm?.theme.main_gradient_contrast_color">
          <ul>
            <li *ngFor="let item of basket.basketItems">
              <span class="amount" *ngIf="item.item.item.unit.id !== units.KG">{{ item.item.amount }} {{ item.item.item.unit.name_short }}</span>
              <span class="amount" *ngIf="item.item.item.unit.id === units.KG">{{ item.item.amount / 1000 }} {{ item.item.item.unit.name_short }}</span>
              <p>{{ item.item.name }}</p>
            </li>
            <li class="subtotal">
              {{ 'HEADER.SUBTOTAL' | translate }}
              <span class="price">{{ firm?.currency.symbol }}{{ basket.basketPrice | number:'1.2-2' }}</span>
            </li>
          </ul>

          <div class="buttons clear" *ngIf="customerService.login">
            <a href="javascript:void(0)" class="edit" (click)="routeService.navigateToBasket(own_domain, firm, language)">
              {{ 'HEADER.EDIT' | translate }}
            </a>
            <button href="javascript:void(0)" class="btn-fill custom-btn" [disabled]="firm?.is_offline"
                    (click)="orderService.navigateToCheckout('user', firm, own_domain, language)">
              {{ 'HEADER.ORDER' | translate }}
            </button>
          </div>
          <div class="buttons clear" *ngIf="!customerService.login">
            <a href="javascript:void(0)" class="edit" (click)="routeService.navigateToBasket(own_domain, firm, language)">
              {{ 'HEADER.EDIT' | translate }}
            </a>
            <button href="javascript:void(0)" class="btn-fill custom-btn"
                    (click)="signin()"
                    style="margin-right: 5px">{{ 'LOGIN' | translate }}</button>
            <a *ngIf="!firm?.is_offline && firm?.allow_guest_checkout" (click)="orderService.navigateToCheckout('guest', firm, own_domain, language)">
              {{ 'ORDER_AS_GUEST' | translate }}
            </a>
          </div>
        </div>
      </div>
      <!--      /Shop buttons (login, shoppingcart, ..)-->


      <!-- Searchbar -->
      <div class="searchbar-container" id="searchfield-container"
           style.background-color="{{firm?.theme.main_color_contrast_color}}AA"
           style.border-color="{{firm?.theme.main_color_contrast_color}}AA"
           [style.color]="firm?.theme.main_color">
        <i class="fas fa-search"></i>
        <input maxlength="30" value="" type="search" id="searchfield"
               [style.color]="firm?.theme.main_color"
               (keydown.enter)="routeService.navigateToSearchPage(own_domain, firm, language, search_term)"
               [(ngModel)]="search_term">
      </div>

      <!-- Language picker -->
      <div class="language-container">
        <mat-form-field *ngIf="firm?.languages.length > 1">
          <mat-select [(ngModel)]="language">
            <mat-option *ngFor="let lang of firm?.languages" [value]="lang.code" (click)="changeLanguage(lang)" [style.background-color]="firm?.theme.main_color_contrast_color" [style.color]="firm?.theme.main_color">
              <p>{{ lang.code }}</p>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

    </div>

  </div>
</header>

<!-- Mobile -->
<header class="mobile-menu" role="navigation">

  <div class="menu-container" [style.background-color]="firm?.theme.main_color" [style.color]="firm?.theme.main_color_contrast_color">

    <!-- Menu toggle -->
    <div id="menuToggle">

      <!-- Toggle -->
      <input class="toggle" type="checkbox" />

      <!-- Hamburger icon -->
      <span class="hamburger-icon" [style.background-color]="firm?.theme.main_color_contrast_color"></span>
      <span class="hamburger-icon" [style.background-color]="firm?.theme.main_color_contrast_color"></span>
      <span class="hamburger-icon" [style.background-color]="firm?.theme.main_color_contrast_color"></span>

      <!-- Menu -->
      <ul id="menu" [style.color]="firm?.theme.main_gradient_contrast_color">

        <!-- Logout -->
        <div class="logout">
          <span *ngIf="customerService.login" class="font-13" (click)="logout()">{{'LOGOUT' | translate}}</span>
        </div>

        <!-- Profile -->
        <eo-profile
          [authenticated]="customerService.login ? true : false"
          [loyalty]="firm?.use_loyalty"
          mode="card"
          unit="{{ 'STAMPS' | translate }}"
          [progress]="customerService?.login?.loyaltyPoints"
          [max]="firm?.loyalty?.points_required"
          [profileName]="customerService?.login?.firstName"
          greetingText="{{ 'HI' | translate }}"
          discoverText="{{ 'DISCOVER' | translate }}"
          btnText="{{ 'HEADER.EDIT_PROFILE' | translate }}"
          (editProfile)="routeService.navigateToProfile(own_domain, firm, language)">
        </eo-profile>

        <!-- Login -->
        <div class="login-container" *ngIf="!customerService.login">
          <eo-button size="full" (btnClicked)="routeService.navigateToProfile(own_domain, firm, language)" label="{{'SIGN_IN' | translate}}"></eo-button>
          <eo-button class="signup-btn" size="full" (btnClicked)="routeService.navigateToSignup(own_domain, firm, language)" label="{{'SIGN_UP' | translate}}"></eo-button>
        </div>

        <!-- Navigation -->
        <div class="menu-btns">
          <a *ngIf="!displayDeliverectMenus()" (click)="routeService.navigateToMenu(own_domain, firm, language)">
            <li>
              <i class="fas fa-list-ul icon"></i>
              <p>{{ 'HEADER.SELECTION' | translate }}</p>
            </li>
          </a>
          <div *ngIf="displayDeliverectMenus()">
            <a *ngFor="let cat of categories" (click)="routeService.navigateToMenu(own_domain, firm, language, cat.index)">
              <li>
                <i class="fas fa-list-ul icon"></i>
                <p>{{ cat.name }}</p>
              </li>
            </a>
          </div>
          <a *ngIf="isReservationEnabled()" (click)="routeService.navigateToReservation(own_domain, firm, language)">
            <li>
              <i class="fas fa-book-open icon"></i>
              <p>{{ 'BOOK_TABLE' | translate }}</p>
            </li>
          </a>
          <a (click)="routeService.navigateToBasket(own_domain, firm, language)">
            <li>
              <i class="fas fa-shopping-basket icon"></i>
              <p>{{ 'MY_CART' | translate }}</p>
            </li>
          </a>
          <a (click)="routeService.navigateToFavorites(own_domain, firm, language)">
            <li>
              <i class="fas fa-star icon"></i>
              <p>{{ 'HEADER.FAVORITES' | translate }}</p>
            </li>
          </a>
          <a (click)="routeService.navigateToLoyalty(own_domain, firm, language)" *ngIf="firm?.use_loyalty">
            <li>
              <i class="fas fa-gift icon"></i>
              <p>{{ 'LOYALTY_CARD' | translate }}</p>
            </li>
          </a>
          <a (click)="routeService.navigateToRecentOrders(own_domain, firm, language)">
            <li>
              <i class="fas fa-box icon"></i>
              <p>{{ 'ORDERS' | translate }}</p>
            </li>
          </a>
          <a (click)="routeService.navigateToInfo(own_domain, firm, language)">
            <li>
              <i class="fas fa-info-circle icon"></i>
              <p>{{ 'INFO.TITLE' | translate }}</p>
            </li>
          </a>
        </div>

        <!-- Search -->
        <div style="margin-bottom: 16px">
          <p class="font-12 v-align">{{ 'SEARCH_PRODUCT' | translate }}</p>
          <div id="searchfield-container"
                style.background-color="{{firm?.theme.main_color_contrast_color}}AA"
                style.border-color="{{firm?.theme.main_color_contrast_color}}AA"
                [style.color]="firm?.theme.main_color">

            <i class="fas fa-search"></i>

            <input maxlength="30" value="" type="search" id="searchfield"
                  [style.color]="firm?.theme.main_color"
                  (keydown.enter)="routeService.navigateToSearchPage(own_domain, firm, language, search_term)"
                  [(ngModel)]="search_term">
          </div>
        </div>

        <!-- Language picker -->
        <div *ngIf="firm?.languages.length > 1">
          <p class="font-12 v-align">{{ 'LANGUAGE' | translate }}</p>
          <mat-form-field>
            <mat-select [(ngModel)]="language">
              <mat-option *ngFor="let lang of firm?.languages" [value]="lang.code" (click)="changeLanguage(lang)" [style.background-color]="firm?.theme.main_color_contrast_color" [style.color]="firm?.theme.main_color">
                <p>{{ lang.code }}</p>
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

      </ul>

    </div>

    <!-- Logo -->
    <a *ngIf="firm?.logo_fullsize" (click)="routeService.navigateToMenu(own_domain, firm, language)" class="logo">
      <img [src]="firm?.logo_fullsize" alt="">
    </a>

    <!-- Cart btn -->
    <a href="javascript:void(0)" (click)="routeService.navigateToBasket(own_domain, firm, language)"><i class="fas fa-shopping-cart cart-btn"></i></a>

  </div>

</header>
