import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-error',
  templateUrl: './errormodal.component.html',
  styleUrls: ['./errormodal.component.css']
})
export class ErrorModal {

  title: any;
  message: any;
  buttons: boolean;

  constructor(public dialogRef: MatDialogRef<ErrorModal>) {}
}
