<div class="box" *ngIf="order != 'ERROR'">

  <p class="order-title">{{ 'OVERVIEW.OVERVIEW_ORDER' | translate }}</p>
  <p class="order-ref">#{{ order?.reference }}</p>

  <h2 class="title-font title">{{ 'CUSTOMER_INFO' | translate }}</h2>
  <div *ngIf="order?.customer">
    <p>{{ order?.customer?.first_name }} {{ order?.customer?.last_name }}</p>
    <p>{{ order?.customer?.email }}</p>
    <p *ngIf="order?.customer.phone">{{ order?.customer?.phone }}</p>
  </div>

  <div *ngIf="!order?.customer">
    <p><span class="bold">{{ 'USER_DETAILS.NAME' | translate }}: </span>{{ order?.customer_name }}</p>
    <p><span class="bold">{{ 'USER_DETAILS.EMAIL' | translate }}: </span>{{ order?.customer_email }}</p>
  </div>

  <!-- Ordered products -->
  <h2 class="title-font title">{{ 'CHECKOUT_SIDEBAR.MY_ORDER' | translate }}</h2>
  <table class="products width-full">
      <tr *ngFor="let item of order?.order_details">

        <!-- Product -->
        <td class="productinfo">
            <p *ngIf="item.product">{{ item.quantity }} <small>({{ item.unit.name}})</small> {{ item.product.name }}</p>
            <p *ngIf="item.menu">{{ item.quantity }} x {{ item.menu.name }}</p>

            <!-- Ingredients & option groups -->
            <ul class="ingredients font-12">
                <div *ngFor="let ingr of item?.ingredients">
                    <li *ngIf="ingr.type == 'free' && ingr.action == 'rem'">- {{ ingr?.name }}</li>
                </div>
                <div *ngFor="let ingr of item.ingredients">
                    <li *ngIf="ingr.type == 'free' && ingr.action == 'add'">+ {{ ingr?.name }}</li>
                </div>
                <div *ngFor="let ingr of item.ingredients">
                    <li *ngIf="ingr.type == 'paid'">+ {{ ingr?.name }} <span>{{ order?.firm?.currency.symbol }}{{ ingr?.price | number:'1.2-2' }}</span></li>
                </div>
                <div *ngFor="let option of item.product_options">
                    <li>+ {{ option.product_option_group_option.name }} {{ order?.firm?.currency.symbol }}{{ option.product_option_group_option.price | number:'1.2-2' }} </li>
                </div>
              </ul>

              <!-- Comment -->
              <span class="comment">{{ item.comment }}</span>
          </td>

          <!-- Price -->
        <td class="price">{{ order?.firm?.currency.symbol }}{{ item.price | number:'1.2-2' }}</td>
    </tr>

    <hr class="line">

    <!-- Total price of order -->
    <tr>
        <td>{{ 'BASKET.TOTAL' | translate }}:</td>
        <td class="total-price">{{ order?.firm?.currency.symbol }}{{ order?.total_price | number:'1.2-2' }}</td>
    </tr>
  </table>

  <!-- Delivery method + timestamp -->
  <h2 class="title-font title">{{ 'RECENT_ORDERS.DELIVERY_METHOD' | translate }}</h2>
  <div class="well white">

      <!-- Pickup -->
      <div *ngIf="order?.delivery_method == 'pickup'" class="delivery">
          <span class="icon pickup"></span>{{ 'OVERVIEW.PICKUP' | translate }} - {{ order?.process_timestamp | date:'dd/MM' }}
          <br>

          <!-- Normal -->
          <span *ngIf="!order.firm_pickup_point_id">{{ order?.firm?.name }}</span>
          <br>
          <span *ngIf="!order.firm_pickup_point_id">{{ order?.firm?.address }}, {{ order?.firm?.zipcode }} {{ order?.firm?.locality }}</span>

          <!-- Pickup point -->
          <span *ngIf="order.firm_pickup_point_id">{{ order?.pickup_point.name }}</span>
          <br>
          <span *ngIf="order.firm_pickup_point_id">{{ order?.pickup_point.address }}, {{ order?.pickup_point.zipcode }} {{ order?.pickup_point.locality }}</span>
      </div>

      <!-- Delivery -->
      <div *ngIf="order?.delivery_method == 'delivery'" class="delivery">
          <span class="icon delivery"></span>{{ 'OVERVIEW.DELIVERY' | translate }} - {{ order?.process_timestamp | date:'dd/MM' }}
          <br>
          <span>{{ order.delivery_street }} {{order.delivery_street_nr }}, {{ order.delivery_zipcode }} {{ order.delivery_locality }}</span>
      </div>

      <!-- Tabel -->
      <div *ngIf="order?.delivery_method == 'table'" class="delivery">
          <i class="fas fa-utensils icon-table color-grey"></i>{{ 'OVERVIEW.TABLE' | translate }} {{ order.table_number }}
      </div>
  </div>

  <!-- Payment method -->
  <h2 class="title-font title">{{ 'CHECKOUT_HEADER.PAYMENT_METHOD' | translate }}</h2>
  <div class="well white">
      <div *ngIf="order?.delivery_method === 'pickup' && order?.payment_method.name === 'cash'" class="delivery">
        <span class="icon cash"></span>{{ firm?.labels?.cash_pickup }}
      </div>
      <div *ngIf="order?.delivery_method === 'delivery' && order?.payment_method.name === 'cash'" class="delivery">
        <span class="icon cash"></span>{{ firm?.labels?.cash_delivery }}
      </div>
      <div *ngIf="order?.delivery_method === 'table' && order?.payment_method.name === 'cash'" class="delivery">
        <span class="icon cash"></span>{{ firm?.labels?.cash_table }}
      </div>
      <div *ngIf="order?.payment_method?.id !== 1" class="delivery">
        <i class="far fa-credit-card card-icon"></i>
        <span>{{ 'ELECTRONIC_PAYMENT' | translate }}</span>
      </div>
  </div>

</div>

<div class="box" *ngIf="order == 'ERROR'">
    <p class="error-title">{{ 'DIALOGS.ERROR' | translate }}</p>
    <p class="error-text">{{ 'GET_ORDER_ERROR' | translate }}</p>
</div>
