import { Component, OnInit } from '@angular/core';
import { FirmService } from '../services/firm.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-terms-of-use',
  templateUrl: './terms-of-use.component.html',
  styleUrls: ['./terms-of-use.component.css']
})
export class TermsOfUseComponent implements OnInit {

  firm: any;
  firm_name: any;
  language: any;
  own_domain: boolean;

  constructor(public firmService: FirmService, 
    public route: ActivatedRoute) {
    window.scrollTo(0, 0);

    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
  }

  ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.firmService.checkWebsite(this.firm, this.language);
      this.firmService.checkForPassword(this.firm);
    });
  }
}
