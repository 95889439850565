import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.css']
})
export class PasswordComponent implements OnInit {

  distributor: any;
  firm: any;
  language: any;
  firm_name: any;
  password: any;
  password_error: boolean;

  constructor(public auth: AuthService, public firmService: FirmService, public route: ActivatedRoute , public router: Router, public languageService: LanguageService) {
    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
  }

  async ngOnInit() {
    let distributor = await this.firmService.getFirmForHost(location.origin, this.language);

    // Get firm with own domain (parameter = language)
    if(distributor != 'NO_CUSTOM_DOMAIN') {
      this.firm = distributor;
    }
    // Get firm without own domain (parameter = firm_name)
    else {
      let response: any = await this.firmService.getFirm(this.firm_name, undefined);
      this.firm = response;
      this.distributor = response.distributor;
    }
  }

  async enterPassword() {
    let response: any = await this.firmService.enterPassword(this.password, this.firm.id, 'nl');

    if(response == "PASSWORD_INCORRECT") {
      this.password_error = true;
    }
    else if(response.passwordToken) {
      this.password_error = false;
      this.saveToken(response);
      this.router.navigate([this.firm.website_details.sitename]);
    }
  }

  async saveToken(response) {
    // get all tokens
    let tokens: any = await JSON.parse(localStorage.getItem('password_tokens'));

    if(tokens != null) {
      // if token already saved, replace by new one
      tokens.forEach(token => {
        if(token.firm_id == this.firm.id) {
          let index = tokens.indexOf(token);

          // remove item
          if(index > -1) {
            tokens.splice(index, 1);
          }
        }
      });
    }
    else {
      tokens = []
    }

    // add new token to list
    let new_token = {
      firm_id: this.firm.id,
      passwordToken: response.passwordToken
    }
    tokens.push(new_token);

    // save list to localStorage
    await localStorage.setItem('password_tokens', JSON.stringify(tokens));
  }

}
