import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { interval as observableInterval } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { DialogsService } from '../services/dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { RouteService } from '../services/route.service';
import { TagService } from '../services/tag.service';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.css'],
})
export class SignupComponent implements OnInit {
  user: any = [];
  firm: any;
  firm_name: any;
  language: any;
  own_domain: boolean;
  countries: any;
  cust_id: any;
  emailform = true;
  waitscreen: boolean;
  verification_error: boolean;

  constructor(
    route: ActivatedRoute,
    public routeService: RouteService,
    public firmService: FirmService,
    private loader: LoaderService,
    private customerService: CustomerService,
    private dialogsService: DialogsService,
    private translate: TranslateService,
    private tagService: TagService
  ) {
    window.scrollTo(0, 0);
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
    });
  }

  async signup() {
    if (this.user.password == this.user.password_confirm) {
      if (this.user.opt_in_eo == null || this.user.opt_in_eo == undefined) {
        this.user.opt_in_eo = false;
      }
      if (this.user.opt_in_merchant == null || this.user.opt_in_merchant == undefined) {
        this.user.opt_in_merchant = false;
      }

      const user: any = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        phone: this.user.phone,
        company: this.user.company,
        email: this.user.email,
        opt_in_eo: this.user.opt_in_eo,
        opt_in_merchant: this.user.opt_in_merchant,
        password: this.user.password,
      };

      this.loader.present();
      const response: any = await this.customerService.add(
        this.firm,
        user,
        this.language,
        this.firm.distributor,
        this.own_domain
      );
      this.cust_id = response.id;
      this.loader.dismiss();

      this.checkMailActivation();
    } else {
      this.dialogsService.openError('Password confirm', 'Password confirm is incorrect, please try again', true);
    }
  }

  checkMailActivation() {
    this.emailform = false;
    this.waitscreen = true;

    // Check activation every 5 seconds
    const sub = observableInterval(5000).subscribe(async () => {
      const response: any = await this.customerService.checkActivation(this.firm.id, this.cust_id, this.language);

      // check if app is activated or not
      if (response.error) {
        if (response.error.code == 'CUSTOMER_NOT_FOUND') {
          sub.unsubscribe();
          this.dialogsService.openError(
            this.translate.instant('DIALOGS.CUST_NOT_FOUND_TITLE'),
            this.translate.instant('DIALOGS.CUST_NOT_FOUND_MESSAGE'),
            false
          );
        }
      } else {
        sub.unsubscribe();
        await this.handleSignup(response.id);
      }
    });
  }

  async handleSignup(userId) {
    this.user.id = userId;
    await this.customerService.saveLogin(this.user);
    this.verification_error = false;
    this.routeService.navigateToProfile(this.own_domain, this.firm, this.language);
    this.tagService.addEvent('eo_web_create_account');
  }
}
