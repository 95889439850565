import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { FavoritesService } from '../services/favorites.service';
import { BasketItemsService } from '../services/basket-items.service';
import { RouteService } from '../services/route.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  
  firm: any;
  firm_name: any;
  language: any;
  own_domain: any;

  constructor(public auth: AuthService, 
              public route: ActivatedRoute, 
              public router: Router, 
              public firmService: FirmService, 
              public favoritesService: FavoritesService, 
              public basket: BasketItemsService,
              public routeService: RouteService) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }
  
  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.firmService.checkWebsite(this.firm, this.language);
    });
  }

  async changeLanguage(lang) {
    if(this.basket.basketAmount > 0) {
      this.basket.removeAll(this.firm.id);
    }

    await this.routeService.navigateToSettings(this.own_domain, this.firm, lang.code.toLowerCase());
    window.location.reload();
  }

}
