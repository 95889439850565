<div class="datepicker clearfix" id="datepicker">

  <div class="day" *ngIf="!limitedToSameDay">
    <mat-form-field class="date-selector" style="width: 110px">
      <input matInput
          [matDatepicker]="myDatepicker"
          [min]="minDate"
          [(ngModel)]="selectedDate"
          (dateChange)="dateChangedHandler()"
          name="date"
          required>
      <mat-datepicker-toggle matSuffix [for]="myDatepicker">
      </mat-datepicker-toggle>
      <mat-datepicker touchUi #myDatepicker disabled="false">
      </mat-datepicker>
    </mat-form-field>
  </div>

  <div class="time" [hidden]="limitedToSameDay && !isToday()">
    <select class="hours" name="hours" [(ngModel)]="selectedHour" (ngModelChange)="hourChangedHandler()" required>
      <option *ngFor="let hour of hoursArray" [value]="hour">
        {{ hour }}
      </option>
    </select>
    :
    <select class="minutes" name="minutes" [(ngModel)]="selectedMinutes" (ngModelChange)="changedHandler()" required>
      <option *ngFor="let minutes of minutesArray" [value]="minutes">
        {{ minutes }}
      </option>
    </select>
  </div>

  <div class="errors">
    <p *ngIf="limitedToSameDay && !isToday()">{{ 'ORDER_TODAY_NOT_POSSIBLE' | translate }}</p>
    <p *ngIf="shopIsClosed" [hidden]="limitedToSameDay && !isToday">{{ 'SHOP_NOT_OPEN' | translate }}</p>
  </div>

</div>
