import { Component, Input, Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import * as moment from 'moment';
import { FirmService } from '../services/firm.service';

/* Sanitize pipe for Google Maps url */
@Pipe({ name: 'safe' })
export class SafePipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}
  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}

@Component({
  selector: 'app-firm-info',
  templateUrl: './firm-info.component.html',
  styleUrls: ['./firm-info.component.css']
})
export class FirmInfoComponent{

  @Input() firm: any;

  url: any;
  firm_info: any;
  sales_terms: any;
  opening_hours: any;
  holidayPeriods: any;
  storeOpen: boolean;
  storeUnknown: boolean = false;
  showSocialMedia: boolean;
  hideEmail = false;

  constructor(public firmService: FirmService, public route: ActivatedRoute) {
    window.scrollTo(0, 0);
  }

  async ngOnChanges() {
    if(this.firm) {
      let sm = this.firm.social_media;

      if(sm.facebook == null && sm.instagram == null && sm.twitter == null && sm.google == null && sm.linkedin == null) {
        this.showSocialMedia = false;
      }
      else {
        this.showSocialMedia = true;
      }

      // Get firm info
      if(this.firm.info && this.firm.info.length > 0) {
        this.firm_info = await this.firm.info.find(info => info.content_type === 'firm_info');
      }

      // get sales terms link
      if(this.firm.links.length > 0) {
        this.sales_terms = this.firm.links[0].link;
      }

      // Set locale
      moment.locale(this.route.snapshot.params.language);

      if(this.firm.periods.opening_hours) {
        this.opening_hours = await this.firmService.setHours(this.firm.periods.opening_hours);
      }

      this.holidayPeriods = await this.firmService.getHolidayPeriods(this.firm.periods.holiday_period);

      this.storeOpen = await this.firmService.isShopOpen(this.firm.periods.holiday_period, this.firm.periods.opening_hours);

      this.url = "https://www.google.com/maps/embed/v1/place?q=" + this.firm.address + ",+" + this.firm.zipcode + "+" + this.firm.locality + "&key=AIzaSyBjz5xKoGi6LnvxRElWyjSWhby5CNS9a8c";
    }
  }

  

}