import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Units } from '../constants/units';

@Component({
  selector: 'eo-weight-selector',
  template: `
    <a
      href="javascript:void(0)"
      class="btn-fill subtract"
      [ngClass]="disableSubtractBtn() ? 'disabled' : 'enabled'"
      (click)="subtract()"
    >
      -
    </a>
    <a
      href="javascript:void(0)"
      class="btn-fill add"
      [ngClass]="disableAddBtn() ? 'disabled' : 'enabled'"
      (click)="add()"
    >
      +
    </a>
    <div class="interval">
      <p *ngIf="currentUnit === units.GRAM">{{ quantity }}</p>
      <p *ngIf="currentUnit === units.KG">{{ quantity / 1000 }}</p>
      <span *ngIf="currentUnit === units.GRAM" class="font-12" (click)="currentUnit = units.KG" tappable>
        {{ 'GRAMS' | translate }}
        <i class="fas fa-chevron-down font-10"></i>
      </span>
      <span *ngIf="currentUnit === units.KG" class="font-12" (click)="currentUnit = units.GRAM" tappable>
        {{ 'KG' | translate }}
        <i class="fas fa-chevron-down font-10"></i>
      </span>
    </div>
  `,
  styleUrls: ['./quantity-selector.component.css'],
  styles: [
    `
      :host {
        display: flex;
        align-items: center;
      }

      .interval {
        display: inline-block;
        margin-left: 10px;
        height: 20px;
      }

      .interval p {
        display: inline-block;
        font-weight: 700;
        margin-right: 2px;
      }
    `,
  ],
})
export class WeightSelectorComponent implements OnInit {
  @Input() quantity: number;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  @Input() interval: number;
  @Input() unitId: number;

  @Output() quantityChanged = new EventEmitter<number>();

  units = Units;

  currentUnit: number;

  constructor() {}

  ngOnInit() {
    this.currentUnit = this.unitId;
  }

  subtract() {
    this.quantity -= this.interval;
    this.changeHandler();
  }

  add() {
    this.quantity += this.interval;
    this.changeHandler();
  }

  disableSubtractBtn() {
    return this.quantity <= this.interval || this.quantity <= this.minQuantity;
  }

  disableAddBtn() {
    return this.maxQuantity ? this.quantity >= this.maxQuantity : false;
  }

  changeHandler() {
    this.quantityChanged.emit(this.quantity);
  }
}
