<!-- Firm info -->
<div class="centered" id="info" [style.color]="firm?.theme.main_gradient_contrast_color">

  <div class="row shop-info">

    <div class="col half">

      <h2 class="title-font h1">{{ firm?.name }}</h2>

      <!-- Contact information -->
      <div class="contact-info">
        <a class="tel" href="tel:{{ firm?.phone }}" *ngIf="firm?.phone"><i class="fas fa-phone-volume icon"></i><span>{{ firm?.phone }}</span></a>
        <eo-email-label [email]="firm?.email" [hide]="firm?.hide_email"></eo-email-label>
        <a class="sales-terms" [href]="sales_terms" *ngIf="sales_terms"><i class="fas fa-file-pdf icon"></i><span>{{ 'SALES_TERMS' | translate }}</span></a>
      </div>

      <!-- Firm description -->
      <div *ngIf="firm_info">{{ firm_info?.content }}</div>

      <!-- Social media -->
      <div class="social-media" *ngIf="showSocialMedia">
        <div *ngIf="firm?.social_media.facebook">
          <a [href]="firm?.social_media.facebook" class="center">
            <i class="fab fa-facebook-square font-30 color-fb"></i>
          </a>
        </div>
        <div *ngIf="firm?.social_media.instagram">
          <a [href]="firm?.social_media.instagram" class="center">
            <img src="assets/img/ig_logo.png" width=35 height=35>
          </a>
        </div>
        <div *ngIf="firm?.social_media.twitter">
          <a [href]="firm?.social_media.twitter" class="center">
            <i class="fab fa-twitter font-30 color-twitter"></i>
          </a>
        </div>
        <div *ngIf="firm?.social_media.google">
          <a [href]="firm?.social_media.google" class="center">
            <i class="fab fa-google-plus font-30 color-google"></i>
          </a>
        </div>
        <div *ngIf="firm?.social_media.linkedin">
          <a [href]="firm?.social_media.linkedin" class="center">
            <i class="fab fa-linkedin font-30 color-linkedin"></i>
          </a>
        </div>
      </div>

      <!-- Opening hours -->
      <div *ngIf="firm?.periods.opening_hours">
        <h2 class="title-font">{{ 'INFO.OPENING_HOURS' | translate }}
          <span *ngIf="storeOpen" class="firm_open">{{ 'INFO.OPEN' | translate }}</span>
          <span *ngIf="!storeOpen" class="firm_closed">{{ 'INFO.CLOSED' | translate }}</span>
        </h2>

        <ul class="hours">
          <li class="row" *ngFor="let day of opening_hours">
            <div class="day">{{day.day}}</div>

            <!-- Catch bad formatted hours -->
            <div *ngIf="day.am && !day.pm && day.am?.type === 'open'" class="hour">{{day.am.from_time}}
              - {{day.am.to_time}}</div>
            <div *ngIf="day.am && !day.pm && day.am?.type === 'closed'"
                 class="hour">{{ 'INFO.CLOSED' | translate }}</div>
            <div *ngIf="!day.am && day.pm && day.pm?.type === 'open'" class="hour">{{day.pm.from_time}}
              - {{day.pm.to_time}}</div>
            <div *ngIf="!day.am && day.pm && day.pm?.type === 'closed'"
                 class="hour">{{ 'INFO.CLOSED' | translate }}</div>

            <!-- Show normal hours -->
            <div *ngIf="(day.am && day.pm) && (day.am?.type === 'open' && day.pm?.type === 'closed')"
                 class="hour">{{day.am.from_time}} - {{day.am.to_time}}</div>
            <div *ngIf="(day.am && day.pm) && (day.am?.type === 'closed' && day.pm?.type === 'open')"
                 class="hour">{{day.pm.from_time}} - {{day.pm.to_time}}</div>
            <div *ngIf="(day.am && day.pm) && (day.am?.type === 'open' && day.pm?.type === 'open')"
                 class="hour">{{day.am.from_time}} - {{day.am.to_time}} & {{day.pm.from_time}}
              - {{day.pm.to_time}}</div>
            <div *ngIf="(day.am && day.pm) && (day.am?.type === 'closed' && day.pm?.type === 'closed')"
                 class="hour closed">{{ 'INFO.CLOSED' | translate }}</div>
          </li>
        </ul>
      </div>

      <!-- Vacation periods -->
      <div *ngIf="holidayPeriods?.length > 0" style="margin-top: 1rem;">
        <h2 class="title-font">{{ 'INFO.HOLIDAYS' | translate }}</h2>
        <div padding>
          <p class="holiday" *ngFor="let holiday of holidayPeriods">
            {{ holiday.from_date }} - {{ holiday.to_date }}
          </p>
        </div>
      </div>

      <br/>
      <br/>
      <p *ngIf="firm?.pickup && !firm?.delivery">{{ 'INFO.PICKUP' | translate }}</p>
      <p *ngIf="!firm?.pickup && firm?.delivery">{{ 'INFO.DELIVERY' | translate }}</p>
      <p *ngIf="firm?.pickup && firm?.delivery">{{ 'INFO.PICKUP_AND_DELIVERY' | translate }}</p>

    </div>

    <!-- Google Maps -->
    <div class="col half" *ngIf="url">
      <div class="google-maps">
        <iframe width="100%" height="450" frameborder="0" style="border:0" [src]="url | safe"></iframe>
      </div>
      <div class="address">
        <a class="location"
           href="https://www.google.be/maps/place/{{firm?.address}}+{{firm?.locality}}+{{firm?.country.name}}"
           target="_blank">
          <span class="icon location"></span>
          <span class="address">{{ firm?.address }}, {{ firm?.zipcode }} {{ firm?.locality }}
            , {{ firm?.country.name }}</span>
        </a>
      </div>
    </div>

  </div>
</div>
