import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DialogsService } from '../services/dialogs.service';

@Component({
  selector: 'app-vat-delivery-method-opener',
  templateUrl: './vat-delivery-method-opener.component.html',
  styleUrls: ['./vat-delivery-method-opener.component.css']
})
export class VatDeliveryMethodOpenerComponent implements OnInit {

  @Input() firm;
  @Output() vatChanged;

  pickup_label: any;
  vat_delivery_method: any;
  openVat: boolean;
 
  constructor(public dialogsService: DialogsService) { 
    this.vatChanged = new EventEmitter<any>();
  }

  async ngOnInit() {
    if(this.firm.uses_vat && this.firm.vat_settings.allowed_vat_percentages.length > 1) {
      this.pickup_label = this.firm.labels.pickup_store;
      
      let vat_delivery_method = await localStorage.getItem('vat_delivery_method');

        if(vat_delivery_method) {
            let method = this.firm.vat_settings.delivery_method.some((method) => method.method == vat_delivery_method);
            if(method == false) {
              this.openVatModal();
            }
            else {
              this.vat_delivery_method = vat_delivery_method;
              this.vatChanged.emit(vat_delivery_method);
            }
        }
        else {
          this.openVatModal();
        }
    }
  }

  openVatModal() {
    this.dialogsService.openVATModal(this.firm)
        .subscribe(res => {
          if(res) {
            this.vat_delivery_method = res;
            this.vatChanged.emit(res);
          }
        });
  }

}
