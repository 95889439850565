<div *ngIf="!firm?.uses_vat || (firm?.uses_vat && !firm?.show_vat_in_frontend)">
  <div class="basketsummary"
    [style.background-color]="firm?.theme.container_color"
    [style.color]="firm?.theme.container_contrast_color">

    <div class="title">{{ 'CHECKOUT_SIDEBAR.MY_ORDER' | translate }}</div>

    <ul class="products clearfix">
      <div *ngIf="basketItems?.length">
        <li *ngFor="let item of basketItems" class="basket-listing">
          <div
            class="basket_image"
            [style.background-color]="firm?.theme.well_color">
            <img src="{{ item.item.item.images[0].thumb }}" alt="">
            <span class="count"
                  [style.background-color]="firm?.theme.theme_color"
                  [style.color]="firm?.theme.theme_color_contrast_color"
            >{{ item.item.item.unit.id === units.KG ? item.item.amount / 1000 : item.item.amount }}</span>
          </div>
          <div class="basket_product">{{ item.item.name }} </div>
        </li>
      </div>
      <div *ngIf="basketItems?.length == 0">
        <p>{{ 'BASKET.EMPTY_MESSAGE' | translate }}</p>
      </div>
    </ul>

    <div class="prices">
      <!-- Discount -->
      <div class="total price" *ngIf="order?.discount_percentage > 0">
        {{ 'CUSTOMER_DISCOUNT' | translate }}
        <strong class="float-right">{{ order?.discount_percentage }}%</strong>
        <hr>
      </div>

      <div class="price">
        {{ 'CHECKOUT_SIDEBAR.SUBTOTAL' | translate }}
        <strong>{{ firm?.currency.symbol }}{{ basketItemsService.basketPrice | number:'1.2-2' }}</strong>
      </div>
      <div *ngIf="order?.delivery_price" class="price">
        {{ 'CHECKOUT_SIDEBAR.DELIVERY' | translate }}
        <strong>{{ firm?.currency.symbol }}{{ order.delivery_price | number:'1.2-2' }}</strong>
      </div>
      <div
        *ngIf="(order?.delivery_method != 'pickup' && order?.delivery_method != undefined) && order?.delivery_price == 0"
        class="price">
        {{ 'CHECKOUT_SIDEBAR.FREE_DELIVERY' | translate }}
      </div>

      <hr>

      <!-- Loyalty -->
      <div class="total price" *ngIf="firm?.use_loyalty && order?.add_loyalty_to_order && firm?.loyalty?.loyalty_type == 'discount'">
        <div *ngIf="firm?.loyalty.discount_type == 'amount'">{{ 'LOYALTY_CARD' | translate }}
          <strong>- {{ firm?.currency.symbol }}{{ firm?.loyalty.discount_value | minimumPrice | number:'1.2-2' }}</strong>
        </div>
        <div *ngIf="firm?.loyalty.discount_type == 'percentage'"> - {{ 'LOYALTY_CARD' | translate }}
          <strong>- {{ firm?.loyalty.discount_value | number:'1.2-2' }}%</strong>
        </div>
      </div>

      <!-- Promo code -->
      <div *ngIf="coupon" class="price">
        {{ 'PROMOTION_CODE' | translate }}
        <strong *ngIf="coupon.type === 'percentage'">- {{ coupon.value }}%</strong>
        <strong *ngIf="coupon.type === 'amount'">{{ firm?.currency.symbol }}{{ coupon.value | number:'1.2-2' }}</strong>
      </div>

      <!-- Service fee -->
      <div *ngIf="firm?.service_fee?.length > 0">
        <div *ngFor="let service_fee of firm?.service_fee" class="price">
          <p *ngIf="service_fee.active &&
                      ((service_fee.online_only && order?.payment_method_id && order?.payment_method_id != 1) || !service_fee.online_only) &&
                      ((service_fee.free_from && sub_total < service_fee.free_from_amount) || !service_fee.free_from) &&
                      ((service_fee.firm_pickup_point_id === order?.firm_pickup_point_id) || (service_fee.firm_pickup_point_id === order?.chosen_pickup_point_id) || !service_fee.firm_pickup_point_id)">
            {{ service_fee?.name?.toLowerCase() }}
            <strong *ngIf="service_fee.fee_choice === serviceFee.FEE">+ {{ firm?.currency.symbol }}{{ service_fee.fee }}</strong>
            <strong *ngIf="service_fee.fee_choice === serviceFee.PERCENTAGE">+ {{ firm?.currency.symbol }}{{ (sub_total / 100) * service_fee.percentage | number:'1.2-2' }}</strong>
          </p>
        </div>
      </div>

      <hr>
      
      <div class="total price">
        {{ 'CHECKOUT_SIDEBAR.TOTAL' | translate }}
        <strong>{{ firm?.currency.symbol }}{{ total_price | number:'1.2-2' }}</strong>
      </div>

    </div>
  </div>
</div>
