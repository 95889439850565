import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { FirmService } from '../services/firm.service';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-demo-password',
  templateUrl: './demo-password.component.html',
  styleUrls: ['./demo-password.component.css']
})
export class DemoPasswordComponent implements OnInit {

  distributor: any;
  firm: any;
  firm_name: any;
  own_domain: boolean;
  password: any;
  password_error: boolean;

  constructor(public auth: AuthService, public firmService: FirmService, public route: ActivatedRoute , public router: Router, public languageService: LanguageService) {
    this.firm_name = this.route.snapshot.params.firm_name;
  }

  async ngOnInit() {
    this.distributor = await this.firmService.getDistributor();
    this.firm = await this.firmService.getFirm(this.firm_name, undefined);
  }

  async enterDemoPassword() {
    let response: any = await this.firmService.enterDemoPassword(this.password, this.firm.id, 'nl');

    if(response == "PASSWORD_INCORRECT") {
      this.password_error = true;
    }
    else if(response.demoPasswordToken) {
      this.password_error = false;
      await localStorage.setItem('demo_password_token', JSON.stringify(response.demoPasswordToken));
      this.router.navigate([this.firm.website_details.sitename]);
    }
  }
}
