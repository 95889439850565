import { Component } from '@angular/core';

@Component({
  selector: 'eo-quantity-selector',
  templateUrl: './quantity-selector.component.html',
  styleUrls: ['./quantity-selector.component.css'],
})
export class QuantitySelectorComponent {
  constructor() {}
}
