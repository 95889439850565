<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.USER_DETAILS' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="firmService.checkEmbed() == false" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="pagecontent centered">
      <h1 class="title-font title">{{ 'HEADER.SETTINGS' | translate }}</h1>

      <div class="row">

        <hr class="line">

        <!-- Privacy Policy -->
        <a [href]="firm?.distributor.privacy_policy_link" target="_blank">
          <div class="setting">
            <p class="settings-label">Privacy Policy</p>
            <button mat-button class="settings-btn">></button>
          </div>
        </a>

        <hr class="line">

        <!-- Terms of Use -->
        <a [href]="firm?.distributor.terms_link" target="_blank">
          <div class="setting">
            <p class="settings-label">Terms Of Use</p>
            <button mat-button class="settings-btn">></button>
          </div>
        </a>

        <hr class="line">

        <!-- Terms of Use -->
        <a [href]="firm?.distributor.cookies_link" target="_blank">
          <div class="setting">
            <p class="settings-label">Cookie Policy</p>
            <button mat-button class="settings-btn">></button>
          </div>
        </a>

        <hr class="line">

        <!-- Give rating -->
        <a href="https://itunes.apple.com/us/app/easyorder-store/id1373149188?mt=8" target="_blank">
          <div class="setting">
            <p class="settings-label">{{ 'HEADER.RATING' | translate }}</p>
            <button mat-button class="settings-btn">></button>
          </div>
        </a>

        <hr class="line">

        <!-- About -->
        <a [href]="firm?.distributor.info_link" target="_blank">
          <div class="setting">
            <p class="settings-label">{{ 'HEADER.ABOUT_EO' | translate }} <small>(production v4.0.3)</small></p>
            <button mat-button class="settings-btn">></button>
          </div>
        </a>

        <hr class="line">

      </div>

    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain"
                [language]="language"></app-footer>

  </div>
</body>
