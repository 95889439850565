import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { CustomerService } from '../services/customer.service';
import { DialogsService } from '../services/dialogs.service';
import { FavoritesService } from '../services/favorites.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { ProductService } from '../services/products.service';
import { SlugifyService } from '../services/slugify.service';
import { RouteService } from '../services/route.service';
import { Units } from '../constants/units';

@Component({
  selector: 'app-item',
  templateUrl: './item.component.html',
  styleUrls: ['./item.component.css'],
})
export class ItemComponent implements OnInit {
  moment = moment;
  units = Units;

  favorite: any;
  own_domain: any;
  firm: any;
  firm_name: any;
  cat_id: any;
  cat_name: any;
  language: any;
  subcat_id: any;
  subcat_name: any;
  subcategory = false;
  parent_category: any;
  prod_name: any;
  prod_id: any;
  item: any;
  mainString: any;
  optionGroupString: any;
  mainExists: boolean;
  promo_from: any;
  promo_to: any;
  socialUrl: any;
  twitterUrl: any;
  imageUrl: any;
  imageIndex: any;

  /* VAT */
  itemDisabled: any;
  VAT_percentage: any;
  VAT: any;
  VAT_total: any;
  pickup_label: any;
  openVat = false;
  vat_delivery_method: any;

  constructor(
    public auth: AuthService,
    public firmService: FirmService,
    public productService: ProductService,
    public router: Router,
    public route: ActivatedRoute,
    private dialogsService: DialogsService,
    public slug: SlugifyService,
    public loader: LoaderService,
    public favoritesService: FavoritesService,
    public translate: TranslateService,
    public customerService: CustomerService,
    public routeService: RouteService
  ) {
    window.scrollTo(0, 0);

    // Get route params
    this.cat_name = route.snapshot.params.cat_name;
    this.language = route.snapshot.params.language;

    this.firm_name = this.route.snapshot.params.firm_name;
    this.cat_id = this.route.snapshot.params.cat_id;
    this.cat_name = this.route.snapshot.params.cat_name;
    this.prod_name = this.route.snapshot.params.prod_name;
    this.prod_id = this.route.snapshot.params.prod_id;

    if (this.route.snapshot.params.subcat_id && this.route.snapshot.params.subcat_name) {
      this.subcategory = true;
      this.subcat_id = this.route.snapshot.params.subcat_id;
      this.subcat_name = this.route.snapshot.params.subcat_name;
    }
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      moment.locale(this.language);
      this.getParentCategory();
      this.initData();
    });
  }

  async initData() {
    if (this.firmService.checkWebsite(this.firm, this.language) && this.firmService.checkForPassword(this.firm)) {
      this.loader.present();

      this.item = await this.productService.getItemById(
        this.firm.id,
        this.language,
        this.prod_id,
        this.firm.uses_deliverect
      );

      if (this.item.option_groups) {
        this.item.option_groups = this.item.option_groups.sort((a, b) => a.sequence - b.sequence);
      }

      this.customerService.getCustomer(this.firm, this.language, this.own_domain);

      if (this.firm.uses_vat == true) {
        this.calculateVat();
      }

      await this.checkFavorite();

      /* Check if ingredients exist
        if exists => make string out of ingredients */
      if (this.item.ingredient_groups.length > 0) {
        this.mainString = '';

        this.item.ingredient_groups.forEach((ingr_group) => {
          ingr_group.ingredients.forEach((ingredient) => {
            if (ingredient.type === 'main' && ingredient.active) {
              this.mainString += ingredient.name + ', ';
              this.mainExists = true;
            }
          });
        });
        this.mainString = this.mainString.slice(0, -2);
      }

      // Check for promo
      if (this.item.has_promotional_price) {
        this.promo_from = moment(this.item.promotion.from).local().format('LL');
        this.promo_to = moment(this.item.promotion.till).local().format('LL');
      }

      // make facebook url
      this.constructSocialMediaUrls();

      this.productService.isDateWithinCurrentYear(this.item.snooze_end);

      this.loader.dismiss();
    }
  }

  async getParentCategory() {
    this.parent_category = await this.productService.getCategoryById(
      this.firm.id,
      this.language,
      this.cat_id,
      this.firm.uses_deliverect
    );
    this.parent_category.isAvailable = this.productService.isAvailable(
      this.parent_category.availabilities,
      this.firm.uses_deliverect
    );
    if (!this.parent_category.isAvailable) {
      this.parent_category.firstAvailableDate = await this.productService.getFirstAvailableDate(
        this.parent_category.availabilities
      );
    }
  }

  async constructSocialMediaUrls() {
    // FACEBOOK
    const fbButton = document.getElementById('fb-share-button');
    if (fbButton) {
      fbButton.addEventListener('click', function () {
        window.open(
          'https://www.facebook.com/sharer/sharer.php?u=' + location.href,
          'facebook-share-dialog',
          'width=800,height=600'
        );
        return false;
      });
    }

    // TWITTER
    this.twitterUrl = 'https://twitter.com/intent/tweet?url=' + location.href + '&hashtags=EasyOrder';
  }

  async calculateVat() {
    this.pickup_label = this.firm.labels.pickup_store;

    this.VAT_percentage = await this.productService.getVatPercentage(this.item, this.firm);

    if (this.VAT_percentage != undefined) {
      this.itemDisabled = false;

      if (this.item.has_promotional_price == false) {
        this.VAT = (this.item.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.price;
      } else if (this.item.has_promotional_price == true) {
        this.VAT = (this.item.promotion.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.promotion.price;
      }
    } else {
      this.itemDisabled = true;
    }
  }

  async checkFavorite() {
    const favorites = await this.favoritesService.get(this.firm.id);

    favorites.forEach((favorite) => {
      if (favorite.item_id == this.item.id) {
        this.favorite = true;
      } else {
        this.favorite = false;
      }
    });
  }

  addToFavorites() {
    this.favoritesService
      .add(this.item.id, this.firm.id)
      .then(() => {
        if (this.favorite) {
          this.favorite = false;
        } else {
          this.favorite = true;
        }
      })
      .catch((err) => {
        this.dialogsService.openError(
          this.translate.instant('DIALOGS.ERROR'),
          this.translate.instant('DIALOGS.ADD_FAVORITES_ERROR'),
          false
        );
      });
  }

  async openModal() {
    const product: any = await this.productService.getItemById(
      this.firm.id,
      this.language,
      this.item.id,
      this.firm.uses_deliverect
    );
    product.vat_percentage = this.VAT_percentage;

    this.dialogsService.openModal(product, this.firm, this.cat_id, this.language);
  }

  vatDeliveryMethodChanged(event) {
    this.vat_delivery_method = event;
  }
}
