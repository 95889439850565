<div class="eo-modal-container">
  <div class="header-container">
    <p class="header">{{ header }}</p>
    <div (click)="cancelHandler()">
      <i class="fas fa-times"></i>
    </div>
  </div>
  <div class="content-container">
    <p class="subheader">{{ subHeader }}</p>

    <ng-content></ng-content>

    <div class="button-container">
      <button
        (click)="submitHandler()"
        [disabled]="disableButton">
        {{ buttonLabel }}
      </button>
    </div>
  </div>
</div>
