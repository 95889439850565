import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { BasketItemsService } from '../services/basket-items.service';
import { Units } from '../constants/units';
import { ServiceFee } from '../constants/service_fee';

@Component({
  selector: 'app-order-side-bar',
  templateUrl: './order-side-bar.component.html',
  styleUrls: ['./order-side-bar.component.css'],
})
export class OrderSideBarComponent implements OnInit {
  readonly serviceFee = ServiceFee;

  @Input() firm;
  @Input() order;
  @Input() basketItems;
  @Input() sub_total;
  @Input() total_price;
  @Input() coupon;
  @Input() extraOptions;

  @Output() totalPriceOutput: EventEmitter<any> = new EventEmitter();
  @Output() isFreeOutput: EventEmitter<any> = new EventEmitter();

  units = Units;

  constructor(public basketItemsService: BasketItemsService) {}

  async ngOnInit() {
    this.basketItems = await this.basketItemsService.get(this.firm.id);

    // Order
    if (this.order) {
      if (this.order.sub_total) {
        this.sub_total = this.order.sub_total;
      } else {
        if (!this.firm.uses_vat) {
          this.sub_total = await this.basketItemsService.calculatePrice(this.firm.id);
        } else if (this.firm.uses_vat && !this.firm.show_vat_in_frontend) {
          this.sub_total = await this.basketItemsService.calculateVatPrice(this.firm.id);
        }
      }

      // Check for empty params
      if (this.order.discount_percentage == undefined) {
        this.order.discount_percentage = 0;
      }
      if (this.order.delivery_price == undefined) {
        this.order.delivery_price = 0;
      }
    }
    // No order
    else {
      if (!this.firm.uses_vat) {
        this.sub_total = await this.basketItemsService.calculatePrice(this.firm.id);
      } else if (this.firm.uses_vat && this.firm.show_vat_in_frontend) {
        this.sub_total = await this.basketItemsService.calculateVatPrice(this.firm.id);
      }
      this.order.discount_percentage = 0;
      this.order.delivery_price = 0;
    }

    if (this.firm.pricelists == false) {
      this.order.discount_percentage = 0;
    }

    // Calculate total_price
    this.total_price =
      this.sub_total -
      (this.sub_total / 100) * Number(this.order.discount_percentage) +
      Number(this.order.delivery_price);
    this.addServiceFees();

    this.totalPriceOutput.emit(this.total_price);
  }

  async ngOnChanges() {
    if (!this.firm.uses_vat) {
      this.sub_total = await this.basketItemsService.calculatePrice(this.firm.id, this.basketItems);
    } else if (this.firm.uses_vat && !this.firm.show_vat_in_frontend) {
      this.sub_total = await this.basketItemsService.calculateVatPrice(this.firm.id, this.basketItems);
    }

    this.total_price = this.sub_total - (this.sub_total / 100) * Number(this.order.discount_percentage);

    // Add loyalty
    if (this.order.add_loyalty_to_order) {
      if (this.firm.loyalty.loyalty_type === 'discount') {
        if (this.firm.loyalty.discount_type === 'amount') {
          this.total_price = this.total_price - this.firm.loyalty.discount_value;
        } else if (this.firm.loyalty.discount_type === 'percentage') {
          this.total_price =
            this.total_price.toFixed(2) - (this.total_price.toFixed(2) / 100) * this.firm.loyalty.discount_value;
        }
      }
    }

    // Calculate coupon
    if (this.coupon && (this.coupon.type === 'percentage' || this.coupon.type === 'amount')) {
      if (this.coupon.type === 'percentage') {
        this.total_price = this.total_price - (this.total_price / 100) * this.coupon.value;
      } else {
        this.total_price = this.total_price - this.coupon.value;
        if (this.total_price < 0) {
          this.total_price = 0;
        }
      }
    }

    if (this.order.delivery_price) {
      this.total_price = this.total_price + this.order.delivery_price;
    }

    this.addServiceFees();

    this.checkIsFree();

    this.checkExtraOptions();
  }

  checkIsFree() {
    if (this.total_price == 0 || this.total_price < 0) {
      this.isFreeOutput.emit(true);
    } else {
      this.isFreeOutput.emit(false);
    }
  }

  addServiceFees() {
    if (this.firm.service_fee.length > 0) {
      this.firm.service_fee.forEach((service_fee) => {
        if (
          service_fee.active &&
          ((service_fee.online_only && this.order.payment_method_id && this.order.payment_method_id != 1) ||
            !service_fee.online_only) &&
          ((service_fee.free_from && this.sub_total < service_fee.free_from_amount) || !service_fee.free_from) &&
          (service_fee.firm_pickup_point_id === this.order.firm_pickup_point_id ||
            service_fee.firm_pickup_point_id === this.order.chosen_pickup_point_id ||
            !service_fee.firm_pickup_point_id)
        ) {
          if (service_fee.fee_choice === ServiceFee.FEE) {
            this.total_price = this.total_price + service_fee.fee;
          } else if (service_fee.fee_choice === ServiceFee.PERCENTAGE) {
            this.total_price = this.total_price + (this.sub_total / 100) * service_fee.percentage;
          }
        }
      });
    }
  }

  checkExtraOptions() {
    if (this.extraOptions && this.extraOptions.length > 0) {
      this.extraOptions.forEach((option) => {
        if (option.price > 0) {
          this.total_price += option.price;
        }
      });
    }
  }
}
