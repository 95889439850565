<eo-modal
  [header]="header"
  [subHeader]="subHeader"
  [buttonLabel]="buttonLabel"
  [disableButton]="!addressForm.valid"
  (submitted)="saveHandler()">
  <div class="address-form">
    <form #addressForm="ngForm" novalidate>

      <input
        [(ngModel)]="formAddress.description"
        [placeholder]="'ADDRESS_DESCRIPTION' | translate"
        class="eo-input"
        name="description"
        type="text"
        required>

      <input
        [(ngModel)]="formAddress.street"
        [placeholder]="'STREET_NAME' | translate"
        class="eo-input"
        name="street"
        type="text"
        required>

      <div class="grid numbers-grid">
        <input
          [(ngModel)]="formAddress.house_number"
          [placeholder]="'HOUSE_NUMBER_SHORT' | translate"
          class="eo-input"
          name="house_number"
          type="text"
          required>

        <input
          [(ngModel)]="formAddress.bus"
          [placeholder]="'OPTIONAL_UNIT' | translate"
          class="eo-input"
          name="bus"
          type="text">
      </div>

      <div class="grid locality-grid">
        <input
          [(ngModel)]="formAddress.zip_code"
          [placeholder]="'ZIPCODE' | translate"
          class="eo-input"
          name="zip_code"
          type="text"
          required>

        <input
          [(ngModel)]="formAddress.locality"
          [placeholder]="'LOCALITY' | translate"
          class="eo-input"
          name="locality"
          type="text"
          required>
      </div>

      <select
        [(ngModel)]="formAddress.country.id"
        [class.placeholder]="!formAddress.country.id"
        class="eo-input"
        name="country"
        required>
        <option [value]="undefined" disabled>{{ 'COUNTRY' | translate }}</option>
        <option *ngFor="let country of countries" [value]="country.id">{{ country?.name }}</option>
      </select>

      <div class="default-toggle">
        <mat-slide-toggle
          [(ngModel)]="formAddress.is_default"
          name="is_default"
          ngDefaultControl>
        </mat-slide-toggle>
        <p>{{ 'SET_DEFAULT_ADDRESS' | translate }}</p>
      </div>
    </form>
  </div>
</eo-modal>
