import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';

@Component({
  selector: 'app-info',
  templateUrl: './info.component.html',
  styleUrls: ['./info.component.css']
})
export class InfoComponent implements OnInit {

  firm: any;
  firm_name: any;
  language: any;
  own_domain: boolean;

  constructor(public firmService: FirmService, 
              public route: ActivatedRoute) {
    window.scrollTo(0, 0);

    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
   }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.firmService.checkWebsite(this.firm, this.language);
      this.firmService.checkForPassword(this.firm);
    });
  }

}