<div class="vat_delivery_method" *ngIf="firm?.uses_vat && firm.vat_settings.allowed_vat_percentages.length > 1">
  <a (click)="openVat == false ? openVat = true : openVat = false">
      <p class="title bold">{{ 'RECENT_ORDERS.DELIVERY_METHOD' | translate }}: <i class="fas fa-sort-down float-right"></i></p>
  </a>

  <div *ngIf="openVat == true">
      <div *ngIf="!vat_delivery_method">
          <p>{{ 'RECENT_ORDERS.DELIVERY_METHOD' | translate }}</p>
      </div>
      <div *ngIf="vat_delivery_method == 'pickup'">
          <p (click)="openVatModal()">{{ pickup_label }}</p>
      </div>
      <div *ngIf="vat_delivery_method == 'delivery'">
          <p (click)="openVatModal()">{{ 'CHECKOUT.DELIVERY' | translate }}</p>
      </div>
      <div *ngIf="vat_delivery_method == 'table'">
          <p (click)="openVatModal()">{{ 'CHECKOUT.TABLE' | translate }}</p>
      </div>

      <div class="buttons" style="margin-top: 10px">
          <a href="javascript:void(0)" class="btn-fill btn-vat" (click)="openVatModal()"><p>{{ 'ADJUST' | translate }}</p></a>
      </div>
  </div>
</div>