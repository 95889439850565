<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.USER_DETAILS' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="pagecontent centered">

      <h1 class="title-font">{{ 'RECENT_ORDERS.TITLE' | translate }}</h1>

      <div class="row">

        <!-- PENDING ORDERS -->
        <h2 class="title-font title">{{ 'RECENT_ORDERS.STATUS_PENDING' | translate }}</h2>

        <!-- No pending orders found -->
        <p *ngIf="pending_orders?.length == 0">{{ 'RECENT_ORDERS.PENDING_ERROR' | translate }}</p>

        <!-- Pending orders -->
        <div *ngIf="pending_orders?.length > 0">
          <a href="javascript:void(0)" *ngFor="let order of pending_orders"
            (click)="routeService.navigateToOrderPage(own_domain, firm, language, order.id)">
            <hr class="line">
            <div class="setting">
              <i class="fas fa-sync-alt icon"></i>
              <p class="settings-label" *ngIf="order.delivery_method == 'pickup'">{{ 'CHECKOUT.PICKUP' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-label" *ngIf="order.delivery_method == 'delivery'">{{ 'CHECKOUT.DELIVERY' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-label" *ngIf="order.delivery_method == 'table'">{{ 'CHECKOUT.TABLE' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-btn">></p>
            </div>
          </a>
          <hr class="line">
        </div>

        <!-- PENDING ORDERS -->
        <h2 class="title-font title" style="margin-top: 40px">{{ 'RECENT_ORDERS.STATUS_PAST' | translate }}</h2>

        <!-- PAST ORDERS -->
        <p *ngIf="past_orders?.length == 0">{{ 'RECENT_ORDERS.PAST_ERROR' | translate }}</p>

        <!-- Pending orders -->
        <div *ngIf="past_orders?.length > 0">
          <a href="javascript:void(0)" *ngFor="let order of past_orders"
            (click)="routeService.navigateToOrderPage(own_domain, firm, language, order.id)">
            <hr class="line">
            <div class="setting">
              <i class="fas fa-check icon"></i>
              <p class="settings-label" *ngIf="order.delivery_method == 'pickup'">{{ 'CHECKOUT.PICKUP' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-label" *ngIf="order.delivery_method == 'delivery'">{{ 'CHECKOUT.DELIVERY' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-label" *ngIf="order.delivery_method == 'table'">{{ 'CHECKOUT.TABLE' | translate }}
                - {{ order.process_timestamp | date:'dd/MM' }}</p>
              <p class="settings-btn">></p>
            </div>
          </a>
          <hr class="line">
        </div>

      </div>

    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>
</body>
