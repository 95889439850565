<div class="featured-box" *ngIf="featuredProducts.length > 0">
  <h1 class="featured-title title-font">{{ 'FEATURED_PRODUCTS' | translate }}</h1>

  <div class="featured-products">
    <eo-menu-card
      *ngFor="let product of featuredProducts"
      [title]="'MENU.MORE_INFO' | translate"
      [image]="product.images | mainThumb"
      [isGold]="product?.extra_points > 0"
      [points]="product?.extra_points"
      (clicked)="openFeatured(product)">
    </eo-menu-card>
  </div>
</div>
