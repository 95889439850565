<head>
    <title>{{ '404.MESSAGE' | translate }}</title>
</head>
<body>
    <div class="main">

        <!-- Header -->
        <header *ngIf="firm" [style.background-image]="'url('+ firm?.website_details.header_image +')'">
            <div class="width-full no-padding">                
                <div class="mainmenu">
                    <div class="centered">
                        <a href="javascript:void(0)" (click)="routeService.navigateToMenu(own_domain, firm, language)" class="logo">
                            <img *ngIf="firm?.logo_square" [src]="firm?.logo_square" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </header>

        <!-- Content -->
        <div class="pagecontent">
            <div class="centered align-center">
                <p class="color-red font-80">404</p>
                <p class="font-30 message">{{ '404.MESSAGE' | translate }}</p>
                <a class="font-25 redirect" (click)="navigate()">{{ 'BACK_TO_HOMEPAGE' | translate }}</a>
            </div>
        </div>

        <!-- Footer -->
        <app-footer *ngIf="firm && !firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

    </div>

    <div id="iosfix-top"></div>
    <div id="iosfix-btm"></div>
</body>