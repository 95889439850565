<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.CHECKOUT' | translate }}</title>
</head>

<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.main_gradient_contrast_color"
>

<app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

<div class="main checkout">
  <div class="centered">

    <!-- Header -->
    <app-order-header [focused]="'checkout'" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-order-header>

    <div class="pagecontent">
      <div class="row">

        <!-- Date + time -->
        <div class="col {{ firm?.uses_vat && !firm?.show_vat_in_frontend ? 'full' : 'third cols-sm no-half colspan2' }}">
          <form #formDate="ngForm">
            <div class="view-deliverymethod">
              <h1 class="title-font">{{ 'CHECKOUT.PICK_DELIVERY' | translate }}</h1>

              <div class="">
                <!-- Options -->
                <ul class="optionlist" id="delivery-options">

                  <!-- Default pickup -->
                  <li *ngIf="(firm?.pickup && firm?.pickup_points?.length === 0 && !firmHasPickupTimeslots()) || (firm?.pickup && firm?.pickup_points?.length > 0 && pickup_main_branch && !firmHasPickupTimeslots())"class="outlined">

                    <!-- Radio button -->
                    <mat-radio-button [checked]="delivery_method === 'pickup' && !pickup_point" ngDefaultControl
                                      [class.selected]="delivery_method === 'pickup' && !pickup_point"
                                      [disabled]="disablePickupMethod()"
                                      (click)="pickup_point=null; delivery_method='pickup'; checkPickupForMainBranch(); reInitCustomDelivery(); setOrderProcessTimestamp()"
                                      [style.background-color]="firm?.theme.container_color"
                                      [style.color]="firm?.theme.container_contrast_color"
                                      id="pickup-selector"
                                      required>
                      <div class="top-container">
                        <div>
                          <span></span>
                          <i class="fas fa-2x fa-shopping-bag icon method-icon"></i>
                          <p class="inline-block method-title">{{ firm?.labels.pickup_store }}</p>
                          <strong class="delivery_info" *ngIf="disablePickupMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                        </div>
                        <mat-radio-button class="child-radio" labelPosition="before" [checked]="delivery_method === 'pickup' && !pickup_point"></mat-radio-button>
                      </div>
                    </mat-radio-button>

                  </li>

                  <!-- Custom pickup -->
                  <li *ngIf="firm?.pickup && !firmHasPickupTimeslots() && firm?.custom_pickup_methods?.length > 0 && firm?.pickup_points?.length === 0" class="outlined">
                    <div class="custom-container" *ngFor="let cust_pickup_method of firm?.custom_pickup_methods">

                      <!-- Radio button -->
                      <mat-radio-button [checked]="selected_custom_pickup_method_id === cust_pickup_method.id"
                                        [class.selected]="selected_custom_pickup_method_id === cust_pickup_method.id"
                                        (click)="pickup_point=null; delivery_method=cust_pickup_method.id; selectCustomPickupMethod()"
                                        [style.background-color]="firm?.theme.container_color"
                                        [style.color]="firm?.theme.container_contrast_color"
                                        [disabled]="disablePickupMethod()"
                                        ngDefaultControl required>
                        <div class="top-container">
                          <div>
                            <span></span>
                            <i class="fas fa-shopping-bag icon method-icon"></i>
                            <p class="inline-block method-title">{{ cust_pickup_method.name }}</p>
                            <mat-icon *ngIf="cust_pickup_method.description" [matTooltip]="cust_pickup_method.description"><i class="fas fa-question-circle option-description"></i></mat-icon>
                            <strong class="delivery_info" *ngIf="disablePickupMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                          </div>
                          <mat-radio-button class="child-radio" labelPosition="before" [checked]="selected_custom_pickup_method_id === cust_pickup_method.id"></mat-radio-button>
                        </div>
                      </mat-radio-button>
                    </div>
                  </li>

                  <!-- Pickup with Timeslots -->
                  <li *ngIf="(firm?.pickup && !firm?.has_pickup_custom_days && firm?.pickup_points?.length === 0 && firmHasPickupTimeslots()) || (firm?.pickup && firm?.pickup_points?.length > 0 && pickup_main_branch && !firm?.has_pickup_custom_days && firmHasPickupTimeslots())" class="outlined">

                    <!-- Radio button -->
                    <mat-radio-button
                      (click)="pickup_point=null; delivery_method='pickup'; resetOrderAddress(); checkPickupForMainBranch(); reInitCustomDelivery(); onTimeslotsMethodSelect()"
                      [style.background-color]="firm?.theme.container_color"
                      [style.color]="firm?.theme.container_contrast_color"
                      [checked]="delivery_method === 'pickup' && !pickup_point"
                      [class.selected]="delivery_method === 'pickup' && !pickup_point"
                      [disabled]="disablePickupMethod()"
                      ngDefaultControl required
                      id="pickup-selector">
                      <div class="top-container">
                        <div>
                          <span></span> <i class="fas fa-shopping-bag icon method-icon"></i>
                          <p class="inline-block method-title">{{ firm?.labels.pickup_store }}</p>
                          <strong class="delivery_info" *ngIf="disablePickupMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                        </div>
                        <mat-radio-button class="child-radio" labelPosition="before" [checked]="delivery_method === 'pickup' && !pickup_point"></mat-radio-button>
                      </div>
                    </mat-radio-button>

                  </li>

                  <!-- Custom pickup with Timeslots -->
                  <li *ngIf="firm?.pickup && !firm?.has_pickup_custom_days && firmHasPickupTimeslots() && firm?.custom_pickup_methods?.length > 0 && firm?.pickup_points?.length === 0" class="outlined">

                    <div class="custom-container" *ngFor="let cust_pickup_method of firm?.custom_pickup_methods">

                      <!-- Radio button -->
                      <mat-radio-button
                        [checked]="selected_custom_pickup_method_id === cust_pickup_method.id"
                        [class.selected]="selected_custom_pickup_method_id === cust_pickup_method.id"
                        (click)="pickup_point=null; delivery_method=cust_pickup_method.id; selectCustomPickupMethod()"
                        [style.background-color]="firm?.theme.container_color"
                        [style.color]="firm?.theme.container_contrast_color"
                        [disabled]="disablePickupMethod()" ngDefaultControl required>
                        <div class="top-container">
                          <div>
                            <span></span> <i class="fas fa-shopping-bag icon method-icon"></i>
                            <p class="inline-block method-title">{{ cust_pickup_method.name }}</p>
                            <mat-icon *ngIf="cust_pickup_method.description" [matTooltip]="cust_pickup_method.description"><i class="fas fa-question-circle option-description"></i></mat-icon>
                            <strong class="delivery_info" *ngIf="disablePickupMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                          </div>
                          <mat-radio-button class="child-radio" labelPosition="before" [checked]="selected_custom_pickup_method_id === cust_pickup_method.id"></mat-radio-button>
                        </div>
                      </mat-radio-button>

                    </div>

                  </li>

                  <!-- Pickup points (with datepicker or timeslots) -->
                  <li *ngIf="firm?.pickup && firm?.pickup_points?.length > 0" class="outlined">

                    <!-- Radio button -->
                    <mat-radio-button
                      (click)="reInitCustomDelivery(); pickup_point=true; delivery_method='pickup'; selectFirstPickupPoint();"
                      [checked]="delivery_method === 'pickup' && pickup_point" ngDefaultControl
                      [class.selected]="delivery_method === 'pickup' && pickup_point"
                      [style.background-color]="firm?.theme.container_color"
                      [style.color]="firm?.theme.container_contrast_color"
                      [disabled]="disablePickupMethod()" required
                      id="pickup_points-selector">
                      <div class="top-container">
                        <div>
                          <span></span> <i class="fas fa-map-marker-alt inline-block icon method-icon"></i>
                          <p class="inline-block method-title">{{ 'PICKUP_POINT' | translate }}</p>
                          <strong class="delivery_info" *ngIf="disablePickupMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                        </div>
                        <mat-radio-button class="child-radio" labelPosition="before" [checked]="delivery_method === 'pickup' && pickup_point"></mat-radio-button>
                      </div>
                    </mat-radio-button>

                    <div class="contents" *ngIf="delivery_method === 'pickup' && pickup_point">
                      <div class="wrap">

                        <div class="radiogroup">
                          <p style="margin: 10px 0 10px 0">{{ 'CHECKOUT.PICKUP_POINT' | translate }}</p>

                          <mat-radio-group [(ngModel)]="pickup_point_id" name="pickuppoint" required>
                            <mat-radio-button
                              id="pickuppoint-{{pickup.id}}"
                              class="pickup_radio width-full" value="{{pickup.id}}"
                              ngDefaultControl
                              [style.background-color]="firm?.theme.container_color"
                              [style.color]="firm?.theme.container_contrast_color"
                              *ngFor="let pickup of firm?.pickup_points"
                              (click)="order.firm_pickup_point_id=pickup.id; order.chosen_pickup_point=pickup; order.chosen_pickup_point_id=pickup.id; hasPickupPointTimeslots() ? onTimeslotsMethodSelect() : setOrderProcessTimestamp(); forceChange()"
                              [disabled]="pickup.temporary_inactive">
                              <div class="top-container">
                                <div>
                                  <p class="method-title">{{ pickup.name }}  <small class="delivery-info">{{ pickup.address }}, {{ pickup.zipcode }} {{ pickup.locality }}</small></p>
                                </div>
                                <mat-radio-button
                                  class="child-radio"
                                  labelPosition="before"
                                  [checked]="order.firm_pickup_point_id === pickup.id">
                                </mat-radio-button>
                              </div>
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>
                  </li>

                  <!-- Default delivery / Custom delivery -->
                  <li *ngIf="firm?.delivery"
                    class="outlined">

                    <!-- Radio button -->
                    <mat-radio-button
                      id="delivery-selector"
                      value="delivery"
                      (click)="selectMainDeliveryMethod()"
                      [disabled]="deliveryMethodIsTemporarilyInactive()"
                      [checked]="delivery_method === 'delivery' && !selected_custom_delivery_method_id"
                      [class.selected]="delivery_method === 'delivery' && !selected_custom_delivery_method_id"
                      [style.background-color]="firm?.theme.container_color"
                      [style.color]="firm?.theme.container_contrast_color"
                      ngDefaultControl>
                      <div class="top-container">
                        <div>
                          <span></span> <i class="fas fa-truck inline-block icon method-icon"></i>
                          <p class="method-title inline-block">{{ 'CHECKOUT.DELIVERY' | translate }} </p>
                          <ng-container *ngIf="deliveryMethodIsTemporarilyInactive()">
                            <strong class="delivery_info">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                          </ng-container>
                        </div>
                        <mat-radio-button value="delivery" class="child-radio" labelPosition="before" [checked]="delivery_method === 'delivery' && !selected_custom_delivery_method_id"></mat-radio-button>
                      </div>
                      <div class="content-container" *ngIf="mainDeliveryIsSelected()">
                        <ng-container *ngTemplateOutlet="deliveryInfo"></ng-container>
                      </div>
                      <div class="bottom-container"></div>
                    </mat-radio-button>

                    <!-- Custom delivery methods -->
                    <div class="custom-delivery" *ngIf="firm?.custom_delivery_methods?.length > 0">
                      <div class="custom-container" *ngFor="let cust_delivery_method of firm?.custom_delivery_methods">
                        <mat-radio-button [value]="cust_delivery_method.id" ngDefaultControl
                                          [style.background-color]="firm?.theme.container_color"
                                          [style.color]="firm?.theme.container_contrast_color"
                                          [disabled]="deliveryMethodIsTemporarilyInactive()"
                                          [class.selected]="selected_custom_delivery_method_id === cust_delivery_method.id"
                                          [checked]="selected_custom_delivery_method_id === cust_delivery_method.id"
                                          (click)="delivery_method=cust_delivery_method.id; selectCustomDeliveryMethod(cust_delivery_method.id)">
                          <div class="top-container">
                            <div>
                              <span></span> <i class="fas fa-truck inline-block icon method-icon"></i>
                              <p class="inline-block method-title">{{ cust_delivery_method.name }} </p>
                              <mat-icon *ngIf="cust_delivery_method.description" [matTooltip]="cust_delivery_method.description"><i class="fas fa-question-circle option-description"></i></mat-icon>
                              <ng-container *ngIf="deliveryMethodIsTemporarilyInactive()">
                                <strong class="delivery_info">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                              </ng-container>
                            </div>
                            <mat-radio-button [value]="cust_delivery_method.id" class="child-radio" labelPosition="before" [checked]="selected_custom_delivery_method_id === cust_delivery_method.id"></mat-radio-button>
                          </div>
                          <div class="content-container" *ngIf="selected_custom_delivery_method_id === cust_delivery_method.id">
                            <ng-container *ngTemplateOutlet="deliveryInfo"></ng-container>
                          </div>
                          <div class="bottom-container"></div>
                        </mat-radio-button>
                      </div>
                    </div>

                  </li>

                  <!-- Table -->
                  <li *ngIf="firm?.table" class="outlined">

                    <!-- Default table -->
                    <mat-radio-group [(ngModel)]="delivery_method" name="delivery_method" (change)="table_number = undefined; resetOrderAddress(); reInitCustomDelivery()" required>
                      <mat-radio-button [checked]="delivery_method === 'table' && !selected_custom_table_method_id"
                                        [class.selected]="delivery_method === 'table' && !selected_custom_table_method_id"
                                        (click)="reInitCustomDelivery(); delivery_method = 'table'; setOrderDeliveryPrice(0)"
                                        value="table"
                                        [disabled]="disableTableMethod()"
                                        [style.background-color]="firm?.theme.container_color"
                                        [style.color]="firm?.theme.container_contrast_color"
                                        id="table-selector"
                                        ngDefaultControl>
                        <div class="top-container">
                          <div>
                            <span></span> <i class="far fa-dot-circle icon method-icon"></i>
                            <p class="inline-block method-title">{{firm?.labels.table}}</p>
                            <strong class="delivery_info" *ngIf="disableTableMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                          </div>
                          <mat-radio-button value="table" class="child-radio" labelPosition="before" [checked]="delivery_method === 'table'"></mat-radio-button>
                        </div>
                      </mat-radio-button>
                    </mat-radio-group>

                  </li>

                  <!-- Custom table -->
                  <li *ngIf="firm?.custom_table_methods?.length > 0" class="outlined">
                    <div class="custom-container" *ngFor="let cust_table_method of firm?.custom_table_methods">

                      <mat-radio-group [(ngModel)]="delivery_method" name="delivery_method"
                                       (change)="selectCustomTableMethod(cust_table_method); table_number = undefined" required>
                        <mat-radio-button (click)="selectCustomTableMethod(cust_table_method)"
                                          [class.selected]="selected_custom_table_method_id === cust_table_method.id"
                                          [checked]="selected_custom_table_method_id === cust_table_method.id"
                                          [disabled]="disableTableMethod()"
                                          [value]="cust_table_method.id"
                                          [style.background-color]="firm?.theme.container_color"
                                          [style.color]="firm?.theme.container_contrast_color"
                                          ngDefaultControl>
                          <div class="top-container">
                            <div>
                              <span></span> <i class="far fa-dot-circle inline-block icon method-icon"></i>
                              <p class="inline-block method-title">{{ cust_table_method.name }} </p>
                              <mat-icon *ngIf="cust_table_method.description" [matTooltip]="cust_table_method.description"><i class="fas fa-question-circle option-description"></i></mat-icon>
                              <strong class="delivery_info" *ngIf="disableTableMethod()">{{ 'TEMPORARILY_INACTIVE' | translate }}</strong>
                            </div>
                            <mat-radio-button [value]="cust_table_method.id" class="child-radio" labelPosition="before" [checked]="cust_table_method.id === selected_custom_table_method_id"></mat-radio-button>
                          </div>
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </li>

                </ul>

                <!-- pickup + delivery hours -->
                <div class="hours-info hidden row outlined" *ngIf="(firm?.periods?.opening_hours && !firm?.has_pickup_custom_days) || (firm?.periods?.delivery_hours && !firm?.has_delivery_custom_days) || (firm?.delivery && firm?.delivery_zipcodes?.length > 0) || (firm?.delivery && firm?.delivery_radius?.length > 0) || (firm?.delivery && firm?.free_delivery_price)">
                  <a (click)="!showHours ? showHours=true : showHours=false" id="displayOpeningHours">{{ 'CHECKOUT.SHOW_AVAILABLE_TIMES' | translate }}</a>

                  <div *ngIf="showHours" class="row" id="hoursInfo" style="margin-top: 20px">

                    <!-- Pickup hours -->
                    <div *ngIf="firm?.periods.pickup_hours && !firm?.has_pickup_custom_days"
                         class="col half">
                      <p style="margin-bottom: 10px">{{ 'CHECKOUT.PICKUP_HOURS' | translate }}
                      </p>

                      <!-- Pickup hours -->
                      <ul class="hours">
                        <li class="row" *ngFor="let day of firm?.periods.pickup_hours">
                          <div class="day">{{day.day}}</div>

                          <!-- Catch bad formatted hours -->
                          <div *ngIf="day.am && !day.pm && day.am.type === 'open'"
                               class="hour">{{day.am.from_time}} - {{day.am.to_time}}</div>
                          <div *ngIf="day.am && !day.pm && day.am.type === 'closed'"
                               class="hour">{{ 'INFO.CLOSED' | translate }}</div>
                          <div *ngIf="!day.am && day.pm && day.pm.type === 'open'"
                               class="hour">{{day.pm.from_time}} - {{day.pm.to_time}}</div>
                          <div *ngIf="!day.am && day.pm && day.pm.type === 'closed'"
                               class="hour">{{ 'INFO.CLOSED' | translate }}</div>

                          <!-- Show normal hours -->
                          <div *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'closed')"
                               class="hour">{{day.am.from_time}} - {{day.am.to_time}}</div>
                          <div *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'open')"
                               class="hour">{{day.pm.from_time}} - {{day.pm.to_time}}</div>
                          <div *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'open')"
                               class="hour">{{day.am.from_time}} - {{day.am.to_time}} &
                            {{day.pm.from_time}} - {{day.pm.to_time}}</div>
                          <div *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'closed')"
                               class="hour closed">{{ 'INFO.CLOSED' | translate }}</div>
                        </li>
                      </ul>
                    </div>

                    <!-- Delivery hours -->
                    <app-delivery-hours *ngIf="firm && !firm?.has_delivery_custom_days"
                                        [firm]="firm"></app-delivery-hours>

                    <!-- Delivery info -->
                    <app-delivery-info *ngIf="firm" [firm]="firm"></app-delivery-info>

                  </div>
                </div>

                <div class="info-box" *ngIf="displayInfoBox()" [style.background-color]="firm?.theme.container_color" [style.color]="firm?.theme.container_contrast_color">

                  <!-- Pickup -->
                  <div *ngIf="pickupMethodIsSelected() || pickupPointIsSelected()">
                    <!-- Timepicker -->
                    <div class="contents" *ngIf="!hasCustomPickupDates() && !firmHasPickupTimeslots() && !hasPickupPointTimeslots()">
                      <div class="wrap">
                        <eo-datepicker *ngIf="date && hours && minutes"
                          [date]="date"
                          [minDate]="minDate"
                          [hour]="hours"
                          [minutes]="minutes"
                          [limitedToSameDay]="isLimitedToSameDayOrdering()"
                          [schedule]="pickupPointIsSelected() && pickupPointHasPickupHours() ? order.chosen_pickup_point.periods.pickup_hours : firm?.periods.pickup_hours"
                          (changed)="datepickerChanged($event)">
                        </eo-datepicker>
                      </div>
                    </div>

                    <!-- Custom dates -->
                    <div class="contents" *ngIf="hasCustomPickupDates() && !firmHasPickupTimeslots() && !hasPickupPointTimeslots()">
                      <div class="wrap">
                        <p style="margin-top: 10px; margin-bottom: 15px">{{ 'CHECKOUT.PICKUP_DATE' | translate }}</p>
                        <div *ngIf="firm?.pickup_custom_days" class="radiogroup">
                          <mat-radio-group [(ngModel)]="custom_date" name="customdate" required>
                            <mat-radio-button
                              class="pickup_radio width-full"
                              *ngFor="let day of firm?.pickup_custom_days"
                              [style.background-color]="firm?.theme.container_color"
                              [style.color]="firm?.theme.container_contrast_color"
                              [value]="day.date"
                              ngDefaultControl>
                              <span
                                class="label-content">{{ day.date | customDate }}
                              </span>
                            </mat-radio-button>
                          </mat-radio-group>
                        </div>
                      </div>
                    </div>

                    <!-- Timeslots -->
                    <div class="contents" *ngIf="!hasCustomPickupDates() && (pickupMethodIsSelected() && firmHasPickupTimeslots() || pickupPointIsSelected() && hasPickupPointTimeslots())">
                      <div class="wrap">
                        <p style="margin-top: 10px; margin-bottom: 15px">{{ 'CHECKOUT.PICKUP_DATE' | translate }}</p>

                        <mat-form-field class="date-selector" style="width: 110px">
                          <input matInput [matDatepicker]="myDatepicker"
                                 (dateChange)="onPickupDateTimeslotChange()"
                                 [min]="minDate" name="delivery_date" [(ngModel)]="date" required>
                          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
                          <mat-datepicker touchUi #myDatepicker disabled="false"></mat-datepicker>
                        </mat-form-field>

                        <label *ngIf="date" style="text-align: left;">{{'TIME' | translate}}</label>

                        <p class="error" *ngIf="timeslots && timeslots.length === 0">{{'MESSAGES.NO_TIMESLOTS_AVAILABLE' | translate}}</p>

                        <mat-chip-list *ngIf="timeslots && timeslots.length > 0" aria-label="Hour Selection">
                          <div class="horizontal-scroll">
                            <mat-chip
                              [ngClass]="selected_timeslot_id === timeslot.id ? 'selected-time-element' : 'time-element'"
                              *ngFor="let timeslot of timeslots"
                              (click)="getMinutesInterval(timeslot.id)">
                              {{ timeslot.from_time }} - {{ timeslot.to_time }}
                            </mat-chip>
                          </div>
                        </mat-chip-list>

                        <mat-chip-list
                          *ngIf="intervals_minutes && intervals_minutes.length > 0"
                          aria-label="Minute selection">
                          <div class="horizontal-scroll">
                            <mat-chip *ngFor="let interval of intervals_minutes"
                                      (click)="setOrderProcessTimestampFromInterval(interval)"
                                      [ngClass]="selected_interval_minute === interval.time && interval.available ?
                                                        'selected-time-element' : (!interval.available ? 'time-element disabled' :'time-element' )">
                              {{interval.time}}
                            </mat-chip>
                          </div>
                        </mat-chip-list>
                      </div>
                    </div>
                  </div>
                  <!--/ Pickup -->

                  <!-- Table -->
                  <div *ngIf="tableMethodIsSelected()">
                    <!-- Default table -->
                    <div class="info-box contents" *ngIf="!customTableIsSelected() && hasTableNumbers()">
                      <div class="wrap">
                        <p>{{firm?.labels.table_number}}:</p>
                        <select name="table_number" [(ngModel)]="table_number" [required]="firm?.table_number_required">
                          <option [ngValue]="undefined" disabled selected class="color-lightgray">{{ 'PICK_CHOICE' | translate }}</option>
                          <option *ngFor="let table of firm?.table_numbers" value="{{table}}">{{ table }}</option>
                        </select>
                      </div>
                    </div>

                    <!-- Custom table -->
                    <div class="contents" *ngIf="customTableIsSelected() && hasTableNumbers()">
                      <div class="wrap">
                        <p>{{ selected_custom_table_method.location_number_name }}</p>
                        <select name="table_number" [(ngModel)]="table_number" [required]="firm?.table_number_required">
                          <option [ngValue]="undefined" disabled selected class="color-lightgray">{{ 'PICK_CHOICE' | translate }}</option>
                          <option *ngFor="let table of selected_custom_table_method.location_numbers" value="{{table}}">{{ table }}</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <!--/ Table -->

                </div>

                <div *ngIf="errMessage" class="formerrors">
                  <p>{{ 'MESSAGES.TIMESTAMP_ERROR' | translate }}</p>
                </div>

                <div class="buttons">
                  <button class="btn-fill buttons custom-btn" (click)="next()"
                          [disabled]="!formDate.valid || !delivery_method || disablePlaceOrder()">{{ 'BUTTONS.NEXT' | translate }}
                    <span class="arrow"></span>
                  </button>
                </div>

              </div>
            </div>

          </form>
        </div>

        <!-- Sidebar: order -->
        <div class="col third col-sm no-half">
          <app-order-side-bar *ngIf="firm && order" [firm]="firm" [order]="order"></app-order-side-bar>
        </div>

      </div>
    </div>
  </div>
</div>
</body>

<ng-template #deliveryInfo>
  <!-- Addresses -->
  <div class="addresses-container">
    <eo-slider *ngIf="addressList">
      <eo-address
        *ngFor='let address of addressList'
        [address]='address'
        (selected)="selectAddress($event)"
        (edit)="presentEditAddressModal($event)">
      </eo-address>
      <div class="eo-card add-card"
           (click)="presentAddAddressModal()">
        <div class="content">
          <div class="add-icon">
            <i class="fas fa-plus-circle add-icon"></i>
          </div>
          <strong>{{ 'ADD_ADDRESS' | translate }}</strong>
        </div>
      </div>
    </eo-slider>

    <div class="address-msg" *ngIf="!deliveryMethodHasErrors()">
      <p *ngIf="delivery_cost > 0 && !minPriceError">{{ 'DELIVERY_COST' | translate }}: {{ firm?.currency.symbol }}{{ delivery_cost | number:'1.2-2'  }}</p>
      <p *ngIf="delivery_cost === 0 && !minPriceError">{{ 'CHECKOUT.FREE_DELIVERY' | translate }}</p>
    </div>

    <div class="address-msg" *ngIf="deliveryMethodHasErrors()">
      <div *ngIf="noAddressError">
        <i class="fas fa-exclamation-triangle"></i>
        <p>{{ 'SELECT_AN_ADDRESS' | translate }}</p>
      </div>

      <div *ngIf="deliveryNotPossible">
        <i class="fas fa-exclamation-triangle"></i>
        <p>{{ 'NO_DELIVERY_POSSIBLE' | translate }}</p>
      </div>
      <p *ngIf="minPriceError">{{ 'DIALOGS.MIN_DELIVERY_PRICE' | translate:{ firm_valuta: firm?.currency?.symbol, min_order_price: min_order_price } }}</p>
    </div>
  </div>

  <!-- Timepicker -->
  <div class="contents" *ngIf="!hasCustomDeliveryDates() && !firmHasDeliveryTimeslots()">
    <div class="wrap">
      <eo-datepicker *ngIf="date && hours && minutes"
        [date]="date"
        [minDate]="minDate"
        [hour]="hours"
        [minutes]="minutes"
        [limitedToSameDay]="isLimitedToSameDayOrdering()"
        [schedule]="firm?.periods.delivery_hours"
        (changed)="datepickerChanged($event)">
      </eo-datepicker>
    </div>
  </div>

  <!-- Custom dates -->
  <div class="contents" *ngIf="hasCustomDeliveryDates() && !firmHasDeliveryTimeslots()">
    <div class="wrap">
      <p>{{ 'CHECKOUT.DELIVERY_DATE' | translate }}</p>
      <div class="radiogroup">
        <mat-radio-group [(ngModel)]="custom_date" name="customdate" required>
          <mat-radio-button
            *ngFor="let day of firm?.delivery_custom_days"
            [style.background-color]="firm?.theme.container_color"
            [style.color]="firm?.theme.container_contrast_color"
            [value]="day.date" class="pickup_radio width-full" ngDefaultControl>
            <span class="label-content">{{ day.date | customDate }}</span>
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>
  </div>

  <!-- Timeslots -->
  <div class="contents" *ngIf="!hasCustomDeliveryDates() && firmHasDeliveryTimeslots()">
    <div class="wrap">

      <p style="margin-top: 10px; margin-bottom: 15px;">
        {{ 'CHECKOUT.DELIVERY_DATE' | translate }}
      </p>
      <mat-form-field class="date-selector" style="width: 110px">
        <input matInput [matDatepicker]="myDatepicker"
               (dateChange)="onDeliveryDateTimeslotChange()"
               [min]="minDate" name="delivery_date" [(ngModel)]="date"
               required>
        <mat-datepicker-toggle matSuffix [for]="myDatepicker">
        </mat-datepicker-toggle>
        <mat-datepicker touchUi #myDatepicker disabled="false">
        </mat-datepicker>
      </mat-form-field>
      <label *ngIf="date"
             style="text-align: left;">{{'TIME' | translate}}</label>
      <p class="error" *ngIf="timeslots && timeslots.length === 0">
        {{'MESSAGES.NO_TIMESLOTS_AVAILABLE' | translate}}</p>
      <mat-chip-list *ngIf="timeslots && timeslots.length > 0"
                     aria-label="Hour Selection">
        <div class="horizontal-scroll">
          <mat-chip
            [ngClass]="selected_timeslot_id === timeslot.id ? 'selected-time-element' : 'time-element'"
            *ngFor="let timeslot of timeslots"
            (click)="getMinutesInterval(timeslot.id)">
            {{ timeslot.from_time }} - {{ timeslot.to_time }}
          </mat-chip>
        </div>
      </mat-chip-list>

      <mat-chip-list
        *ngIf="intervals_minutes && intervals_minutes.length > 0"
        aria-label="Minute selection">
        <div class="horizontal-scroll">
          <mat-chip *ngFor="let interval of intervals_minutes"
                    (click)="setOrderProcessTimestampFromInterval(interval)"
                    [ngClass]="selected_interval_minute === interval.time && interval.available ?
                                                            'selected-time-element' : (!interval.available ? 'time-element disabled' :'time-element' )">
            {{interval.time}}
          </mat-chip>
        </div>
      </mat-chip-list>
    </div>
  </div>
</ng-template>
