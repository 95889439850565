import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eo-loyalty-reward-box',
  templateUrl: './loyalty-reward.component.html',
  styleUrls: ['./loyalty-reward.component.css'],
})
export class LoyaltyRewardComponent {
  @Input() loyaltyType: string;
  @Input() currency: string;

  // Discount
  @Input() discountType: string;
  @Input() discountValue: number;

  // Product/category
  @Input() productList: any;

  @Output() productChanged = new EventEmitter<any>();

  selectedProduct: any;

  constructor() {}

  ngOnChanges(): void {
    this.setInitialProduct();
  }

  setInitialProduct() {
    if (this.productList) this.setSelectedProduct(this.productList[0]);
  }

  setSelectedProduct(product) {
    this.selectedProduct = product;
    this.productChangedHandler();
  }

  isLoyaltyProductInStock() {
    return this.selectedProduct && this.selectedProduct.in_stock;
  }

  listHasMultipleProducts() {
    return this.productList && this.productList.length > 1;
  }

  productChangedHandler() {
    this.productChanged.emit(this.selectedProduct);
  }
}
