import { Location } from '@angular/common';
import { Component, OnInit, Pipe, PipeTransform, ChangeDetectorRef } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../services/auth.service';
import { BasketItemsService } from '../services/basket-items.service';
import { DialogsService } from '../services/dialogs.service';
import { FirmService } from '../services/firm.service';
import { LoaderService } from '../services/loader.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import { Units } from '../constants/units';
import { TagService } from '../services/tag.service';
import { TimestampService } from '../services/timestamp.service';
import { AddToCartService } from '../services/add-to-cart.service';
import { LanguageService } from '../services/language.service';
import { DeliveryMethods } from '../constants/delivery_methods';
import { Address } from '../core/models/address';
import { CustomerService } from '../services/customer.service';

@Pipe({ name: 'customDate' })
export class CustomDatePipe implements PipeTransform {
  transform(date) {
    return moment(date).format('DD MMMM YYYY - HH:mm');
  }
}

export const MY_FORMATS = {
  display: {
    dateInput: 'L',
  },
};

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: 'nl' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
  ],
})
export class CheckoutComponent implements OnInit {
  addressList: Address[];
  user: any;
  timezone: any;
  own_domain: boolean;
  order: any;

  firm: any;
  firm_name: any;
  language: any;

  basketItems: any;
  sub_total: any;
  total_price: any;
  delivery_cost: any;

  pickup_point_id: any;
  table_number: any;
  pickup_point: boolean;
  pickup_main_branch = false;
  main_branch_id: number;
  processTimestamp: any;

  selected_custom_pickup_method_id: any;
  selected_custom_delivery_method_id: any;
  selected_custom_table_method_id: any;
  selected_custom_table_method_name: any;
  selected_custom_table_method: any; // this should replace the _id, and _name

  /* Order */
  delivery_method: any;
  showHours: any = false;
  date: any;
  minDate: any;
  hours: any;
  minutes: any;
  custom_date: any;

  /* Firm info */
  openingHours: any;
  pickupHours: any;
  deliveryHours: any;
  timeslots: Array<any>;
  intervals_minutes: Array<any>;
  selected_interval_minute: any;
  selected_timeslot_id: any;

  /* Errors */
  errMessage: any;
  minPriceError = false;
  noAddressError = false;
  deliveryNotPossible = false;
  min_order_price: any;

  disablePickup: boolean;
  disableDelivery: boolean;
  disableTable: boolean;

  constructor(
    public auth: AuthService,
    public route: ActivatedRoute,
    public router: Router,
    public routeService: RouteService,
    public translate: TranslateService,
    public location: Location,
    public firmService: FirmService,
    public slug: SlugifyService,
    public basketItemsService: BasketItemsService,
    public orderService: OrderService,
    public loader: LoaderService,
    public dialogsService: DialogsService,
    private cdr: ChangeDetectorRef,
    private tagService: TagService,
    private timestampService: TimestampService,
    private addToCartService: AddToCartService,
    private languageService: LanguageService,
    private customerService: CustomerService
  ) {
    window.scrollTo(0, 0);
  }

  ngAfterViewInit() {
    this.cdr.detectChanges();
  }

  /* Get all data */
  async ngOnInit() {
    // Route params
    this.firm_name = this.route.snapshot.params.firm_name;
    this.language = this.route.snapshot.params.language;
    this.languageService.setLanguage(this.language);

    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then(async (data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;

      // Get order
      this.order = await this.orderService.getOrder();

      this.checkForErrors();
    });
  }

  onTimeslotsMethodSelect() {
    this.resetTimeSlots();
    this.date = moment().format();
    this.minDate = moment();
    this.setOrderProcessTimestamp();
    if (this.delivery_method === 'pickup' || this.selected_custom_pickup_method_id || this.order.firm_pickup_point_id) {
      this.getPickupTimeslots();
    }
    if (this.delivery_method === 'delivery' || this.selected_custom_delivery_method_id) {
      this.getDeliveryTimeslots();
    }
  }

  resetTimeSlots() {
    this.timeslots = null;
    this.selected_timeslot_id = null;
    this.intervals_minutes = [];
    this.selected_interval_minute = null;
  }

  checkForErrors() {
    if (!this.firm.has_website) {
      this.router.navigate([this.firm.website_details.sitename, this.language, '404']);
    } else if (!this.order) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else if (!this.order.user) {
      this.routeService.navigateToBasket(this.own_domain, this.firm, this.language);
    } else {
      this.initData();
    }
  }

  async initData() {
    this.tagService.addEvent('eo_web_checkout');
    this.orderService.setUuid();

    if (!this.isGuestCheckout()) {
      const login = this.customerService.getLogin();
      await this.customerService.linkFirm(this.firm, login.id, this.language, this.firm.distributor, this.own_domain);
      this.user = await this.customerService.get(
        this.firm,
        login.id,
        this.language,
        this.firm.distributor,
        this.own_domain
      );
    } else {
      this.user = await this.customerService.loginAsGuest(this.firm, this.language);
      this.order.user = {
        id: this.user.id,
      };
    }

    if (this.firm.pickup_points && this.firm.pickup_points.length > 0) {
      this.firm.pickup_points.forEach((pickup_point) => {
        if (pickup_point.main_branch) {
          this.pickup_main_branch = true;
          this.main_branch_id = pickup_point.id;

          const index = this.firm.pickup_points.indexOf(pickup_point);
          if (index !== -1) {
            this.firm.pickup_points.splice(index, 1);
          }
        }
      });
    }

    await this.setProducts();

    this.checkVat();

    this.checkDeliveryMethods();
  }

  checkDeliveryMethods() {
    const availableMethods = [];

    if (this.firm.pickup && this.firm.pickup_points.length > 0 && !this.pickup_main_branch) {
      availableMethods.push('pickup_points');
    } else if (this.firm.pickup) {
      availableMethods.push('pickup');
    }

    if (this.firm.delivery) {
      availableMethods.push('delivery');
    }
    if (this.firm.table) {
      availableMethods.push('table');
    }

    if (availableMethods.length === 1) {
      setTimeout(() => {
        const el = document.getElementById(`${availableMethods[0]}-selector`);
        if (el) el.click();

        if (availableMethods[0] === 'delivery') this.selectMainDeliveryMethod();
      });
    }
  }

  async setProducts() {
    this.basketItems = await this.basketItemsService.get(this.firm.id);

    // set products
    const products = [];
    this.basketItems.forEach((item) => {
      const ingredients = [];
      const product_options = [];

      // set ingredients
      item.item.ingredients.forEach((ingr) => {
        const ingredient = {
          id: ingr.id,
          type: ingr.type,
          price: ingr.price,
          quantity: ingr.amount,
          action: ingr.action,
          selected: ingr.selected,
        };
        ingredients.push(ingredient);
      });

      // set product options
      if (item.item.options_selected) {
        item.item.options_selected.forEach((option) => {
          const product_option = {
            group_id: option.id,
            id: option.selected_option.id,
            price: option.selected_option.price,
          };
          product_options.push(product_option);
        });
      }

      // Parse init item
      const init_item = JSON.parse(item.item.init_item);

      // set product
      const product: any = {
        id: init_item.id,
        parent_cat_id: init_item.category.parent_category_id,
        cat_id: init_item.category.category_id,
        quantity: item.item.amount,
        ingredients: ingredients,
        product_options: product_options,
        comment: item.item.comment,
        unit_id: item.item.unit_id,
      };

      if (item.item.unit_id === Units.KG) {
        product.quantity = item.item.amount / 1000;
      }

      if (init_item.weight != null) {
        product.weight = init_item.weight;
      }

      if (this.firm.uses_vat) {
        product.vat_percentage = item.item.item.vat_percentage;
      }

      // Set price of product
      if (item.item.item.has_promotional_price) {
        product.price = init_item.promotion.price;
      } else {
        product.price = init_item.price;
      }

      products.push(product);
    });
    this.order.products = products;
  }

  checkPickupForMainBranch() {
    this.resetOrderAddress();
    this.setOrderDeliveryPrice(0);
    this.order.firm_pickup_point_id = undefined;
    this.order.delivery_method = 'pickup';
    this.forceChange();
  }

  /* Calculate the delivery cost of the order */
  async calculateDeliveryCost() {
    this.resetAddressErrors();

    if (this.userHasAddress()) {
      const order = {
        ...this.order,
        delivery_street: this.user.street,
        delivery_street_nr: this.user.house_number,
        delivery_zipcode: this.user.zipcode,
        delivery_locality: this.user.locality,
        delivery_country_id: this.user.country?.id,
      };

      const response: any = await this.orderService.calculateOrder(
        this.firm,
        this.order.user.id,
        order,
        this.language,
        this.firm.distributor,
        this.own_domain
      );

      if (response.error) {
        if (response.error.code === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY') {
          this.min_order_price = response.error.message;
          this.minPriceError = true;
        } else {
          this.deliveryNotPossible = true;
        }
      } else {
        this.delivery_cost = Number(response.delivery_price);
      }
    } else {
      this.noAddressError = true;
    }
  }

  resetAddressErrors() {
    this.minPriceError = false;
    this.deliveryNotPossible = false;
    this.noAddressError = false;
  }

  selectCustomPickupMethod() {
    this.resetOrderAddress();
    this.setOrderDeliveryPrice(0);
    this.selected_custom_pickup_method_id = this.delivery_method;
    this.selected_custom_delivery_method_id = null;
    this.selected_custom_table_method_id = null;
    this.selected_custom_table_method = null;
    if (this.firm && this.firm.has_pickup_custom_days) {
      this.custom_date = this.firm.pickup_custom_days[0].date;
    } else if (!this.firmHasPickupTimeslots()) {
      this.setOrderProcessTimestamp();
    } else {
      this.onTimeslotsMethodSelect();
    }
  }

  selectCustomDeliveryMethod(id) {
    if (this.selected_custom_delivery_method_id !== id) {
      this.selected_custom_pickup_method_id = null;
      this.selected_custom_delivery_method_id = id;
      this.selected_custom_table_method_id = null;
      this.selected_custom_table_method = null;

      this.setAddressList().then(() => {
        this.setDefaultAddress();
        this.calculateDeliveryCost().then(() => {
          this.setOrderDeliveryPrice(this.delivery_cost);
        });
      });

      this.setOrderDeliveryPrice(this.delivery_cost);

      if (this.firm && this.firm.has_delivery_custom_days) {
        this.custom_date = this.firm.delivery_custom_days[0].date;
      } else if (!this.firmHasDeliveryTimeslots()) {
        this.setOrderProcessTimestamp();
      } else {
        this.onTimeslotsMethodSelect();
      }
    }
  }

  selectCustomTableMethod(method) {
    this.resetOrderAddress();
    this.setOrderDeliveryPrice(0);
    this.selected_custom_pickup_method_id = null;
    this.selected_custom_delivery_method_id = null;
    this.selected_custom_table_method_id = method.id;
    this.selected_custom_table_method_name = method.name;
    this.selected_custom_table_method = method;
  }

  selectMainDeliveryMethod() {
    if (!this.mainDeliveryIsSelected()) {
      this.delivery_method = 'delivery';
      this.reInitCustomDelivery();
      this.setAddressList().then(() => {
        this.setDefaultAddress();
        this.calculateDeliveryCost().then(() => {
          this.setOrderDeliveryPrice(this.delivery_cost);
        });
      });
      this.setOrderDeliveryPrice(this.delivery_cost);
      if (!this.firmHasDeliveryTimeslots()) {
        this.setOrderProcessTimestamp();
      } else {
        this.onTimeslotsMethodSelect();
      }
    }
  }

  selectFirstPickupPoint() {
    this.resetOrderAddress();
    this.setOrderDeliveryPrice(0);
    this.pickup_point_id = this.firm.pickup_points[0].id;
    setTimeout(() => {
      document.getElementById(`pickuppoint-${this.firm.pickup_points[0].id}`).click();
    });
  }

  reInitCustomDelivery() {
    this.selected_custom_pickup_method_id = null;
    this.selected_custom_delivery_method_id = null;
    this.selected_custom_table_method_id = null;
    this.selected_custom_table_method = null;
    this.order.chosen_pickup_point_id = null;
    this.pickup_point = null;
    this.forceChange();
  }

  async checkVat() {
    if (this.firm.uses_vat && this.firm.vat_settings.allowed_vat_percentages.length > 1) {
      const vat_delivery_method = await localStorage.getItem('vat_delivery_method');
      this.disablePickup = vat_delivery_method && vat_delivery_method !== 'pickup';
      this.disableDelivery = vat_delivery_method && vat_delivery_method !== 'delivery';
      this.disableTable = vat_delivery_method && vat_delivery_method !== 'table';

      this.delivery_method = vat_delivery_method;
      if (!this.firmHasPickupTimeslots() && !this.firmHasDeliveryTimeslots() && !this.hasPickupPointTimeslots()) {
        this.setOrderProcessTimestamp();
      }
    }
  }

  next() {
    if (
      (this.delivery_method === 'pickup' || this.selected_custom_pickup_method_id) &&
      this.firm.has_pickup_custom_days
    ) {
      this.saveOrder();
    } else if (
      (this.delivery_method === 'delivery' || this.selected_custom_delivery_method_id) &&
      this.firm.has_delivery_custom_days
    ) {
      this.saveOrder();
    } else {
      if (
        ((this.selected_custom_pickup_method_id ||
          (this.delivery_method === 'pickup' && !this.order.firm_pickup_point_id)) &&
          !this.firmHasPickupTimeslots()) ||
        (this.order && this.order.firm_pickup_point_id && !this.hasPickupPointTimeslots()) ||
        ((this.delivery_method === 'delivery' || this.selected_custom_delivery_method_id) &&
          !this.firmHasDeliveryTimeslots())
      ) {
        this.validateOrderProcessTimestamp();
      }
      // No time needed
      else {
        this.saveOrder();
      }
    }
  }

  onPickupDateTimeslotChange() {
    this.resetTimeSlots();
    this.getPickupTimeslots();
  }

  onDeliveryDateTimeslotChange() {
    this.resetTimeSlots();
    this.getDeliveryTimeslots();
  }

  firmHasPickupTimeslots() {
    return this.firm && this.firm.order_timeslots.pickup && this.firm.order_timeslots.pickup.active;
  }

  firmHasDeliveryTimeslots() {
    return this.firm && this.firm.order_timeslots.delivery && this.firm.order_timeslots.delivery.active;
  }

  hasPickupPointTimeslots() {
    return this.order.chosen_pickup_point ? this.order.chosen_pickup_point.use_timeslots : false;
  }

  getPickupTimeslots() {
    const categoriesId = this.getBasketItemsCategoriesId();
    const productsId = this.getBasketProductsId();
    this.firmService
      .getFirmTimeslots(
        moment(this.date).format('YYYY-MM-DD'),
        'pickup',
        productsId,
        categoriesId,
        this.order.firm_pickup_point_id,
        this.firm.distributor,
        this.firm,
        this.own_domain,
        this.language
      )
      .then((data) => {
        this.timeslots = data.data;
        this.checkSelectedDateIfNotChange(data.date);
        this.intervals_minutes = [];
      });
  }

  getDeliveryTimeslots() {
    const categoriesId = this.getBasketItemsCategoriesId();
    const productsId = this.getBasketProductsId();
    this.firmService
      .getFirmTimeslots(
        moment(this.date).format('YYYY-MM-DD'),
        'delivery',
        productsId,
        categoriesId,
        this.order.firm_pickup_point_id,
        this.firm.distributor,
        this.firm,
        this.own_domain,
        this.language
      )
      .then((data) => {
        this.timeslots = data.data;
        this.checkSelectedDateIfNotChange(data.date);
        this.intervals_minutes = [];
      });
  }

  checkSelectedDateIfNotChange(date) {
    if (moment(this.date).format('YYYY-MM-DD') !== date) {
      this.minDate =
        moment(this.date).format('YYYY-MM-DD') === moment().format('YYYY-MM-DD')
          ? moment(date).format()
          : moment().format();
      this.date = moment(date).format();
    }
  }

  getBasketItemsCategoriesId() {
    const res = [];
    this.order.products.forEach((item) => {
      res.push(item.cat_id);
    });
    return res;
  }

  getBasketProductsId() {
    const res = [];
    this.order.products.forEach((item) => {
      res.push(item.id);
    });
    return res;
  }

  getMinutesInterval(timeslot_id) {
    this.selected_timeslot_id = timeslot_id;
    const categoriesId = this.getBasketItemsCategoriesId();
    const productsId = this.getBasketProductsId();
    const delivery_method = this.selected_custom_pickup_method_id
      ? 'pickup'
      : this.selected_custom_delivery_method_id
      ? 'delivery'
      : this.delivery_method;
    this.firmService
      .getTimeIntervals(
        timeslot_id,
        moment(this.date).format('YYYY-MM-DD'),
        delivery_method,
        productsId,
        categoriesId,
        this.order.firm_pickup_point_id,
        this.firm.distributor,
        this.firm,
        this.own_domain,
        this.language
      )
      .then((data: any) => {
        this.intervals_minutes = data.intervals;
      });
  }

  setOrderProcessTimestampFromInterval(interval) {
    this.selected_interval_minute = interval.time;
    this.order.process_timestamp = moment(
      moment(this.date).format('YYYY-MM-DD') + ' ' + interval.time,
      'YYYY-MM-DD HH:mm'
    ).format();
    this.order.timeslot_id = this.selected_timeslot_id;
  }

  setOrderProcessTimestamp() {
    const categories = this.addToCartService.getAllCategoryIds(this.basketItems);
    const products = this.addToCartService.getAllProductIds(this.basketItems);

    this.timestampService
      .calculateTimestamp(
        categories,
        products,
        this.selected_custom_pickup_method_id
          ? 'pickup'
          : this.selected_custom_delivery_method_id
          ? 'delivery'
          : this.delivery_method,
        this.order.chosen_pickup_point_id,
        this.firm,
        this.order.user.id,
        this.language,
        this.own_domain
      )
      .then((timestamp) => {
        const date = moment(timestamp).format('YYYY-MM-DD');
        this.date = date;
        this.minDate = date;
        this.hours = moment(timestamp).format('HH');
        this.minutes = String(5 * Math.ceil(Number(moment(timestamp).format('mm')) / 5));
        if (this.minutes === '0') this.minutes = '00';
        else if (this.minutes === '5') this.minutes = '05';
        else if (this.minutes === '60') {
          this.hours = moment(Number(this.hours)).add('hours', Number(this.hours)).format('HH');
          this.minutes = '00';
        }

        this.processTimestamp = this.getDateWithTime(this.date, this.hours, this.minutes);
      });
  }

  validateOrderProcessTimestamp() {
    this.loader.present();

    const categories = this.addToCartService.getAllCategoryIds(this.basketItems);
    const products = this.addToCartService.getAllProductIds(this.basketItems);

    this.timestampService
      .validateTimestamp(
        this.processTimestamp,
        categories,
        products,
        this.selected_custom_pickup_method_id
          ? 'pickup'
          : this.selected_custom_delivery_method_id
          ? 'delivery'
          : this.delivery_method,
        this.order.chosen_pickup_point_id,
        this.firm,
        this.order.user.id,
        this.language,
        this.own_domain
      )
      .then((timestamp) => {
        if (!timestamp) this.errMessage = true;
        else {
          this.order.process_timestamp = timestamp;
          this.errMessage = false;
          this.saveOrder();
        }
      });

    this.loader.dismiss();
  }

  getDateWithTime(date, hour, minutes) {
    const newDate = new Date(date);
    newDate.setHours(hour, minutes);
    return newDate;
  }

  disablePickupMethod() {
    return this.disablePickup || this.firm.pickup_temporary_inactive;
  }

  deliveryMethodHasErrors() {
    return this.minPriceError || this.noAddressError || this.deliveryNotPossible;
  }

  deliveryMethodIsTemporarilyInactive() {
    return this.firm.delivery_temporary_inactive;
  }

  disableTableMethod() {
    return this.disableTable || this.firm.table_temporary_inactive;
  }

  /* Save order */
  async saveOrder() {
    this.order.delivery_method = this.delivery_method;

    if (this.selected_custom_pickup_method_id || this.selected_custom_delivery_method_id) {
      this.order.delivery_method = this.selected_custom_pickup_method_id
        ? 'pickup'
        : this.selected_custom_delivery_method_id
        ? 'delivery'
        : this.delivery_method;
      this.order.custom_delivery_pickup_method_id = this.selected_custom_pickup_method_id
        ? this.selected_custom_pickup_method_id
        : this.selected_custom_delivery_method_id;
    }

    if (this.selected_custom_table_method_id) {
      this.order.delivery_method = 'table';
      this.order.table_number = this.table_number;
      this.order.custom_table_method_id = this.selected_custom_table_method_id;
      this.order.custom_table_method_name = this.selected_custom_table_method_name;
      this.order.process_timestamp = moment().format();
    }

    if (this.delivery_method === 'pickup' || this.selected_custom_pickup_method_id || this.order.firm_pickup_point_id) {
      if (!this.firm.has_pickup_custom_days && !this.firmHasPickupTimeslots() && !this.hasPickupPointTimeslots()) {
        this.order.process_timestamp = this.processTimestamp;
      } else if (this.firm.has_pickup_custom_days) {
        this.order.process_timestamp = this.custom_date;
      }
      this.order.delivery_option = 0;
      this.order.table_number = undefined;
      this.order.delivery_price = 0;
      if (this.pickup_point_id) {
        this.order.firm_pickup_point_id = Number(this.pickup_point_id);
      }
    } else if (this.delivery_method === 'delivery' || this.selected_custom_delivery_method_id) {
      if (!this.firm.has_delivery_custom_days && !this.firmHasDeliveryTimeslots()) {
        this.order.process_timestamp = this.processTimestamp;
      } else if (this.firm.has_delivery_custom_days) {
        this.order.process_timestamp = this.custom_date;
      }
      this.order.delivery_option = 1;
      this.order.table_number = undefined;
      this.order.delivery_price = Number(this.delivery_cost);
    } else if (this.delivery_method === 'table') {
      this.order.process_timestamp = moment().format();
      this.order.delivery_option = 2;
      this.order.table_number = this.table_number;
      this.order.delivery_price = 0;
    }
    await this.orderService.saveOrder(this.order);
    this.routeService.navigateToPaymentMethod(this.own_domain, this.firm, this.language);
  }

  disablePlaceOrder() {
    return (
      (((this.delivery_method === 'pickup' && !this.order.firm_pickup_point_id) ||
        this.selected_custom_pickup_method_id) &&
        this.firmHasPickupTimeslots() &&
        (!this.selected_interval_minute || !this.selected_timeslot_id)) ||
      (this.order &&
        this.delivery_method === 'pickup' &&
        this.order.firm_pickup_point_id &&
        this.hasPickupPointTimeslots() &&
        (!this.selected_interval_minute || !this.selected_timeslot_id)) ||
      ((this.delivery_method === 'delivery' || this.selected_custom_delivery_method_id) &&
        this.firmHasDeliveryTimeslots() &&
        (!this.selected_interval_minute || !this.selected_timeslot_id)) ||
      (this.deliveryMethodIsSelected() && this.deliveryMethodHasErrors())
    );
  }

  displayInfoBox() {
    return (
      this.pickupMethodIsSelected() ||
      (this.tableMethodIsSelected() && this.hasTableNumbers())
    );
  }

  hasTableNumbers() {
    return this.firm.table_numbers.length > 0;
  }

  pickupMethodIsSelected() {
    return this.delivery_method === DeliveryMethods.Pickup || this.customPickupIsSelected();
  }

  deliveryMethodIsSelected() {
    return this.delivery_method === DeliveryMethods.Delivery || this.customDeliveryIsSelected();
  }

  tableMethodIsSelected() {
    return this.delivery_method === DeliveryMethods.Table || this.customTableIsSelected();
  }

  mainDeliveryIsSelected() {
    return this.delivery_method === DeliveryMethods.Delivery && !this.customDeliveryIsSelected();
  }

  pickupPointIsSelected() {
    return this.pickup_point;
  }

  pickupPointHasPickupHours() {
    return this.order.chosen_pickup_point.periods && this.order.chosen_pickup_point.periods.length > 0;
  }

  customPickupIsSelected() {
    return this.selected_custom_pickup_method_id;
  }

  customDeliveryIsSelected() {
    return this.selected_custom_delivery_method_id;
  }

  customTableIsSelected() {
    return this.selected_custom_table_method_id;
  }

  hasCustomPickupDates() {
    return this.firm.has_pickup_custom_days;
  }

  hasCustomDeliveryDates() {
    return this.firm.has_delivery_custom_days;
  }

  datepickerChanged(event) {
    this.setProcessTimestamp(event.date, event.hour, event.minutes);
  }

  isLimitedToSameDayOrdering() {
    return (
      (this.delivery_method === DeliveryMethods.Pickup &&
        !this.pickup_point &&
        this.firm.delivery_methods.pickup &&
        this.firm.delivery_methods.pickup.orders_only_during_opening_hours) ||
      (this.delivery_method === DeliveryMethods.Delivery &&
        this.firm.delivery_methods.delivery &&
        this.firm.delivery_methods.delivery.orders_only_during_opening_hours)
    );
  }

  setProcessTimestamp(date, hour, minutes) {
    this.processTimestamp = this.getDateWithTime(date, hour, minutes);
  }

  async setAddressList() {
    if (!this.isGuestCheckout()) {
      this.addressList = await this.getAddresses();
    } else {
      if (!this.addressList) {
        this.addressList = [];
      }
    }
  }

  setDefaultAddress() {
    const defaultAddress = this.addressList.find((address) => address.is_default);
    if (defaultAddress) {
      this.setUserAddress(defaultAddress);
      this.setOrderAddress(defaultAddress);
    } else {
      this.resetUserAddress();
      this.resetOrderAddress();
    }
  }

  resetUserAddress() {
    this.user.street = null;
    this.user.house_number = null;
    this.user.zipcode = null;
    this.user.locality = null;
    this.user.country = null;
  }

  resetOrderAddress() {
    this.order.delivery_street = null;
    this.order.delivery_street_nr = null;
    this.order.delivery_zipcode = null;
    this.order.delivery_locality = null;
    this.order.delivery_country_id = null;
  }

  setUserAddress(address) {
    this.user.street = address.street;
    this.user.house_number = address.bus ? `${address.house_number} ${address.bus}` : address.house_number;
    this.user.zipcode = address.zip_code;
    this.user.locality = address.locality;
    this.user.country = address.country;
  }

  setOrderAddress(address) {
    this.order.delivery_street = address.street;
    this.order.delivery_street_nr = address.bus ? `${address.house_number} ${address.bus}` : address.house_number;
    this.order.delivery_zipcode = address.zip_code;
    this.order.delivery_locality = address.locality;
    this.order.delivery_country_id = address.country?.id;
  }

  userHasAddress() {
    return (
      this.user.street && this.user.house_number && this.user.zipcode && this.user.locality && this.user.country?.id
    );
  }

  selectAddress(address) {
    if (!address.is_default) {
      address.is_default = true;
      this.updateDefaultAddressInList(address);

      if (!this.isGuestCheckout()) {
        this.updateAddress(address).then(() => {
          this.setAddressList().then(() => {
            this.setDefaultAddress();
            this.calculateDeliveryCost().then(() => {
              this.setOrderDeliveryPrice(this.delivery_cost);
            });
          });
        });
      } else {
        this.setDefaultAddress();
        this.calculateDeliveryCost().then(() => {
          this.setOrderDeliveryPrice(this.delivery_cost);
        });
      }
    }
  }

  updateDefaultAddressInList(address) {
    this.addressList.forEach((a) => {
      if (a.id !== address.id) {
        a.is_default = false;
      } else {
        a.is_default = true;
      }
    });
  }

  setOrderDeliveryPrice(price) {
    this.order.delivery_price = price ? price : 0;
    this.forceChange();
  }

  presentEditAddressModal(address) {
    const header = this.translate.instant('CHANGE_ADDRESS');
    const subHeader = this.translate.instant('EDIT_FIELDS_BELOW');
    const buttonLabel = this.translate.instant('BUTTONS.SAVE');

    const dialog = this.dialogsService.openAddressModal(header, subHeader, buttonLabel, this.firm.countries, address);
    dialog.subscribe(({ data }) => {
      if (data && !data.cancelled) {
        if (address.is_default !== data.address.is_default) {
          this.updateDefaultAddressInList(data.address);
        }

        if (!this.isGuestCheckout()) {
          this.updateAddress(data.address).then(() => {
            this.setAddressList().then(() => {
              this.setDefaultAddress();
              this.calculateDeliveryCost().then(() => {
                this.setOrderDeliveryPrice(this.delivery_cost);
              });
            });
          });
        } else {
          this.updateAddressInList(data.address);
          this.setDefaultAddress();
          this.calculateDeliveryCost().then(() => {
            this.setOrderDeliveryPrice(this.delivery_cost);
          });
        }
      }
    });
  }

  presentAddAddressModal() {
    const header = this.translate.instant('ADD_ADDRESS');
    const subHeader = this.translate.instant('FILL_IN_ADDRESS');
    const buttonLabel = this.translate.instant('ADD_ADDRESS');

    const dialog = this.dialogsService.openAddressModal(header, subHeader, buttonLabel, this.firm.countries);
    dialog.subscribe(({ data }) => {
      if (data && !data.cancelled) {
        if (data.address.is_default) {
          this.updateDefaultAddressInList(data.address);
        }

        if (!this.isGuestCheckout()) {
          this.addAddress(data.address).then(() => {
            this.setAddressList().then(() => {
              this.setDefaultAddress();
              this.calculateDeliveryCost().then(() => {
                this.setOrderDeliveryPrice(this.delivery_cost);
              });
            });
          });
        } else {
          this.addAddressToList({
            ...data.address,
            id: this.addressList.length + 1,
          });
          this.setDefaultAddress();
          this.calculateDeliveryCost().then(() => {
            this.setOrderDeliveryPrice(this.delivery_cost);
          });
        }
      }
    });
  }

  addAddressToList(address) {
    this.addressList.push(address);
  }

  updateAddressInList(updatedAddress) {
    const index = this.addressList.findIndex((address) => address.id === updatedAddress.id);
    this.addressList.splice(index, 1, updatedAddress);
  }

  async getAddresses(): Promise<Array<Address>> {
    return this.customerService.getAllCustomerAddresses(this.user.id, this.user.uuid, this.firm.id, this.language);
  }

  async updateAddress(address) {
    return this.customerService.updateCustomerAddress(
      this.user.id,
      this.user.uuid,
      address,
      this.firm.id,
      this.language
    );
  }

  async addAddress(address) {
    return await this.customerService.addCustomerAddress(
      this.user.id,
      this.user.uuid,
      address,
      this.firm.id,
      this.language
    );
  }

  isGuestCheckout() {
    return this.order.isGuest;
  }

  forceChange() {
    this.order = JSON.stringify(this.order);
    this.order = JSON.parse(this.order);
  }
}
