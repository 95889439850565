<eo-modal
  [header]="header"
  [subHeader]="subHeader"
  [buttonLabel]="buttonLabel"
  (submitted)="handleOnSubmit()">

  <div scrollX="true" *ngIf="addressList?.length > 0">
    <mat-radio-group [(ngModel)]="selectedId">
      <div class="radio-container flex align-items-center"
        *ngFor="let address of addressList"
        (click)="selectAddress(address)" tappable>
        <div class="title-container">
          <p>{{ address.description }}</p>
          <p class="ellipsis">({{ address.street }} {{ address.house_number}}, {{ address.zip_code}} {{ address.locality }}, {{ address.country?.name}})</p>
        </div>
        <div class="radio-button-container flex ion-justify-content-end ion-align-items-center">
          <mat-radio-button [value]="address.id"></mat-radio-button>
        </div>
      </div>
    </mat-radio-group>
  </div>
  <p class="no-address-msg" *ngIf="addressList?.length === 0">{{ 'NO_ADDRESSES' | translate }}</p>
</eo-modal>
