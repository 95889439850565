import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class LoaderService {
  constructor(private spinner: NgxSpinnerService) {}

  present() {
    this.spinner.show();
  }

  dismiss() {
    this.spinner.hide();
  }
}
