import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, ViewChild } from '@angular/core';

@Component({
  selector: 'eo-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements AfterViewInit {
  @ViewChild('scrollContainer') scrollContainer: ElementRef;

  displayLeftButton: boolean;
  displayRightButton: boolean;

  constructor(private cdr: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.checkCoordinates();
    this.cdr.detectChanges();
  }

  scroll(x, y) {
    document.getElementById('scrollContainer').scrollBy(x, y);
    this.checkCoordinates();
  }

  checkCoordinates() {
    const scrollLeft = this.getScrollLeft();
    const offsetWidth = this.getOffsetWidth();
    const scrollWidth = this.getScrollWidth();
    const clientWidth = this.getClientWidth();

    if (scrollWidth === clientWidth) {
      this.displayLeftButton = false;
      this.displayRightButton = false;
    } else if (scrollLeft === 0) {
      this.displayLeftButton = false;
      this.displayRightButton = true;
    } else if (scrollLeft + offsetWidth === scrollWidth) {
      this.displayLeftButton = true;
      this.displayRightButton = false;
    } else {
      this.displayLeftButton = true;
      this.displayRightButton = true;
    }
  }

  getScrollLeft() {
    return this.scrollContainer.nativeElement.scrollLeft;
  }

  getOffsetWidth() {
    return this.scrollContainer.nativeElement.offsetWidth;
  }

  getScrollWidth() {
    return this.scrollContainer.nativeElement.scrollWidth;
  }

  getClientWidth() {
    return this.scrollContainer.nativeElement.clientWidth;
  }
}
