import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CustomerService } from '../services/customer.service';
import { FirmService } from '../services/firm.service';

@Component({
  selector: 'app-password-reset',
  templateUrl: './password-reset.component.html',
  styleUrls: ['./password-reset.component.css']
})
export class PasswordResetComponent implements OnInit {
  
  firm: any;
  own_domain: boolean;
  firm_name: any;
  language: any;
  email: any;

  constructor(route: ActivatedRoute, 
              public firmService: FirmService, 
              public customerService: CustomerService) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  async initData() {
    this.firmService.checkWebsite(this.firm, this.language);
  }

}
