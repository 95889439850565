<head>
  <title>{{ firm?.name }}</title>
</head>
<body>

<div class="main">

  <!-- Header -->
  <div class="pagecontent" style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">

    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="view-productcategory">
      <div class="centered">

        <!-- Vat delivery method -->
        <app-vat-delivery-method-opener *ngIf="firm" [firm]="firm" (vatChanged)="vatDeliveryMethodChanged($event)"></app-vat-delivery-method-opener>

        <div *ngIf="!subcategory" class="breadcrumb">
          <a href="javascript:void(0)" (click)="routeService.navigateToMenu(own_domain, firm, language)">{{ 'CATEGORY.SELECTION' | translate }}</a>
        </div>

        <div *ngIf="subcategory" class="breadcrumb">
          <a href="javascript:void(0)" (click)="routeService.navigateToMenu(own_domain, firm, language)">{{ 'CATEGORY.SELECTION' | translate }}</a>
          <a href="javascript:void(0)" (click)="routeService.navigateToCategory(own_domain, firm, language, cat_name, cat_id)">{{ cat_name }}</a>
        </div>

        <h1 class="title-font">{{ category?.name }}</h1>

        <!-- Parent category not available -->
        <div class="unavailable" *ngIf="parent_category && firm?.only_show_available_parent_categories && !parent_category?.isAvailable">
          <p><span>{{ parent_category?.name }}</span> {{ 'CAT_NOT_AVAILABLE_ATM' | translate }}</p>
          <p *ngIf="parent_category?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: parent_category?.firstAvailableDate?.day_name?.toLowerCase()} }} {{ 'FROM' | translate:{time: parent_category?.firstAvailableDate?.from_time} }}</p>
        </div>

        <!-- Empty category -->
        <div *ngIf="category?.products.length === 0 && category?.subcategories.length === 0">
          <p>{{ 'MESSAGES.EMPTY_CATEGORY' | translate }}</p>
        </div>

        <!-- Products -->
        <div class="products row" *ngIf="category?.products.length > 0">
          <app-list-item
            *ngFor="let item of category?.products"
            [vat_delivery_method]="vat_delivery_method" [item]="item"
            [firm]="firm" [own_domain]="own_domain"
            [isAvailable]="parent_category?.isAvailable"></app-list-item>
        </div>

        <!-- Subcategories -->
        <ul class="categories sub row" *ngIf="category?.subcategories.length > 0">
          <li class="col third no-single" *ngFor="let subcat of category?.subcategories">
            <eo-menu-card
              [title]="subcat.name"
              [image]="subcat.images | mainThumb"
              (clicked)="menuCardClicked(category, subcat)">
            </eo-menu-card>
          </li>
        </ul>
      </div>
    </div>

    <!-- Order now footer -->
    <app-order-now-bar *ngIf="firm" [firm]="firm"></app-order-now-bar>

    <div style="height: 2rem;"></div>
  </div>


  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

</div>

<div id="iosfix-top"></div>
<div id="iosfix-btm"></div>

</body>
