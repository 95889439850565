import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { CustomerService } from '../services/customer.service';
import { LoaderService } from '../services/loader.service';
import { Address } from '../core/models/address';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss'],
})
export class UserProfileComponent {
  @Input() user: any;
  @Input() firm: any;
  @Input() language: any;
  @Input() own_domain: any;
  @Input() addressList: Address[];

  @Output() addressSelect = new EventEmitter<Address>();
  @Output() addressEdit = new EventEmitter<Address>();
  @Output() addressAdd = new EventEmitter<void>();

  @Output() businessDetailsClicked = new EventEmitter<void>();

  constructor(
    public customerService: CustomerService,
    private loader: LoaderService,
    private snackBar: MatSnackBar,
    private translate: TranslateService
  ) {}

  async save() {
    this.loader.present();

    if(!this.user.country) {
      this.user.country = [];
    }
    if(this.user.opt_in_eo == null || this.user.opt_in_eo == undefined) {
      this.user.opt_in_eo = false;
    }
    if(this.user.opt_in_merchant == null || this.user.opt_in_merchant == undefined) {
      this.user.opt_in_merchant = false;
    }

    let user: any = {
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      phone: this.user.phone,
      company: this.user.company,
      email: this.user.email,
      street: this.user.street,
      house_number: this.user.house_number,
      zipcode: this.user.zipcode,
      locality: this.user.locality,
      country_id: this.user.country.id,
      opt_in_eo: this.user.opt_in_eo,
      opt_in_merchant: this.user.opt_in_merchant
    }

    await this.customerService.update(this.firm, this.user.id, user, this.language, this.firm.distributor, this.own_domain);
    this.snackBar.open(this.translate.instant('MESSAGES.CUSTOMER_UPDATED'), "", {duration: 2000});
    this.loader.dismiss();
  }

  selectDeliveryAddressHandler(address) {
    this.addressSelect.emit(address);
  }

  editDeliveryAddressHandler(address) {
    this.addressEdit.emit(address);
  }

  addDeliveryAddressHandler() {
    this.addressAdd.emit();
  }

  businessDetailsHandler() {
    this.businessDetailsClicked.emit();
  }

  userHasBusinessDetails(user) {
    return this.customerService.userHasBusinessDetails(user);
  }
}
