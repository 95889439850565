import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'eo-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.css'],
})
export class ImageCarouselComponent implements OnInit {
  @Input() images: Array<any>;
  imageUrl: string;
  imageIndex = 0;

  ngOnInit() {
    this.imageUrl = this.images[0].detail;
    this.sortImages();
  }

  sortImages() {
    if (this.images) {
      this.images.sort(function (x, y) {
        if (y.main - x.main) {
          return y.main - x.main;
        }
      });
    }
  }

  previousImage() {
    if (this.imageIndex != 0) {
      this.imageUrl = this.images[this.imageIndex - 1].detail;
      this.imageIndex = this.imageIndex - 1;
    }
  }

  nextImage() {
    if (this.imageIndex + 1 < this.images.length) {
      this.imageUrl = this.images[this.imageIndex + 1].detail;
      this.imageIndex += 1;
    }
  }
}
