<div class="centered">
    
    <!-- Pickup + delivery hours -->
    <div class="col full no-margin"
        *ngIf="(firm?.periods?.pickup_hours && !firm?.has_pickup_custom_days && !firm?.order_timeslots?.pickup?.active) ||
                (firm?.periods?.delivery_hours && !firm?.has_delivery_custom_days && !firm?.order_timeslots?.delivery?.active) ||
                firm?.order_timeslots?.pickup?.active ||
                firm?.order_timeslots?.delivery?.active ||
                (firm?.delivery && firm?.delivery_zipcodes?.length > 0) ||
                (firm?.delivery && firm?.delivery_radius?.length > 0) || 
                (firm?.delivery && firm?.free_delivery_price)">

        <!-- Toggle pickup/delivery hours -->
        <div class="buttons" style="margin-left: -5px">
            <a href="javascript:void(0)" class="btn-fill" id="displayOpeningHours"
                (click)="!showHours ? showHours=true : showHours=false"
                [style.background-color]="firm?.theme.main_gradient_contrast_color"
                [style.color]="firm?.theme.main_gradient_color_from">
                {{ 'CHECKOUT.SHOW_AVAILABLE_TIMES' | translate }}
            </a>
        </div>

        <div *ngIf="showHours" class="row" id="hoursInfo" style="margin-top: 20px">

        <!-- Pickup hours -->
        <div *ngIf="firm?.periods.pickup_hours && !firm?.has_pickup_custom_days && !firm?.order_timeslots?.pickup?.active" class="col half">
            <p style="margin-bottom: 10px">{{ 'CHECKOUT.PICKUP_HOURS' | translate }}</p>

            <!-- Pickup hours -->
            <ul class="hours">
                <li class="row" *ngFor="let day of pickup_hours">
                    <div class="day">{{day.day}}</div>

                    <!-- Catch bad formatted hours -->
                    <div *ngIf="day.am && !day.pm && day.am.type === 'open'" class="hour">
                        {{day.am.from_time}} - {{day.am.to_time}}
                    </div>
                    <div *ngIf="day.am && !day.pm && day.am.type === 'closed'" class="hour">
                        {{ 'INFO.CLOSED' | translate }}
                    </div>
                    <div *ngIf="!day.am && day.pm && day.pm.type === 'open'" class="hour">
                        {{day.pm.from_time}} - {{day.pm.to_time}}
                    </div>
                    <div *ngIf="!day.am && day.pm && day.pm.type === 'closed'" class="hour">
                        {{ 'INFO.CLOSED' | translate }}
                    </div>

                    <!-- Show normal hours -->
                    <div *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'closed')" class="hour">
                        {{day.am.from_time}} - {{day.am.to_time}}
                    </div>
                    <div *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'open')" class="hour">
                        {{day.pm.from_time}} - {{day.pm.to_time}}
                    </div>
                    <div *ngIf="(day.am && day.pm) && (day.am.type === 'open' && day.pm.type === 'open')" class="hour">
                        {{day.am.from_time}} - {{day.am.to_time}} & {{day.pm.from_time}} - {{day.pm.to_time}}
                    </div>
                    <div *ngIf="(day.am && day.pm) && (day.am.type === 'closed' && day.pm.type === 'closed')" class="hour closed">
                        {{ 'INFO.CLOSED' | translate }}
                    </div>
                </li>
            </ul>
        </div>

            <!-- Pickup timeslots -->
            <div *ngIf="firm?.order_timeslots?.pickup?.active" class="col half">
                <p style="margin-bottom: 10px">{{ 'CHECKOUT.PICKUP_HOURS' | translate }}</p>
                <ul class="hours">
                    <li class="row" *ngFor="let timeslot of pickup_timeslots">
                        <div class="day">{{timeslot.day}}</div>
                        <div class="timeslot">
                            <div class="hour" *ngFor="let hour of timeslot.hours">{{ hour.from_time }} - {{ hour.to_time }}</div>
                        </div>
                    </li>
                </ul>
            </div>

            <!-- Delivery hours -->
            <app-delivery-hours *ngIf="firm && !firm?.has_delivery_custom_days && !firm?.order_timeslots?.delivery?.active" [firm]="firm"></app-delivery-hours>

            <!-- Delivery timeslots -->
            <div *ngIf="firm?.order_timeslots?.delivery?.active" class="col half">
            <p style="margin-bottom: 10px">{{ 'CHECKOUT.DELIVERY_HOURS' | translate }}</p>
                <ul class="hours">
                    <li class="row" *ngFor="let timeslot of delivery_timeslots">
                        <div class="day">{{timeslot.day}}</div>
                        <div class="timeslot" *ngFor="let hour of timeslot.hours">{{hour.from_time}} - {{hour.to_time}}</div>
                    </li>
                </ul>
            </div>

            <!-- Delivery info -->
            <app-delivery-info *ngIf="firm" [firm]="firm"></app-delivery-info>
        </div>

    </div>

</div>