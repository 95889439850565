import { Component, EventEmitter, Input, Output } from '@angular/core';
import { interval as observableInterval } from 'rxjs/internal/observable/interval';
import { CustomerService } from '../services/customer.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'eo-social-logins',
  templateUrl: './social-logins.component.html',
  styleUrls: ['./social-logins.component.css'],
})
export class SocialLoginsComponent {
  @Input() firm: any;
  @Input() language: string;

  @Output() loggedIn = new EventEmitter<number>();

  window: Window;
  sub: Subscription;

  constructor(private customerService: CustomerService) {}

  async socialMediaLogin(social_media) {
    const response: any = await this.customerService.initiateSocialLogin(this.firm.id, this.language, social_media);

    const screen_width = screen.width;
    const screen_height = screen.height;

    let width;
    let height;

    if (screen_width < 480) {
      width = screen_width / 1.1;
      height = screen_height / 1.1;
    } else {
      width = screen_width / 2.5;
      height = screen_height / 1.5;
    }

    const left = screen.availWidth / 2 - width / 2;
    const top = screen.availHeight / 2 - height / 2;

    const windowFeatures =
      'width=' +
      width +
      ',height=' +
      height +
      ',status,resizable,left=' +
      left +
      ',top=' +
      top +
      'screenX=' +
      left +
      ',screenY=' +
      top;

    this.window = window.open(String(response.login_url), 'myWindow', windowFeatures);

    this.sub?.unsubscribe();
    this.sub = observableInterval(5000).subscribe(async () => {
      const response: any = await this.customerService.checkSocialToken(this.firm.id, this.language);

      if (this.window.closed) {
        this.sub.unsubscribe();
      }

      if (response.error) {
        if (response.error.code == 'SESSION_TOKEN_INVALID') {
          this.sub.unsubscribe();
          this.window.close();
        }
      } else {
        this.sub.unsubscribe();
        this.window.close();
        this.loggedIn.emit(response.customer.id);
      }
    });
  }

  ngOnDestroy() {
    this.sub?.unsubscribe();
  }
}
