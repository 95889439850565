import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService } from '../services/dialogs.service';
import { ProductService } from '../services/products.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';
import * as moment from 'moment';

@Component({
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: ['./list-item.component.css'],
})
export class ListItemComponent implements OnInit, OnChanges {
  @Input() item: any;
  @Input() firm: any;
  @Input() own_domain: any;
  @Input() language: any;
  @Input() vat_delivery_method: any;
  @Input() isAvailable: boolean;

  moment = moment;
  firm_name: any;
  cat_name: any;
  cat_id: any;
  subcat_name: any;
  subcat_id: any;

  pickup_label: any;
  VAT_percentage: any;
  itemDisabled: boolean;
  VAT: any;
  VAT_total: any;

  constructor(
    public dialogsService: DialogsService,
    public router: Router,
    public route: ActivatedRoute,
    public slug: SlugifyService,
    public productService: ProductService,
    public routeService: RouteService
  ) {
    this.firm_name = this.route.snapshot.params.firm_name;
    this.cat_name = this.route.snapshot.params.cat_name;
    this.cat_id = this.route.snapshot.params.cat_id;
    this.subcat_name = this.route.snapshot.params.subcat_name;
    this.subcat_id = this.route.snapshot.params.subcat_id;
    this.language = this.route.snapshot.params.language;
    moment.locale(this.language);
  }

  ngOnInit() {
    if (this.firm.uses_vat) {
      const vat_delivery_method = this.vat_delivery_method;

      const delivery_methods = this.firm.vat_settings.delivery_method.filter(
        (method) => method.method == vat_delivery_method
      );

      this.item.vat_percentages.forEach((item_percentage) => {
        delivery_methods.forEach((method) => {
          if (method.vat_percentage == item_percentage) {
            this.VAT_percentage = item_percentage;
          }
        });
      });

      this.calculateVat();
    }
  }

  ngOnChanges() {
    if (this.firm.uses_vat) {
      this.calculateVat();
    }
  }

  async calculateVat() {
    this.VAT_percentage = await this.productService.getVatPercentage(this.item, this.firm);

    if (this.VAT_percentage != undefined) {
      this.itemDisabled = false;

      if (this.item.has_promotional_price == false) {
        this.VAT = (this.item.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.price;
      } else if (this.item.has_promotional_price == true) {
        this.VAT = (this.item.promotion.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.promotion.price;
      }
    } else {
      this.itemDisabled = true;
    }
  }

  async openModal(item) {
    const product: any = await this.productService.getItemById(
      this.firm.id,
      this.language,
      item.id,
      this.firm.uses_deliverect
    );
    product.vat_percentage = this.VAT_percentage;

    this.dialogsService.openModal(product, this.firm, product.category.category_id, this.language);
  }
}
