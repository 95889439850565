import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { Router } from '@angular/router';
import { StoreService } from './store.service';
import { DialogsService } from './dialogs.service';
import { config } from '../../config';
import { SlugifyService } from './slugify.service';
import * as moment from 'moment';
import { DOMService } from './dom.service';
import { TagService } from './tag.service';
import { ThemingService } from './theming.service';

@Injectable()
export class FirmService {
  private endpoint_firms = '/firms';
  private endpoint_firm = '/firm';
  private endpoint_firm_host = '/firm_host';
  private endpoint_firm_by_name = '/firm_webshop';
  private endpoint_distributor = '/distributor_webshops';
  private endpoint_password = '/firm/checkpassword';
  private endpoint_demo_password = '/firm/checkdemopassword';
  private endpoint_distributor_by_domain = '/distributor_domain';
  private endpoint_reservation_times = '/firm/reservations/times';
  private endpoint_reservation_confirm = '/firm/reservations/confirm';

  token: any;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    private domService: DOMService,
    private tagService: TagService,
    public errorService: ErrorService,
    public router: Router,
    public storeService: StoreService,
    public dialogsService: DialogsService,
    public slug: SlugifyService,
    private themingService: ThemingService
  ) {}

  async getDistributor(hostname?): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            language: '1',
            Authorization: 'Bearer ' + this.token,
            Accept: 'application/json',
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        let body;

        if (hostname) {
          body = {
            domain: hostname,
          };
        } else {
          body = {
            domain: location.hostname,
          };
        }

        this.http.post(environment.API_URL + this.endpoint_distributor_by_domain, body, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            if (err.status === 401) {
              this.token = await this.auth.getToken();
              const response = await this.getDistributor(hostname);
              resolve(response);
            } else {
              resolve('CUSTOM_DOMAIN');
            }
          }
        );
      });
    }
  }

  async getDistributorFirms(): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            language: '1',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            Accept: 'application/json',
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          domain: location.hostname,
        };

        this.http.post(environment.API_URL + this.endpoint_distributor, body, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkFirmsErrors(err.status);

            if (check == 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = this.getDistributorFirms();
              resolve(response);
            } else {
              this.router.navigate(['404']);
            }
          }
        );
      });
    }
  }

  async getAllFirms(store_id?): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: store_id ? store_id : await this.storeService.getStoreId(),
            language: '1',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            Accept: 'application/json',
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        this.http.get(environment.API_URL + this.endpoint_firms, httpOptions).subscribe(
          (data: any) => {
            if (data.data.distributor.theme) {
              this.themingService.setTheme(data.data.distributor.theme);
            }
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkFirmsErrors(err.status);

            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              if (store_id) {
                const response = await this.getAllFirms(store_id);
                resolve(response);
              } else {
                const response = await this.getAllFirms();
                resolve(response);
              }
            }
          }
        );
      });
    }
  }

  async getFirm(firm_name, lang_code): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          name: String(firm_name),
        };

        this.http.post(environment.API_URL + this.endpoint_firm_by_name, body, httpOptions).subscribe(
          async (data: any) => {
            if (data.data.theme) {
              this.themingService.setTheme(data.data.theme);
            }
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkFirmErrors(err.status, await this.parseError(err));
            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = this.getFirm(firm_name, lang_code);
              resolve(response);
            }
          }
        );
      });
    }
  }

  async getFirmTimeslots(
    date,
    delivery_method,
    products,
    categories,
    firm_pickup_point_id,
    distributor,
    firm,
    own_domain,
    lang_code
  ): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            language: String(lang_code),
            firmId: String(firm.id),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: '4.3',
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          date,
          delivery_method,
          products,
          categories,
          firm_pickup_point_id,
        };

        this.http.post(environment.API_URL + this.endpoint_firm + '/timeslots', body, httpOptions).subscribe(
          (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkCalculateErrors(
              err.status,
              await this.parseError(err),
              distributor,
              firm,
              own_domain,
              lang_code
            );

            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = await this.getFirmTimeslots(
                date,
                delivery_method,
                products,
                categories,
                firm_pickup_point_id,
                distributor,
                firm,
                own_domain,
                lang_code
              );
              resolve(response);
            }
          }
        );
      });
    }
  }

  async getTimeIntervals(
    timeslot_id,
    date,
    delivery_method,
    products,
    categories,
    firm_pickup_point_id,
    distributor,
    firm,
    own_domain,
    lang_code
  ) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            language: String(lang_code),
            firmId: String(firm.id),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: '4.2',
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          date,
          delivery_method,
          products,
          categories,
          firm_pickup_point_id,
        };

        this.http
          .post(environment.API_URL + this.endpoint_firm + '/timeslots/' + timeslot_id, body, httpOptions)
          .subscribe(
            (data: any) => {
              resolve(data.data);
            },
            async (err) => {
              const check = await this.errorService.checkCalculateErrors(
                err.status,
                await this.parseError(err),
                distributor,
                firm,
                own_domain,
                lang_code
              );

              if (check === 'TOKEN_INVALID') {
                this.token = await this.auth.getToken();
                const response = await this.getTimeIntervals(
                  timeslot_id,
                  date,
                  delivery_method,
                  products,
                  categories,
                  firm_pickup_point_id,
                  distributor,
                  firm,
                  own_domain,
                  lang_code
                );
                resolve(response);
              }
            }
          );
      });
    }
  }

  async getFirmForHost(host_name, lang_code): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + this.token,
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          host: String(host_name),
        };

        this.http.post(environment.API_URL + this.endpoint_firm_host, body, httpOptions).subscribe(
          async (data: any) => {
            if (data.data.theme) {
              this.themingService.setTheme(data.data.theme);
            }
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkHostErrors(err.status, await this.parseError(err));

            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = await this.getFirmForHost(host_name, lang_code);
              resolve(response);
            } else if (check === 'NO_CUSTOM_DOMAIN') {
              resolve('NO_CUSTOM_DOMAIN');
            }
          }
        );
      });
    }
  }

  async getMultiFirmOwnDomain(host, lang_code, firm_name?): Promise<any> {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: '4.3',
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        let body;

        if (firm_name != undefined && firm_name != '') {
          body = {
            host: String(host),
            name: String(firm_name),
          };
        } else {
          body = {
            host: String(host),
          };
        }

        this.http.post(environment.API_URL + this.endpoint_firm_by_name, body, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkFirmErrors(err.status, await this.parseError(err));

            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = await this.getMultiFirmOwnDomain(host, lang_code);
              resolve(response);
            }
          }
        );
      });
    }
  }

  async enterPassword(password, firm_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          password: password,
        };

        this.http.post(environment.API_URL + this.endpoint_password, body, httpOptions).subscribe(
          (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkPasswordErrors(err.status, await this.parseError(err));

            if (check == 'TOKEN_INVALID') {
              this.enterPassword(password, firm_id, lang_code);
            } else if (check == '403') {
              resolve('PASSWORD_INCORRECT');
            }
          }
        );
      });
    }
  }

  async getTimeSlots(reservation, firm_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        this.http.post(environment.API_URL + this.endpoint_reservation_times, reservation, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkFirmErrors(err.status, await this.parseError(err));
            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = await this.getTimeSlots(reservation, firm_id, lang_code);
              resolve(response);
            }
          }
        );
      });
    }
  }

  async confirmReservation(reservation, firm_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        this.http.post(environment.API_URL + this.endpoint_reservation_confirm, reservation, httpOptions).subscribe(
          (data: any) => {
            resolve('');
          },
          async (err) => {
            const check = await this.errorService.checkFirmErrors(err.status, await this.parseError(err));

            if (check === 'TOKEN_INVALID') {
              this.token = await this.auth.getToken();
              const response = await this.confirmReservation(reservation, firm_id, lang_code);
              resolve(response);
            } else if (check == 'RESERVATION_ERROR') {
              const error = await this.parseError(err);

              if (error.message) {
                resolve(error.message);
              } else {
                resolve(error.errors.email);
              }
            }
          }
        );
      });
    }
  }

  async enterDemoPassword(password, firm_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        const body = {
          password: password,
        };

        this.http.post(environment.API_URL + this.endpoint_demo_password, body, httpOptions).subscribe(
          (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkPasswordErrors(err.status, await this.parseError(err));

            if (check == 'TOKEN_INVALID') {
              this.enterPassword(password, firm_id, lang_code);
            } else if (check == '403') {
              resolve('PASSWORD_INCORRECT');
            }
          }
        );
      });
    }
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    } catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async getFirmByCheckingDomain(firm_name, language) {
    let response;
    const distributor = await this.getFirmForHost(location.origin, language);

    if (distributor) {
      // Get firm with own domain (parameter = language)
      if (distributor != 'NO_CUSTOM_DOMAIN') {
        if (distributor.multifirm == true) {
          response = {
            firm: await this.getFirm(firm_name, language),
            own_domain: true,
          };
          response.firm.parent_is_multifirm = true;
        } else {
          response = {
            firm: distributor,
            own_domain: true,
          };
        }

        if (response.firm.tracking) this.tagService.setMarketingTags(response.firm.tracking);
      } else {
        response = {
          firm: await this.getFirm(firm_name, language),
          own_domain: false,
        };
      }
    }

    this.domService.updateFavicon(response.firm.logo_square);

    return response;
  }

  async checkWebsite(firm, lang_code) {
    if (!firm.has_website) {
      this.router.navigate([firm.website_details.sitename, lang_code, '404']);
    } else {
      return true;
    }
  }

  async checkForPassword(firm) {
    if (firm.password_protected == true) {
      const tokens = await JSON.parse(localStorage.getItem('password_tokens'));

      if (tokens == null) {
        this.router.navigate(['password', firm.website_details.sitename]);
      } else {
        const token: any = tokens.some((pt) => pt.firm_id === firm.id);
        if (token == false) {
          this.router.navigate(['password', firm.website_details.sitename]);
        } else {
          return true;
        }
      }
    } else {
      return true;
    }
  }

  checkEmbed() {
    if (location.origin === environment.EMBED_URL) {
      return true;
    } else {
      return false;
    }
  }

  setHours(days) {
    const week_days = days;

    let i = 0;
    for (i; i <= days.length; i++) {
      if (week_days[i] && week_days[i + 1]) {
        if (
          week_days[i].pm &&
          week_days[i].pm.type === 'open' &&
          week_days[i].pm.to_time === '23:59' &&
          week_days[i + 1].am &&
          week_days[i + 1].am.type === 'open' &&
          week_days[i + 1].am.from_time === '00:00'
        ) {
          week_days[i].pm.to_time = week_days[i + 1].am.to_time;
          week_days[i + 1].am.type = 'closed';
        } else if (
          week_days[i].pm &&
          week_days[i].pm.type === 'open' &&
          week_days[i].pm.to_time === '23:59' &&
          ((week_days[i + 1].am && week_days[i + 1].am.type === 'open' && week_days[i + 1].am.from_time !== '00:00') ||
            (week_days[i + 1].am && week_days[i + 1].am.type === 'closed'))
        ) {
          week_days[i].pm.to_time = '00:00';
        }
      } else if (week_days[i] && !week_days[i + 1]) {
        if (
          week_days[i].pm &&
          week_days[i].pm.type === 'open' &&
          week_days[i].pm.to_time === '23:59' &&
          week_days[0].am &&
          week_days[0].am.type === 'open' &&
          week_days[0].am.from_time === '00:00'
        ) {
          week_days[i].pm.to_time = week_days[0].am.to_time;
          week_days[0].am.type = 'closed';
        } else if (
          week_days[i].pm &&
          week_days[i].pm.type === 'open' &&
          week_days[i].pm.to_time === '23:59' &&
          ((week_days[0].am && week_days[0].am.type === 'open' && week_days[0].am.from_time !== '00:00') ||
            (week_days[0].am && week_days[0].am.type === 'closed'))
        ) {
          week_days[i].pm.to_time = '00:00';
        }
      }
    }

    return week_days;
  }

  getHolidayPeriods(holidays) {
    const holidayPeriods = [];

    if (holidays.length > 0) {
      holidays.forEach((holiday) => {
        holidayPeriods.push({
          from_date: moment(holiday.from_date).local().format('LL'),
          to_date: moment(holiday.to_date).local().format('LL'),
        });
      });
    }

    return holidayPeriods;
  }

  async isShopOpen(holidays, openingHours, todayDate?) {
    let storeOpen = false;
    if (!todayDate) todayDate = new Date();

    const onHoliday = await this.isOnHoliday(holidays, todayDate);
    if (!onHoliday) {
      storeOpen = await this.isInsideOpeningHours(
        openingHours,
        moment().format('HH:mm'),
        todayDate.getDay() !== 0 ? todayDate.getDay() : 7
      );
    }

    return storeOpen;
  }

  // Check if today is in a holiday period
  isOnHoliday(holidays, todayDate: Date) {
    let onHoliday = false;

    if (holidays.length > 0) {
      const today = new Date(new Date(todayDate).setHours(0, 0, 0, 0));

      holidays.forEach((period) => {
        const from = new Date(new Date(period.from_date).setHours(0, 0, 0, 0));
        const to = new Date(new Date(period.to_date).setHours(0, 0, 0, 0));

        // Works in browser, but not in tests
        if (from <= today && today <= to) {
          onHoliday = true;
        }
        // Add to make tests work
        else if (from.getTime() === today.getTime() || to.getTime() === today.getTime()) onHoliday = true;
      });
    }

    return onHoliday;
  }

  isInsideOpeningHours(openingHours, currentTime, todayId) {
    let opened = false;

    // Check opening hours
    if (openingHours.length > 0) {
      // Search for current day in openinghours list
      let day: any = openingHours.filter((day) => day.day_id === todayId);
      day = day[0];

      if (day) {
        // am & pm
        if (day.am && day.am.type === 'open' && day.pm && day.pm.type === 'open') {
          if (
            (day.am.from_time < currentTime && currentTime < day.am.to_time) ||
            (day.pm.from_time < currentTime && currentTime < day.pm.to_time)
          ) {
            opened = true;
          }
        }
        // only am
        else if (day.am && day.am.type === 'open' && day.pm && day.pm.type === 'closed') {
          if (day.am.from_time < currentTime && currentTime < day.am.to_time) {
            opened = true;
          }
        }
        // only pm
        else if (day.pm && day.pm.type === 'open' && day.am && day.am.type === 'closed') {
          if (day.pm.from_time < currentTime && currentTime < day.pm.to_time) {
            opened = true;
          }
        }
      }
    }

    return opened;
  }

  isHawaiian() {
    return location.hostname === 'app.hawaiianpokebowl.be';
  }

  isReservationEnabled(reservations): boolean {
    return reservations && reservations.active;
  }
}
