<div *ngIf="firm?.delivery" class="col half">
  <p>{{ 'CHECKOUT.DELIVERY_INFO' | translate }}</p>
  
  <ul class="hours">
      <div *ngIf="firm?.delivery_zipcodes?.length > 0">

        <div class="grid-container">
            <p>{{ 'CHECKOUT.CITIES' | translate }}</p>
            <p>{{ 'MIN_ORDER' | translate }}</p>
        </div>

        <div class="grid-container" *ngFor="let el of firm?.delivery_zipcodes">
            <p class="grid-item">{{ el.zipcode }} <span *ngIf="el.city">({{ el.city }})</span></p>
            <p class="grid-item">{{ firm?.currency.symbol }}{{ el.min_order_price | number:'1.2-2' }}</p>
        </div>
        
      </div>
      <div *ngIf="firm?.delivery_radius?.length > 0">
        <br>
        {{ 'CHECKOUT.COSTS' | translate }}
        <li class="row" *ngFor="let radius of firm?.delivery_radius">
            <div class="day">{{radius.radius_from}} - {{ radius.radius_to}}km</div>
            <div class="hour">{{ firm?.currency.symbol }}{{ radius.price | number:'1.2-2' }}</div>
        </li>
      </div>
      <br>
      <p *ngIf="firm?.free_delivery_price === 0">{{ 'CHECKOUT.FREE_DELIVERY' | translate }}</p>
      <p *ngIf="firm?.free_delivery_price > 0">{{ 'CHECKOUT.FREE_DELIVERY_FROM' | translate }} {{ firm?.currency.symbol }}{{ firm?.free_delivery_price | number:'1.2-2' }}</p>
  </ul>
</div>