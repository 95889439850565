import { Component, Input } from '@angular/core';
import { FirmService } from '../services/firm.service';

@Component({
  selector: 'app-hours-toggle',
  templateUrl: './hours-toggle.component.html',
  styleUrls: ['./hours-toggle.component.css']
})
export class HoursToggleComponent {

  @Input() firm: any;

  showHours = false;
  pickup_hours: any;
  pickup_timeslots: any = [];
  delivery_timeslots: any = [];

  constructor(private firmService: FirmService) { }

  async ngOnChanges() {
    if(this.firm) {
      if(this.firm.periods.pickup_hours) {
        this.pickup_hours = await this.firmService.setHours(this.firm.periods.pickup_hours);
      }
      if(this.firm.order_timeslots.pickup.active) {
        this.pickup_timeslots = await this.formatTimeslots(this.firm.order_timeslots.pickup.timeslots);
      }
      if(this.firm.order_timeslots.delivery.active) {
        this.delivery_timeslots = await  this.formatTimeslots(this.firm.order_timeslots.delivery.timeslots);
      }
    }
  }

  async formatTimeslots(original_timeslots) {
    const formatted_timeslots = [];
    let day_id = 1;
    do {
      const timeslots = await original_timeslots.filter(timeslot => timeslot.day_id === day_id);

      if(timeslots.length > 0) {
        const timeslot_hours = [];

        await timeslots.forEach(timeslot => {
          timeslot_hours.push({
            from_time: timeslot.from_time,
            to_time: timeslot.to_time
          });
        });

        formatted_timeslots.push({
          day: timeslots[0].day,
          hours: timeslot_hours
        });
      }

      day_id += 1;
    } while (day_id <= 7);

    return formatted_timeslots;
  }

}
