<div class="view-userdetails">
  <h1 class="title-font">{{ 'USER_DETAILS.MY_INFO' | translate }}</h1>

  <form #formDetails="ngForm" *ngIf="user">

    <!-- User info (for both order & profile) -->
    <div class="well">

      <div class="input">
        <label for="email">{{ 'USER_DETAILS.EMAIL' | translate }} *</label>
        <div class="inputfield">
          <input name="email" type="email" [(ngModel)]="user.email" required pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
        </div>
      </div>

      <div class="input">
        <label for="name">{{ 'USER_DETAILS.FIRST_NAME' | translate }} *</label>
        <div class="inputfield">
          <input name="firstName" type="text" [(ngModel)]="user.first_name" required>
        </div>
      </div>

      <div class="input">
        <label for="name">{{ 'USER_DETAILS.LAST_NAME' | translate }} *</label>
        <div class="inputfield">
          <input name="lastName" type="text" [(ngModel)]="user.last_name" required>
        </div>
      </div>

      <div class="input">
        <label for="phone">{{ 'USER_DETAILS.PHONE' | translate }} *</label>
        <div class="inputfield">
          <input name="phone" type="tel" [(ngModel)]="user.phone" required>
        </div>
      </div>

      <div class="input">
        <label for="company">{{ 'USER_DETAILS.COMPANY' | translate }}</label>
        <div class="inputfield">
          <input name="company" type="text" [(ngModel)]="user.company">
        </div>
      </div>
    </div>

    <!-- Business details -->
    <div class="invoice-details-container" *ngIf="firm?.has_module_invoicing">
      <br/>
      <h1 class="title-font">{{ 'COMPANY_DETAILS' | translate }}</h1>
      <div class="eo-card add-card width-33"
           *ngIf="!userHasBusinessDetails(user)"
           (click)="businessDetailsHandler()">
        <div class="content">
          <div class="add-icon">
            <i class="fas fa-plus-circle add-icon"></i>
          </div>
          <strong>{{ 'ADD_COMPANY_DETAILS' | translate }}</strong>
        </div>
      </div>
      <div
        class="eo-card details-card width-40"
        *ngIf="userHasBusinessDetails(user)">
          <div class="content">
            <eo-round-button *ngIf="user?.vat_validation_status !== 'validating'"
              class="edit"
             [iconName]="'fas fa-pen'"
             (clicked)="businessDetailsHandler()">
            </eo-round-button>

            <div class="business-details">
              <strong>{{ this.user.company }}</strong>
              <p *ngIf="this.user.invoice_reference">{{ this.user.invoice_reference }}</p>
              <p>{{ this.user.vat_number }}</p>
            </div>

            <eo-vat-validation-badge [status]="user?.vat_validation_status"></eo-vat-validation-badge>
          </div>
      </div>
    </div>

    <br/>

    <!-- Addresses -->
    <div class="addresses-container">
      <div class="addresses-header flex align-items-center justify-space-between">
        <h1 class="title-font">{{ 'ADDRESSES' | translate }}</h1>
        <button class="btn-fill custom-btn flex align-items-center font-13"
                (click)="addDeliveryAddressHandler()">
          <div class="add-icon mr-5">
            <i class="fas fa-plus-circle add-icon"></i>
          </div>
          {{ 'ADD_ADDRESS' | translate }}
        </button>
      </div>
      <div class='grid-container' *ngIf="addressList">
        <eo-address
          *ngFor='let address of addressList'
          [address]='address'
          (selected)="selectDeliveryAddressHandler($event)"
          (edit)="editDeliveryAddressHandler($event)">
        </eo-address>
        <div class="eo-card add-card"
             (click)="addDeliveryAddressHandler()">
          <div class="content">
            <div class="add-icon">
              <i class="fas fa-plus-circle add-icon"></i>
            </div>
            <strong>{{ 'ADD_ADDRESS' | translate }}</strong>
          </div>
        </div>
      </div>
    </div>

    <br/>

    <!-- Checkboxes (for profile) -->
    <div class="gdpr title-font">
      <h2>{{ 'GDPR.GIVE_PERMISSION' | translate }}</h2>
      <mat-checkbox name="opt_in_eo" [(ngModel)]="user.opt_in_eo" disableRipple="true"><span>{{ 'GDPR.EO_OPT_IN' | translate }}</span></mat-checkbox>
      <mat-checkbox name="opt_in_merchant" [(ngModel)]="user.opt_in_merchant" disableRipple="true"><span>{{ 'GDPR.MERCHANT_OPT_IN' | translate:{firm_name: firm?.name} }}</span></mat-checkbox>
    </div>

    <!-- Save button -->
    <div class="buttons">
      <a class="reset-pw"
         (click)="customerService.resetPassword(user.email, firm, language)">{{ 'RESET_PASSWORD' | translate }}</a>
      <br>
      <button class="btn-fill save-btn custom-btn"
              (click)="save()"
              [disabled]="!formDetails.valid">
        {{ 'BUTTONS.SAVE' | translate }}
        <span class="arrow"></span></button>
    </div>
  </form>

</div>
