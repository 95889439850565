import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainThumb',
})
export class MainThumbPipe implements PipeTransform {
  transform(images: any, args?: any): any {
    if (!images) {
      return;
    }

    return images.find((img) => img.main)?.thumb;
  }
}
