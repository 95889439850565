import { Component, Input, OnInit } from '@angular/core';
import { Address } from '../../core/models/address';
import { MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-select-invoice-address-modal',
  templateUrl: './select-invoice-address-modal.component.html',
  styleUrls: ['./select-invoice-address-modal.component.scss'],
})
export class SelectInvoiceAddressModalComponent implements OnInit {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() addressList: Address[];
  @Input() selectedId: number;

  selectedAddress: Address;

  constructor(private dialogRef: MatDialogRef<SelectInvoiceAddressModalComponent>) {}

  ngOnInit() {
    if (!this.selectedId) {
      this.setInitialSelectedId();
    }
  }

  setInitialSelectedId() {
    if (this.addressList?.length > 0) {
      this.selectAddress(this.addressList[0]);
    }
  }

  selectAddress(address: Address) {
    this.selectedId = address.id;
    this.selectedAddress = address;
  }

  handleOnSubmit() {
    this.dismiss({
      saved: true,
      address: this.selectedAddress,
    });
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
