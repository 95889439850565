import { Injectable } from '@angular/core';

@Injectable()
export class FavoritesService {

  constructor() { }

  /* Add item to favorites
  If item already exists delete from list */
  async add(item_id, firm_id) {
    let favorites = await JSON.parse(localStorage.getItem('favorites'));
    

    // Add firm_id to favorite object
    let favoriteObj = {
      firm_id: firm_id,
      item_id: item_id
    }

    if(favorites == null || favorites == undefined) {
      favorites = [];
    }

    // list is not empty
    if(favorites.length != 0) {

      // check if item is in list
      var found = favorites.some(function (favItem) {
        return (favItem.firm_id === firm_id) && (favItem.item_id === item_id);
      })

      // item not found - push
      if (!found) {
        favorites.push(favoriteObj);
      }
      // item found - remove
      else {
        favorites.forEach(favObj => {
          if(favObj.firm_id === firm_id && favObj.item_id === item_id) {
            let index = favorites.indexOf(favObj);
            if (index !== -1) {
                favorites.splice(index, 1);
            }
          }
        });
      }
    }
    // list is empty, add item
    else {
      favorites.push(favoriteObj);
    }

    await localStorage.setItem('favorites', JSON.stringify(favorites));
  }

  /* Get the favorite list */
  async get(firm_id) {
    let response = await localStorage.getItem('favorites');

    var allFavorites;

    if(response) {
      allFavorites = JSON.parse(response);
    }

    var firmFavorites;

    if(allFavorites == null || allFavorites == undefined) {      
      firmFavorites = [];
    }
    else {
      firmFavorites = allFavorites.filter(function (favObj) {
        return favObj.firm_id == firm_id;
      })
    }

    return firmFavorites;
  }

  /* Remove one favorite */
  async remove(favorite) {
    let favorites = await JSON.parse(localStorage.getItem('favorites'));

    if(favorites == null || favorites == undefined) {
      favorites = [];
    }

    favorites.forEach(favObj => {
      if(favObj.firm_id === favorite.firm_id && favObj.item_id === favorite.item_id) {
        let index = favorites.indexOf(favObj);
        if (index !== -1) {
          favorites.splice(index, 1);
        }
      }
    });

    await localStorage.setItem('favorites', JSON.stringify(favorites));
  }

  /* Remove all favorites */
  async removeAll(firm_id) {
    let allFavorites = await JSON.parse(localStorage.getItem('favorites'));

    // Backward iteration for deleting all items (foreach doesn't work with splice)
    for (var i = allFavorites.length - 1; i >= 0; i--) {
      if(allFavorites[i].firm_id == firm_id) {
        let index = allFavorites.indexOf(allFavorites[i]);
        // remove item
        if(index > -1) {
          allFavorites.splice(index, 1);
        }
      }
    }

    await localStorage.setItem('favorites', JSON.stringify(allFavorites));
  }

}
