<head>
    <title>{{ firm?.name }} | Terms of Use</title>
</head>

<!-- Header -->
<app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

<!-- Content -->
<div class="main"
    style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
    [style.color]="firm?.theme.main_gradient_contrast_color">
    <div class="pagecontent">
        <div class="view-homepage">
            <div class="centered">

                <!-- Terms of use -->
                <div class="text">
                    <h1>TERMS OF USE for Customer</h1>

                    <div class="bodytext">

                        The Terms of Use for Customers are applicable to the relations between EASYORDER BVBA and
                        the Customers. For the Terms of Use for Online Shop we refer to the General term and
                        conditions.
                        <br /><br />

                        <b>Definitions</b><br />
                        <ul>
                            <li>Customer: a natural person or legal entity placing an Order through the Platform
                            </li>
                            <li>Offer: the selection of Products/Services offered by the Online Shop that can be
                                ordered by the Customer through the Platform.</li>
                            <li>Order: an order placed by the Customer with the Online Shop through the Platform.
                            </li>
                            <li>Agreement: an agreement between the Customer and the Online Shop regarding an Order
                                and the delivery or collection of the Order.</li>
                            <li>Platform: the website, apps and tools and other equipment of EASYORDER BVBA and its
                                affiliated companies and business partners on which the Online Shop is made
                                available on.</li>
                            <li>Platform Service: the commercial services and/or activities that are offered to the
                                Customer by Platform, including showing the Offer, facilitation of the conclusion of
                                Agreements and transmission of Orders to the Online shop.</li>
                            <li>Online Shop: a company that offering products and/or services through the Platform
                                with the purpose to sell these products/services to a customer.</li>
                            <li>Online Shop information: the information about the Online shop like, company and
                                contact information, general information, product range, prices for each individual
                                product/service (including VAT), company logo, graphics, delivery area, delivery
                                costs and minimum order amounts, general sales conditions.</li>
                        </ul>
                        <br />

                        <b>General</b><br />
                        This Platform is operated by EASYORDER BVBA, BTW BE 0655.723.562, Ikaroslaan 24, 1930
                        Zaventem, Belgium. EASYORDER BVBA, also refers by EASYORDER, offers this Platform including
                        all information, tools and services conditioned upon your acceptance of all the following
                        Terms of Use.
                        EASYORDER BVBA is a member of the KEYWARE Group all companies affiliated with KEYWARE
                        TECHNOLOGIES NV, being the companies in which KEYWARE TECHNOLOGIES NV exercised control in
                        accordance with Article 5 of the Companies Code
                        <br /><br />

                        <b>Applicability</b><br />
                        The present Terms of Use for Customers are only applicable to the Platform Service.
                        EASYORDER BVBA is not responsible for the Offer/Order/Agreement. Towards the execution of an
                        agreement only the general terms and conditions of the Online Shop shall be applicable.

                        <br /><br />
                        <b>Execution of Agreement</b><br />
                        By placing an Order, the Customer directly concludes an Agreement with the Online Shop for
                        delivery and/or collection of the by the Customer selected products/services. The Agreement
                        is effective as from the moment the Customer finalizes the Order by clicking the 'Confirm
                        Order' button during the process of placing an Order through the Platform.
                        After receipt of the Order, the Platform will electronically confirm the Order to the
                        Customer and make available for the Online Shop. The Agreement can only be executed by the
                        Online Shop if the Customer provides correct and complete contact and address information
                        when placing the Order.
                        In general, the Customer shall not be entitled to dissolve the Agreement. Cancellation of
                        the Order with the Online Shop is only possible by the Customer if the Online shop
                        explicitly indicates that Cancellation of the Order by the Customer is possible. The Online
                        Shop is entitled to cancel the Order, the Agreement e.g., if the Offer is no longer
                        available, if the Customer has provided an incorrect or inoperative contact information, or
                        in case of force majeure.
                        Complaints from the Customer about the Offer, the Order or the performance of the Agreement,
                        need to be addressed towards Online Shop. The sole responsibility for to execution of the
                        Agreement lies with the Online Shop.
                        <br /><br />

                        <b>Accuracy, Completeness and Timeliness of Information on this Platform</b><br />
                        EASYORDER is not responsible if information made available on this Platform is not accurate,
                        complete or current. The material on this Platform is provided by the Online Shop.
                        <br /><br />

                        <b>Intellectual Property</b><br />
                        EASYORDER retains all rights (including copyrights, trademarks as well as any other
                        intellectual property right) in relation to all information provided on or via this Platform
                        (including all texts, graphics and logos), excluding the Online Shop Information. You may
                        not copy, download, publish, distribute or reproduce any of the information contained on
                        this Platform in any form without the prior written consent of EASYORDER or the appropriate
                        consent of the owner. However, you may print out and/or download information contained on
                        this web site for your own personal, non-commercial use.
                        <br /><br />

                        <b>Conduct on the Site</b><br />
                        For the execution of an agreement on this platform personal data need to be provided. By
                        registering at and/or when providing personal data, in consideration of your use of the
                        platform, you agree to provide true, accurate, current and complete information about
                        yourself.
                        More information about how EASYORDER handles personal data is detailed in the Privacy
                        statement
                        <br /><br />

                        <b>Linked Sites</b><br />
                        This Platform may provide links or references from or to other external web sites (inclusive
                        the Online shop web site when available).
                        EASYORDER have not necessarily reviewed all the information on those other sites and are not
                        responsible for the content of those or any other sites or any products or services that may
                        be offered through those or any other sites. Different terms and conditions may apply to
                        your use of any linked sites. EASYORDER disclaims any responsibility for the content, use or
                        availability of such other sites and is not responsible for any losses, damages or other
                        liabilities incurred as a result of your use of any linked sites.
                        <br /><br />

                        <b>Disclaimer</b><br />
                        Your use of this Platform is at your sole risk. EASYORDER and the Online Shop will use
                        reasonable efforts to provide accurate information on this Platform. Given the changing
                        nature of laws, rules and regulations, and the inherent hazards of electronic communication,
                        there may be delays, omissions or inaccuracies in information contained in this Platform.
                        EASYORDER and Online Shop expressly disclaims all warranties of any kind, whether express or
                        implied, including but not limited to the implied warranties of merchantability and fitness
                        for a particular purpose of (including but not limited to) any information contained on/or
                        provided via this Platform and/or any service described or promoted on this Platform,
                        including warranties with respect to infringement of any patent, copyright, or other rights
                        of third parties.
                        <br /><br />

                        <b>Limitation of Liability</b><br />
                        EASYORDER and Online Shop shall not be liable for any injury, loss, damage or expense
                        arising out of any access to or use of this Platform or any site linked to it, including,
                        without limitation, any loss of profit, indirect, incidental or consequential loss.
                        <br /><br />

                        <b>Underage</b><br />
                        The sale of alcoholic drinks is prohibited for minors.
                        <br /><br />

                        <b>Changes to These Terms of Use</b><br />
                        EASYORDER may at any time make changes to these terms of use by posting revised terms on the
                        site. It is your responsibility to check periodically for any changes we make to the Terms
                        of Use. Your continued use of the site after any changes to the Terms of Use or other
                        policies means you accept the changes.
                        <br /><br />

                        <b>Jurisdiction and Applicable Law</b><br />
                        By accessing this Platform, you agree that the laws of Belgium shall apply to all matters
                        arising from or relating to the use and contents of this web site or information provided to
                        you via this Platform. You also agree to submit to the exclusive jurisdiction in first
                        instance of the District Court of Brussels, Belgium with respect to such matters.
                        We recognize that it is possible for you to obtain access to this Platform from any
                        jurisdiction in the world, but we have no practical ability to prevent such access. This
                        Platform has been designed to comply with the laws of Belgium. If any material on this
                        Platform, or your use of the Platform, is contrary to the laws of the place where you are
                        when you access it, the Platform is not intended for you, and we ask you not to use the
                        Platform. You are responsible for informing yourself of the laws of your jurisdiction and
                        complying with them.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>
