import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { DialogsService } from '../services/dialogs.service';
import { FavoritesService } from '../services/favorites.service';
import { ProductService } from '../services/products.service';
import { RouteService } from '../services/route.service';
import { SlugifyService } from '../services/slugify.service';

@Component({
  selector: 'app-category-item',
  templateUrl: './category-item.component.html',
  styleUrls: ['./category-item.component.css'],
})
export class CategoryItemComponent implements OnInit {
  @Output() reloadData: EventEmitter<any> = new EventEmitter();

  @Input() parent: any;
  @Input() item: any;
  @Input() firm: any;
  @Input() subcategory: any;
  @Input() own_domain: any;
  @Input() images_enabled: boolean;
  @Input() vat_delivery_method: any;

  /* Only for favorites */
  @Input() favoriteObj: any;
  @Input() favorites: boolean;

  moment = moment;
  firm_name: any;
  cat_name: any;
  cat_id: any;
  language: any;
  subcat_name: any;
  subcat_id: any;

  /* VAT */
  itemDisabled: any;
  VAT_percentage: any;
  VAT: any;
  VAT_total: any;
  pickup_label: any;

  constructor(
    public router: Router,
    public slug: SlugifyService,
    public route: ActivatedRoute,
    public routeService: RouteService,
    public productService: ProductService,
    public dialogsService: DialogsService,
    public favoritesService: FavoritesService
  ) {
    this.firm_name = this.route.snapshot.params.firm_name;
    this.cat_name = this.route.snapshot.params.cat_name;
    this.cat_id = this.route.snapshot.params.cat_id;
    this.language = this.route.snapshot.params.language;
    this.subcat_name = this.route.snapshot.params.subcat_name;
    this.subcat_id = this.route.snapshot.params.subcat_id;
    moment.locale(this.language);
  }

  async ngOnInit() {
    if (this.firm.uses_vat) {
      this.VAT_percentage = await this.productService.getVatPercentage(this.item, this.firm);
      this.calculatePrice();
    }
  }

  async ngOnChanges() {
    if (this.firm.uses_vat) {
      this.VAT_percentage = await this.productService.getVatPercentage(this.item, this.firm);
      this.calculatePrice();
    }
  }

  calculatePrice() {
    if (this.VAT_percentage != undefined) {
      this.itemDisabled = false;

      if (this.item.has_promotional_price == false) {
        this.VAT = (this.item.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.price;
      } else if (this.item.has_promotional_price == true) {
        this.VAT = (this.item.promotion.price / 100) * this.VAT_percentage;
        this.VAT_total = this.VAT + this.item.promotion.price;
      }
    } else {
      this.itemDisabled = true;
    }
  }

  removeFavorite() {
    this.favoritesService.remove(this.favoriteObj).then(async () => {
      this.reloadData.emit(true);
    });
  }

  /* Open the order modal */
  async openModal(item) {
    const product: any = await this.productService.getItemById(
      this.firm.id,
      this.language,
      item.id,
      this.firm.uses_deliverect
    );
    product.vat_percentage = this.VAT_percentage;

    this.dialogsService.openModal(product, this.firm, this.cat_id, this.language);
  }
}
