import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';

@Component({
  selector: 'app-reservation',
  templateUrl: './reservation.component.html',
  styleUrls: ['./reservation.component.css']
})
export class ReservationComponent implements OnInit {

  firm_name: any;
  language: any;
  firm: any;
  own_domain: boolean;

  constructor(public router: Router, public route: ActivatedRoute, public auth: AuthService, public firmService: FirmService) {
    window.scrollTo(0, 0);

    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.firmService.checkWebsite(this.firm, this.language);
    });
  }

}
