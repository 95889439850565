import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UUID } from 'angular2-uuid';
import { config } from '../../config';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';
import { ErrorService } from './error.service';
import { RouteService } from './route.service';
import { StoreService } from './store.service';
import { LoaderService } from './loader.service';
import { DialogsService } from './dialogs.service';
import { CustomerService } from './customer.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class OrderService {
  endpoint_calculate: any = '/order/calculate';
  endpoint_confirm: any = '/order/calculate?confirm=1';
  endpoint_calculate_order: any = '/order/calculate_delivery';
  endpoint_addorder: any = '/order';
  endpoint_orders: any = '/orders/';
  endpoint_pending = '/orders/pending';
  endpoint_past = '/orders/past';
  endpoint_status = '/payment_status';
  endpoint_order_detail = '/order_detail/';
  endpoint_coupon = '/coupon';

  token: any;
  uuid: any;

  constructor(
    private http: HttpClient,
    public auth: AuthService,
    public router: Router,
    public routeService: RouteService,
    public errorService: ErrorService,
    public storeService: StoreService,
    private loader: LoaderService,
    private customerService: CustomerService
  ) {}

  /* Get order (session) */
  async getOrder() {
    return JSON.parse(localStorage.getItem('order'));
  }

  /* Save order (session) */
  async saveOrder(new_order) {
    await localStorage.setItem('order', JSON.stringify(new_order));
  }

  async getFirstAvailableTimestampForOrder(firm, cust_id, order, lang_code, distributor, own_domain): Promise<any> {
    await this.getUuid();

    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions;

        if (this.uuid != undefined && this.uuid.firm_id == firm.id) {
          httpOptions = {
            headers: new HttpHeaders({
              storeId: await this.storeService.getStoreId(),
              passwordToken: await this.checkToken(firm.id),
              demoPasswordToken: await this.getDemoToken(),
              firmId: String(firm.id),
              language: String(lang_code),
              Accept: 'application/json',
              Authorization: 'Bearer ' + (await localStorage.getItem('token')),
              appVersion: config.VERSION,
              apiVersion: config.VERSION,
              deviceOS: 'Web',
              deviceOSVersion: 'Web',
              deviceModel: 'Web',
              customerId: String(cust_id),
              uuid: String(this.uuid),
              customerToken: await JSON.parse(localStorage.getItem('customerToken')),
            }),
          };
        } else {
          httpOptions = {
            headers: new HttpHeaders({
              firmId: String(firm.id),
              language: String(lang_code),
              Accept: 'application/json',
              Authorization: 'Bearer ' + (await localStorage.getItem('token')),
              appVersion: config.VERSION,
              apiVersion: config.VERSION,
              deviceOS: 'Web',
              deviceOSVersion: 'Web',
              deviceModel: 'Web',
              customerId: String(cust_id),
              customerToken: await JSON.parse(localStorage.getItem('customerToken')),
            }),
          };
        }

        const body: any = {
          categories: order.categories,
          delivery_method: order.delivery_method,
          products: order.products,
          timestamp: order.timestamp,
        };

        if (order.firm_pickup_point_id) {
          body.firm_pickup_point_id = order.firm_pickup_point_id;
        }

        this.http.post(environment.API_URL + this.endpoint_calculate, body, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkCalculateErrors(
              err.status,
              await this.parseError(err),
              distributor,
              firm,
              own_domain,
              lang_code
            );

            if (check == 'TOKEN_INVALID') {
              this.getFirstAvailableTimestampForOrder(firm, cust_id, order, lang_code, distributor, own_domain);
            }
          }
        );
      });
    }
  }

  /* Validate time */
  async validateTimestampForOrder(firm, cust_id, order, lang_code, distributor, own_domain): Promise<any> {
    await this.getUuid();

    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm.id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm.id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(cust_id),
            uuid: String(this.uuid),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.post(environment.API_URL + this.endpoint_confirm, order, httpOptions).subscribe(
          (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkCalculateErrors(
              err.status,
              await this.parseError(err),
              distributor,
              firm,
              own_domain,
              lang_code
            );

            if (check == 'TOKEN_INVALID') {
              this.validateTimestampForOrder(firm, cust_id, order, lang_code, distributor, own_domain);
            }
          }
        );
      });
    }
  }

  /* Calculate delivery cost of order */
  async calculateOrder(firm, cust_id, order, lang_code, distributor, own_domain) {
    await this.getUuid();

    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions;

        if (this.uuid != undefined && this.uuid.firm_id == firm.id) {
          httpOptions = {
            headers: new HttpHeaders({
              storeId: await this.storeService.getStoreId(),
              passwordToken: await this.checkToken(firm.id),
              demoPasswordToken: await this.getDemoToken(),
              firmId: String(firm.id),
              language: String(lang_code),
              Accept: 'application/json',
              Authorization: 'Bearer ' + (await localStorage.getItem('token')),
              appVersion: config.VERSION,
              apiVersion: config.VERSION,
              deviceOS: 'Web',
              deviceOSVersion: 'Web',
              deviceModel: 'Web',
              customerId: String(cust_id),
              uuid: String(this.uuid),
              customerToken: await JSON.parse(localStorage.getItem('customerToken')),
            }),
          };
        } else {
          httpOptions = {
            headers: new HttpHeaders({
              firmId: String(firm.id),
              language: String(lang_code),
              Accept: 'application/json',
              Authorization: 'Bearer ' + (await localStorage.getItem('token')),
              appVersion: config.VERSION,
              apiVersion: config.VERSION,
              deviceOS: 'Web',
              deviceOSVersion: 'Web',
              deviceModel: 'Web',
              customerId: String(cust_id),
              customerToken: await JSON.parse(localStorage.getItem('customerToken')),
            }),
          };
        }

        if ((this.uuid != undefined || this.uuid != null) && this.uuid.firm_id == firm.id) {
          httpOptions.headers.append('uuid', String(this.uuid));
        }

        this.http.post(environment.API_URL + this.endpoint_calculate_order, order, httpOptions).subscribe(
          async (data: any) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkCalculateErrors(
              err.status,
              await this.parseError(err),
              distributor,
              firm,
              own_domain,
              lang_code
            );

            if (check == 'TOKEN_INVALID') {
              this.calculateOrder(firm, cust_id, order, lang_code, distributor, own_domain);
            } else if (
              check === 'DELIVERY_DISTANCE_TOO_FAR' ||
              check === 'ERROR_CALCULATING_DELIVERY_DISTANCE' ||
              check === 'DELIVERY_ZIPCODE_INVALID_FOR_FIRM' ||
              check === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY'
            ) {
              resolve(err);
            }
          }
        );
      });
    }
  }

  /* Add order to server */
  async add(firm, order, customer_id, lang_code, own_domain, isFree?) {
    await this.getUuid();

    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let store_id;
        await config.STORE_IDS.forEach((id) => {
          if (id.domain == location.hostname) {
            store_id = id.store_id;
          }
        });

        const httpOptions = {
          headers: new HttpHeaders({
            passwordToken: await this.checkToken(firm.id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm.id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            uuid: String(this.uuid),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        if (store_id) {
          httpOptions.headers.append('storeId', store_id);
        }

        const orderStr = JSON.stringify(order);
        const body = JSON.parse(orderStr);

        if (body.products.length == 0) {
          body.products = undefined;
        }

        body.coupon = undefined;

        if (isFree) {
          if (isFree == true) {
            body.payment_method_id = 1;
          }
        }

        this.http.post(environment.API_URL + this.endpoint_addorder, body, httpOptions).subscribe(
          (data) => {
            resolve(data);
          },
          async (err) => {
            const error = await this.parseError(err);

            if (error.errors) {
              if (error.errors.error == 'SHOP_OFFLINE') {
                err.error.type = 'shop_offline';
                resolve(error);
              } else {
                const check = await this.errorService.checkOrderErrors(err.status, error, firm, own_domain, lang_code);

                if (check == 'TOKEN_INVALID') {
                  await this.add(firm, order, customer_id, lang_code, own_domain, isFree);
                } else if (check == 'BASKET_CHANGE') {
                  err.error.type = 'basket_change';
                  resolve(err.error);
                } else if (error === 'INVALID_UUID') {
                  await this.setUuid();
                  await this.add(firm, order, customer_id, lang_code, own_domain, isFree);
                }
              }
            } else {
              const check = await this.errorService.checkOrderErrors(err.status, error, firm, own_domain, lang_code);

              if (check == 'TOKEN_INVALID') {
                this.add(firm, order, customer_id, lang_code, own_domain, isFree);
              } else if (check == 'BASKET_CHANGE') {
                err.error.type = 'basket_change';
                resolve(err.error);
              }
            }
          }
        );
      });
    }
  }

  /* Get order from server */
  async get(firm_id, order_id, customer_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm_id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.get(environment.API_URL + this.endpoint_orders + order_id, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            resolve('ERROR');
          }
        );
      });
    }
  }

  /* Get order from server */
  async getStatus(firm_id, order_id, customer_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm_id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http
          .get(environment.API_URL + this.endpoint_orders + order_id + this.endpoint_status, httpOptions)
          .subscribe(
            (data: any) => {
              resolve(data);
            },
            async (err) => {
              resolve('ERROR');
            }
          );
      });
    }
  }

  /* Delete order from server */
  async delete(firm_id, order_id, customer_id, lang_code) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm_id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.delete(environment.API_URL + this.endpoint_orders + order_id, httpOptions).subscribe(
          (data) => {
            resolve(data);
          },
          async (err) => {
            const check = await this.errorService.checkDeleteErrors(err.status, await this.parseError(err));

            if (check == 'TOKEN_INVALID') {
              this.delete(firm_id, order_id, customer_id, lang_code);
            }
          }
        );
      });
    }
  }

  /* Delete order from server */
  async getPendingOrders(firm, customer_id, lang_code, own_domain) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm.id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm.id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.get(environment.API_URL + this.endpoint_pending, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkOrderErrors(
              err.status,
              await this.parseError(err),
              firm,
              own_domain,
              lang_code
            );

            if (check == 'TOKEN_INVALID') {
              this.getPendingOrders(firm, customer_id, lang_code, own_domain);
            }
          }
        );
      });
    }
  }

  /* Delete order from server */
  async getPastOrders(firm, customer_id, lang_code, own_domain) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm.id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm.id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.get(environment.API_URL + this.endpoint_past, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            const check = await this.errorService.checkOrderErrors(
              err.status,
              await this.parseError(err),
              firm,
              own_domain,
              lang_code
            );

            if (check == 'TOKEN_INVALID') {
              this.getPastOrders(firm, customer_id, lang_code, own_domain);
            }
          }
        );
      });
    }
  }

  /* Get order by reference */
  async getOrderByReference(reference) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
          }),
        };

        this.http.get(environment.API_URL + this.endpoint_order_detail + reference, httpOptions).subscribe(
          (data: any) => {
            resolve(data.data);
          },
          async (err) => {
            resolve('ERROR');
          }
        );
      });
    }
  }

  /* check coupon */
  async checkCoupon(firm_id, body, customer_id, lang_code) {
    await this.getUuid();

    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {
        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        const httpOptions = {
          headers: new HttpHeaders({
            storeId: await this.storeService.getStoreId(),
            passwordToken: await this.checkToken(firm_id),
            demoPasswordToken: await this.getDemoToken(),
            firmId: String(firm_id),
            language: String(lang_code),
            Accept: 'application/json',
            Authorization: 'Bearer ' + (await localStorage.getItem('token')),
            appVersion: config.VERSION,
            apiVersion: config.VERSION,
            deviceOS: 'Web',
            deviceOSVersion: 'Web',
            deviceModel: 'Web',
            customerId: String(customer_id),
            uuid: String(this.uuid),
            customerToken: await JSON.parse(localStorage.getItem('customerToken')),
          }),
        };

        this.http.post(environment.API_URL + this.endpoint_coupon, body, httpOptions).subscribe(
          (data) => {
            resolve(data);
          },
          async (err) => {
            const error = await this.parseError(err);

            const check = await this.errorService.checkCouponErrors(err.status, error);

            if (check == 'TOKEN_INVALID') {
              this.checkCoupon(firm_id, body, customer_id, lang_code);
            } else if (check == 'ORDER_PRICE_TOO_LOW_FOR_COUPON') {
              resolve(error);
            }
          }
        );
      });
    }
  }

  /* Functions */
  async getUuid() {
    const uuid_str = await localStorage.getItem('uuid');
    if (uuid_str != null) {
      this.uuid = JSON.parse(uuid_str);
    }
  }

  async setUuid() {
    await localStorage.setItem('uuid', JSON.stringify(UUID.UUID()));
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    } catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    const tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    let passwordToken = [];

    if (tokens != null) {
      tokens.forEach((token) => {
        if (token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    let demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if (demoToken == null) {
      demoToken = '';
    }

    return demoToken;
  }

  async navigateToCheckout(method, firm, own_domain, language) {
    let user;

    if (method === 'guest') {
      user = await this.customerService.loginAsGuest(firm, language);
      this.saveToOrder(method, user, firm);
    } else if (method === 'user') {
      this.loader.present();
      const login = await JSON.parse(localStorage.getItem('login'));
      if (login) {
        await this.customerService.linkFirm(firm, login.id, language, firm.distributor, own_domain);
        user = await this.customerService.get(firm, login.id, language, firm.distributor, own_domain);
        this.saveToOrder(method, user, firm);
      }
      this.loader.dismiss();
    }

    // Check user info
    if (!user.first_name || !user.last_name || !user.phone || !user.email) {
      this.routeService.navigateToExtraInfo(method, own_domain, firm, language);
    } else {
      this.routeService.navigateToDeliveryMethod(own_domain, firm, language);
    }
  }

  async saveToOrder(method, user, firm) {
    const order: any = {
      discount_percentage: 0,
      user: {
        id: user.id,
      },
    };

    if (method === 'user') {
      order.isGuest = false;
      order.user.first_name = user.first_name;
      order.user.last_name = user.last_name;
      order.user.phone = user.phone;
      order.user.company = user.company;

      if (firm.use_loyalty) {
        if (user.loyalty) {
          if (user.loyalty.add_loyalty_to_order == true) {
            order.add_loyalty_to_order = true;
          } else {
            order.add_loyalty_to_order = false;
          }
        } else {
          order.add_loyalty_to_order = false;
        }
      } else {
        order.add_loyalty_to_order = false;
      }
    } else if (method === 'guest') {
      order.isGuest = true;
      order.add_loyalty_to_order = false;
    }
    await this.saveOrder(order);
  }
}
