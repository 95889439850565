import { Component, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { BasketItemsService } from '../services/basket-items.service';

@Component({
  selector: 'app-ordermodal',
  templateUrl: './ordermodal.component.html',
  styleUrls: ['./ordermodal.component.css'],
})
export class OrderModalComponent {
  basketItem: any;
  init_product: any;
  product: any;
  firm: any;
  cat_id: any;
  language: any;
  ingredients: any = [];
  options_selected: any = [];
  options_price: any = 0;
  amount: any = 1;
  groups_by_sequence: any = [];
  cost: any;
  message: any;
  orderBtnDisabled = false;
  basketAmount: number;
  amountAlreadyInCart: number;

  constructor(
    public dialogRef: MatDialogRef<OrderModalComponent>,
    public basketItemsService: BasketItemsService,
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    // copy init_product
    this.init_product = JSON.stringify(this.product);

    this.setInitBasketItem();
    this.getGroupsBySequence();

    const basketItems = await this.basketItemsService.get(this.firm.id);
    this.basketAmount = this.basketItemsService.getTotalBasketAmount(this.firm.id, basketItems);
    this.amountAlreadyInCart = this.basketItemsService.getProductAmountFromBasket(this.basketItem.item.id, basketItems);
  }

  async setInitBasketItem() {
    if (this.product.ingredient_groups.length > 0) {
      this.product.ingredient_groups.forEach((ingr_gr) => {
        ingr_gr.ingredients.forEach((ingr) => {
          if (ingr.type == 'main') {
            this.ingredients.push(ingr);
          }
        });
      });
    }

    const amount = await this.basketItemsService.setAmount(this.amount, this.product.unit.name_singular);

    this.basketItem = {
      id: this.product.id,
      cat_id: this.product.category.category_id,
      item: this.product,
      init_item: this.init_product,
      name: this.product.name,
      amount: amount,
      total_price: this.cost,
      ingredients: this.ingredients,
      comment: this.message,
      unit_id: this.product.unit.id,
      product_options: this.options_selected,
    } as any;

    if (this.firm.uses_vat == true) {
      this.basketItem.vat_percentage = this.product.VAT_percentage;
    }

    if (this.product.has_promotional_price == true) {
      this.basketItem.price = this.product.promotion.price;
    } else {
      this.basketItem.price = this.product.price;
    }
  }

  /* Add item(s) to the basket */
  async addToBasket() {
    await this.basketItemsService.add(this.basketItem, this.firm.id, this.firm.website_details.sitename, this.firm);
  }

  /* Outputs */
  itemChanged(event) {
    this.basketItem = event.basketItem;
  }

  async getGroupsBySequence() {
    if (this.product.ingredient_groups) {
      await this.product.ingredient_groups.forEach((ingr_gr) => {
        this.groups_by_sequence.push({
          id: ingr_gr.id,
          sequence: ingr_gr.sequence,
          type: 'ingredient_group',
          min_selection: ingr_gr.min_selection,
          max_selection: ingr_gr.max_selection,
        });
      });
    }

    if (this.product.option_groups) {
      await this.product.option_groups.forEach((opt_gr) => {
        this.groups_by_sequence.push({
          id: opt_gr.id,
          sequence: opt_gr.sequence,
          type: 'option_group',
        });
      });
    }
    this.groups_by_sequence = this.groups_by_sequence.sort((a, b) => a.sequence - b.sequence);
  }

  disableOrderBtn(event) {
    this.orderBtnDisabled = event;
    this.cdr.detectChanges();
  }
}
