import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { interval as observableInterval } from 'rxjs';
import { CustomerService } from '../services/customer.service';
import { LoaderService } from '../services/loader.service';
import { RouteService } from '../services/route.service';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.css'],
})
export class SigninComponent {
  @Input() firm;
  @Input() language;
  @Input() own_domain;

  @Output() userOutput: EventEmitter<any> = new EventEmitter();

  email: any;
  password: any;

  constructor(
    private loader: LoaderService,
    private customerService: CustomerService,
    public routeService: RouteService
  ) {}

  async login() {
    const user = await this.customerService.loginWithPassword(
      this.email,
      this.password,
      this.firm,
      this.language,
      this.own_domain
    );
    this.userOutput.emit(user);
  }

  async handleSignin(customerId) {
    await this.customerService.linkFirm(this.firm, customerId, this.language, this.firm.distributor, this.own_domain);
    const user: any = await this.customerService.get(
      this.firm,
      customerId,
      this.language,
      this.firm.distributor,
      this.own_domain
    );
    await this.customerService.saveLogin(user);
    this.userOutput.emit(user);
  }
}
