import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';

import { BasketItemsService } from '../services/basket-items.service';

@Component({
  selector: 'app-edit-ordermodal',
  templateUrl: './edit-ordermodal.component.html',
  styleUrls: ['./edit-ordermodal.component.css'],
})
export class EditOrderModalComponent implements OnInit {
  basketItem: any;
  init_product: any;
  product: any;

  total_price: any;

  firm: any;
  maincolor: any;
  fontcolor: any;
  cat_id: any;
  index: any;
  amount: any;
  cost: any;
  message: any;
  language: any;

  ingredients: any = [];
  groups_by_sequence: any = [];

  options_selected: any = [];
  options_price: any = 0;

  basketAmount: number;
  amountAlreadyInCart: number;

  constructor(
    public dialogRef: MatDialogRef<EditOrderModalComponent>,
    public basketItemsService: BasketItemsService,
    public route: ActivatedRoute,
    private cdr: ChangeDetectorRef
  ) {}

  async ngOnInit() {
    // Get product
    this.product = JSON.parse(this.product);

    // set init_product
    this.init_product = this.product.item.init_item;

    this.setInitBasketItem();
    this.getGroupsBySequence();

    const basketItems = await this.basketItemsService.get(this.firm.id);
    this.basketAmount = this.basketItemsService.getTotalBasketAmount(this.firm.id, basketItems);
    this.basketAmount -= this.basketItem.amount;
    this.amountAlreadyInCart = this.basketItemsService.getProductAmountFromBasket(this.basketItem.item.id, basketItems);
  }

  async setInitBasketItem() {
    this.ingredients = await this.basketItemsService.setIngredientTypes(this.ingredients);

    this.basketItem = {
      id: this.product.item.id,
      cat_id: this.product.item.item.category.category_id,
      item: this.product.item.item,
      init_item: this.init_product,
      name: this.product.item.name,
      amount: this.product.item.amount,
      price: this.product.item.price,
      total_price: this.product.item.total_price,
      ingredients: this.product.item.ingredients,
      comment: this.product.item.comment,
      unit_id: this.product.item.unit_id,
      product_options: this.product.item.product_options,
      options_selected: this.product.item.options_selected,
    } as any;

    if (this.firm.uses_vat == true) {
      this.basketItem.vat_percentage = this.product.item.vat_percentage;
    }

    if (this.product.has_promotional_price == true) {
      this.basketItem.price = this.product.item.promotion.price;
    } else {
      this.basketItem.price = this.product.item.price;
    }

    // Update basket
    this.product.item = this.basketItem;
  }

  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }

  /* Add item(s) to the basket */
  async addToBasket() {
    // Get basket items
    const basketItems = await this.basketItemsService.get(this.firm.id);

    // Remove item from list
    if (this.index > -1) {
      basketItems.splice(this.index, 1);
    }

    // Add object to list
    basketItems.push({
      firm_id: this.firm.id,
      firm_name: this.firm.website_details.sitename,
      item: this.basketItem,
    });

    // Save new list to storage
    await this.basketItemsService.remove(basketItems, this.firm.id);
  }

  /* Outputs */
  itemChanged(event) {
    this.basketItem = event.basketItem;
  }

  async getGroupsBySequence() {
    if (this.product.item.item.ingredient_groups) {
      await this.product.item.item.ingredient_groups.forEach((ingr_gr) => {
        this.groups_by_sequence.push({
          id: ingr_gr.id,
          sequence: ingr_gr.sequence,
          type: 'ingredient_group',
          min_selection: ingr_gr.min_selection,
          max_selection: ingr_gr.max_selection,
        });
      });
    }

    if (this.product.item.item.option_groups) {
      await this.product.item.item.option_groups.forEach((opt_gr) => {
        this.groups_by_sequence.push({
          id: opt_gr.id,
          sequence: opt_gr.sequence,
          type: 'option_group',
        });
      });
    }
    this.groups_by_sequence = this.groups_by_sequence.sort((a, b) => a.sequence - b.sequence);
  }
}
