<head>
    <title>{{ distributor?.name }} Webstore | {{ 'FIRMS.CUSTOMERS' | translate }}</title>
</head>
<body>
    <div class="main">

        <!-- Header (minimal) -->
        <div class="index_header width-full" [style.background-color]="distributor?.main_color" [style.background-image]="'url('+ distributor?.header_logo +')'"></div>

        <agm-map *ngIf="enableMap" class="map width-full background-grey" [latitude]="lat" [longitude]="long" [zoom]="13" [disableDefaultUI]="true">
            <agm-marker [latitude]="lat" [longitude]="long" [iconUrl]="selfIcon">
                <agm-info-window>
                    {{ 'MY_LOCATION' | translate }}
                </agm-info-window>
            </agm-marker>
            <agm-marker *ngFor="let firm of firms" [latitude]="convertToNumber(firm?.geocode.lat)" [longitude]="convertToNumber(firm?.geocode.long)" [iconUrl]="markerIcon">
                <agm-info-window>
                    <div class="info-window">
                        <p class="title bold font-17">{{ firm?.name }}</p>
                        <p class="address">{{ firm?.address }}, {{ firm?.zipcode }} {{ firm?.locality }}</p>
                        <div class="buttons">
                            <a href="javascript:void(0)" class="btn-fill maps-button" (click)="checkFirm(firm, firm.website_details.sitename)">{{ 'OPEN_STORE' | translate }}</a>
                        </div>
                    </div>
                </agm-info-window>
            </agm-marker>
        </agm-map>

        <a href="javascript:void(0)" class="mapButton float-right" *ngIf="enableMap == true" (click)="enableMap == true ? enableMap = false : enableMap = true">{{ 'HIDE_MAP' | translate }}</a>
        <a href="javascript:void(0)" class="mapButton float-right" *ngIf="enableMap == false" (click)="enableMap == true ? enableMap = false : enableMap = true">{{ 'SHOW_MAP' | translate }}</a>

        <div class="pagecontent">
            <div class="view-seoindex">
                <div class="centered">

                    <ul class="row">

                        <li class="col half" *ngFor="let firm of firms">
                            <a tappable (click)="checkFirm(firm, firm.website_details.sitename)">
                                <span class="header">
								<img *ngIf="firm.logo_fullsize != null" [src]="firm.logo_fullsize" alt=""/>
							</span>

                            <span class="item">
                                <h2>{{ firm.name }}</h2>

                                <span class="address">
                                        {{ firm.address }}<br/>
                                        {{ firm.zipcode }} {{ firm.locality }}<br/>
                                        {{ firm.phone }}<br/>
                                </span>

                                <span class="link">{{ 'FIRMS.VISIT_SITE' | translate }}</span>
                            </span>
                            </a>
                        </li>


                    </ul>
                </div>
            </div>

        </div>

        <!-- Footer -->
        <app-footer *ngIf="!firmService.checkEmbed()" [distributor]="distributor"></app-footer>
    </div>

</body>