import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';
import { BasketItemsService } from './basket-items.service';
import { DialogsService } from './dialogs.service';
import { LoaderService } from './loader.service';

@Injectable()
export class ErrorService {
  constructor(
    public location: Location,
    private translate: TranslateService,
    private loader: LoaderService,
    private auth: AuthService,
    private router: Router,
    private basketItemsService: BasketItemsService,
    private dialogsService: DialogsService
  ) {}

  checkConnection() {
    if (navigator) {
      if (navigator.onLine) {
        return true;
      } else {
        this.dialogsService.openError(
          this.translate.instant('DIALOGS.INTERNET_TITLE'),
          this.translate.instant('DIALOGS.INTERNET_MESSAGE'),
          false
        );

        // dismiss loader
        this.loader.dismiss();

        return false;
      }
    }
  }

  /* Service checks */
  async checkCountriesErrors(status, err) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkEmailErrors(status, err) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'CUSTOMER_NOT_ACTIVATED' || err.code === 'SESSION_TOKEN_INVALID') {
      return err.code;
    } else if (err.code === 'CUSTOMER_NOT_FOUND') {
      localStorage.setItem('login', null);
      return err.code;
    } else if (err.code === 'CUSTOMER_BLOCKED_FOR_FIRM') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.BLOCKED_TITLE'),
        this.translate.instant('DIALOGS.BLOCKED_MESSAGE'),
        true
      );
    } else if (err.code === 'CUSTOMER_INVALID_FOR_FIRM') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.CUST_INVALID_TITLE'),
        this.translate.instant('DIALOGS.CUST_INVALID_MESSAGE'),
        false
      );
    } else if (err.code === 'VALIDATION_ERROR') {
      let errors = '\n';

      for (const key in err.errors) {
        const value = err.errors[key];
        errors = errors + value + '\n';
      }

      this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errors, false);
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkCustomerErrors(status, err, distributor, firm, own_domain, language) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'CUSTOMER_NOT_ACTIVATED') {
      return err.code;
    } else if (err.code === 'CUSTOMER_NOT_FOUND') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.CUST_NOT_FOUND_TITLE'),
        this.translate.instant('DIALOGS.CUST_NOT_FOUND_MESSAGE'),
        false
      );

      // Remove login
      localStorage.setItem('login', null);

      // redirect to basket page
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      const language = this.router.routerState.snapshot.root.children[0].params.language;

      const isTest = location.hostname === distributor.webshop_domain_test;

      if (location.hostname === distributor.webshop_domain || isTest) {
        this.router.navigate([firm_name, language, 'basket']);
      } else {
        if (distributor) {
          if (distributor.multifirm) {
            this.router.navigate([firm_name, language, 'basket']);
          } else {
            this.router.navigate([language, 'basket']);
          }
        } else {
          this.router.navigate([language, 'basket']);
        }
      }
    } else if (err.code === 'CUSTOMER_BLOCKED_FOR_FIRM') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.BLOCKED_TITLE'),
        this.translate.instant('DIALOGS.BLOCKED_MESSAGE'),
        true
      );
    } else if (err.code === 'CUSTOMER_INVALID_FOR_FIRM') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.CUST_INVALID_TITLE'),
        this.translate.instant('DIALOGS.CUST_INVALID_MESSAGE'),
        false
      );
    } else if (err.code === 'VALIDATION_ERROR') {
      let errors = '\n';

      for (const key in err.errors) {
        const value = err.errors[key];
        errors = errors + value + '\n';
      }

      this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errors, false);
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.logoutUser(firm, own_domain, language);
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkFirmsErrors(status) {
    this.loader.dismiss();

    if (status === 401) {
      return 'TOKEN_INVALID';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkFirmErrors(status, err) {
    if (status === 401) {
      return 'TOKEN_INVALID';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP') {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
          this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE'),
          true
        )
        .subscribe(() => {
          this.redirect404();
        });
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (err.code === 'RESERVATION_NOT_POSSIBLE' || err.code === 'VALIDATION_ERROR') {
      return 'RESERVATION_ERROR';
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkHostErrors(status, err) {
    if (status === 401) {
      return 'TOKEN_INVALID';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'FIRM_NOT_FOUND') {
      return 'NO_CUSTOM_DOMAIN';
    } else if (err.code === 'FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP') {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
          this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE'),
          true
        )
        .subscribe(() => {
          this.redirect404();
        });
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkPasswordErrors(status, err) {
    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (status === 403) {
      return '403';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkCalculateErrors(status, err, distributor, firm, own_domain, language) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (
      err.code === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY' ||
      err.code === 'DELIVERY_ZIPCODE_INVALID_FOR_FIRM' ||
      err.code === 'DELIVERY_DISTANCE_TOO_FAR'
    ) {
      return err.code;
    } else if (err.code === 'ERROR_CALCULATING_DELIVERY_DISTANCE') {
      return err.code;
    } else if (err.code === 'FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
        this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE_ORDER'),
        false
      );
    } else if (err.code === 'BASKET_HAS_INVALID_PRODUCTS') {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.INVALID_PRODUCT_TITLE'),
          this.translate.instant('DIALOGS.INVALID_PRODUCT_MESSAGE'),
          true
        )
        .subscribe(async () => {
          // redirect to basket page
          const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
          const language = this.router.routerState.snapshot.root.children[0].params.language;

          // remove corrupt order & basket from storage
          await sessionStorage.setItem('order', null);
          await localStorage.setItem('order', null);
          await this.basketItemsService.removeAllByFirmname(firm_name);

          const isTest = location.hostname === distributor.webshop_domain_test;

          if (location.hostname === distributor.webshop_domain || isTest) {
            this.router.navigate([firm_name, language, 'basket']);
          } else {
            if (distributor) {
              if (distributor.multifirm) {
                this.router.navigate([firm_name, language, 'basket']);
              } else {
                this.router.navigate([language, 'basket']);
              }
            } else {
              this.router.navigate([language, 'basket']);
            }
          }
        });
    } else if (err.code === 'VALIDATION_ERROR') {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.VALIDATION_TITLE'),
          this.translate.instant('DIALOGS.VALIDATION_MESSAGE'),
          true
        )
        .subscribe(async () => {
          // remove corrupt order from sessionStorage
          await sessionStorage.setItem('order', null);

          // redirect to basket page
          const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
          const language = this.router.routerState.snapshot.root.children[0].params.language;

          const isTest = location.hostname === distributor.webshop_domain_test;

          if (location.hostname === distributor.webshop_domain || isTest) {
            this.router.navigate([firm_name, language, 'basket']);
          } else {
            if (distributor) {
              if (distributor.multifirm) {
                this.router.navigate([firm_name, language, 'basket']);
              } else {
                this.router.navigate([language, 'basket']);
              }
            } else {
              this.router.navigate([language, 'basket']);
            }
          }
        });
    } else if (err.code === 'FIRM_NO_PICKUP') {
      this.dialogsService.openError(null, this.translate.instant('DIALOGS.PICKUP_MESSAGE'), false);
    } else if (err.code === 'FIRM_NO_DELIVERY') {
      this.dialogsService.openError(null, this.translate.instant('DIALOGS.DELIVERY_MESSAGE'), false);
    } else if (err.code === 'FIRM_NO_TABLE') {
      this.dialogsService.openError(null, this.translate.instant('DIALOGS.TABLE_MESSAGE'), false);
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.logoutUser(firm, own_domain, language);
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkOrderErrors(status, err, firm, own_domain, language) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'INVALID_UUID') {
      return 'INVALID_UUID';
    } else if (err.code === 'INVALID_COUPON_CODE') {
      this.dialogsService.openError(null, this.translate.instant('INVALID_COUPON'), false);
    } else if (err.code === 'ORDER_PRICE_TOO_LOW_FOR_COUPON' || err.code === 'TABLE_NR_REQUIRED') {
      this.dialogsService.openError(null, err.message, false);
    } else if (err.code === 'COUPON_CODE_USED') {
      this.dialogsService.openError(null, this.translate.instant('COUPON_CODE_ALREADY_USED'), false);
    } else if (err.code === 'ORDER_PRICE_TOO_LOW_FOR_DELIVERY') {
      this.dialogsService.openError(
        null,
        this.translate.instant('DIALOGS.MIN_DELIVERY_PRICE', { min_order_price: firm.min_price_for_delivery }),
        false
      );
    } else if (err.code === 'CUSTOMER_NOT_ACTIVATED') {
      this.dialogsService.openError(null, this.translate.instant('DIALOGS.CUST_NOT_ACTIVATED'), false);
    } else if (err.code === 'DELIVERY_ZIPCODE_INVALID_FOR_FIRM') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.ZIPCODE_TITLE'),
        this.translate.instant('DIALOGS.ZIPCODE_MESSAGE'),
        false
      );
    } else if (err.code === 'INVALID_TIMESTAMP') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.DATETIME_TITLE'),
        this.translate.instant('DIALOGS.DATETIME_MESSAGE'),
        false
      );
    } else if (err.code === 'FIRM_MISSING_SETTINGS_FOR_DELIVERY_OR_PICKUP') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.MISSING_SETTINGS_TITLE'),
        this.translate.instant('DIALOGS.MISSING_SETTINGS_MESSAGE_ORDER'),
        false
      );
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'CUSTOMER_NOT_FOUND') {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.CUST_NOT_FOUND_TITLE'),
          this.translate.instant('DIALOGS.CUST_NOT_FOUND_MESSAGE_ORDER'),
          true
        )
        .subscribe(async () => {
          // clear storage (login & order)
          localStorage.setItem('login', null);
          localStorage.setItem('order', undefined);

          // redirect to basket page
          const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
          const language = this.router.routerState.snapshot.root.children[0].params.language;

          const isTest = location.hostname === firm.distributor.webshop_domain_test;

          if (location.hostname === firm.distributor.webshop_domain || isTest) {
            this.router.navigate([firm_name, language, 'basket']);
          } else {
            if (firm.parent_is_multifirm) {
              this.router.navigate([firm_name, language, 'basket']);
            } else {
              this.router.navigate([language, 'basket']);
            }
          }
        });
    } else if (
      err.code === 'BASKET_HAS_INVALID_PRODUCTS' ||
      err.code === 'BASKET_HAS_INVALID_INGREDIENTS' ||
      err.code === 'BASKET_HAS_INVALID_PRODUCT_OPTIONS' ||
      err.code === 'VALIDATION_ERROR'
    ) {
      this.dialogsService
        .openError(
          this.translate.instant('DIALOGS.ERROR'),
          this.translate.instant('DIALOGS.INVALID_ORDER_MESSAGE'),
          true
        )
        .subscribe(async () => {
          // redirect to basket page
          const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
          const language = this.router.routerState.snapshot.root.children[0].params.language;

          // clear basket & sessionStorage
          sessionStorage.clear();
          this.basketItemsService.removeAllByFirmname(firm_name);

          const isTest = location.hostname === firm.distributor.webshop_domain_test;

          if (location.hostname === firm.distributor.webshop_domain || isTest) {
            this.router.navigate([firm_name, language, 'basket']);
          } else {
            if (firm.parent_is_multifirm) {
              this.router.navigate([firm_name, language, 'basket']);
            } else {
              this.router.navigate([language, 'basket']);
            }
          }
        });
    } else if (err.code === 'ERROR_CALCULATING_DELIVERY_DISTANCE') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.DISTANCE_TITLE'),
        this.translate.instant('DIALOGS.DISTANCE_MESSAGE'),
        false
      );
    } else if (err.code === 'DELIVERY_DISTANCE_TOO_FAR') {
      this.dialogsService.openError(null, this.translate.instant('DIALOGS.DISTANCE_TOO_FAR_MESSAGE'), false);
    } else if (err.code === 'INVALID_PAYMENT_METHOD') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.INVALID_PAYMENT_TITLE'),
        this.translate.instant('DIALOGS.INVALID_PAYMENT_MESSAGE'),
        false
      );
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.logoutUser(firm, own_domain, language);
    } else if (err.code === 'MAX_BASKET_QUANTITY_EXCEEDED') {
      this.dialogsService.openError(this.translate.instant('DIALOGS.INVALID_PAYMENT_TITLE'), err.message, false);
    } else if (err.code === 'COUNTRY_NOT_SUPPORTED') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.ERROR'),
        this.translate.instant('COUNTRY_ERR'),
        false
      );
    } else if (status === 422) {
      return 'BASKET_CHANGE';
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkDeleteErrors(status, err) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (err.code === 'ORDER_NOT_FOUND') {
      // Do nothing
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      // Do nothing
    } else {
      // Do nothing
    }
  }

  async checkProductsErrors(status, err) {
    this.loader.dismiss();

    if (status === 'TEST') {
      return 'NO_PRODUCTS';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'PRODUCT_NOT_FOUND' || err.code === 'CATEGORY_NOT_FOUND' || err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkPaymentErrors(status, err, firm, own_domain, language) {
    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'INVALID_PAYMENT_METHOD') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.INVALID_PAYMENT_TITLE'),
        this.translate.instant('DIALOGS.INVALID_PAYMENT_MESSAGE'),
        false
      );
    } else if (err.code === 'ORDER_NOT_FOUND') {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.ORDER_NOT_FOUND_TITLE'),
        this.translate.instant('DIALOGS.ORDER_NOT_FOUND_MESSAGE'),
        false
      );
    } else if (err.code === 'ORDER_ALREADY_PAID') {
      return err.code;
    } else if (err.code === 'VALIDATION ERROR') {
      let errors = '\n';

      for (const key in err.errors) {
        const value = err.errors[key];
        errors = errors + value + '\n';
      }

      this.dialogsService.openError(this.translate.instant('DIALOGS.VALIDATION_TITLE'), errors, false);
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (err.code === 'CUSTOMER_TOKEN_INVALID') {
      this.logoutUser(firm, own_domain, language);
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkSearchErrors(status, err) {
    this.loader.dismiss();

    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (err.code === 'PASSWORD_TOKEN_INVALID') {
      const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
      this.router.navigate(['/password', firm_name]);
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'FIRM_NOT_FOUND') {
      this.redirect404();
    } else if (err.code === 'DEMO_PASSWORD_TOKEN_INVALID') {
      this.handleDemoPasswordError();
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkCouponErrors(status, err) {
    if (status === 401) {
      await this.auth.getToken();
      return 'TOKEN_INVALID';
    } else if (status === 503) {
      this.showMaintenanceError();
    } else if (err.code === 'ORDER_PRICE_TOO_LOW_FOR_COUPON') {
      return err.code;
    } else if (status === 0) {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.SERVER_TITLE'),
        this.translate.instant('DIALOGS.SERVER_MESSAGE'),
        false
      );
    } else {
      this.dialogsService.openError(
        this.translate.instant('DIALOGS.UNKNOWN_TITLE'),
        this.translate.instant('DIALOGS.UNKNOWN_MESSAGE'),
        false
      );
    }
  }

  async checkErrors(status) {
    let errorTriggered = true;

    switch (status) {
      case 401: {
        await this.auth.getToken();
        break;
      }
      case 503: {
        this.showMaintenanceError();
        break;
      }
      case 500: {
        this.showServerError();
        break;
      }
      default: {
        errorTriggered = false;
        break;
      }
    }

    return errorTriggered;
  }

  /* Show maintenance error */
  showMaintenanceError() {
    this.dialogsService.openError(
      this.translate.instant('DIALOGS.MAINTENANCE_TITLE'),
      this.translate.instant('DIALOGS.MAINTENANCE_MESSAGE'),
      true
    );
  }

  /* Display server error */
  showServerError() {
    this.dialogsService.openError(
      this.translate.instant('DIALOGS.SERVER_TITLE'),
      this.translate.instant('DIALOGS.SERVER_MESSAGE'),
      false
    );
  }

  /* Handle demo password error (afname only) */
  handleDemoPasswordError() {
    const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
    this.router.navigate(['/demo-password', firm_name]);
  }

  /* Handle customer token invalid */
  logoutUser(firm, own_domain, language) {
    localStorage.setItem('uuid', undefined);
    localStorage.removeItem('login');
    localStorage.setItem('customerToken', undefined);

    if (!own_domain) {
      if (firm) {
        this.router.navigate([firm.website_details.sitename, language, 'profile']);
      }
    } else {
      if (firm) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([firm.website_details.sitename, language, 'profile']);
        } else {
          this.router.navigate([language, 'profile']);
        }
      }
    }
  }

  redirect404() {
    const firm_name = this.router.routerState.snapshot.root.children[0].params.firm_name;
    const language = this.router.routerState.snapshot.root.children[0].params.language;

    if (firm_name && language) {
      this.router.navigate([firm_name, language, '404']);
    } else if (firm_name && !language) {
      this.router.navigate([firm_name, '404']);
    } else {
      this.router.navigate(['404']);
    }
  }

  formatErrors(errors) {
    let errorStr = '\n';

    for (const key in errors) {
      const value = errors[key];
      errorStr = errorStr + value + '\n';
    }

    return errorStr;
  }
}
