import { Component } from '@angular/core';

@Component({
  selector: 'eo-out-of-stock-label',
  templateUrl: './out-of-stock-label.component.html',
  styleUrls: ['./out-of-stock-label.component.css'],
})
export class OutOfStockLabelComponent {
  constructor() {}
}
