import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { ErrorService } from './error.service';
import { config } from '../../config';
import { StoreService } from './store.service';
import { AuthService } from './auth.service';
import { ProductService } from './products.service';

@Injectable()
export class SearchService {

  endpoint = "/products/search/?q=";

  token: any;

  constructor(public http: HttpClient, public errorService: ErrorService, public storeService: StoreService, private auth: AuthService, private productService: ProductService) { }

  async get(firm, search_query, lang_code) {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if(!this.token) {
            this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
              { 
                'storeId': await this.storeService.getStoreId(),
                'passwordToken': await this.checkToken(firm.id),
                'demoPasswordToken': await this.getDemoToken(),
                'firmId': String(firm.id),
                'language': String(lang_code),
                'Accept': 'application/json',
                'Authorization': "Bearer " + await localStorage.getItem('token'),
                'appVersion': config.VERSION,
                'apiVersion': config.VERSION,
                'deviceOS': 'Web',
                'deviceOSVersion': 'Web',
                'deviceModel': 'Web'
              }
          )
        }

        this.http.get(environment.API_URL + this.endpoint + search_query, httpOptions)
                  .subscribe(
                    async (data: any) => {
                      resolve(data.data.products);
                    },
                    async err => {
                      let check = await this.errorService.checkSearchErrors(err.status, await this.parseError(err));

                      if(check == 'TOKEN_INVALID') {
                          this.get(firm.id, search_query, lang_code);
                      }
                    });
      })
    }
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    }
    catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    let tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    var passwordToken = [];

    if(tokens != null) {
      tokens.forEach(token => {
        if(token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    var demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if(demoToken == null) {
      demoToken = "";
    }

    return demoToken;
  }

}
