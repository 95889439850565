<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.USER_DETAILS' | translate }}</title>
</head>
<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.main_gradient_contrast_color"
>
<div class="main">

  <!-- Header -->
  <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
  <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

  <div class="centered width-50">

    <!-- Content -->
    <div class="pagecontent">
      <div class="row">

        <h1 class="title-font">{{ 'LOYALTY_CARD' | translate }}</h1>

        <div *ngIf="firm?.loyalty.active; else noLoyaltyActive">
          <!-- Discount -->
          <div class="loyalty" *ngIf="firmLoyalty?.loyalty_type == 'discount'">
            <div *ngIf="customerLoyalty?.add_loyalty_to_order == true">
              <p *ngIf="firmLoyalty?.discount_type == 'amount'"
                 class="winning-txt">{{ 'LOYALTY_CONGRATS' | translate }} {{ 'LOYALTY_NEXT_ORDER_DISCOUNT' | translate }} {{ firmValuta }}{{ firmLoyalty?.discount_value }} {{ 'DISCOUNT' | translate }}
                !</p>
              <p *ngIf="firmLoyalty?.discount_type == 'percentage'"
                 class="winning-txt">{{ 'LOYALTY_CONGRATS' | translate }} {{ 'LOYALTY_NEXT_ORDER_DISCOUNT' | translate }} {{ firmLoyalty?.discount_value | number:'1.0-0' }}
                % {{ 'DISCOUNT' | translate }}!</p>
            </div>
            <div *ngIf="customerLoyalty?.add_loyalty_to_order == false">
              <p *ngIf="firmLoyalty?.discount_type == 'amount'">{{ 'LOYALTY_SAVE_MONEY' | translate:{
                amount: firmLoyalty?.points_required - customerLoyalty?.current_points,
                firm_valuta: firmValuta,
                discount: firmLoyalty?.discount_value
              } }}</p>
              <p *ngIf="firmLoyalty?.discount_type == 'percentage'">{{ 'LOYALTY_SAVE_%' | translate:{
                amount: firmLoyalty?.points_required - customerLoyalty?.current_points,
                discount: firmLoyalty?.discount_value | number:'1.0-0'
              } }}</p>
            </div>
          </div>

          <!-- Free product -->
          <div class="loyalty" *ngIf="firmLoyalty?.loyalty_type == 'product'">
            <p *ngIf="customerLoyalty?.add_loyalty_to_order == true"
               class="winning-txt">{{ 'LOYALTY_CONGRATS' | translate }} {{ 'LOYALTY_NEXT_ORDER_PRODUCT' | translate }}
              : {{ firmLoyalty?.free_product.name }}
              ' {{ 'VALUE_OF' | translate }} {{ firmValuta }}{{ firmLoyalty?.free_product.price }}.</p>
            <p *ngIf="customerLoyalty?.add_loyalty_to_order == false" class="weight-300"
               style="line-height: 20px">{{ 'LOYALTY_SAVE_PRODUCT' | translate:{amount: firmLoyalty?.points_required - customerLoyalty?.current_points} }}
              : <span class="weight-400">'{{ firmLoyalty?.free_product.name }}
                ' {{ 'VALUE_OF' | translate }} {{ firmValuta }}{{ firmLoyalty?.free_product.price }}.</span></p>
          </div>

          <!-- Loyalty card -->
          <div class="loyalty-card align-center">

            <div
              class="point"
              *ngFor="let point of points"
              [style.border-color]="point.filled_point ? firm?.theme.theme_color : firm?.theme.main_gradient_contrast_color"
              [style.background-color]="point.filled_point ? firm?.theme.theme_color: 'transparent' "
            >
              <i class="fas fa-gift"
                 [style.color]="point.filled_point ? firm?.theme.main_gradient_color_from : firm?.theme.main_gradient_contrast_color"
              ></i>
            </div>

          </div>
        </div>
      </div>

      <ng-template #noLoyaltyActive>
        <p>{{ 'LOYALTY_INACTIVE' | translate }}</p>
      </ng-template>
    </div>
  </div>

  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

</div>
</body>
