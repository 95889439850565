import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class LanguageService {
  availableLanguages = [
    {
      id: '1',
      code: 'nl',
      name: 'Nederlands',
    },
    {
      id: '2',
      code: 'fr',
      name: 'Français',
    },
    {
      id: '3',
      code: 'en',
      name: 'English',
    },
    {
      id: '4',
      code: 'es',
      name: 'Español',
    },
    {
      id: '5',
      code: 'de',
      name: 'Deutsch',
    },
    {
      id: '6',
      code: 'pt',
      name: 'Portuguese',
    },
  ];

  constructor(public router: Router, private translate: TranslateService) {}

  getBrowserLang(languages?) {
    if (!languages) {
      languages = this.availableLanguages;
    }

    // Get browser language
    const userLang = navigator.language.substring(0, 2);

    // Check if language is in list of firm languages
    const lang = languages.filter((lang) => lang.code === userLang);

    // If lang is not supported by firm return first language of the list
    if (lang.length == 0) {
      return languages[0].code;
    }
    // If supported return browser language
    else {
      return userLang;
    }
  }

  /* Check if language in url */
  async checkForLanguage(language, firm, own_domain, parameter) {
    if (!language) {
      const lang = await this.getBrowserLang(firm.languages);

      if (own_domain) {
        if (firm.parent_is_multifirm) {
          this.router.navigate([parameter, lang]);
        } else {
          this.router.navigate([lang]);
        }
      } else {
        this.router.navigate([parameter, lang]);
      }
    } else {
      return true;
    }
  }

  setLanguage(language) {
    language = this.getValidatedLanguage(language);
    if (language) {
      this.translate.use(language.code);
    } else {
      this.router.navigate(['404']);
    }
  }

  getValidatedLanguage(language) {
    return this.availableLanguages.find((l) => l.code === language.toLowerCase());
  }
}
