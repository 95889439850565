import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'eo-menu-card',
  templateUrl: './menu-card.component.html',
  styleUrls: ['./menu-card.component.css'],
})
export class MenuCardComponent {
  @Input() title: string;
  @Input() image: string;
  @Input() isGold = false;
  @Input() points: number = null;

  @Output() clicked = new EventEmitter<any>();

  constructor() {}

  clickedHandler() {
    this.clicked.emit();
  }
}
