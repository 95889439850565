import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { CustomerService } from '../services/customer.service';
import { DialogsService } from '../services/dialogs.service';
import { FirmService } from '../services/firm.service';
import { LanguageService } from '../services/language.service';
import { LoaderService } from '../services/loader.service';
import { ProductService } from '../services/products.service';
import { SlugifyService } from '../services/slugify.service';
import { RouteService } from '../services/route.service';
import { DOMService } from '../services/dom.service';
import { TagService } from '../services/tag.service';

declare const gtag;

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css'],
})
export class MenuComponent implements OnInit {
  token: any;
  own_domain: any;
  distributor: any;
  firm: any;
  firms: any;
  parameter: any;
  firm_name: any;
  language: any;
  products: any;
  openingHours: any;
  holidayPeriods: any;
  url: any;
  multifirm_section: boolean;
  menu_section: boolean;
  template: any;
  parent_category: any;
  category_name: any;
  vat_delivery_method: any;
  pickup_label: any;
  openVat = false;
  reservation = false;
  hideEmail = false;

  constructor(
    public firmService: FirmService,
    public router: Router,
    public route: ActivatedRoute,
    public slug: SlugifyService,
    public loader: LoaderService,
    public auth: AuthService,
    public languageService: LanguageService,
    public dialogsService: DialogsService,
    public customerService: CustomerService,
    public routeService: RouteService,
    public productService: ProductService,
    private domService: DOMService,
    private tagService: TagService
  ) {
    window.scrollTo(0, 0);
    this.parameter = this.route.snapshot.params.parameter;
    this.language = this.route.snapshot.params.language;
    this.firm_name = this.route.snapshot.params.firm_name;
  }

  async ngOnInit() {
    const distributor = await this.firmService.getFirmForHost(location.origin, this.language);

    // Get firm with own domain (parameter = language)
    if (distributor !== 'NO_CUSTOM_DOMAIN') {
      this.own_domain = true;

      if (distributor) {
        if (distributor.multifirm == true) {
          if (this.parameter != undefined && this.parameter != '') {
            this.firm_name = this.parameter;
          }
        } else {
          if (this.parameter) {
            this.language = this.parameter;
          }
        }
      }

      const response: any = await this.firmService.getMultiFirmOwnDomain(
        location.origin,
        this.language,
        this.firm_name
      );

      if (response.is_store === true) {
        const firms_response = await this.firmService.getAllFirms(response.store_id);
        this.firms = firms_response.firms;
        this.distributor = firms_response.distributor;
        this.menu_section = false;
        this.multifirm_section = true;
      } else {
        this.menu_section = true;
        this.multifirm_section = false;

        if (distributor) {
          if (distributor.multifirm == true) {
            this.firm = await this.firmService.getFirm(this.firm_name, this.language);
            this.firm.parent_is_multifirm = true;
          } else {
            this.firm = response;
          }
        } else {
          this.firm = response;
        }

        if (this.firm.tracking) this.tagService.setMarketingTags(this.firm.tracking);

        this.initData();
      }
    }
    // Get firm without own domain (parameter = firm_name)
    else {
      if (this.parameter) {
        this.firm_name = this.parameter;
      }
      const response: any = await this.firmService.getFirm(this.firm_name, this.language);

      // Check if chosen firm is a multifirm or not
      if (response.is_store == true) {
        const lang = await this.languageService.getBrowserLang();
        this.languageService.setLanguage(lang);

        const firms_response = await this.firmService.getAllFirms(response.store_id);
        this.firms = firms_response.firms;
        this.distributor = firms_response.distributor;

        // set header
        const sidebar_text = document.createElement('style');
        sidebar_text.type = 'text/css';
        sidebar_text.innerHTML = '.multifirm_header { background: ' + this.distributor.main_color + ' }';
        document.getElementsByTagName('head')[0].appendChild(sidebar_text);

        this.multifirm_section = true;
      } else {
        this.menu_section = true;

        this.firm = response;

        this.initData();
      }
    }
  }

  /* Load products */
  async initData() {
    if (
      (await this.languageService.checkForLanguage(this.language, this.firm, this.own_domain, this.parameter)) &&
      (await this.firmService.checkWebsite(this.firm, this.language)) &&
      (await this.firmService.checkForPassword(this.firm))
    ) {
      // Get products
      this.loader.present();

      this.domService.updateFavicon(this.firm.logo_square);

      gtag('set', 'dimension1', this.firm.id);

      if (
        (this.firm.website_details.template.reference == 'list' ||
          this.firm.website_details.template.reference == 'list-full-width') &&
        this.firm.product_images_enabled == true
      ) {
        this.setListWithImageStyles();
      }

      this.products = await this.productService.getCategories(this.firm, this.language, this.firm.uses_deliverect);

      if (this.firm.uses_deliverect && this.products && this.products.length > 0) {
        const original_category_list = this.products;

        this.route.queryParams.subscribe((params: any) => {
          if (params['cat_index']) {
            this.parent_category = original_category_list[params['cat_index']];
          } else {
            this.parent_category = original_category_list[0];
          }
          this.products = this.parent_category.subcategories;
        });
      }

      this.customerService.getCustomer(this.firm, this.language, this.own_domain);

      this.loader.dismiss();
    }
  }

  setCategoryName(cat_name) {
    this.category_name = cat_name;
  }

  setListWithImageStyles() {
    const order_btn = document.createElement('style');
    order_btn.type = 'text/css';
    order_btn.innerHTML = '.order-btn { transform: translateY(55%) !important }';
    document.getElementsByTagName('head')[0].appendChild(order_btn);

    const product_info = document.createElement('style');
    product_info.type = 'text/css';
    product_info.innerHTML = '.product .product-info { margin-left: 10px; }';
    document.getElementsByTagName('head')[0].appendChild(product_info);
  }

  /* Outputs */
  vatDeliveryMethodChanged(event) {
    this.vat_delivery_method = event;
  }

  menuCardClicked(cat) {
    this.routeService.navigateToCategory(this.own_domain, this.firm, this.language, cat.name, cat.category_id);
  }
}
