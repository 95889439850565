<head>
  <title>{{ firm?.name }}</title>
</head>
<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.container_contrast_color"
>

  <div class="main">

      <!-- Header -->
      <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
      <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

      <div class="pagecontent">

          <div class="view-productcategory">
              <div class="centered">

                <!-- Vat delivery method -->
                <app-vat-delivery-method-opener *ngIf="firm" [firm]="firm" (vatChanged)="vatDeliveryMethodChanged($event)"></app-vat-delivery-method-opener>

                  <h1 class="title-font">{{ 'HEADER.FAVORITES' | translate }}</h1>

                  <div *ngIf="favorites?.length == 0 || !favorites">
                      <p>{{ 'MESSAGES.NO_FAVORITES' | translate }}</p>
                  </div>

                  <!-- Products -->
                  <ul class="products " *ngIf="favorites?.length > 0 && firm?.product_images_enabled">
                      <li class="" *ngFor="let prod of favorites">
                        <app-category-item (reloadData)="reloadData($event)" [favoriteObj]="prod" [favorites]="true" [images_enabled]="true" [vat_delivery_method]="vat_delivery_method" [item]="prod.item" [firm]="firm" [own_domain]="own_domain" [parent]="'favorites'"></app-category-item>
                      </li>
                  </ul>

                  <!-- Products without image -->
                  <ul class="products row no-picture " *ngIf="favorites?.length > 0 && !firm?.product_images_enabled">
                      <li class="col third col-sm no-picture" *ngFor="let prod of favorites">
                        <app-category-item (reloadData)="reloadData($event)" [favoriteObj]="prod" [favorites]="true" [images_enabled]="false" [vat_delivery_method]="vat_delivery_method" [item]="prod.item" [firm]="firm" [own_domain]="own_domain" [parent]="'favorites'"></app-category-item>
                      </li>
                  </ul>

              </div>
          </div>

      </div>

      <!-- Footer -->
      <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>
  </div>

  <div id="iosfix-top"></div>
  <div id="iosfix-btm"></div>

</body>
