<head>
    <title>{{ firm?.name }} | Cookie Policy</title>
</head>

<!-- Header -->
<app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

<!-- Content -->
<div class="main"
    style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
    [style.color]="firm?.theme.main_gradient_contrast_color">
    <div class="pagecontent">
        <div class="view-homepage">
            <div class="centered">

                <!-- Cookie policy -->
                <div class="text">
                    <h1>Cookie Policy</h1>

                    <div class="bodytext">

                        <b>Use of Cookies by Keyware Group</b><br />
                        In the Keyware Group, were EASYORDER BVBA is a member of, Cookies are important to the use
                        of a website. to improve your experience, we use cookies to remember your preferences and
                        collect statistics to optimize the site functionality and to deliver content tailored to
                        your interests.
                        <br /><br />

                        <b>What are Cookies ?</b><br />
                        We use the terms “cookies” to refer to all technologies that we may use to store data in
                        your browser or device or that collect information or help us identify you in the manner
                        described above. We have:
                        <br /><br />
                        <ul>
                            <li>
                                <b>Cookies</b> are small text files (typically made up of letters and numbers)
                                placed in the memory of your browser or device when you visit a website or view a
                                message. When a user visits a website or application more than once, the browser
                                sends Cookies back to the website or application, allowing the website or
                                application to recognize the user and remember things like their personalized
                                details and preferences. There are several types of cookies:
                            </li>
                            <ul>
                                <li>
                                    Session cookies expire at the end of your browser session and allow us to link
                                    your actions during that particular browser session.
                                </li>
                                <li>
                                    Persistent cookies are stored on your device in between browser sessions,
                                    allowing us to remember your preferences or actions across multiple sites.
                                </li>
                                <li>
                                    First-party cookies are set by the site you are visiting.
                                </li>
                                <li>
                                    Third-party cookies are set by a third party site separate from the site you are
                                    visiting.
                                </li>
                            </ul>
                            <li>
                                <b>Web beacons</b><br />
                                Small graphic images (also known as “pixel tags” or “clear GIFs”) that may be
                                included on our sites, services, applications, messaging, and tools, that typically
                                work in conjunction with cookies to identify our users and user behavior.
                            </li>
                            <li>
                                <b>Similar technologies</b>
                                Technologies that store information in your browser or device utilizing local shared
                                objects or local storage, such as flash cookies, HTML 5 cookies, and other web
                                application software methods. These technologies can operate across all of your
                                browsers, and in some instances may not be fully managed by your browser and may
                                require management directly through your installed applications or device. We do not
                                use these technologies for storing information to target advertising to you on or
                                off our sites.
                            </li>
                        </ul>
                        <br />
                        <b>What “Cookies” do we use?</b><br />
                        We mainly use Cookies for the operation and security of our products and services, to
                        improve our services for analyzing trends and administering our services.
                        We currently use Cookies for the following purposes:
                        <ul>
                            <li>
                                <b>Operation of Our Service:</b> We use Cookies to monitor session and status data,
                                to learn how you, and other users, use our services and how well they perform. This
                                allows us to identify errors, learn how our services perform, and improve and
                                develop our services over time. We also use Cookies to remember if you have seen our
                                cookie banner.
                            </li>
                            <li>
                                <b>Security: </b><br />
                                We use Cookies to enable and support our security features, and to help us detect
                                malicious activity and violations of our Terms of Sale and End User License
                                Agreement.
                            </li>
                            <li>
                                <b>Preferences: </b><br />
                                We use temporary 'session' cookies which enables your browser to remember which
                                pages on our website have already been visited and the language you have selected.
                                This information may be used to help us to improve, administer and diagnose problems
                                with our website and servers.
                            </li>
                            <li>
                                <b>Third Party Cookies</b><br />
                                Some Cookies that have been set on our services may not be related to or controlled
                                by us and may originate from one of our partners, affiliates or service providers.
                                We do not control the use of these Cookies and cannot access them due to the way
                                Cookies work, as Cookies can only be accessed by the party which originally set
                                them.
                                You should check the applicable third party's website for more information about
                                these Cookies.
                            </li>
                        </ul>
                        <br />
                        <b>The table below explains the cookies we use and why: (www.easyorderapp.com)</b><br /><br>

                        <table class="tbl">
                            <thead>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>Cookie Name</td>
                                    <td>Provider</td>
                                    <td>Type</td>
                                    <td>Expiry</td>
                                    <td>Purpose</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><b>Necessary</b></td>
                                    <td>laravel_session</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>2 hours</td>
                                    <td>This cookie is used for tracking the session. It tracks user data, such as
                                        logged in credentials.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>language</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>2 hours</td>
                                    <td>This cookies is used to save the user’s chosen language.</td>
                                </tr>
                                <tr>
                                    <td><b>Statistics</b></td>
                                    <td>_ga</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>2 years</td>
                                    <td>Registers a unique ID that is used to generate statistical data on how the
                                        visitor uses the website.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>_gat</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>Session</td>
                                    <td>Used by Google Analytics to throttle request rate.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>_gid</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>Session</td>
                                    <td>Registers a unique ID that is used to generate statistical data on how the
                                        visitor uses the website.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>__utma</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>2 years from set/update</td>
                                    <td>Used to distinguish users and sessions. The cookie is created when the
                                        javascript library executes and no existing __utma cookies exists. The
                                        cookie is updated every time data is sent to Google Analytics..</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>__utmb</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>30 mins from set/update</td>
                                    <td>Used to determine new sessions/visits. The cookie is created when the
                                        javascript library executes and no existing __utmb cookies exists. The
                                        cookie is updated every time data is sent to Google Analytics..</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>__utmc</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>End of browser session</td>
                                    <td>Not used in ga.js. Set for interoperability with urchin.js. Historically,
                                        this cookie operated in conjunction with the __utmb cookie to determine
                                        whether the user was in a new session/visit.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>__utmz</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>6 months from set/update</td>
                                    <td>Stores the traffic source or campaign that explains how the user reached
                                        your site. The cookie is created when the javascript library executes and is
                                        updated every time data is sent to Google Analytics.</td>
                                </tr>
                                <tr>
                                    <td>&nbsp;</td>
                                    <td>__utmt</td>
                                    <td>easyorderapp.com</td>
                                    <td>HTTP</td>
                                    <td>10 minutes</td>
                                    <td>Used to throttle request rate.</td>
                                </tr>
                            </tbody>
                        </table>

                        <br /><br />
                        <b>How to modify or delete cookies</b><br />
                        To modify or delete any of these cookies, please go to your web browser settings. More
                        information can be found on how to do this for all major web browsers at <a
                            href="http://www.aboutcookies.org/">www.aboutcookies.org.</a>
                        <br /><br />

                        To make sure you find out your way on how to change the settings, here are a few links that
                        may be useful to configure appropriately the different browser you may be using.
                        <ul>
                            <li><a
                                    href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies">Cookie
                                    settings in Internet Explorer</a></li>
                            <li><a
                                    href="https://support.mozilla.org/en-US/kb/cookies-information-websites-store-on-your-computer?redirectlocale=en-US&amp;redirectslug=Cookies">Cookie
                                    settings in Firefox</a></li>
                            <li><a
                                    href="https://support.google.com/chrome/answer/95647?hl=en">Cookie
                                    settings in Chrome</a></li>
                            <li><a
                                    href="http://support.apple.com/kb/HT1677">Cookie
                                    settings in Safari</a></li>
                        </ul>
                        <br />

                        <b>Changes to this Cookie setting</b><br />
                        KEYWARE Group may at any time make changes to these Cookie setting by posting revised Cookie
                        Setting on the site. It is your responsibility to check periodically for any changes we make
                        to the Cookie Setting. We therefore advise you to consult this Cookie Setting frequently to
                        check any such change. Your continued use of the site after any changes to the Cookie
                        Setting means you accept the changes.
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>
