import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AddToCartService {
  hasReachedGroupMin(group) {
    let selectedIngredients = 0;
    if (group && group.min_selection) {
      group.ingredients.forEach((ingr) => {
        if (ingr.selected && ingr.type !== 'main') {
          selectedIngredients += Number(ingr.amount);
        }
      });
      return selectedIngredients >= group.min_selection;
    } else {
      return true;
    }
  }

  haveAllGroupsReachedGroupMin(groups) {
    let result = true;
    if (groups) {
      groups.forEach((group) => {
        const hasReached = this.hasReachedGroupMin(group);
        if (!hasReached) result = false;
      });
    }
    return result;
  }

  haveAllGroupsBeenSelected(allGroups, selectedGroups) {
    return allGroups ? allGroups.length === selectedGroups.length : true;
  }

  getAllCategoryIds(cartItems): Array<number> {
    const categories = [];
    cartItems.forEach((cartItem) => {
      categories.push(Number(cartItem.item.cat_id));
    });
    return categories;
  }

  getAllProductIds(cartItems): Array<number> {
    const products = [];
    cartItems.forEach((cartItem) => {
      products.push(cartItem.item.id);
    });
    return products;
  }
}
