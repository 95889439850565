import { Injectable } from '@angular/core';
import { ProductService } from './products.service';

@Injectable({
  providedIn: 'root',
})
export class LoyaltyService {
  constructor(private productService: ProductService) {}

  isActive(usesLoyalty, loyaltySettings) {
    return usesLoyalty && loyaltySettings && loyaltySettings.active;
  }

  canBeAddedToOrder(addLoyaltyToOrder) {
    return addLoyaltyToOrder;
  }

  getProductList(loyaltyType, freeProduct, firm, language) {
    return new Promise(async (resolve) => {
      switch (loyaltyType) {
        case 'product':
          resolve([freeProduct]);
          break;
        case 'category':
          const products = await this.productService.getProductsByCategoryId(
            firm.id,
            language,
            firm.loyalty.free_product_category.id,
            firm.uses_deliverect
          );
          resolve(products);
          break;
      }
    });
  }
}
