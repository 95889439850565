<div>
  <!-- Success message -->
  <div class="success-msg" *ngIf="enableSuccess">
    <p>{{ 'BOOKING_SAVED' | translate }}</p>
    <p>{{ 'BOOKING_EMAIL' | translate }}</p>
  </div>

  <div *ngIf="!enableSuccess">
    <!-- Reservation information -->
    <div class="reservation-info">

      <!-- Amount of guests -->
      <div class="row">
        <p>{{ 'BOOKING_GUESTS' | translate }}:</p>
        <mat-form-field>
          <mat-select [(ngModel)]="chosen_amount" (selectionChange)="getTimeSlots()">
            <mat-option *ngFor="let amount of amounts" [value]="amount">
              <span *ngIf="amount == 1">{{ amount }} {{ 'PERSON' | translate }}</span>
              <span *ngIf="amount != 1">{{ amount }} {{ 'PERSONS' | translate }}</span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <!-- Date -->
      <div class="row">
        <p>{{ 'DATE' | translate }}:</p>
        <mat-form-field (click)="myDatepicker.open()">
          <input style="color: #525252" (click)="myDatepicker.open()" class="picker-label" matInput [matDatepicker]="myDatepicker" [min]="minDate" [max]="maxDate" name="delivery_date" [(ngModel)]="date" (dateChange)="getTimeSlots()" disabled>
          <mat-datepicker-toggle matSuffix [for]="myDatepicker"></mat-datepicker-toggle>
          <mat-datepicker touchUi #myDatepicker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>

      <!-- Time -->
      <div class="row" *ngIf="times?.length > 0 && enableTime">
        <p>{{ 'TIME' | translate }}:</p>
        <mat-form-field>
          <mat-select [(ngModel)]="chosen_time" (selectionChange)="checkDisable()">
            <mat-option *ngFor="let time of times" [value]="time">
              {{ time }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <p class="msg" *ngIf="times?.length == 0 && enableTime">{{ 'NO_SLOTS_FOUND' | translate }}</p>
    </div>

    <!-- User information -->
    <div class="user-info">
      <input style="float: left" class="half" name="first_name" type="text" placeholder="{{ 'USER_DETAILS.FIRST_NAME' | translate }} *" [(ngModel)]="first_name" (ngModelChange)="checkDisable()">
      <input style="float: right" class="half" name="last_name" type="text" placeholder="{{ 'USER_DETAILS.LAST_NAME' | translate }} *" [(ngModel)]="last_name" (ngModelChange)="checkDisable()">
      <input class="full" name="email" type="text" placeholder="{{ 'USER_DETAILS.EMAIL' | translate }} *" [(ngModel)]="email" (ngModelChange)="checkDisable()">
      <input class="full" name="phone" type="text" placeholder="{{ 'USER_DETAILS.PHONE' | translate }} *" [(ngModel)]="phone" (ngModelChange)="checkDisable()">
      <input class="full remark" name="remarks" type="text" placeholder="{{ 'REMARKS' | translate }}" [(ngModel)]="remarks" (ngModelChange)="checkDisable()">
    </div>

    <!-- Confirm button -->
    <div class="buttons" style="text-align: center">
      <button href="javascript:void(0)" class="btn-fill" (click)="confirmReservation()" [disabled]="!disableBtn">{{ 'BUTTONS.CONFIRM' | translate }}</button>
    </div>

    <!-- Error -->
    <div class="error-msg" *ngIf="enableError">
      <p>{{ error_msg }}</p>
    </div>
  </div>
</div>