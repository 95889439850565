<!-- Header (minimal) -->
<div class="multifirm_header" [style.background-image]="'url('+ firm?.logo_fullsize +')'"></div>

<div class="pagecontent" style="margin-top: 50px; margin-bottom: 350px">
    <div class="view-seoindex">
        <div class="centered width-40">

          <form #formPassword="ngForm">
            <p class="password-title font-20 weight-300 align-center">{{ 'ENTER_PASSWORD' | translate }}</p>
            <div class="input">
              <div class="inputfield">
                  <input required="required" name="password" type="password" value="" id="password" [(ngModel)]="password" placeholder="{{ 'PASSWORD' | translate }}" #passwordRef="ngModel" required>
              </div>
            </div>
            <div class="buttons">
              <button class="btn-fill buttons password-btn width-full" (click)="enterPassword()" [disabled]="formPassword.invalid">{{ 'LOGIN' | translate }}<span class="arrow"></span></button>
            </div>
          </form>

          <p *ngIf="password_error == true" class="password-error align-center color-red" text-center>{{ 'PASSWORD_INCORRECT' | translate }}</p>

          <div text-center class="align-center font-14">
            <p class="weight-300">{{ 'ORDER_WITH_PASSWORD' | translate }}</p>
            <p class="weight-300">{{ 'CONTACT_SHOP' | translate }}</p>
          </div>

        </div>
    </div>
</div>

<!-- Footer -->
<app-footer *ngIf="!firmService.checkEmbed()"></app-footer>