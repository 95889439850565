import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import * as Sentry from '@sentry/angular';
import { Integrations } from '@sentry/tracing';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import { defineCustomElements, applyPolyfills } from 'easyorderstencil/loader';
import { config } from './config';

if (environment.production) {
  Sentry.init({
    dsn: 'https://806005ee822242b9a2db23d30ba62dae@o740136.ingest.sentry.io/5798724',
    environment: `WEBSHOP_${environment.env}`,
    release: `eo-webshop@${config.VERSION}`,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: ['localhost', environment.API_URL],
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    tracesSampleRate: environment.SAMPLE_RATE,
  });

  enableProdMode();
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.log(err));

applyPolyfills().then(() => {
  defineCustomElements();
});
