import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

import { DialogsService } from './dialogs.service';
import { TranslateService } from '@ngx-translate/core';
import { config } from '../../config';

@Injectable()
export class AuthService {

  endpoint = "/client/login";
  
  constructor(private http: HttpClient, public dialogsService: DialogsService, public translate: TranslateService) { }

  async getToken(): Promise<any> {
    return new Promise(async (resolve) => {
      let httpOptions = {
        headers: new HttpHeaders(
          { 
            'Accept': 'application/json',
            'appVersion': config.VERSION,
            'apiVersion': config.VERSION,
            'deviceOS': 'Web',
            'deviceOSVersion': 'Web',
            'deviceModel': 'Web'
          }
        )
      };

      let body = {
        client_id: 'app',
        client_secret: 'VhtDuF5GQjXyMu3Md8XTKuys3t4vy8astxOAGCeE'
      }

      await this.http.post(environment.API_URL + this.endpoint, body, httpOptions)
            .subscribe(
              async (data: any) => {
                await localStorage.setItem('token', data.access_token);
                resolve(data.access_token);
              },
              err => {
                if(err.status == 503) {
                  this.dialogsService.openError(this.translate.instant('DIALOGS.MAINTENANCE_TITLE'), this.translate.instant('DIALOGS.MAINTENANCE_MESSAGE'), false);
                }
                else {
                  this.dialogsService.openError(this.translate.instant('DIALOGS.AUTH_TITLE'), this.translate.instant('DIALOGS.AUTH_MESSAGE'), false);
                }
              }
            );
    })
  }
  
}