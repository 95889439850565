<head>
    <title>{{ firm?.name }} | {{ 'INFO.TITLE' | translate }}</title>
</head>

<!-- Header -->
<app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

<!-- Content -->
<div
  class="main"
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.main_gradient_contrast_color"
>
    <div class="pagecontent">
        <div class="view-homepage">
            <!-- Firm info -->
            <app-firm-info [firm]="firm"></app-firm-info>
            <app-hours-toggle [firm]="firm"></app-hours-toggle>
        </div>
    </div>
</div>

<!-- Footer -->
<app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>