<div class="header row">
  
  <!-- Breadcrumb -->
  <ul class="nav">

    <!-- Basket -->
    <li>
      <div>
        <a (click)="navigateTo('basket')">
          <span>{{ 'HEADER.BASKET' | translate }}</span>
        </a>
      </div>
      <div class="underline" [style.background-color]="firm?.theme.theme_color"></div>
      <div class="check" [style.color]="firm?.theme.theme_color">
        <i class="fas fa-check-circle"></i>
      </div>
    </li>

    <!-- Checkout (delivery) -->
    <li [class.active]="focused === 'checkout'" id="checkout_focus">
      <div>
        <a (click)="navigateTo('delivery')">
          <span>{{ 'CHECKOUT_HEADER.ORDER_INFO' | translate }}</span>
        </a>
      </div>
      <div class="underline" [style.background-color]="firm?.theme.theme_color"></div>
      <div class="check" [style.color]="firm?.theme.theme_color">
        <i [class]="focused === 'checkout' ? 'far fa-circle' : 'fas fa-check-circle'"></i>
      </div>
    </li>

    <!-- Payment -->
    <li [class.active]="focused === 'payment'" id="payment_focus">
      <div>
        <a (click)="navigateTo('payment')">
          <span>{{ 'CHECKOUT_HEADER.PAYMENT_METHOD' | translate }}</span>
        </a>
      </div>
      <div class="check" [style.color]="firm?.theme.theme_color">
        <i [class]="focused === 'payment' ? 'far fa-circle' : 'fas fa-check-circle'"></i>
      </div>
    </li>

  </ul>
</div>
