import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { BasketItemsService } from '../services/basket-items.service';
import { CustomerService } from '../services/customer.service';
import { DialogsService } from '../services/dialogs.service';
import { FavoritesService } from '../services/favorites.service';
import { FirmService } from '../services/firm.service';
import { OrderService } from '../services/order.service';
import { Units } from '../constants/units';
import { RouteService } from '../services/route.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-embedded-header',
  templateUrl: './embedded-header.component.html',
  styleUrls: ['./embedded-header.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class EmbeddedHeaderComponent {
  @Input() firm: any;
  @Input() own_domain: boolean;
  @Input() language: boolean;
  @Input() profileName?: any;

  login: any;
  parameter: any;
  firm_name: any;
  search_term: any;
  basketItems: any;
  sub_total: any;
  showMenu = false;
  selectedLang: any;
  units = Units;

  constructor(
    public router: Router,
    public route: ActivatedRoute,
    public firmService: FirmService,
    public basket: BasketItemsService,
    public translate: TranslateService,
    public dialogsService: DialogsService,
    public favoritesService: FavoritesService,
    public orderService: OrderService,
    public routeService: RouteService,
    public customerService: CustomerService,
    private languageService: LanguageService
  ) {}

  ngOnInit() {
    this.languageService.setLanguage(this.language);
  }

  async ngOnChanges() {
    await this.customerService.setLocalCustomer();
    const response = localStorage.getItem('login');

    if (response == null) {
      this.login == response;
    } else {
      this.login = JSON.parse(response);
    }

    if (this.firm) {
      this.basketItems = await this.basket.get(this.firm.id);
      this.basket.basketAmount = this.basket.getAmount(this.firm.id);
      if (!this.firm.uses_vat) {
        this.basket.basketPrice = this.basket.calculatePrice(this.firm.id);
      } else {
        this.basket.basketPrice = this.basket.calculateVatPrice(this.firm.id);
      }
    }
  }

  async changeLanguage(lang) {
    if (this.basket.basketAmount > 0) {
      this.basket.removeAll(this.firm.id);
    }

    if (!this.own_domain) {
      await this.router.navigate([this.firm.website_details.sitename, lang.code.toLowerCase()]);
      window.location.reload();
    } else {
      if (this.firm.parent_is_multifirm) {
        await this.router.navigate([this.firm.website_details.sitename, lang.code.toLowerCase()]);
        window.location.reload();
      } else {
        await this.router.navigate([lang.code.toLowerCase()]);
        window.location.reload();
      }
    }
  }

  logout() {
    this.login = null;
    this.customerService.logout();

    this.dialogsService
      .openError(this.translate.instant('DIALOGS.LOGOUT_TITLE'), this.translate.instant('DIALOGS.LOGOUT_MESSAGE'), true)
      .subscribe(() => {
        if (!this.own_domain) {
          this.router.navigate([this.firm.website_details.sitename, this.language]);
        } else {
          if (this.firm.parent_is_multifirm) {
            this.router.navigate([this.firm.website_details.sitename, this.language]);
          } else {
            this.router.navigate([this.language]);
          }
        }
      });
  }
}
