<head>
  <title>{{ firm?.name }} | {{ 'SIGN_UP' | translate }}</title>
</head>
<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
<div class="main">

  <!-- Header -->
  <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
  <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

  <div class="centered width-50">

    <!-- Content -->
    <div class="pagecontent">
      <div class="row">
        <div class="view-userdetails">
          <h1 class="title-font">{{ 'COMPLETE_ACCOUNT' | translate }}</h1>

          <div *ngIf="emailform">
            <form #formDetails="ngForm">
              <div class="well">
                <div class="input">
                  <label for="email">{{ 'USER_DETAILS.EMAIL' | translate }} *</label>
                  <div class="inputfield">
                    <input name="email" type="email" [(ngModel)]="user.email" required
                           pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
                  </div>
                </div>

                <div class="input">
                  <label for="name">{{ 'USER_DETAILS.FIRST_NAME' | translate }} *</label>
                  <div class="inputfield">
                    <input name="firstName" type="text" [(ngModel)]="user.first_name" required>
                  </div>
                </div>

                <div class="input">
                  <label for="name">{{ 'USER_DETAILS.LAST_NAME' | translate }} *</label>
                  <div class="inputfield">
                    <input name="lastName" type="text" [(ngModel)]="user.last_name" required>
                  </div>
                </div>

                <div class="input">
                  <label for="phone">{{ 'USER_DETAILS.PHONE' | translate }} *</label>
                  <div class="inputfield">
                    <input name="phone" type="tel" [(ngModel)]="user.phone" required>
                  </div>
                </div>

                <div class="input">
                  <label for="company">{{ 'USER_DETAILS.COMPANY' | translate }}</label>
                  <div class="inputfield">
                    <input name="company" type="text" [(ngModel)]="user.company">
                  </div>
                </div>

                <div class="input">
                  <label for="password">{{ 'PASSWORD' | translate }}*</label>
                  <div class="inputfield">
                    <input name="password" type="password" [(ngModel)]="user.password">
                  </div>
                </div>

                <div class="input">
                  <label for="password_confirm">{{ 'CONFIRM_PASSWORD' | translate }}*</label>
                  <div class="inputfield">
                    <input name="password_confirm" type="password" [(ngModel)]="user.password_confirm">
                  </div>
                </div>
              </div>

              <br/>

              <!-- Checkboxes (for profile) -->
              <div class="gdpr title-font">
                <h2>{{ 'GDPR.GIVE_PERMISSION' | translate }}</h2>
                <mat-checkbox name="opt_in_eo" [(ngModel)]="user.opt_in_eo" disableRipple="true" ngDefaultControl><span>{{ 'GDPR.EO_OPT_IN' | translate }}</span></mat-checkbox>
                <mat-checkbox name="opt_in_merchant" [(ngModel)]="user.opt_in_merchant" disableRipple="true" ngDefaultControl><span>{{ 'GDPR.MERCHANT_OPT_IN' | translate:{firm_name: firm?.name} }}</span></mat-checkbox>
              </div>

              <!-- Save button -->
              <div class="buttons">
                <button class="btn-fill buttons save-btn"
                  (click)="signup()"
                  [disabled]="!formDetails.valid">
                  {{ 'SIGN_UP' | translate }}
                  <span class="arrow"></span>
                </button>
              </div>
            </form>

            <div class="signin-btn">
              <a
                class="font-14"
                (click)="routeService.navigateToProfile(own_domain, firm, language)">
                {{ 'ALREADY_HAVE_ACCOUNT' | translate }}
                {{ 'CLICK_HERE' | translate }}
              </a>
            </div>

            <eo-social-logins
              [firm]="firm"
              [language]="language"
              (loggedIn)="handleSignup($event)">
            </eo-social-logins>
          </div>

          <!-- Wait screen -->
          <div *ngIf="waitscreen" class="waitscreen">
            <div class="well">
              <div class="row">
                <p>{{ 'USER_DETAILS.CHECK_MAIL' | translate }}
                  <br> {{ 'USER_DETAILS.OPEN_ACTIVATION_LINK' | translate }}
                </p>
                <small style="margin-top: 15px">{{ 'USER_DETAILS.DURATION' | translate }}</small>
                <small>{{ 'USER_DETAILS.30_SECONDS' | translate }}</small>
              </div>
            </div>

            <div class="buttons">
              <button class="btn-fill buttons"
                (click)="showConfirmation = true; activationMessage = false; verification_error = false">
                {{ 'BUTTONS.CHECK_ACTIVATION' | translate }}
                <span class="arrow"></span></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Footer -->
  <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

</div>
</body>
