import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { FirmService } from '../services/firm.service';
import { OrderService } from '../services/order.service';
import { RouteService } from '../services/route.service';

@Component({
  selector: 'app-recent-orders',
  templateUrl: './recent-orders.component.html',
  styleUrls: ['./recent-orders.component.css']
})
export class RecentOrdersComponent implements OnInit {

  firm: any;
  firm_name: any;
  language: any;
  pending_orders: any;
  past_orders: any;
  own_domain: any;

  constructor(public auth: AuthService, public router: Router, public route: ActivatedRoute, public routeService: RouteService, public firmService: FirmService, public orderService: OrderService) {
    window.scrollTo(0, 0);

    // Route params
    this.firm_name = route.snapshot.params.firm_name;
    this.language = route.snapshot.params.language;
  }

  async ngOnInit() {
    this.firmService.getFirmByCheckingDomain(this.firm_name, this.language).then((data) => {
      this.firm = data.firm;
      this.own_domain = data.own_domain;
      this.initData();
    });
  }

  async initData() {
    if(this.firmService.checkWebsite(this.firm, this.language)) {
      let login: any = JSON.parse(await localStorage.getItem('login'));
      this.pending_orders = await this.orderService.getPendingOrders(this.firm, login.id, this.language, this.own_domain);
      this.past_orders = await this.orderService.getPastOrders(this.firm, login.id, this.language, this.own_domain);
    }
  }

}