<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.USER_DETAILS' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme?.main_gradient_color_from }}, {{ firm?.theme?.main_gradient_color_to }})" [style.color]="firm?.theme?.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="firmService.checkEmbed() == false" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="centered width-50">
      <!-- Content -->
      <div class="pagecontent">
        <div class="row">
          <app-signin *ngIf="firm && !user" [firm]="firm" [own_domain]="own_domain" [language]="language" (userOutput)="setUser($event); setAddressList()"></app-signin>
          <app-user-profile
            *ngIf="user"
            [user]="user"
            [firm]="firm"
            [language]="language"
            [own_domain]="own_domain"
            [addressList]='addressList'
            (addressSelect)='selectAddress($event)'
            (addressEdit)='presentEditAddressModal($event)'
            (addressAdd)='presentAddAddressModal()'
            (businessDetailsClicked)='presentBusinessDetailsModal()'>
          </app-user-profile>
        </div>
      </div>

    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language" style="margin-top: 2rem;"></app-footer>

  </div>
</body>
