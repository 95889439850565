import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'mainDetail',
})
export class MainDetailPipe implements PipeTransform {
  transform(images: any, args?: any): any {
    if (!images) {
      return;
    }

    return images.find((img) => img.main)?.detail;
  }
}
