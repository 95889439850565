<div class="new-well">
    
    <div *ngIf="loyaltyType === 'discount'">
        <p *ngIf="discountType === 'amount'">
            <i class="fas fa-gift gift-icon"></i>
            {{ 'LOYALTY_DISCOUNT' | translate }} {{ currency }}{{ discountValue }} {{ 'DISCOUNT' | translate }}!
        </p>
        <p *ngIf="discountType === 'percentage'">
            <i class="fas fa-gift gift-icon"></i>{{ 'LOYALTY_DISCOUNT' | translate }} {{ discountValue | number:'1.0-0' }}% {{ 'DISCOUNT' | translate }}!
        </p>
    </div>

    <div *ngIf="loyaltyType === 'product' || loyaltyType === 'category'">
        <p><i class="fas fa-gift gift-icon"></i>{{ 'LOYALTY_PRODUCT' | translate }}: </p>
        <p>{{ selectedProduct?.name }} {{ 'VALUE_OF' | translate }} {{ currency }}{{ selectedProduct?.price | number:'1.2-2' }}</p>

        <div *ngIf="listHasMultipleProducts()">
            <div class="product-select">
                <p>{{ 'CHOOSE_FREE_PRODUCT' | translate }}:</p>
                <mat-radio-group [(ngModel)]="selectedProduct" name="selected_product" required>
                    <mat-radio-button 
                        *ngFor="let product of productList"
                        class="width-full" [value]="product"
                        (change)="setSelectedLoyaltyProduct(product)"
                        ngDefaultControl>
                        {{ product.name }}
                    </mat-radio-button>
                </mat-radio-group>
            </div>
        </div>

        <div class="loyalty-msg" *ngIf="!isLoyaltyProductInStock()">
            <p>{{ 'PRODUCT_CURRENTLY_NOT_IN_STOCK' | translate }}</p>
            <p>{{ 'LOYALTY_SAVED_FOR_NEXT_ORDER' | translate }}</p>
        </div>

    </div>

</div>