<div class="slider-container">

  <eo-round-button
    class="left"
    *ngIf="displayLeftButton"
    [iconName]="'fas fa-chevron-left'"
    (clicked)="scroll(-100, 0)">
  </eo-round-button>

  <eo-round-button
    class="right"
    *ngIf="displayRightButton"
    [iconName]="'fas fa-chevron-right'"
    (clicked)="scroll(100, 0)">
  </eo-round-button>

  <div class="scroll-container" #scrollContainer id="scrollContainer" (scroll)="checkCoordinates()">
    <ng-content></ng-content>
  </div>
</div>
