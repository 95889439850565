import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'eo-unit-selector',
  template: `
    <input name="amount" type="number" id="amount" [(ngModel)]="quantity" [readonly]="true" />

    <a (click)="subtract()" class="btn-fill subtract" [ngClass]="disableSubtractBtn() ? 'disabled' : 'enabled'"> - </a>
    <a (click)="add()" class="btn-fill add" [ngClass]="disableAddBtn() ? 'disabled' : 'enabled'"> + </a>
  `,
  styleUrls: ['./quantity-selector.component.css'],
})
export class UnitSelectorComponent {
  @Input() quantity: number;
  @Input() minQuantity: number;
  @Input() maxQuantity: number;
  @Input() maxCartQuantity: number;
  @Input() basketAmount: number;
  @Input() amountAlreadyInCart: number;

  @Output() quantityChanged = new EventEmitter<number>();

  constructor() {}

  subtract() {
    this.quantity -= 1;
    this.changeHandler();
  }

  add() {
    this.quantity += 1;
    this.changeHandler();
  }

  disableSubtractBtn() {
    return this.quantity <= 1 || this.quantity <= this.minQuantity;
  }

  disableAddBtn() {
    return (
      (this.maxQuantity ? Number(this.amountAlreadyInCart) + Number(this.quantity) >= this.maxQuantity : false) ||
      (this.maxCartQuantity ? Number(this.basketAmount) + Number(this.quantity) >= this.maxCartQuantity : false)
    );
  }

  changeHandler() {
    this.quantityChanged.emit(this.quantity);
  }
}
