<form
  #formEmail="ngForm"
>
  <div class="view-login">
    <h1 class="title-font greeting">{{ 'HI' | translate }},</h1>
    <h1 class="title-font">{{ 'LOGIN_BELOW' | translate }}</h1>

    <!-- Enter e-mail -->
    <div class="emailform">
      <div class="well">

        <div class="input">
          <div class="inputfield">
            <input placeholder="{{ 'USER_DETAILS.EMAIL' | translate }}*" required="required" name="email" type="email"
                   value="" id="email" [(ngModel)]="email" #emailRef="ngModel" required
                   pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
          </div>
        </div>
        <div class="input">
          <div class="inputfield">
            <input placeholder="{{ 'PASSWORD' | translate }}*" required="required" name="password" type="password"
                   value="" id="password" [(ngModel)]="password" #passwordRef="ngModel" required>
          </div>
        </div>

        <div class="buttons next-btn">
          <button class="btn-fill buttons custom-btn" (click)="login()"
                  [disabled]="!formEmail.valid">{{ 'LOGIN' | translate }}<span class="arrow"></span></button>
        </div>

        <a class="font-14" href="javascript:void(0)" (click)="routeService.navigateToSignup(own_domain, firm, language)">{{ 'DONT_HAVE_AN_ACCOUNT' | translate }}
          <span>{{ 'CLICK_HERE' | translate }}</span></a>
        <br>
        <a class="font-14" href="javascript:void(0)"
           (click)="routeService.navigateToPasswordReset(own_domain, firm, language)">{{ 'FORGOT_PASSWORD'  | translate }}
          <span>{{ 'CLICK_HERE' | translate }}</span></a>

        <eo-social-logins
          [firm]="firm"
          [language]="language"
          (loggedIn)="handleSignin($event)">
        </eo-social-logins>
      </div>
    </div>
  </div>
</form>
