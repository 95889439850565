import { Component, Input } from '@angular/core';

@Component({
  selector: 'eo-email-label',
  templateUrl: './email-label.component.html',
  styleUrls: ['./email-label.component.css']
})
export class EmailLabelComponent {

  @Input() email: string;
  @Input() hide: boolean;

}
