import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {config} from '../../config';
import {environment} from '../../environments/environment';
import {AuthService} from './auth.service';
import {ErrorService} from './error.service';
import {StoreService} from './store.service';
import * as moment from 'moment';

@Injectable()
export class ProductService {

  private endpoint = '/sync/products';
  private endpoint_cat = '/category/';
  private endpoint_prod = '/product/';

  products: any;
  token: any;

  constructor(private http: HttpClient, public auth: AuthService, public errorService: ErrorService, public storeService: StoreService) {}

  async getCategories(firm, lang_code, uses_deliverect): Promise<any> {

    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm.id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm.id),
              'language': String(lang_code),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint, httpOptions)
          .subscribe(
            (data: any) => {
              if(firm.uses_deliverect) {
                this.setDeliverectCategories(data.data);
              }

              data.data.forEach(p => {
                this.setProductAvailableData(p, uses_deliverect);
              });
              resolve(data.data);
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getCategories(firm, lang_code, uses_deliverect);
              }
            });
      });
    }
  }

  async getCategoryById(firm_id, lang_code, cat_id, uses_deliverect) {
    if(this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm_id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm_id),
              'language': String(lang_code),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint_cat + cat_id, httpOptions)
          .subscribe(
            (data: any) => {
              resolve(this.setProductAvailableData(data.data[0], uses_deliverect));
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getCategoryById(firm_id, lang_code, cat_id, uses_deliverect);
              }
            });
      });
    }
  }

  async getProductsByCategoryId(firm_id, lang_code, cat_id, uses_deliverect) {
    let response: any = await this.getCategoryById(firm_id, lang_code, cat_id, uses_deliverect);

    if(response.products && response.products.length > 0) {
      return response.products;
    }
    else if(response.subcategories && response.subcategories.length > 0) {
      var array = [];
      await response.subcategories.forEach(subcat => {
        subcat.products.forEach(prod => {
          prod.name = `${prod.name} (${subcat.name})`;
          array.push(prod);
        });
      });
      return array;
    }
  }

  async getItemById(firm_id, lang_code, prod_id, uses_deliverect) {
    if (this.errorService.checkConnection()) {
      return new Promise(async (resolve) => {

        this.token = await localStorage.getItem('token');
        if (!this.token) {
          this.token = await this.auth.getToken();
        }

        let httpOptions = {
          headers: new HttpHeaders(
            {
              'storeId': await this.storeService.getStoreId(),
              'passwordToken': await this.checkToken(firm_id),
              'demoPasswordToken': await this.getDemoToken(),
              'firmId': String(firm_id),
              'language': String(lang_code),
              'Accept': 'application/json',
              'Authorization': 'Bearer ' + await localStorage.getItem('token'),
              'appVersion': config.VERSION,
              'apiVersion': config.VERSION,
              'deviceOS': 'Web',
              'deviceOSVersion': 'Web',
              'deviceModel': 'Web'
            }
          )
        };

        this.http.get(environment.API_URL + this.endpoint_prod + prod_id, httpOptions)
          .subscribe(
            async (data: any) => {
              resolve(this.setProductAvailableData(data.data, uses_deliverect));
            },
            async err => {
              let check = await this.errorService.checkProductsErrors(err.status, await this.parseError(err));

              if (check == 'TOKEN_INVALID') {
                this.getItemById(firm_id, lang_code, prod_id, uses_deliverect);
              }
            });

      });
    }
  }

  setDeliverectCategories(categories): any[] {
    let index = 0;
    const deliverectCategories = [];

    categories.forEach((cat) => {
      deliverectCategories.push({
        name: cat.name,
        index: index,
      });
      index += 1;
    });

    localStorage.setItem('deliverectCategories', JSON.stringify(deliverectCategories));

    return deliverectCategories;
  }

  async getDeliverectCategories(firm, langCode): Promise<any> {
    const categories = JSON.parse(localStorage.getItem('deliverectCategories'));

    if (!categories) {
      const categories = await this.getCategories(firm, langCode, firm.uses_deliverect);
      return this.setDeliverectCategories(categories);
    }

    return categories;
  }

  parseError(err) {
    try {
      return JSON.parse(err.error);
    }
    catch (error) {
      error = err.error;
      return err.error;
    }
  }

  async checkToken(firm_id) {
    let tokens = await JSON.parse(localStorage.getItem('password_tokens'));

    var passwordToken = [];

    if (tokens != null) {
      tokens.forEach(token => {
        if (token.firm_id == firm_id) {
          passwordToken = token.passwordToken;
        }
      });
    }

    return passwordToken;
  }

  async getDemoToken() {
    var demoToken = await JSON.parse(localStorage.getItem('demo_password_token'));

    if (demoToken == null) {
      demoToken = '';
    }

    return demoToken;
  }

  removeProduct(original_products, original_product) {
    let index = original_products.indexOf(original_product);
    if (index !== -1) {
      original_products.splice(index, 1);
    }
  }

  getProducts(categories: any) {
    categories.forEach(cat => {
      if (cat.subcategories.length > 0) {
        this.getProducts(cat.subcategories);
      } else {
        cat.products.forEach(prod => {
          this.products.push(prod);
        });
      }
    });
  }

  /* Get VAT percentage of a product */
  async getVatPercentage(product, firm) {
    var VAT_percentage;

    if (firm.vat_settings.allowed_vat_percentages.length == 1) {
      VAT_percentage = firm.vat_settings.allowed_vat_percentages[0];
    }
    else {
      let vat_delivery_method = await localStorage.getItem('vat_delivery_method');

      let delivery_methods = firm.vat_settings.delivery_method.filter((method) => method.method == vat_delivery_method);

      if(product) {
        product.vat_percentages.forEach(item_percentage => {
          delivery_methods.forEach(method => {
            if (method.vat_percentage == item_percentage) {
              VAT_percentage = item_percentage;
            }
          });
        });
      }
    }

    return VAT_percentage;
  }

  isAvailable(availabilities, deliverect?) {
    let isAvailable = false;
    if(availabilities && availabilities.length > 0) {
      availabilities.forEach(av => {
        if(moment().isoWeekday() === av.day_id) {
          let available = moment(`${moment().hour()}:${moment().minute()}`, 'HH:mm').isBetween(moment(av.from_time, 'HH:mm'), moment(av.to_time, 'HH:mm'));
          if(available) isAvailable = true;
        }
      });
    }
    else if(availabilities && availabilities.length === 0 && deliverect) isAvailable = false;
    else if(availabilities && availabilities.length === 0 && !deliverect) isAvailable = true;
    return isAvailable;
  }

  /**
   * Returns the next available date
   * Don't use this function if available now (ProductService#isAvailable).
   * @param availabilities
  */
  async getFirstAvailableDate(availabilities) {
    let day_id = moment().isoWeekday();
    let counter = 1;

    if(availabilities && availabilities.length > 0) {
      while (availabilities.find(av =>
              (av.day_id === day_id) &&
              (av.day_id !== moment().isoWeekday() || (av.day_id === moment().isoWeekday() && !moment().isBetween(av.from_time, av.to_time))) ) === undefined &&
              counter <= 7) {
        day_id = (day_id === 7) ? day_id = 1 : day_id += 1;
        counter += 1;
      }
      let availableDays = availabilities.filter(av => av.day_id === day_id);

      if(availableDays && availableDays.length === 1) return availableDays[0];
      else if(availableDays && availableDays.length > 1) {
        if(day_id === moment().isoWeekday()) {
          let firstSlot = await availableDays.find(day => moment(`${moment().hour()}:${moment().minute()}`, 'HH:mm').isBefore(moment(day.from_time, 'HH:mm')));
          return firstSlot;
        }
        else {
          return availableDays[0];
        }
      }
    }
  }

  isSnoozed(item) {
    return (item.snooze_start && item.snooze_end) &&
      moment().isBetween(item.snooze_start, item.snooze_end);
  }

  isDateWithinCurrentYear(date) {
    return moment(date).isBefore(moment().add(1, 'y'));
  }

  async setProductAvailableData(category, uses_deliverect) {
    category.isAvailable = this.isAvailable(category.availabilities, uses_deliverect);

    if(!category.isAvailable) {
      category.firstAvailableDate = await this.getFirstAvailableDate(category.availabilities);
    }

    if('category' in category) {
      category.category = await this.setProductAvailableData(category.category, uses_deliverect);
    }

    return category;
  }

}
