<div class="eo-card"
     [class.active]="displaySelected && address.is_default"
     (click)="$event.stopPropagation(); selectedHandler(address)">
  <div class="selected" *ngIf="displaySelected && address.is_default">
    {{ 'SELECTED' | translate }}
  </div>
  <div class="content">
    <eo-round-button class="edit"
      [iconName]="'fas fa-pen'"
      (clicked)="editHandler(address)">
    </eo-round-button>

    <b>{{ address.description }}</b>
    <div class="address">
      <p>{{ address.street }} {{ address.house_number }} <span *ngIf="address.bus">{{ address.bus }}</span></p>
      <p>{{ address.zip_code }} {{ address.locality }}</p>
      <p>{{ address.country?.name }}</p>
    </div>
  </div>
</div>
