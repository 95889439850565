<div
  class="category-item"
  [style.background-color]="firm?.theme.container_color"
  [style.color]="firm?.theme.container_contrast_color"
  [ngClass]="{ 'gold-container': item?.extra_points && item?.extra_points > 0 }">

  <figure class="image" [style.background-color]="firm?.theme.well_color">
    <img src="{{ item.images[0].detail }}" alt="" style="object-fit: cover;">
  </figure>

  <eo-extra-points *ngIf="item?.extra_points && item?.extra_points > 0" [points]="item?.extra_points" [position]="'top-left'"></eo-extra-points>

  <div class="content">

    <!-- Badges -->
    <div class="badge-container" *ngIf="!item?.in_stock || item?.has_promotional_price || item?.popular">
      <div *ngIf="!item?.in_stock" class="stock-container container-shadow">
        <p>{{ 'NOT_IN_STOCK' | translate }}</p>
      </div>
      <div *ngIf="item?.has_promotional_price" class="promo-container container-shadow">
        <p>{{ 'PROMO' | translate }}</p>
      </div>
      <div *ngIf="item?.popular" class="popular-container container-shadow">
        <p>{{ 'POPULAR' | translate }}</p>
      </div>
    </div>

    <!-- Product title -->
    <div class="title">{{ item.name }}</div>

    <!-- NO VAT -->
    <div class="info" *ngIf="!firm?.uses_vat">

      <!-- No promotion -->
      <span class="price" *ngIf="item.has_promotional_price == false">{{ firm?.currency.symbol }}{{ item.price | number:'1.2-2' }}
        / {{ item.unit.name_singular}}
      </span>

      <!-- With promotion -->
      <div *ngIf="item.has_promotional_price == true">
          <del class="price">{{ firm?.currency.symbol }}{{ item.price | number:'1.2-2' }}
            / {{ item.unit.name_singular}}
          </del>
          <span class="color-red">{{ firm?.currency.symbol }}{{ item.promotion.price | number:'1.2-2' }}
            / {{ item.unit.name_singular}}
          </span>
      </div>
    </div>

    <!-- VAT (BE only) -->
    <div class="info" *ngIf="firm?.uses_vat && !firm?.show_vat_in_frontend">

      <!-- No promotion -->
      <span class="price" *ngIf="!item.has_promotional_price">{{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}
        / {{ item.unit.name_singular}}
      </span>

      <!-- With promotion -->
            <div *ngIf="item.has_promotional_price">
                <del
                  class="price">{{ firm?.currency.symbol }}{{ item.price + (item.price / 100 * VAT_percentage) | number:'1.2-2' }}
                  / {{ item.unit.name_singular}}</del>
                <span class="color-red">{{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}
                  / {{ item.unit.name_singular}}</span>
            </div>
    </div>

    <!-- VAT -->
    <div class="info" *ngIf="firm?.uses_vat && firm?.show_vat_in_frontend">
      <!-- No promotion -->
            <div *ngIf="!item.has_promotional_price" class="margin-top">
                <p no-margin>{{ 'SUBTOTAL' | translate }}: {{ firm?.currency.symbol }}{{ item?.price | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</p>
                <p no-margin>{{ 'VAT' | translate }} ({{ VAT_percentage }}
                  %): {{ firm?.currency.symbol }}{{ VAT | number:'1.2-2' }}</p>
                <p no-margin>{{ 'BASKET.TOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
            </div>

      <!-- With promotion -->
            <div *ngIf="item.has_promotional_price" class="margin-top">
                <del>{{ 'SUBTOTAL' | translate }}
                  : {{ firm?.currency.symbol }}{{ item.price + (item.price / 100 * VAT_percentage) | number:'1.2-2' }}
                  /{{ item?.unit.name_singular}}</del>
                <p no-margin>{{ 'VAT' | translate }} ({{ VAT_percentage }}
                  %): {{ firm?.currency.symbol }}{{ VAT | number:'1.2-2' }}</p>
                <p no-margin class="color-red">{{ 'PROMOTION' | translate }}
                  : {{ firm?.currency.symbol }}{{ VAT_total | number:'1.2-2' }}/{{ item?.unit.name_singular}}</p>
            </div>
    </div>

    <!-- Snoozed message -->
    <div class="margin-top font-14" *ngIf="productService.isSnoozed(item)">
      <p *ngIf="productService.isDateWithinCurrentYear(item?.snooze_end)">{{ 'AVAILABLE_IN' | translate:{date: moment().to(item?.snooze_end)} }}</p>
      <p *ngIf="!productService.isDateWithinCurrentYear(item?.snooze_end)">{{ 'NOT_AVAILABLE_ATM' | translate }}</p>
    </div>

    <!-- Parent category not available -->
    <div class="unavailable margin-top font-14" *ngIf="(parent === 'search' || parent === 'favorites') && firm?.only_show_available_parent_categories && !item?.isAvailable; else spacer">
      <p>{{ 'NOT_AVAILABLE_ATM' | translate }}</p>
      <p *ngIf="item?.firstAvailableDate">{{ 'AVAILABLE_ON' | translate:{date: item?.firstAvailableDate?.day_name.toLowerCase()} }} {{ 'FROM' | translate:{time: item?.firstAvailableDate?.from_time} }}</p>
    </div>

    <ng-template #spacer>
      <div style="flex: 1"></div>
    </ng-template>

    <div class="buttons" *ngIf="!productService.isSnoozed(item)">
        <!-- Order button -->
        <a *ngIf="firm?.website_details.is_webshop && !itemDisabled && item?.in_stock == true"
           (click)="openModal(item)" class="btn-fill add button custom-btn">
          + <span class="label">{{ 'CATEGORY.ADD_TO_BASKET' | translate }}</span>
        </a>

        <!-- Remove button -->
        <a *ngIf="favorites" (click)="removeFavorite()"
           class="btn-fill button custom-btn">
          <i class="far fa-trash-alt"></i>
        </a>
    </div>
  </div>
</div>
