
import { interval as observableInterval } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-paymentmodal',
  templateUrl: './paymentmodal.component.html',
  styleUrls: ['./paymentmodal.component.css']
})
export class PaymentModal implements OnInit {

  html: any;
  safeHtml: any;

  constructor(public dialogRef: MatDialogRef<PaymentModal>, private sanitized: DomSanitizer) {}

  async ngOnInit() {
    this.safeHtml = await this.sanitized.bypassSecurityTrustHtml(this.html);

    // Check form init
    var sub = observableInterval(1000).subscribe(async (val) => {

      if(!document.forms[0]) {}
      else {
        window.document.forms[0].submit();
        sub.unsubscribe();
      }

    })

  }

}
