<footer
  *ngIf="distributor"
  [style.background-color]="firm?.theme.main_gradient_color_to"
  [style.color]="firm?.theme.main_gradient_contrast_color">
    <div class="centered">
        <div class="powered" *ngIf="distributor?.powered_by_logo">
            <p>Powered by</p>
            <img class="easyorder" [src]="distributor?.powered_by_logo">
        </div>
        <a [href]="distributor?.info_link" target="_blank">{{ distributor?.info_link }}</a>
    </div>
    <div class="centered urls" *ngIf="distributor">
        <a [href]="distributor?.privacy_policy_link" target="_blank">Privacy Policy</a>
        <a [href]="distributor?.terms_link" target="_blank" class="margin">Terms Of Use</a>
        <a [href]="distributor?.cookies_link" target="_blank">Cookie Policy</a>
    </div>
</footer>

<footer
  *ngIf="firm"
  [style.background-color]="firm?.theme.main_gradient_color_to"
  [style.color]="firm?.theme.main_gradient_contrast_color">
    <div class="centered">
        <div class="powered" *ngIf="firm?.distributor?.powered_by_logo">
            <p>Powered by</p>
            <img class="easyorder" [src]="firm?.distributor.powered_by_logo">
        </div>
        <a [href]="firm?.distributor.info_link" target="_blank">{{ firm?.distributor.info_link }}</a>
    </div>
    <div class="centered urls" *ngIf="firm">
        <a href="javascript:void(0)" (click)="routeService.navigateToPrivacyPolicy(own_domain, firm, language)">Privacy Policy</a>
        <a href="javascript:void(0)" (click)="routeService.navigateToTermsOfUse(own_domain, firm, language)" class="margin">Terms Of Use</a>
        <a href="javascript:void(0)" (click)="routeService.navigateToCookiePolicy(own_domain, firm, language)">Cookie Policy</a>
    </div>
    <div class="centered" *ngIf="firm && firm.vat">
        <p>{{ firm.name }} ({{ firm.vat }})</p>
    </div>
</footer>
