<head>
  <title>{{ firm?.name }} | {{ 'NEED_MORE_INFO' | translate }}</title>
</head>

<body style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})" [style.color]="firm?.theme.main_gradient_contrast_color">
  <div class="main">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="centered width-50">

      <!-- Content -->
      <div class="pagecontent">
        <div class="row">
          <div class="view-userdetails">
            <h1 class="title-font">{{ 'NEED_MORE_INFO' | translate }}</h1>

            <!-- User form -->
            <form #formDetails="ngForm" *ngIf="user && method === 'user'">
              <div class="well">
                <div class="input" *ngIf="!user?.email">
                  <label for="email">{{ 'USER_DETAILS.EMAIL' | translate }} *</label>
                  <div class="inputfield">
                    <input name="email" type="email" [(ngModel)]="email" required
                          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
                  </div>
                </div>
                <div class="input" *ngIf="!user?.first_name">
                  <label for="name">{{ 'USER_DETAILS.FIRST_NAME' | translate }} *</label>
                  <div class="inputfield">
                    <input name="firstName" type="text" [(ngModel)]="first_name" required>
                  </div>
                </div>
                <div class="input" *ngIf="!user?.last_name">
                  <label for="name">{{ 'USER_DETAILS.LAST_NAME' | translate }} *</label>
                  <div class="inputfield">
                    <input name="lastName" type="text" [(ngModel)]="last_name" required>
                  </div>
                </div>
                <div class="input" *ngIf="!user?.phone">
                  <label for="phone">{{ 'USER_DETAILS.PHONE' | translate }} *</label>
                  <div class="inputfield">
                    <input name="phone" type="tel" [(ngModel)]="phone" required>
                  </div>
                </div>
                <div class="input" *ngIf="!user?.company">
                  <label for="company">{{ 'USER_DETAILS.COMPANY' | translate }}</label>
                  <div class="inputfield">
                    <input name="company" type="text" [(ngModel)]="company">
                  </div>
                </div>
              </div>
              <br/>
              <div class="buttons">
                <button
                  class="btn-fill buttons save-btn custom-btn"
                  (click)="save()"
                  [disabled]="!formDetails.valid">
                  {{ 'CONTINUE' | translate }} <span class="arrow"></span>
                </button>
              </div>
            </form>

            <!-- Guest form -->
            <form #formDetails="ngForm" *ngIf="user && method === 'guest'">
              <div class="well">
                <div class="input">
                  <label for="email">{{ 'USER_DETAILS.EMAIL' | translate }} *</label>
                  <div class="inputfield">
                    <input name="email" type="text" [(ngModel)]="email" required
                          pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$">
                  </div>
                </div>
                <div class="input">
                  <label for="name">{{ 'USER_DETAILS.NAME' | translate }} *</label>
                  <div class="inputfield">
                    <input name="guestName" type="text" [(ngModel)]="guest_name" required>
                  </div>
                </div>
                <div class="input">
                  <label for="phone">{{ 'USER_DETAILS.PHONE' | translate }} *</label>
                  <div class="inputfield">
                    <input name="phone" type="tel" [(ngModel)]="phone" required>
                  </div>
                </div>
                <div class="input">
                  <label for="company">{{ 'USER_DETAILS.COMPANY' | translate }}</label>
                  <div class="inputfield">
                    <input name="company" type="text" [(ngModel)]="company">
                  </div>
                </div>
              </div>
              <br/>
              <div class="buttons">
                <button class="btn-fill buttons save-btn" (click)="save()"
                  [disabled]="!formDetails.valid">
                  {{ 'CONTINUE' | translate }}
                  <span class="arrow"></span>
                </button>
              </div>
            </form>

          </div>
        </div>
      </div>
    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>
</body>
