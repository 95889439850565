import { Injectable } from '@angular/core';

@Injectable()
export class ThemingService {
  constructor() {}

  setTheme(theme) {
    this.setColors(theme);
    this.setCustomFonts(theme);
  }

  setColors(theme) {
    document.body.style.setProperty('--main-color', this.convertHexToRGB(theme.main_color));
    document.body.style.setProperty('--contrast-color', this.convertHexToRGB(theme.main_color_contrast_color));

    document.body.style.setProperty('--main-gradient-color', this.convertHexToRGB(theme.main_gradient_color_from));
    document.body.style.setProperty('--main-gradient-from-color', this.convertHexToRGB(theme.main_gradient_color_from));
    document.body.style.setProperty('--main-gradient-to-color', this.convertHexToRGB(theme.main_gradient_color_to));
    document.body.style.setProperty(
      '--main-gradient-contrast-color',
      this.convertHexToRGB(theme.main_gradient_contrast_color)
    );

    document.body.style.setProperty('--custom-color', this.convertHexToRGB(theme.theme_color));
    document.body.style.setProperty('--custom-contrast-color', this.convertHexToRGB(theme.theme_color_contrast_color));

    document.body.style.setProperty('--container-color', this.convertHexToRGB(theme.container_color));
    document.body.style.setProperty('--container-contrast-color', this.convertHexToRGB(theme.container_contrast_color));

    document.body.style.setProperty('--tab-color', this.convertHexToRGB(theme.tab_color));
    document.body.style.setProperty('--tab-contrast-color', this.convertHexToRGB(theme.tab_contrast_color));

    document.body.style.setProperty(
      '--success-gradient-from-color',
      this.convertHexToRGB(theme.success_gradient_color_from)
    );
    document.body.style.setProperty(
      '--success-gradient-to-color',
      this.convertHexToRGB(theme.success_gradient_color_to)
    );
    document.body.style.setProperty(
      '--success-gradient-contrast-color',
      this.convertHexToRGB(theme.success_gradient_contrast_color)
    );

    document.body.style.setProperty('--wells-color', this.convertHexToRGB(theme.well_color));
    document.body.style.setProperty('--wells-contrast-color', this.convertHexToRGB(theme.well_contrast_color));

    document.body.style.setProperty('--btn-border-radius', theme.button_radius);
  }

  convertHexToRGB(hex) {
    hex = hex.replace('#', '');
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    return r + ',' + g + ',' + b;
  }

  async setCustomFonts(theme) {
    if (theme.font_header_title) {
      await this.setFont('Title Font', theme.font_header_title);
    }

    if (theme.font_general) {
      await this.setFont('General Font', theme.font_general, 'normal');
      await this.setFont('General Font', theme.font_general, 'bold');
      await this.setFont('General Font', theme.font_general, 900);
      await this.setFont('General Font', theme.font_general, 500);
    }
  }

  async setFont(name, url, weight?) {
    // @ts-ignore
    const font = new FontFace(name, `url(${url})`, { weight });
    (document as any).fonts.add(font);
    font.load().catch((e) => {
      console.log('Loading font failed: ', e);
    });
  }
}
