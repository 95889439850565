import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { DateService } from '../services/date.service';

@Component({
  selector: 'eo-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.css'],
})
export class DatepickerComponent implements OnInit {
  @Input() date: any;
  @Input() minDate: any;
  @Input() hour: string;
  @Input() minutes: string;
  @Input() limitedToSameDay: boolean;
  @Input() schedule: any;

  @Output() changed = new EventEmitter<any>();

  shopIsClosed = false;
  hoursArray: Array<string>;
  minutesArray: Array<string>;
  minTime: string;
  closingTime: string;

  selectedDate: any;
  selectedHour: string;
  selectedMinutes: string;

  constructor(private dateService: DateService) {}

  ngOnInit(): void {
    this.setSelected();
  }

  ngOnChanges() {
    this.setSelected();
  }

  setSelected() {
    this.selectedDate = this.date;
    this.selectedHour = this.hour;
    this.selectedMinutes = this.minutes;
    if (this.selectedDate) this.setTimeIntervals();
  }

  setTimeIntervals() {
    const day = this.dateService.getSelectedDay(this.selectedDate, this.schedule);

    if (!this.dateService.isClosed(day)) {
      this.shopIsClosed = false;

      this.minTime = this.isToday() ? `${this.hour}:${this.minutes}` : this.dateService.getOpeningTime(day);
      this.closingTime = this.dateService.getClosingTime(day);

      this.setSelectableHours(this.minTime, this.closingTime);
      this.setEarliestHour();

      this.prepareMinutesArray();
      this.setEarliestTime();
    } else {
      this.shopIsClosed = true;
    }
  }

  isToday() {
    return this.dateService.isToday(this.selectedDate);
  }

  setSelectableHours(openingTime, closingTime) {
    this.hoursArray = this.dateService.getTimeArray(
      this.dateService.splitHours(openingTime),
      this.dateService.splitHours(closingTime)
    );
  }

  prepareMinutesArray() {
    const openingHour = this.dateService.transformTime(String(this.dateService.splitHours(this.minTime)));
    const closingHour = this.dateService.transformTime(String(this.dateService.splitHours(this.closingTime)));

    const openingMinutes = this.dateService.splitMinutes(this.minTime);
    const closingMinutes = this.dateService.splitMinutes(this.closingTime);

    this.setSelectableMinutes(openingHour, openingMinutes, closingHour, closingMinutes);
  }

  setSelectableMinutes(openingHour, openingMinutes, closingHour, closingMinutes) {
    if (this.selectedHour === openingHour && this.selectedHour === closingHour) {
      this.minutesArray = this.dateService.getTimeArray(openingMinutes, closingMinutes);
    } else if (this.selectedHour === openingHour) {
      this.minutesArray = this.dateService.getTimeArray(openingMinutes, 59);
    } else if (this.selectedHour === closingHour) {
      this.minutesArray = this.dateService.getTimeArray(0, closingMinutes);
    } else {
      this.minutesArray = this.dateService.getTimeArray(0, 59);
    }
  }

  setEarliestHour() {
    this.selectedHour = this.hoursArray[0];
  }

  setEarliestTime() {
    this.selectedMinutes = this.minutesArray[0];
  }

  dateChangedHandler() {
    this.setTimeIntervals();
    this.changedHandler();
  }

  hourChangedHandler() {
    this.prepareMinutesArray();
    this.setEarliestTime();
    this.changedHandler();
  }

  changedHandler() {
    this.changed.emit({
      date: this.selectedDate,
      hour: this.selectedHour,
      minutes: this.selectedMinutes,
    });
  }
}
