import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { OrderModalComponent } from '../../ordermodal/ordermodal.component';

@Component({
  selector: 'eo-modal',
  templateUrl: './eo-modal.component.html',
  styleUrls: ['./eo-modal.component.scss'],
})
export class EoModalComponent {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() disableButton: boolean;

  @Output() submitted = new EventEmitter<void>();

  constructor(private dialogRef: MatDialogRef<OrderModalComponent>) {}

  cancelHandler() {
    this.dismiss({ cancelled: true });
  }

  submitHandler() {
    this.submitted.emit();
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
