import { Component, OnInit } from '@angular/core';
import { BasketItemsService } from '../services/basket-items.service';

@Component({
  selector: 'app-vat-delivery-method',
  templateUrl: './vat-delivery-method.component.html',
  styleUrls: ['./vat-delivery-method.component.css']
})
export class VatDeliveryMethodComponent implements OnInit {

  firm: any;
  vat_delivery_methods: any;
  selectedMethod: any;
  pickup_label: any;
  pickup: boolean;
  delivery: boolean;
  table: boolean;

  constructor(public bis: BasketItemsService) { }

  async ngOnInit() {
    this.selectedMethod = await localStorage.getItem('vat_delivery_method');

    this.pickup_label = this.firm.labels.pickup_store;
    this.vat_delivery_methods = this.firm.vat_settings.delivery_method;

    this.pickup =   this.vat_delivery_methods.some((method) => method.method == 'pickup');
    this.delivery = this.vat_delivery_methods.some((method) => method.method == 'delivery');
    this.table =    this.vat_delivery_methods.some((method) => method.method == 'table');
  }

  async save() {
    await localStorage.setItem('vat_delivery_method', this.selectedMethod);

    let basketItems = await this.bis.get(this.firm.id);
    if(basketItems.length > 0) {
      await this.bis.removeAll(this.firm.id);
    }
  }

}