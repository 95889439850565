import { Component, OnInit } from '@angular/core';
import { OrderService } from '../services/order.service';
import { ActivatedRoute } from '@angular/router';
import { LoaderService } from '../services/loader.service';
import { AuthService } from '../services/auth.service';
import { LanguageService } from '../services/language.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.css']
})
export class OrderDetailComponent implements OnInit {

  reference: any;
  order: any;
  firm: any;

  constructor(public auth: AuthService, public orderService: OrderService, public route: ActivatedRoute, public loader: LoaderService, public languageService: LanguageService, public translateService: TranslateService) {
    this.reference = this.route.snapshot.params.reference;
  }

  async ngOnInit() {
    this.loader.present();
    this.order = await this.orderService.getOrderByReference(this.reference);
    this.firm = await this.firm
    this.translateService.use(this.order.locale);
    this.loader.dismiss();
  }

}
