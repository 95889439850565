<head>
  <title>{{ firm?.name }} | {{ 'OVERVIEW.TITLE' | translate }}</title>
</head>
<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.container_contrast_color">
<app-header [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<div class="main checkout">
  <div class="centered">

    <!-- Header -->
    <app-order-header [firm]="firm" [own_domain]="own_domain" [language]="language"></app-order-header>

    <div class="pagecontent">
      <div class="row">

        <!-- Content -->
        <div [className]="!firm?.uses_vat || (firm?.uses_vat && !firm?.show_vat_in_frontend) ? 'col third col-sm no-half colspan2' : 'col full'">

          <!-- Shop offline -->
          <div *ngIf="shop_offline" class="categories row header-msg" [style.background-color]="offline_settings?.bg_color">
            <p [style.color]="offline_settings?.text_color" style="white-space: pre-wrap">
              {{ offline_settings?.message }}
            </p>
          </div>

          <!-- Only if basket has changed -->
          <div class="basket-change" *ngIf="enable_change_basket">
            <!-- Product -->
            <div *ngIf="invalid_product">
              <p class="title">{{ 'PRODUCT_CHANGE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'INVALID_PRODUCT_PRICE'">{{ error.product_name }}
                  : {{ firm?.currency.symbol }}{{ error.old_price | number:'1.2-2' }}
                  → {{ firm?.currency.symbol }}{{ error.new_price | number:'1.2-2' }}</p>
              </div>
            </div>
            <!-- Ingredient -->
            <div *ngIf="invalid_ingredient">
              <p class="title">{{ 'INGREDIENT_CHANGE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'INVALID_INGREDIENT_PRICE'">({{ error.product_name }}
                  ) {{ error.ingredient_name }}: {{ firm?.currency.symbol }}{{ error.old_price | number:'1.2-2' }}
                  → {{ firm?.currency.symbol }}{{ error.new_price | number:'1.2-2' }}</p>
              </div>
            </div>
            <!-- Option -->
            <div *ngIf="invalid_option">
              <p class="title">{{ 'OPTION_CHANGE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'INVALID_PRODUCT_OPTION_PRICE'">({{ error.product_name }}
                  ) {{ error.option_name }}: {{ firm?.currency.symbol }}{{ error.old_price | number:'1.2-2' }}
                  → {{ firm?.currency.symbol }}{{ error.new_price | number:'1.2-2' }}</p>
              </div>
            </div>
            <!-- Out of stock -->
            <div *ngIf="not_in_stock">
              <p class="title">{{ 'ITEM_NOT_IN_STOCK' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'NOT_IN_STOCK'">{{ error.product_name }}</p>
              </div>
            </div>
            <!-- Inactive product / product snoozed -->
            <div *ngIf="product_not_available">
              <p class="title">{{ 'PRODUCT_NOT_AVAILABLE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p
                  *ngIf="error.error === 'PRODUCT_NOT_FOUND' || error.error === 'PRODUCT_IS_SNOOZED'">{{ error.product_name }}</p>
              </div>
            </div>
            <!-- Ingredient snoozed -->
            <div *ngIf="ingredient_not_available">
              <p class="title">{{ 'INGREDIENT_NOT_AVAILABLE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'INGREDIENT_IS_SNOOZED'">({{ error.product_name }}
                  ) {{ error.ingredient_name }}</p>
              </div>
            </div>
            <!-- Category not available -->
            <div *ngIf="category_not_available">
              <p class="title">{{ 'CATEGORY_NOT_AVAILABLE' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'PRODUCT_CATEGORY_NOT_AVAILABLE'">{{ error.product_category_name }}</p>
                <div class="availability">
                  <p>{{ 'AVAILABLE_ON' | translate:{date: ''} }}:</p>
                  <p *ngFor="let day of error.valid_periods">
                    <span>{{ day.day_name }}</span> ({{ day.from_time }} - {{ day.to_time }})
                  </p>
                </div>
              </div>
            </div>
            <!-- General error message -->
            <div *ngIf="generalError">
              <p class="title">{{ 'DIALOGS.ERROR' | translate }}:</p>
              <div *ngFor="let error of errors">
                <p *ngIf="error.error === 'GENERAL_MESSAGE'">{{ error.message }}</p>
              </div>
            </div>
          </div>

          <div class="view-overview">
            <h1 class="title-font">{{ 'OVERVIEW.OVERVIEW_ORDER' | translate }}</h1>

            <!-- User details -->
            <div class="eo-well">
              <div class="flex width-full">
                <div class="width-50">
                  <div class="name" *ngIf="order?.user?.first_name || order?.user?.last_name">{{ order?.user?.first_name }} {{ order?.user.last_name }}</div>
                  <div class="phone" *ngIf="order?.user?.phone">{{ order?.user?.phone }}</div>
                  <div class="company" *ngIf="order?.user?.company">{{ order?.user?.company }}</div>
                  <div class="name" *ngIf="order?.customer_name">{{ order?.customer_name }}</div>
                  <div class="phone" *ngIf="order?.customer_phone">{{ order?.customer_phone }}</div>
                  <div class="company" *ngIf="order?.customer_company">{{ order?.customer_company }}</div>
                </div>

                <div class="width-50" *ngIf="invoicingIsPossible() && userHasBusinessDetails(user)" class="business-details">
                  <strong>{{ 'COMPANY_DETAILS' | translate }}</strong>
                  <div class="flex">
                    <p>{{ user.company }}</p>
                    <eo-vat-validation-badge [status]="user.vat_validation_status"></eo-vat-validation-badge>
                  </div>

                  <p *ngIf="user.invoice_reference">{{ user.invoice_reference }}</p>
                  <p>{{ user.vat_number }}</p>
                </div>
              </div>

              <div class="invoice-toggle-container flex align-items-center"
                   *ngIf="invoicingIsPossible()">
                <mat-slide-toggle
                  [(ngModel)]="order.customer_is_professional"
                  [disabled]="!userHasBusinessDetails(user) || user.vat_validation_status !== 'success'"
                  (change)="invoiceSegmentChanged($event.checked)"
                  name="customer_is_professional"
                  ngDefaultControl>
                </mat-slide-toggle>
                <div [class.disabled]="!userHasBusinessDetails(user) || user.vat_validation_status !== 'success'">
                  <p class="font-13">{{ 'ORDER_WITH_INVOICE' | translate }}</p>
                  <p class="font-9 line-15" *ngIf="!userHasBusinessDetails(user) || user.vat_validation_status !== 'success'">
                    ({{ 'ORDER_WITH_INVOICE_NOT_AVAILABLE' | translate }})
                  </p>
                </div>
              </div>
            </div>

            <!-- Invoice address -->
            <div class="eo-well"
              *ngIf="order?.customer_is_professional">
              <strong class="font-15">{{ 'ORDER_WITH_INVOICE' | translate }}</strong>

              <div class="flex align-items-center justify-space-between mt-20">
                <p class="font-13">{{ 'INVOICING_ADDRESS' | translate }}</p>
                <button class="btn-fill custom-btn flex align-items-center font-14"
                        *ngIf="selectedInvoiceAddress"
                        (click)="presentSelectInvoiceAddressModal()">
                  <div class="mr-5">
                    <i class="fas fa-pen"></i>
                  </div>
                  {{ 'MODIFY' | translate }}
                </button>
              </div>

              <div *ngIf="!selectedInvoiceAddress"
                   class="eo-card add-card"
                   (click)="presentSelectInvoiceAddressModal()">
                <div class="content">
                  <div class="add-icon">
                    <i class="fas fa-plus-circle add-icon"></i>
                  </div>
                  <strong>{{ 'ADD_ADDRESS' | translate }}</strong>
                </div>
              </div>

              <div *ngIf="selectedInvoiceAddress">
                <eo-address
                  [address]="selectedInvoiceAddress"
                  [displaySelected]="false"
                  (edit)="presentSelectInvoiceAddressModal()">
                </eo-address>
              </div>

              <div *ngIf="invoicingAddressHasErrors()" class="address-msg">
                <div>
                  <i class="fas fa-exclamation-triangle"></i>
                  <p>{{ 'ADDRESS_INCOMPLETE' | translate }}</p>
                </div>
              </div>

            </div>

            <!-- Delivery method + timestamp -->
            <div class="new-well" [style.background-color]="firm?.theme.container_color"
                 [style.color]="firm?.theme.container_color_contrast_color">
              <div *ngIf="order?.delivery_method === 'pickup'" class="delivery">
                <span class="icon pickup"></span>{{ 'OVERVIEW.PICKUP' | translate }} - {{ datetime }}
                <br>
                <span *ngIf="!order.firm_pickup_point_id">{{ firm?.address }}
                  , {{ firm?.zipcode }} {{ firm?.locality }}</span>
                <span *ngIf="order.firm_pickup_point_id">{{ pickup_point?.name }}</span>
                <br>
                <span *ngIf="order.firm_pickup_point_id">{{ pickup_point?.address }}
                  , {{ pickup_point?.zipcode }} {{ pickup_point?.locality }}</span>
              </div>
              <div *ngIf="order?.delivery_method === 'delivery'" class="delivery">
                <span class="icon delivery"></span>{{ 'OVERVIEW.DELIVERY' | translate }} - {{ datetime }}
                <br>
                <span>{{ order.delivery_street }} {{order.delivery_street_nr }}
                  , {{ order.delivery_zipcode }} {{ order.delivery_locality }}</span>
              </div>
              <div *ngIf="order?.delivery_method === 'table'" class="delivery">
                <span *ngIf="!order.custom_table_method_id"><i class="fas fa-utensils icon-table"></i>{{ firm?.labels?.table }}</span>
                <span *ngIf="order.custom_table_method_id"><i class="fas fa-utensils icon-table"></i>{{ order.custom_table_method_name }}</span>
                <span *ngIf="order.table_number">{{ order.table_number }}</span>
              </div>

              <a
                class="edit title-font"
                (click)="routeService.navigateToDeliveryMethod(own_domain, firm, language)">{{ 'BASKET.EDIT' | translate }}</a>
            </div>

            <!-- Payment method -->
            <div class="new-well" [style.background-color]="firm?.theme.container_color"
                 [style.color]="firm?.theme.container_color_contrast_color">
              <div *ngIf="order?.delivery_method === 'pickup' && order?.payment_method === 'cash'" class="delivery">
                <span class="icon cash"></span>
                <span *ngIf="!order?.firm_custom_delivery_pickup_payment_method_id">{{ firm?.labels?.cash_pickup }}</span>
                <span *ngIf="order?.firm_custom_delivery_pickup_payment_method_id">{{ order?.custom_payment_method?.name }}</span>
              </div>
              <div *ngIf="order?.delivery_method === 'delivery' && order?.payment_method === 'cash'" class="delivery">
                <span class="icon cash"></span>
                <span *ngIf="!order?.firm_custom_delivery_pickup_payment_method_id">{{ firm?.labels?.cash_delivery }}</span>
                <span *ngIf="order?.firm_custom_delivery_pickup_payment_method_id">{{ order?.custom_payment_method?.name }}</span>
              </div>
              <div *ngIf="order?.delivery_method === 'table' && order?.payment_method === 'cash'" class="delivery">
                <span class="icon cash"></span>{{ firm?.labels?.cash_table }}
              </div>
              <div *ngIf="order?.payment_method_id !== 1" class="delivery">
                <i class="far fa-credit-card card-icon"></i>
                <span>{{ 'ELECTRONIC_PAYMENT' | translate }}</span>
              </div>
            </div>

            <!-- Optional checkout extra's -->
            <div class="new-well extra"
                 [style.background-color]="firm?.theme.container_color"
                 [style.color]="firm?.theme.container_color_contrast_color"
                 *ngIf="firm?.checkout_options && firm?.checkout_options.length > 0">

                <div *ngFor="let option of firm.checkout_options">
                  <mat-checkbox disableRipple="true" (change)="checkExtraOption(option)" [checked]="option.selected">{{ option.name }} <span *ngIf="option.price > 0">({{ firm?.currency?.symbol }}{{ option.price }})</span></mat-checkbox>
                </div>
            </div>

            <!-- Loyalty -->
            <eo-loyalty-reward-box
              *ngIf="isLoyaltyActive()"
              [loyaltyType]="firm?.loyalty.loyalty_type"
              [discountType]="firm?.loyalty.discount_type"
              [discountValue]="firm?.loyalty.discount_value"
              [productList]="loyaltyProducts | async"
              [currency]="firm?.currency.symbol"
              (productChanged)="loyaltyProductChanged($event)">
            </eo-loyalty-reward-box>

            <!-- VAT order details -->
            <div class="new-well" [style.background-color]="firm?.theme.container_color"
                 [style.color]="firm?.theme.container_color_contrast_color"
                 *ngIf="firm?.uses_vat && firm?.show_vat_in_frontend">
              <ul class="products clearfix">
                <div *ngIf="basketItems?.length > 0">
                  <!-- Products -->
                  <li *ngFor="let item of basketItems" style="margin-top: 10px">
                    <div class="basket_amount"
                         *ngIf="item.item.item.unit.id !== units.KG">{{ item.item.amount }} {{ item.item.item.unit.name_short }}</div>
                    <div class="basket_amount"
                         *ngIf="item.item.item.unit.id === units.KG">{{ item.item.amount / 1000 }} {{ item.item.item.unit.name_short }}</div>
                    <div class="basket_product">{{ item.item.name }} </div>

                    <!-- VAT -->
                    <div class="VAT">
                      <div>
                        <p no-margin>{{ 'zBTW' | translate }}</p>
                        <p no-margin
                           class="color-grey">{{ firm?.currency.symbol }}{{ item.item.total_price - (item.item.total_price / 100 * order?.discount_percentage) | number:'1.2-2' }}</p>
                      </div>
                      <div>
                        <p no-margin>{{ 'VAT' | translate }} ({{ item.item.item.vat_percentage }}%)</p>
                        <p no-margin
                           class="color-grey">{{ firm?.currency.symbol }}{{ (item.item.total_price - (item.item.total_price / 100 * order?.discount_percentage)) / 100 * item.item.item.vat_percentage | number:'1.2-2' }}</p>
                      </div>
                      <div>
                        <p no-margin>{{ 'BTWi' | translate }}</p>
                        <p no-margin
                           class="color-grey">{{ firm?.currency.symbol }}{{ item.item.total_price - (item.item.total_price / 100 * order?.discount_percentage) + (item.item.total_price - (item.item.total_price / 100 * order?.discount_percentage)) / 100 * item.item.item.vat_percentage | number:'1.2-2' }}</p>
                      </div>
                    </div>
                  </li>
                </div>
                <div *ngIf="basketItems?.length === 0">
                  <p>{{ 'BASKET.EMPTY_MESSAGE' | translate }}</p>
                </div>
              </ul>

              <div class="prices">
                <!-- Subtotal (excl VAT) -->
                <div class="price">
                  {{ 'CHECKOUT_SIDEBAR.SUBTOTAL' | translate }} <span>(incl. BTW)</span>
                  <strong class="float-right">{{ firm?.currency.symbol }}{{ sub_total | number:'1.2-2' }}</strong>
                </div>

                <!-- Delivery cost / free delivery -->
                <div *ngIf="order?.delivery_price" class="price">
                  {{ 'CHECKOUT_SIDEBAR.DELIVERY' | translate }}
                  <strong
                    class="float-right">{{ firm?.currency.symbol }}{{ order.delivery_price | number:'1.2-2' }}</strong>
                </div>
                <div *ngIf="order?.delivery_price === 0" class="price">
                  {{ 'CHECKOUT_SIDEBAR.FREE_DELIVERY' | translate }}
                </div>

                <div *ngIf="firm?.service_fee?.length > 0">
                  <hr>
                  <div *ngFor="let service_fee of firm?.service_fee" class="price">
                    <p *ngIf="service_fee.active &&
                                                ((service_fee.online_only && order?.payment_method_id && order?.payment_method_id != 1) || !service_fee.online_only) &&
                                                ((service_fee.free_from && sub_total < service_fee.free_from_amount) || !service_fee.free_from) &&
                                                ((service_fee.firm_pickup_point_id === order?.firm_pickup_point_id) || !service_fee.firm_pickup_point_id)">
                      {{ service_fee?.name?.toLowerCase() }}
                      <span
                        *ngIf="service_fee.fee_choice === serviceFee.FEE">({{ firm?.currency.symbol }}{{ service_fee.fee }}
                        )</span>
                      <span
                        *ngIf="service_fee.fee_choice === serviceFee.PERCENTAGE">({{ firm?.currency.symbol }}{{ (sub_total / 100) * service_fee.percentage | number:'1.2-2' }}
                        )</span>
                    </p>
                  </div>
                </div>

                <hr>

                <!-- Total (incl VAT) -->
                <div class="total price">
                  {{ 'CHECKOUT_SIDEBAR.TOTAL' | translate }}
                  <strong
                    class="float-right">{{ firm?.currency.symbol }}{{ sub_total + order?.delivery_price - order?.coupon?.value + service_fee_price | number:'1.2-2' }}</strong>
                </div>
              </div>
            </div>

            <!-- Coupon -->
            <div class="new-well coupon" [style.background-color]="firm?.theme.container_color" [style.color]="firm?.theme.container_color_contrast_color" *ngIf="firm?.uses_coupons">
              <article>
                <p>{{ 'PROMOTION_CODE' | translate }}</p>
                <div *ngIf="enterCoupon">
                  <input name="coupon" type="text" placeholder="bv. EO2019" [(ngModel)]="coupon">
                </div>
              </article>

              <p *ngIf="couponInvalid" class="coupon-err">{{ 'PROMOTION_CODE_NOT_VALID' | translate }}</p>
              <p *ngIf="couponAlreadyUsed" class="coupon-err">{{ 'COUPON_CODE_ALREADY_USED' | translate }}</p>
              <p *ngIf="couponTooLow" class="coupon-err">{{ couponTooLowMsg }}</p>

              <div *ngIf="!enterCoupon">

                <!-- Percentage | amount | free product -->
                <p *ngIf="order?.coupon?.type === 'percentage' || order?.coupon?.type === 'amount' || order?.coupon?.type === 'product'">
                  <span *ngIf="order?.coupon?.type === 'percentage'">{{ order?.coupon?.code }} (- {{ order?.coupon?.value }}%)</span>
                  <span *ngIf="order?.coupon?.type === 'amount'">{{ order?.coupon?.code }} (- {{ firm?.currency.symbol }}{{ order?.coupon?.value | number:'1.2-2' }})</span>
                  <span *ngIf="order?.coupon?.type === 'product'">{{ 'FREE_PRODUCT' | translate }}: {{ order?.coupon?.free_product?.name}} {{ 'VALUE_OF' | translate }} {{ firm?.currency.symbol }}{{ order?.coupon?.free_product?.price | number:'1.2-2' }}.</span>
                  <a class="coupon-close" (click)="removeCouponFromOrder()"><i class="far fa-times-circle"></i></a>
                </p>

                <!-- Choose free product out of category -->
                <div *ngIf="order?.coupon.type === 'category'">

                    <p class="discount-label">
                        <i class="fas fa-gift gift-icon"></i>{{ 'FREE_PRODUCT' | translate }}:
                        <a class="coupon-close" (click)="removeCouponFromOrder()"><i class="far fa-times-circle"></i></a>
                    </p>
                    <p>{{ selected_coupon_product?.name }} {{ 'VALUE_OF' | translate }} {{ firm?.currency.symbol }}{{ selected_coupon_product?.price | number:'1.2-2' }}</p>

                    <div *ngIf="order?.coupon?.free_products?.length > 1">
                        <div class="product-select">
                            <p>{{ 'CHOOSE_FREE_PRODUCT' | translate }}:</p>

                            <mat-radio-group [(ngModel)]="selected_coupon_product" name="selected_coupon_product" required>
                                <mat-radio-button
                                    *ngFor="let product of order?.coupon?.free_products"
                                    (change)="setSelectedCouponProduct($event.value); saveCouponToOrder()"
                                    class="pickup_radio width-full"
                                    [checked]="selected_coupon_product.id === product.id"
                                    [value]="product"
                                    ngDefaultControl>
                                    {{ product.name }}
                                </mat-radio-button>
                            </mat-radio-group>

                        </div>
                    </div>
                </div>

              </div>

              <button class="btn-fill custom-btn" [disabled]="!coupon" (click)="checkCoupon()">{{ 'CHECK' | translate }}</button>
            </div>

            <!-- General note -->
            <div class="remarks">
              <div class="input">
                <div class="inputfield">
                  <textarea [(ngModel)]="comment" placeholder="{{ 'MODAL.MESSAGE' | translate }}" rows="1" name="remarks" cols="50"></textarea>
                </div>
              </div>
            </div>

            <!-- Buttons -->
            <div class="buttons">
              <button class="btn-fill custom-btn" [disabled]="disableOrderButton()" (click)="placeOrder()">
                {{ 'OVERVIEW.PLACE_ORDER' | translate }}
                <span class="arrow"></span>
              </button>
            </div>

            <!-- Errors -->
            <div *ngIf="errMessage" class="formerrors">{{ errMessage }}</div>
          </div>

        </div>

        <!-- Sidebar: order -->
        <div class="col third col-sm no-half">
          <app-order-side-bar *ngIf="firm && order" [firm]="firm" [order]="order" [basketItems]="basketItems"
                              [total_price]="total_price" [coupon]="order?.coupon" [extraOptions]="extraOptions"
                              (totalPriceOutput)="totalPriceOutput($event)"
                              (isFreeOutput)="isFreeOutput($event)"></app-order-side-bar>
        </div>

      </div>
    </div>
  </div>
</div>
</body>
