<head>
  <title>{{ firm?.name }} | {{ 'BOOK_TABLE' | translate }}</title>
</head>
<body>
  <div class="main" style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.main_gradient_contrast_color">

    <!-- Header -->
    <app-header *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
    <app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

    <div class="centered width-50">

      <!-- Content -->
      <div class="pagecontent">
        <!-- Title -->
        <div class="title">
          <h1 class="title-font">{{ 'BOOK_TABLE' | translate }}</h1>
        </div>
        <app-reservation-tool *ngIf="firm" [firm]="firm" [own_domain]="own_domain"></app-reservation-tool>
      </div>
    </div>

    <!-- Footer -->
    <app-footer *ngIf="!firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-footer>

  </div>
</body>
