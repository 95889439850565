<div class="verifying-badge" *ngIf="statusIsValidating()">
  {{ 'VERIFYING' | translate }}...
</div>

<div class="success-badge" *ngIf="statusHasSucceeded()">
  <i class="far fa-check-circle"></i>
  <p>{{ 'VERIFIED' | translate }}</p>
</div>

<div class="failed-badge" *ngIf="statusHasFailed()">
  <i class="far fa-times-circle"></i>
  <p>{{ 'FAILED' | translate }}</p>
</div>
