<head>
  <title>{{ firm?.name }} | {{ 'CHECKOUT_HEADER.PAYMENT_METHOD' | translate }}</title>
</head>
<body
  style.background="linear-gradient(to bottom, {{ firm?.theme.main_gradient_color_from }}, {{ firm?.theme.main_gradient_color_to }})"
  [style.color]="firm?.theme.container_contrast_color"
>

<app-header *ngIf="firmService.checkEmbed() == false" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-header>
<app-embedded-header *ngIf="firmService.checkEmbed()" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-embedded-header>

<div class="main checkout">
  <div class="centered">

    <!-- Header -->
    <app-order-header [focused]="'payment'" [firm]="firm" [own_domain]="own_domain" [language]="language"></app-order-header>

    <!-- Content -->
    <div class="pagecontent">
      <div class="row">

        <!-- Payment methods -->
        <div [className]="!firm?.uses_vat || (firm?.uses_vat && !firm?.show_vat_in_frontend) ? 'col third col-sm no-half colspan2' : 'col full'">
          <form #formPayment="ngForm">

            <div class="view-deliverymethod">
              <h1 class="title-font">{{ 'PAYMENT.METHOD' | translate }}</h1>
              <div class="">

                <p *ngIf="payment_methods?.length === 0 && ((order?.delivery_method === 'pickup' && (!firm?.custom_pickup_payment_methods || firm?.custom_pickup_payment_methods?.length === 0)) || (order?.delivery_method === 'delivery' && (!firm?.custom_delivery_payment_methods || firm?.custom_delivery_payment_methods?.length === 0)))">{{ 'MESSAGES.NO_PAYMENT_METHODS' | translate }}</p>

                <!-- Normal payment methods -->
                <ul *ngIf="payment_methods?.length > 0" class="optionlist" id="delivery-options">

                  <li *ngFor="let method of payment_methods" class="outlined">
                    <!-- Electronic -->
                    <mat-radio-button *ngIf="method.payment_method_id !== 1" disableRipple="true"
                                      [checked]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"
                                      [class.selected]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"
                                      (click)="choosePaymentMethod(method.payment_method_id, false)"
                                      [style.background-color]="firm?.theme.container_color"
                                      [style.color]="firm?.theme.container_color_contrast_color">
                      <div class="top-container">
                        <div>
                          <i class="far fa-credit-card card-icon"></i>
                          <span>{{ 'ELECTRONIC_PAYMENT' | translate }}</span>
                        </div>
                        <mat-radio-button class="child-radio" labelPosition="before" [checked]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"></mat-radio-button>
                      </div>
                    </mat-radio-button>

                    <!-- Cash -->
                    <mat-radio-button *ngIf="method.payment_method === 'cash'"
                                      disableRipple="true"
                                      (click)="choosePaymentMethod(method.payment_method_id, false)"
                                      [checked]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"
                                      [class.selected]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"
                                      [style.background-color]="firm?.theme.container_color"
                                      [style.color]="firm?.theme.container_color_contrast_color">
                      <div class="top-container">
                        <div>
                          <span class="icon cash"></span>
                          <span *ngIf="order.delivery_method === 'pickup'">{{ firm?.labels?.cash_pickup }}</span>
                          <span *ngIf="order.delivery_method === 'delivery'">{{ firm?.labels?.cash_delivery }}</span>
                          <span *ngIf="order.delivery_method === 'table'">{{ firm?.labels?.cash_table }}</span>
                        </div>
                        <mat-radio-button class="child-radio" labelPosition="before" [checked]="!order.firm_custom_delivery_pickup_payment_method_id && order.payment_method_id === method.payment_method_id"></mat-radio-button>
                      </div>
                    </mat-radio-button>
                  </li>

                </ul>

                <!-- Custom payment methods - pickup -->
                <ul
                  *ngIf="order?.delivery_method === 'pickup' && firm?.custom_pickup_payment_methods && firm?.custom_pickup_payment_methods?.length > 0"
                  class="optionlist" id="delivery-options" style="margin-top: .5rem;">
                  <li *ngFor="let method of firm?.custom_pickup_payment_methods" class="outlined">
                    <div class="checker">
                      <mat-radio-button disableRipple="true" (click)="choosePaymentMethod(method, true)"
                                        [class.selected]="order.firm_custom_delivery_pickup_payment_method_id === method.id"
                                        [checked]="order.firm_custom_delivery_pickup_payment_method_id === method.id"
                                        [style.background-color]="firm?.theme.container_color"
                                        [style.color]="firm?.theme.container_color_contrast_color">
                        <div class="top-container">
                          <div>
                            <span class="icon cash"></span>
                            <span>{{ method.name }}</span>
                          </div>
                          <mat-radio-button class="child-radio" labelPosition="before" [checked]="order.firm_custom_delivery_pickup_payment_method_id === method.id"></mat-radio-button>
                        </div>
                      </mat-radio-button>
                    </div>
                  </li>
                </ul>

                <!-- Custom payment methods - delivery -->
                <ul *ngIf="order?.delivery_method === 'delivery' && firm?.custom_delivery_payment_methods && firm?.custom_delivery_payment_methods?.length > 0"
                  class="optionlist" id="delivery-options">
                  <li *ngFor="let method of firm?.custom_delivery_payment_methods" class="outlined" >
                    <div class="checker">
                      <mat-radio-button disableRipple="true" (click)="choosePaymentMethod(method, true)"
                                        [checked]="order.firm_custom_delivery_pickup_payment_method_id === method.id"
                                        [class.selected]="order.firm_custom_delivery_pickup_payment_method_id === method.id"
                                        [style.background-color]="firm?.theme.container_color"
                                        [style.color]="firm?.theme.container_color_contrast_color">
                        <div class="top-container">
                          <div>
                            <span class="icon cash"></span>
                            <span>{{ method.name }}</span>
                          </div>
                          <mat-radio-button class="child-radio" labelPosition="before" [checked]="order.firm_custom_delivery_pickup_payment_method_id === method.id"></mat-radio-button>
                        </div>
                      </mat-radio-button>
                    </div>
                  </li>
                </ul>

                <!-- Buttons -->
                <div class="buttons">
                  <button class="btn-fill custom-btn" (click)="goToOverview()"
                          [disabled]="!formPayment.valid || payment_methods?.length == 0 || !this.order?.payment_method">{{ 'BUTTONS.NEXT' | translate }}
                    <span class="arrow"></span></button>
                </div>

              </div>
            </div>

          </form>
        </div>

        <!-- Sidebar: order -->
        <div class="col third col-sm no-half">
          <app-order-side-bar *ngIf="firm && order" [firm]="firm" [order]="order"></app-order-side-bar>
        </div>

      </div>
    </div>
  </div>
</div>
</body>
