<!-- Multiple images -->
<div>

    <div class="outlinedimage slideshow">
        <ul class="slides clear">
        <li class="slide">
            <img [src]="imageUrl" alt="">
        </li>
        </ul>
    </div>

    <div class="multiple-img-btns">
        <div class="left" (click)="previousImage()">
        <i class="fas fa-chevron-left icon"></i>
        </div>

        <div class="right" (click)="nextImage()">
        <i class="fas fa-chevron-right icon"></i>
        </div>
    </div>

</div>