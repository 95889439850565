<eo-modal
  [header]="header"
  [subHeader]="subHeader"
  [buttonLabel]="buttonLabel"
  [disableButton]="!detailsForm.valid"
  (submitted)="saveHandler()">

  <form #detailsForm="ngForm" novalidate>
    <input
      [(ngModel)]="formDetails.company"
      [placeholder]="'COMPANY_NAME' | translate"
      class="eo-input"
      name="company"
      type="text"
      required>

    <input
      [(ngModel)]="formDetails.invoice_reference"
      [placeholder]="'REFERENCE_OPTIONAL' | translate"
      class="eo-input"
      name="invoice_reference"
      type="text">

    <input
      [(ngModel)]="formDetails.vat_number"
      [placeholder]="'VAT_NUMBER' | translate"
      class="eo-input"
      name="vat_number"
      type="text"
      required>
  </form>
</eo-modal>
