<div id="popup">
  <div class="content">
    <div class="wrap">

      <div class="error-title align-center color-lightgray" *ngIf="title != null">
        <div>{{ title }}</div>
        <a class="close"></a>
      </div>

      <div class="align-center" mat-dialog-content>

        <p class="error-message">{{ message }}</p>

        <div mat-dialog-actions *ngIf="buttons">
          <button class="width-full" mat-button mat-dialog-close>{{ 'BUTTONS.OK' | translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>