<div id="popup">
  <div class="content">
    <div class="wrap">

      <div class="dialog-title font-20">
        <div>{{ 'RECENT_ORDERS.DELIVERY_METHOD' | translate }}</div>
        <a class="close"></a>
      </div>

      <div class="popupbody align-center">

        <p text-center>{{ 'SET_DELIVERY_METHOD_TO_CONTINUE' | translate }}:</p>

        <mat-radio-group name="vat_delivery_method" [(ngModel)]="selectedMethod" class="delivery_methods">

          <mat-radio-button *ngIf="pickup" [checked]="selectedMethod == 'pickup'" value="pickup" ngDefaultControl><span class="icon pickup"></span>{{ pickup_label }}</mat-radio-button>
          <mat-radio-button *ngIf="delivery" [checked]="selectedMethod == 'delivery'" value="delivery" ngDefaultControl><span class="icon delivery"></span>{{ 'CHECKOUT.DELIVERY' | translate }}</mat-radio-button>
          <mat-radio-button *ngIf="table" [checked]="selectedMethod == 'table'" value="table" ngDefaultControl><span class="icon table"></span>{{ 'CHECKOUT.TABLE' | translate }}</mat-radio-button>

        </mat-radio-group>

        <div mat-dialog-actions class="dialog-buttons">
          <button class="width-40 margin-auto background-white" mat-button mat-dialog-close>{{ 'BUTTONS.CANCEL' | translate }}</button>
          <button class="width-40 margin-auto background-white" mat-button [mat-dialog-close]="selectedMethod" (click)="save()" [disabled]="!selectedMethod">{{ 'BUTTONS.OK' | translate }}</button>
        </div>

      </div>
    </div>
  </div>
</div>