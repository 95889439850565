import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DOMService {

  favIcon: HTMLLinkElement = document.querySelector("#appIcon");

  updateFavicon(icon) {
    this.favIcon.href = icon;
  }
  
}
