import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FirmService } from '../services/firm.service';
import { AuthService } from '../services/auth.service';
import { SlugifyService } from '../services/slugify.service';
import { LoaderService } from '../services/loader.service';
import { RouteService } from '../services/route.service';
import { LanguageService } from '../services/language.service';

@Component({
  selector: 'app-firms',
  templateUrl: './firms.component.html',
  styleUrls: ['./firms.component.css'],
})
export class FirmsComponent implements OnInit {
  token: any;
  firms: any = [];
  distributor: any;
  public onlineOffline: boolean = navigator.onLine;

  selfIcon: any = {
    url: '../assets/img/blue-dot.png',
    scaledSize: { width: 30, height: 30 },
  };

  markerIcon: any = {
    scaledSize: { width: 40, height: 40 },
  };

  enableMap: boolean;
  lat: number;
  long: number;

  constructor(
    public auth: AuthService,
    public router: Router,
    private languageService: LanguageService,
    public routeService: RouteService,
    public firmService: FirmService,
    public slug: SlugifyService,
    public loader: LoaderService
  ) {
    window.scrollTo(0, 0);
  }

  async ngOnInit() {
    await this.findMe();

    const lang = await this.languageService.getBrowserLang();
    this.languageService.setLanguage(lang);

    this.loader.present();
    const response = await this.firmService.getDistributorFirms();
    this.firms = response.firms;
    this.distributor = response.distributor;

    if (this.distributor) {
      this.markerIcon.url = this.distributor.map_marker;

      const isTest = location.hostname == this.distributor.webshop_domain_test;
      if (isTest == true) {
        this.distributor.main_color = '#6fab3f';
      }
    }

    this.loader.dismiss();
  }

  findMe() {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          this.lat = position.coords.latitude;
          this.long = position.coords.longitude;
          this.enableMap = true;
        },
        (error) => {
          this.lat = 50.85045;
          this.long = 4.34878;
          this.enableMap = true;
        }
      );
    } else {
      this.lat = 50.85045;
      this.long = 4.34878;
      this.enableMap = true;
    }
  }

  convertToNumber(string) {
    return Number(string);
  }

  async checkFirm(selected_firm, firm_name) {
    const firm: any = await this.firmService.getFirm(this.slug.slugify(firm_name), 'nl');

    if (this.firmService.checkForPassword(selected_firm)) {
      if (firm.is_store == true) {
        this.router.navigate([firm_name]);
      } else {
        this.routeService.navigateToFirm(firm, firm_name);
      }
    }
  }
}
