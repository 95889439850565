import { Component, Input } from '@angular/core';
import { FirmService } from '../services/firm.service';

@Component({
  selector: 'app-delivery-hours',
  templateUrl: './delivery-hours.component.html',
  styleUrls: ['./delivery-hours.component.css']
})
export class DeliveryHoursComponent {

  @Input() firm;
  delivery_hours: any;

  constructor(private firmService: FirmService) {}

  async ngOnInit() {
    if(this.firm.periods.delivery_hours) {
      this.delivery_hours = await this.firmService.setHours(this.firm.periods.delivery_hours);
    }
  }

}
