import { Component, Input, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { BusinessDetails } from '../../core/models/BusinessDetails';

@Component({
  selector: 'app-business-details-modal',
  templateUrl: './business-details-modal.component.html',
  styleUrls: ['./business-details-modal.component.scss'],
})
export class BusinessDetailsModalComponent implements OnInit {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() buttonLabel: string;
  @Input() businessDetails: BusinessDetails;

  formDetails: any;

  constructor(private dialogRef: MatDialogRef<BusinessDetailsModalComponent>) {}

  ngOnInit() {
    this.setForm();
  }

  setForm() {
    this.formDetails = { ...this.businessDetails };
  }

  saveHandler() {
    this.dismiss({
      cancelled: false,
      businessDetails: { ...this.formDetails },
    });
  }

  dismiss(data) {
    this.dialogRef.close({ data });
  }
}
