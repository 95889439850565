<div *ngIf="firm?.periods.delivery_hours && !firm?.has_delivery_custom_days" class="col half">
  <p style="margin-bottom: 10px">{{ 'CHECKOUT.DELIVERY_HOURS' | translate }}</p>

  <ul class="hours">
      <li class="row" *ngFor="let day of delivery_hours">
          <div class="day">{{day.day}}</div>

          <!-- Catch bad formatted hours -->
          <div *ngIf="day.am && !day.pm && day.am.type == 'open'" class="hour">{{day.am.from_time}} - {{day.am.to_time}}</div>
          <div *ngIf="day.am && !day.pm && day.am.type == 'closed'" class="hour">{{ 'INFO.CLOSED' | translate }}</div>
          <div *ngIf="!day.am && day.pm && day.pm.type == 'open'" class="hour">{{day.pm.from_time}} - {{day.pm.to_time}}</div>
          <div *ngIf="!day.am && day.pm && day.pm.type == 'closed'" class="hour">{{ 'INFO.CLOSED' | translate }}</div>

          <!-- Show normal hours -->
          <div *ngIf="(day.am && day.pm) && (day.am.type == 'open' && day.pm.type == 'closed')" class="hour">{{day.am.from_time}} - {{day.am.to_time}}</div>
          <div *ngIf="(day.am && day.pm) && (day.am.type == 'closed' && day.pm.type == 'open')" class="hour">{{day.pm.from_time}} - {{day.pm.to_time}}</div>
          <div *ngIf="(day.am && day.pm) && (day.am.type == 'open' && day.pm.type == 'open')" class="hour">{{day.am.from_time}} - {{day.am.to_time}} & {{day.pm.from_time}} - {{day.pm.to_time}}</div>
          <div *ngIf="(day.am && day.pm) && (day.am.type == 'closed' && day.pm.type == 'closed')" class="hour closed">{{ 'INFO.CLOSED' | translate }}</div>
      </li>
  </ul>
</div>