<div id="popup">
  <div class="content">
    <div class="wrap">

      <div class="dialog-title font-20">
        <div>{{ product.item.name }}</div>
        <a class="close"></a>
      </div>

      <div class="popupbody">
        <app-order-item
          *ngIf="basketItem && firm"
          [parent]="'basket'"
          [firm]="firm"
          [language]="language"
          [sequenceGroup]="groups_by_sequence"
          [basketItem]="basketItem"
          (itemChangedOutput)="itemChanged($event)">
        </app-order-item>

        <div mat-dialog-actions class="dialog-buttons">
          <button class="width-40 margin-auto background-white" mat-button mat-dialog-close>{{ 'BUTTONS.CANCEL' | translate }}</button>
          <button
            class="width-40 margin-auto background-white"
            mat-button
            mat-dialog-close
            (click)="addToBasket()">
            {{ 'BUTTONS.OK' | translate }}
          </button>
        </div>
      </div>
    </div>
  </div>
</div>