import { Component, Input } from '@angular/core';

@Component({
  selector: 'eo-vat-validation-badge',
  templateUrl: './vat-validation-badge.component.html',
  styleUrls: ['./vat-validation-badge.component.scss'],
})
export class VatValidationBadgeComponent {
  @Input() status: string;

  statusIsValidating() {
    return this.status === 'validating';
  }

  statusHasSucceeded() {
    return this.status === 'success';
  }

  statusHasFailed() {
    return this.status === 'failed';
  }
}
